import styled from '@emotion/styled';
import React, { useEffect } from 'react';

import { ChildData } from '~/types/push/find';

import { getPushFindStatusData } from '~/api/push/find';

interface Props {
  isPushId: number | undefined;
  childInfo: ChildData[] | undefined;
  setActiveComponent: React.Dispatch<React.SetStateAction<string>>;
}

export default function RecipientList({
  isPushId,
  childInfo,
  setActiveComponent,
}: Props) {
  useEffect(() => {
    getPushFindStatusData({ pushId: isPushId }).catch(console.error);
  }, []);

  const handleTogleComponent = () => {
    setActiveComponent('');
  };

  return (
    <>
      <Wrapper></Wrapper>
      <Container>
        <Title>수신자리스트</Title>
        <InfoTitleWrapper>
          <div>ID</div>
          <div>이름</div>
          <div>학급정보</div>
        </InfoTitleWrapper>
        <ListWrapper>
          {childInfo?.map((item) => (
            <ListItems
              key={item.childId}
              color={item.sendYn === 'Y' ? '#000' : '#65696D'}
              bgColor={item.sendYn === 'Y' ? '#fff' : '#f1f2f4'}
            >
              <div>{item.loginId}</div>
              <div>{item.name}</div>
              <div>
                {item.grade}학년 {item.clazz}반 {item.number}번
              </div>
            </ListItems>
          ))}
        </ListWrapper>
        <ButtonWrapper onClick={handleTogleComponent}>확인</ButtonWrapper>
      </Container>
    </>
  );
}

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
`;

const Container = styled.div`
  position: absolute;
  width: 520px;
  height: 600px;
  padding: 60px 40px 40px 40px;
  flex-direction: column;
  align-items: center;
  border-radius: 16px;
  background: #fff;
  z-index: 3;
`;

const Title = styled.div`
  margin-bottom: 34px;
  color: #000;
  font-size: 24px;

  font-weight: 700;
  line-height: 1.6em;
`;

const InfoTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 519px;
  height: 56px;
  border-radius: 10px 10px 0px 0px;
  background: #fafafa;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    :nth-of-type(1) {
      width: 173px;
    }
    :nth-of-type(2) {
      width: 173px;
    }
    :nth-of-type(3) {
      width: 173px;
    }
  }
`;

const ListWrapper = styled.div`
  height: 386px;
  overflow-y: scroll;
  position: absolute;

  ::-webkit-scrollbar {
    width: 12px;
  }

  ::-webkit-scrollbar-thumb {
    height: 75%;
    border-radius: 100px;
    background: #e2e2ea;
    background-clip: padding-box;
    border-radius: 50px;
  }
`;

const ListItems = styled.div<{ bgColor: string }>`
  display: flex;
  width: 519px;
  height: 63px;
  border-bottom: 1px solid #f1f2f4;
  background-color: ${(props) => props.bgColor};
  color: ${(props) => props.color};

  font-size: 14px;

  font-weight: 400;
  div {
    display: flex;
    align-items: center;
    justify-content: center;
    :nth-of-type(1) {
      width: 173px;
    }
    :nth-of-type(2) {
      width: 173px;
    }
    :nth-of-type(3) {
      width: 173px;
    }
  }
`;

const ButtonWrapper = styled.button`
  margin-top: 416px;
  margin-left: 160px;
  width: 200px;
  height: 56px;
  border-radius: 10px;
  color: #fff;
  background: #0062ff;
`;
