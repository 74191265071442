import { AxiosResponse } from 'axios';

import {
  ResponseStudentsRelationRequestParams,
  ResponseStudentsRelationResponse,
  ResponseStudentsRequestParams,
  ResponseStudentsResponse,
} from '~/types/student/response';

import api from '~/api/base';

const BASE_URL = 'api/v1/student';

export const getResponseStudentsStatusData = (
  param: ResponseStudentsRequestParams,
): Promise<AxiosResponse<ResponseStudentsResponse>> =>
  api.post(`${BASE_URL}/response/students`, param);

export const getResponseStudentsRelationStatusData = (
  param: ResponseStudentsRelationRequestParams,
): Promise<AxiosResponse<ResponseStudentsRelationResponse>> =>
  api.post(`${BASE_URL}/accept/relation`, param);
