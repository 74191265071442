import styled from '@emotion/styled';

import { ReactComponent as JoyCog140 } from '~/assets/JoyCog140.svg';

interface Props {
  handleNextPageButton(): void;
}

export default function SignupSuccess({ handleNextPageButton }: Props) {
  return (
    <>
      <Wrapper>
        <JoyCog140 style={{ marginTop: '58px', marginBottom: '26px' }} />
        <p>조이코그 관리자 계정에 오신것을 환영합니다.</p>
        <SuccessImg />
        <button onClick={handleNextPageButton}>로그인</button>
      </Wrapper>
      <Reserved>© 2023 eMotiv. All rights reserved.</Reserved>
    </>
  );
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 720px;
  height: 800px;
  border-radius: 40px;
  background: #fff;

  /* shadow */
  box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.08);

  & > p {
    margin-bottom: 76px;

    color: #000;
    text-align: center;

    font-size: 32px;

    font-weight: 700;
    line-height: 180%; /* 57.6px */
    text-transform: capitalize;
  }

  & > button {
    width: 480px;
    height: 56px;
    border-radius: 10px;
    background: #2c55fb;

    color: #fff;
    text-align: center;

    /* body/B02-18px-bold */

    font-size: 18px;

    font-weight: 700;
    line-height: 1.3em; /* 23.4px */
  }
`;

const SuccessImg = styled.div`
  width: 350px;
  height: 350px;
  margin-bottom: 76px;
  background: url('/images/welcome.png') no-repeat center;
  background-size: cover;
`;

const Reserved = styled.div`
  margin-top: 30px;
  color: #65696d;
  text-align: center;

  /* body/B04-14px-medium */

  font-size: 14px;

  font-weight: 500;
  line-height: 1.6em; /* 22.4px */
`;
