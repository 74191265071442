import styled from '@emotion/styled';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import Modal from '~/utils/portal/components/Modal';

import { getCheckPasswordStatusData } from '~/api/manager/check';

import useAuth from '~/hooks/useAuth';

export default function CheckProfilePage() {
  const navigate = useNavigate();
  const { user, loginId } = useAuth();

  const [password, setPassword] = useState('');
  const [isModal, setIsModal] = useState(false);
  const [showPw, setShowPw] = useState(false);

  const handlePasswordCheck = () => {
    getCheckPasswordStatusData({
      managerId: Number(user?.managerId),
      password,
    })
      .then(({ data }) => {
        if (
          data.status === 20002 ||
          data.status === 19100 ||
          data.status === 20008 ||
          data.status === 20003 ||
          data.data.isValid === false
        ) {
          setIsModal(true);
        } else {
          navigate('../change-password');
        }
      })
      .catch(console.error);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handlePasswordCheck();
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setPassword(value);
  };

  const handleShowPw = () => {
    setShowPw((prev) => !prev);
  };

  const handleLoginPassworddDelete = () => {
    setPassword('');
  };

  return (
    <Wrapper>
      <Container>
        <div>
          <h1>비밀번호 재확인</h1>
          <div>
            회원님의 정보를 안전하게 보호하기 위해 비밀번호를
            <br />
            다시 한번 확인해주세요.
          </div>
        </div>
        <div>
          <h2>아이디</h2>
          <IdContainer>
            <div>{loginId}</div>
          </IdContainer>
        </div>
        <div>
          <h2>비밀번호</h2>
          <InputContainer>
            <PasswordInput
              type={showPw ? 'text' : 'password'}
              onChange={handleInputChange}
              value={password}
              onKeyDown={handleKeyPress}
            />
            {password.length > 0 && (
              <DeleteButton onClick={handleLoginPassworddDelete} right="52px" />
            )}
            {showPw ? (
              <ShowPassword onClick={handleShowPw} />
            ) : (
              <NonShowPassword onClick={handleShowPw} />
            )}
          </InputContainer>
        </div>
        <ButtonContainer>
          <Link to="/home/dashboard">
            <Button bgColor="#fff" color="#2c55fb">
              취소
            </Button>
          </Link>
          <Button bgColor="#2c55fb" color="#fff" onClick={handlePasswordCheck}>
            확인
          </Button>
        </ButtonContainer>
      </Container>
      {isModal && (
        <Modal
          title="비밀번호가 일치하지 않습니다."
          onClose={() => setIsModal(false)}
        ></Modal>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  height: calc(100vh - 81px);
  background: #fafafa;
  @media (max-height: 1080px) {
    min-height: 999px;
  }
  @media (max-width: 1651px) {
    width: 1652px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 416px;
  height: 381px;
  margin-top: 30px;
  margin-left: 586px;
  padding: 32px;
  gap: 30px;
  border-radius: 12px;
  background: #fff;

  /* shadow */
  box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.08);

  h1 {
    margin-bottom: 12px;
    color: #000;

    /* heading/H06-18px-bold */

    font-size: 18px;

    font-weight: 700;
    line-height: 1.6em; /* 28.8px */
  }

  div {
    color: #111827;

    /* body/B03-16px-medium */

    font-size: 16px;

    font-weight: 500;
    line-height: 1.3em; /* 20.8px */
  }

  h2 {
    margin-bottom: 10px;
    color: #000;

    font-size: 14px;

    font-weight: 500;
    line-height: 100%; /* 14px */
  }
`;

const IdContainer = styled.div`
  display: flex;
  width: 376px;
  height: 26px;
  padding: 13px 20px;
  align-items: center;
  border-radius: 10px;
  background: #f8f9fa;
  & > div {
    color: #dedcdc;

    font-size: 16px;

    font-weight: 400;
    line-height: 1.6em; /* 25.6px */
  }
`;

const PasswordInput = styled.input`
  color: #000;

  font-size: 16px;

  font-weight: 400;
  line-height: 1.6em; /* 25.6px */
  width: 370px;
  height: 24px;
  padding: 13px 22px;
  border-radius: 10px;
  border: 1px solid #e2e2ea;
  :focus {
    border-radius: 10px;
    border: 1px solid #3467ff;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
`;

const Button = styled.button<{ bgColor: string }>`
  width: 200px;
  height: 56px;
  border-radius: 10px;
  border: 1px solid #2c55fb;
  color: ${(props) => props.color};
  background-color: ${(props) => props.bgColor};

  font-size: 16px;

  font-weight: 700;
  line-height: 1.3em;

  :nth-of-type(1) {
    margin-right: 12px;
  }
`;

const InputContainer = styled.div`
  position: relative;
`;

const DeleteButton = styled.div<{ right: string }>`
  top: 16px;
  right: ${(props) => props.right};
  position: absolute;
  width: 20px;
  height: 20px;
  background: url(/images/delete.png) no-repeat center;
`;

const ShowPassword = styled.div`
  top: 16px;
  right: 24px;
  position: absolute;
  width: 20px;
  height: 20px;
  background: url(/images/see.png) no-repeat center;
`;

const NonShowPassword = styled.div`
  top: 16px;
  right: 24px;
  position: absolute;
  width: 20px;
  height: 20px;
  background: url(/images/Nonsee.png) no-repeat center;
`;
