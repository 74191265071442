import styled from '@emotion/styled';
import Chart from 'chart.js/auto';
import { useEffect, useRef } from 'react';

interface Props {
  transformedData?: { [key: string]: number | string };
}

const ReportChart = ({ transformedData }: Props) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const text =
    (transformedData?.probability as number) <= 45.9
      ? '양호'
      : (transformedData?.probability as number) >= 70
      ? '주의'
      : '관심';

  const color =
    (transformedData?.probability as number) <= 45.9
      ? '#39B974'
      : (transformedData?.probability as number) >= 70
      ? '#EF5C5C'
      : '#F29E00';

  useEffect(() => {
    if (canvasRef.current) {
      const ctx = canvasRef.current.getContext('2d');

      if (ctx) {
        const backgroundColor = [
          (transformedData?.probability as number) <= 45.9
            ? '#39B974'
            : (transformedData?.probability as number) >= 70
            ? '#EF5C5C'
            : '#F29E00',
          'rgba(54, 54, 54, 0.16)',
        ];
        const percent = transformedData?.probability;

        const minusPercent = 100 - Number(transformedData?.probability);

        const chart = new Chart(ctx, {
          type: 'doughnut',
          data: {
            datasets: [
              {
                data: [percent as number, minusPercent],
                backgroundColor: backgroundColor,
              },
            ],
          },
          options: {
            animation: false,
            plugins: {
              legend: {
                display: false,
              },
              tooltip: {
                enabled: false,
              },
            },
            elements: {},
            cutout: 40,
          },
        });

        return () => {
          chart.destroy();
        };
      }
    }
  }, [transformedData]);

  return (
    <Wrapper>
      <canvas ref={canvasRef}></canvas>
      <Text color={color}>{text}</Text>
    </Wrapper>
  );
};

export default ReportChart;

const Wrapper = styled.div`
  position: relative;
  width: 115px;
  height: 115px;
`;

const Text = styled.div<{ color: string }>`
  position: absolute;
  top: 44px;
  left: 40px;
  color: ${(props) => props.color};
  text-align: center;

  font-size: 20px;

  font-weight: 700;
  line-height: 1.5em; /* 30px */
`;
