import { Suspense, lazy } from 'react';
import { CookiesProvider } from 'react-cookie';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { RecoilRoot } from 'recoil';

import FindIdPwPage from '~/pages/FindIdPwPage';
import Loading from '~/pages/Loading';
import PrivacyPolicyPage from '~/pages/PrivacyPolicyPage';
import SignupPage from '~/pages/SignupPage';
import SuccessPage from '~/pages/SuccessPage';
import TermsOfUsePage from '~/pages/TermsOfUsePage';
import ActivityLogPage from '~/pages/home/ActivityLogPage';
import ChangePasswordPage from '~/pages/home/ChangePasswordPage';
import ChangeProfilePage from '~/pages/home/ChangeProfilePage';
import CheckPasswordPage from '~/pages/home/CheckPasswordPage';
import CheckProfilePage from '~/pages/home/CheckProfilePage';
import DashboardPage from '~/pages/home/DashboardPage';
import InstitutionInformationPage from '~/pages/home/InstitutionInformationPage';
import MailBoxPage from '~/pages/home/MailBoxPage';
import StudentManagementPage from '~/pages/home/StudentManagementPage';

import GlobalStyle from '~/styles/GlobalStyle';

import HomeLayout from '~/layouts/HomeLayout';

import ReceiveMailBox from '~/components/mailBox/ReceiveMailBox';
import SendMailBox from '~/components/mailBox/sendMailBox';
import SendPushBox from '~/components/mailBox/sendPushBox';
import ProtectedRoute from '~/components/protectedRoute/ProtectedRoute';
import Student from '~/components/studentManagement/Index';
import StudentRequest from '~/components/studentManagement/StudentRequest/Index';
import CreateStudent from '~/components/studentManagement/createStudent/Index';
import Enhancement from '~/components/studentManagement/enhancement/Index';
import Measurement from '~/components/studentManagement/measurement/Index';
import StudentReceived from '~/components/studentManagement/studentReceived/Index';

const MainPage = lazy(() => import('~/pages/MainPage'));

function App() {
  return (
    <RecoilRoot>
      <CookiesProvider>
        <GlobalStyle />
        <BrowserRouter>
          <Suspense fallback={<Loading />}>
            <Routes>
              <Route path="/" element={<MainPage />} />
              <Route path="/signup" element={<SignupPage />} />
              <Route path="/findidpw" element={<FindIdPwPage />} />
              <Route path="/privacy" element={<PrivacyPolicyPage />} />
              <Route path="/terms-use" element={<TermsOfUsePage />} />
              <Route
                path="/home"
                element={
                  <ProtectedRoute>
                    <HomeLayout />
                  </ProtectedRoute>
                }
              >
                <Route path="activity-log" element={<ActivityLogPage />} />
                <Route path="check-profile" element={<CheckProfilePage />} />
                <Route path="change-profile" element={<ChangeProfilePage />} />
                <Route path="check-password" element={<CheckPasswordPage />} />
                <Route
                  path="change-password"
                  element={<ChangePasswordPage />}
                />
                <Route path="dashboard" element={<DashboardPage />} />
                <Route
                  path="institution-information"
                  element={<InstitutionInformationPage />}
                />
                <Route path="mail-box" element={<MailBoxPage />}>
                  <Route path="send-mail" element={<SendMailBox />} />
                  <Route path="receive-mail" element={<ReceiveMailBox />} />
                  <Route path="send-push" element={<SendPushBox />} />
                </Route>
                <Route
                  path="student-management"
                  element={<StudentManagementPage />}
                >
                  <Route path="student" element={<Student />}>
                    <Route path="measurement" element={<Measurement />} />
                    <Route path="enhancement" element={<Enhancement />} />
                  </Route>
                  <Route
                    path="student-received"
                    element={<StudentReceived />}
                  />
                  <Route path="student-request" element={<StudentRequest />} />
                  <Route path="create-student" element={<CreateStudent />} />
                </Route>
              </Route>
              <Route path="success" element={<SuccessPage />} />
            </Routes>
          </Suspense>
        </BrowserRouter>
      </CookiesProvider>
    </RecoilRoot>
  );
}

export default App;
