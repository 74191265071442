import styled from '@emotion/styled';
import moment from 'moment';
import { ChangeEvent, useEffect, useState } from 'react';

import {
  RequestDataList,
  RequestStudentsResponse,
} from '~/types/student/request';

import StudentRequestCalendar from '~/components/studentManagement/StudentRequest/StudentRequestCalendar';
import StudentRequestPaginate from '~/components/studentManagement/StudentRequest/StudentRequestPaginate';

const ITEMS_LABEL = [
  {
    key: 1,
    label: 'ID',
  },
  {
    key: 2,
    label: '이름',
  },
  {
    key: 3,
    label: '성별',
  },
  {
    key: 4,
    label: '학급정보',
  },
  {
    key: 5,
    label: '인지능력측정 결과',
  },
  {
    key: 6,
    label: '인지능력측정 일시',
  },
  {
    key: 7,
    label: '측정 횟수',
  },
  {
    key: 8,
    label: '가입 일시',
  },
  {
    key: 9,
    label: '요청 다시 보내기',
  },
];

interface DropdownState {
  calendar: boolean;
}

interface Props {
  isData?: RequestStudentsResponse;
  isDate: Date;
  isLoading: boolean;
  setIsDate: React.Dispatch<React.SetStateAction<Date>>;
  handleUserCheckboxChange: (
    sendId: number,
  ) => (event: ChangeEvent<HTMLInputElement>) => void;
  checkedSendId: RequestDataList[];
  handleAllCheck: () => void;
  allCheck: boolean;
  handleSelectAllChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function StudentRequestList({
  isData,
  isDate,
  isLoading,
  setIsDate,
  handleUserCheckboxChange,
  checkedSendId,
  allCheck,
  handleSelectAllChange,
}: Props) {
  const [isDropdownOpen, setIsDropdownOpen] = useState<DropdownState>({
    calendar: false,
  });
  const [searchText, setSearchText] = useState('');
  const [filteredData, setFilteredData] = useState<RequestDataList[]>([]);

  const dateFilter = (value?: string | Date | null) => {
    const GMT = moment().format('Z');

    const [gmtSign, gmtValue] = GMT.split(':');
    const gmtHours = parseInt(gmtSign);
    const gmtMinutes = parseInt(gmtValue);

    const countryTime = moment
      .utc(value)
      .utcOffset(gmtHours * 60 + gmtMinutes)
      .format('YYYY-MM-DD');
    return countryTime;
  };

  const calendarDateFilter = (value: string | Date | null) => {
    const GMT = moment().format('Z');
    const [gmtSign, gmtValue] = GMT.split(':');
    const gmtHours = parseInt(gmtSign);
    const gmtMinutes = parseInt(gmtValue);

    const selectedDate = moment
      .utc(value)
      .utcOffset(gmtHours * 60 + gmtMinutes);

    moment.updateLocale('en', {
      week: {
        dow: 1,
      },
    });

    const year = selectedDate.format('YYYY');
    const month = selectedDate.format('MM');
    const day = selectedDate.format('DD');

    return `${year}년 ${month}월 ${day}일`;
  };

  const handleDropdown = (dropdownName: keyof DropdownState) => {
    setIsDropdownOpen((prevState) => ({
      ...prevState,
      [dropdownName]: !prevState[dropdownName],
    }));
  };

  const handleCalendarReset = () => {
    setIsDate(new Date());
  };

  const applyFilters = () => {
    if (!isData?.data?.requestList) {
      return [];
    }

    const searchTextFilteredData = searchText
      ? isData.data.requestList.filter((item) => {
          const name = item.name || '';
          const loginId = item.loginId || '';
          return (
            name.toLowerCase().includes(searchText.toLowerCase()) ||
            loginId.toLowerCase().includes(searchText.toLowerCase())
          );
        })
      : isData.data.requestList;

    return searchTextFilteredData;
  };

  const applyDateFilter = (list: RequestDataList[], date: Date) => {
    if (dateFilter(date) === dateFilter(new Date())) {
      return list;
    }

    const formattedDate = dateFilter(date);
    return list.filter((item) => {
      const joinDate = item.joinDate;
      return dateFilter(joinDate) === formattedDate;
    });
  };

  useEffect(() => {
    const searchTextFilteredData = applyFilters();
    const dateFilteredData = applyDateFilter(searchTextFilteredData, isDate);
    setFilteredData(dateFilteredData);
  }, [searchText, isData, isDate]);

  return (
    <>
      {isLoading && (
        <>
          {isData?.data?.requestList?.length !== 0 ? (
            <>
              <SearchWrapper>
                <InputContainer>
                  <SearchInput
                    name="filter"
                    type="text"
                    placeholder="찾을 학생의 ID 또는 이름을 입력해주세요."
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    bdColor={searchText.length <= 0 ? '#e2e2ea;' : '#0062FF'}
                  />
                  <InputImg
                    url={
                      searchText.length <= 0
                        ? '/images/search.png'
                        : '/images/blueDelete.png'
                    }
                    onClick={() => setSearchText('')}
                  />
                </InputContainer>
                <ButtonConatiner>
                  <Button
                    bdColor={
                      dateFilter(isDate) === dateFilter(new Date())
                        ? '#e2e2ea;'
                        : '#0062FF'
                    }
                    width="320px"
                    onClick={() => handleDropdown('calendar')}
                  >
                    <div>{calendarDateFilter(isDate)}</div>
                  </Button>
                  <ButtonImg
                    onClick={handleCalendarReset}
                    right="16px"
                    url={
                      dateFilter(isDate) === dateFilter(new Date())
                        ? '/images/calendar.png'
                        : '/images/blueDelete.png'
                    }
                  />
                  {isDropdownOpen.calendar && (
                    <StudentRequestCalendar
                      isDate={isDate}
                      setIsDate={setIsDate}
                      setIsDropdownOpen={setIsDropdownOpen}
                    />
                  )}
                </ButtonConatiner>
              </SearchWrapper>
              <ItemsLabel>
                {ITEMS_LABEL.map((item) => (
                  <div key={item.key}>
                    {item.label === 'ID' && (
                      <>
                        <input
                          type="checkbox"
                          name="all"
                          checked={allCheck}
                          onChange={handleSelectAllChange}
                        />
                      </>
                    )}
                    {item.label}
                  </div>
                ))}
              </ItemsLabel>
              <StudentRequestPaginate
                itemsPerPage={8}
                isData={filteredData}
                handleUserCheckboxChange={handleUserCheckboxChange}
                checkedSendId={checkedSendId}
              />
            </>
          ) : (
            <NoDataContainer>
              <div>
                <NodataImg></NodataImg>
                <h1>관리 학생이 없습니다.</h1>
                <h2>새로운 학생을 등록 또는 연결해보세요.</h2>
              </div>
            </NoDataContainer>
          )}
        </>
      )}
    </>
  );
}

const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-bottom: 24px;
`;

const InputContainer = styled.div`
  position: relative;
  font-size: 14px;

  font-weight: 500;
`;

const SearchInput = styled.input<{ bdColor: string }>`
  width: 1174px;
  height: 18px;
  padding: 15px 19px;
  border-radius: 10px;
  border: 1px solid ${(props) => props.bdColor};

  ::placeholder {
    color: #8c9299;
    font-size: 14px;

    font-weight: 500;
  }
`;

const InputImg = styled.div<{ url: string }>`
  position: absolute;
  top: 16.32px;
  right: 20px;
  width: 17px;
  height: 17px;
  background: url(${(props) => props.url}) no-repeat center;
  background-size: cover;
`;

const ButtonConatiner = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

const ItemsLabel = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 56px;
  border-radius: 10px;
  background: #fafafa;

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    font-size: 14px;

    font-weight: 400;
    height: 56px;

    & > input {
      width: 16px;
      height: 16px;
      margin-left: 16px;
      margin-right: 10px;
      margin-bottom: 5px;
    }

    :nth-of-type(1) {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 200px;
    }
    :nth-of-type(2) {
      width: 100px;
    }
    :nth-of-type(3) {
      width: 100px;
    }
    :nth-of-type(4) {
      width: 140px;
    }
    :nth-of-type(5) {
      width: 180px;
    }
    :nth-of-type(6) {
      width: 210px;
    }
    :nth-of-type(7) {
      width: 100px;
    }
    :nth-of-type(8) {
      width: 210px;
    }
    :nth-of-type(9) {
      width: 304px;
    }
  }
`;

const Button = styled.button<{ width: string; bdColor: string }>`
  display: flex;
  align-items: center;
  width: ${(props) => props.width};
  height: 50px;
  padding: 16px;
  border-radius: 10px;
  color: ${(props) => props.color};
  border: 1px solid ${(props) => props.bdColor};
`;

const ButtonImg = styled.div<{ right: string; url: string }>`
  position: absolute;
  top: 15px;
  right: ${(props) => props.right};
  width: 20px;
  height: 20px;
  background: url(${(props) => props.url}) no-repeat center;
  background-size: center;
`;

const NoDataContainer = styled.div`
  display: flex;
  padding: 169px 538px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  & > div {
    width: 278px;
    height: 278px;
    border-radius: 278px;
    background: linear-gradient(
      180deg,
      rgba(52, 103, 255, 0.08) 0%,
      rgba(84, 81, 255, 0) 100%
    );
  }

  h1 {
    margin-bottom: 15px;
    color: #65696d;
    text-align: center;

    /* heading/H02-20px-bold */

    font-size: 20px;

    font-weight: 700;
    line-height: 1.6em; /* 32px */
    letter-spacing: -0.3px;
  }

  h2 {
    margin-bottom: 55px;
    color: #65696d;
    text-align: center;

    /* body/B02-18px-medium */

    font-size: 18px;

    font-weight: 500;
    line-height: 1.6em; /* 28.8px */
  }

  button {
    width: 300px;
    height: 56px;
    background-color: #0062ff;
    color: #fff;
    border-radius: 10px;
  }
`;

const NodataImg = styled.div`
  width: 130px;
  height: 89px;
  margin-top: 84px;
  margin-left: 65px;
  margin-bottom: 24px;
  background: url('/images/studentNodataImg.png') no-repeat center;
  background-size: cover;
`;
