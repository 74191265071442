import { AxiosResponse } from 'axios';

import {
  AuthRequestParams,
  AuthResponse,
  PgResultResponse,
  PgResultSearchRequestParams,
  PgResultSearchResponse,
} from '~/types/auth/auth';

import api from '~/api/noToken';

const BASE_URL = '/api/v1/inicis';

export const getPgTestStatusData = (
  param: AuthRequestParams,
): Promise<AxiosResponse<AuthResponse>> =>
  api.post(`${BASE_URL}/authentication`, param);

export const getPgResultStatusData = (): Promise<
  AxiosResponse<PgResultResponse>
> => api.post(`${BASE_URL}/authentication`);

export const getPgResultSearchStatusData = (
  param: PgResultSearchRequestParams,
) => api.post<PgResultSearchResponse>(`${BASE_URL}/find/result`, param);
