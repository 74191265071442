import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';

import { AuthResponse, authenticaionDetailData } from '~/types/auth/auth';

import { ReactComponent as JoyCog140 } from '~/assets/JoyCog140.svg';

import Modal from '~/utils/portal/components/Modal';

import {
  getPgResultSearchStatusData,
  getPgTestStatusData,
} from '~/api/auth/auth';

interface Props {
  setSignupPageCount: React.Dispatch<React.SetStateAction<number>>;
  setAuthData: React.Dispatch<authenticaionDetailData>;
  pgData?: AuthResponse;
  setPgData: React.Dispatch<AuthResponse>;
}

const SignupPassCheck: React.FC<Props> = ({
  setSignupPageCount,
  setAuthData,
  pgData,
  setPgData,
}: Props) => {
  const [isModal, setIsModal] = useState(false);

  useEffect(() => {
    getPgTestStatusData({
      directAgency: null,
      reqSvcCd: 'SIMPLE',
      userBirth: null,
      userName: null,
      userPhone: null,
    })
      .then(({ data }) => {
        setPgData(data);
      })
      .catch(console.error);
  }, []);

  const popupCenter = (): Window | null => {
    const _width = 400;
    const _height = 620;
    const xPos = document.body.offsetWidth / 2 - _width / 2;

    window.addEventListener('message', (event) => {
      if (event.source !== windowRef) return;

      setIsModal(true);
    });

    const windowRef = window.open(
      '',
      'sa_popup',
      `width=${_width}, height=${_height}, left=${xPos}, menubar=yes, status=yes, titlebar=yes, resizable=yes`,
    );

    return windowRef;
  };

  const callSa = async () => {
    const windowRef = popupCenter();
    if (windowRef) {
      const saForm = window.document.querySelector('form[name="saForm"]');
      if (saForm instanceof HTMLFormElement) {
        saForm.setAttribute('target', 'sa_popup');
        saForm.setAttribute('method', 'post');
        saForm.setAttribute('action', 'https://sa.inicis.com/auth');
        saForm.submit();
      }
    }
  };

  const handleModal = () => {
    setIsModal(false);
    getPgResultSearchStatusData({
      mtxId: pgData?.data.authentication.mtxId as string,
      loginId: null,
    })
      .then(({ data }) => {
        if (data.data.authentication.resultCode === '0000') {
          setAuthData(data.data.authentication);
          setSignupPageCount(3);
        } else {
          window.location.reload();
        }
      })
      .catch(console.error);
  };

  return (
    <>
      <Wrapper>
        <JoyCog140
          style={{
            marginBottom: '89px',
            marginLeft: '171px',
          }}
        />
        <h1>조이코그 관리자 회원가입</h1>
        <form name="saForm" hidden>
          <input
            type="text"
            name="mid"
            value={pgData?.data.authentication.mid ?? 0}
            readOnly
          />
          mid
          <br />
          <input
            type="text"
            name="reqSvcCd"
            value={pgData?.data.authentication.reqSvcCd ?? 0}
            readOnly
          />
          reqSvcCd
          <br />
          <input
            type="text"
            name="mTxId"
            value={pgData?.data.authentication.mtxId ?? 0}
            readOnly
          />
          mTxId
          <br />
          <input
            type="text"
            name="authHash"
            value={pgData?.data.authentication.authHash ?? 0}
            readOnly
          />
          authHash
          <br />
          <input
            type="text"
            name="flgFixedUser"
            value={pgData?.data.authentication.flgFixedUser ?? 0}
            readOnly
          />
          flgFixedUser
          <br />
          <input
            type="text"
            name="userName"
            value={pgData?.data.authentication.userName ?? 0}
            readOnly
          />
          userName
          <br />
          <input type="text" name="userPhone" value="" readOnly />
          userPhone
          <br />
          <input type="text" name="userBirth" value="" readOnly />
          userBirth
          <br />
          <input type="text" name="userHash" value="" readOnly />
          userHash
          <br />
          <input
            name="reservedMsg"
            value={pgData?.data.authentication.reservedMsg ?? 0}
            readOnly
          />
          reservedMsg
          <br />
          <input type="text" name="directAgency" value="" readOnly />
          directAgency
          <br />
          <input
            type="text"
            name="successUrl"
            value={pgData?.data.authentication.successUrl ?? 0}
            readOnly
          />
          successUrl
          <br />
          <input
            type="text"
            name="failUrl"
            value={pgData?.data.authentication.failUrl ?? 0}
            readOnly
          />
          failUrl
          <br />
        </form>
        <button onClick={callSa}>본인인증하기</button>
        <p>서비스 시작을 위해</p>
        <p>본인 명의의 휴대전화 번호로 인증이 필요합니다.</p>
      </Wrapper>
      <Reserved>© 2023 eMotiv. All rights reserved.</Reserved>

      {isModal && (
        <Modal title="본인인증이 완료되었습니다." onClose={handleModal} />
      )}
    </>
  );
};

export default SignupPassCheck;

const Wrapper = styled.div`
  width: 482px;
  height: 445px;
  padding: 100px 119px 120px 119px;
  border-radius: 40px;
  background: rgba(255, 255, 255, 0.9);
  /* shadow */
  box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.08);
  & > h1 {
    margin-bottom: 80px;
    color: #171819;
    text-align: center;

    font-size: 32px;

    font-weight: 700;
    line-height: 1.3em; /* 41.6px */
    text-transform: capitalize;
  }
  & > button {
    width: 480px;
    height: 56px;
    margin-bottom: 80px;
    border-radius: 10px;
    background: #0062ff;
    color: #fff;
    text-align: center;
    /* body/B02-18px-bold */

    font-size: 18px;

    font-weight: 700;
    line-height: 1.3em; /* 23.4px */
  }
  & > p {
    color: #171819;
    text-align: center;
    /* body/B02-18px-medium */

    font-size: 18px;

    font-weight: 500;
    line-height: 1.6em; /* 28.8px */
  }
`;

const Reserved = styled.div`
  margin-top: 163px;
  color: #65696d;
  text-align: center;
  /* body/B04-14px-medium */

  font-size: 14px;

  font-weight: 500;
  line-height: 1.6em; /* 22.4px */
`;
//함수안에서 로직이 실행될때는 네비게이트
