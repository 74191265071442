import styled from '@emotion/styled';
import { useState } from 'react';
import { Outlet } from 'react-router-dom';

import Menu from '~/components/menu/Index';
import Nav from '~/components/nav/Index';

const HomeLayout = () => {
  const [isNavDropdown, setIsNavDropdown] = useState(false);

  const handleNavDropDownClick = () => {
    setIsNavDropdown((prev) => !prev);
  };

  return (
    <Wrapper>
      <Menu />
      <NavContainer>
        <Nav
          isNavDropdown={isNavDropdown}
          handleNavDropDownClick={handleNavDropDownClick}
        />
        <Outlet />
      </NavContainer>
    </Wrapper>
  );
};

export default HomeLayout;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
`;

const NavContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
