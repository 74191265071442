import styled from '@emotion/styled';
import { useEffect, useState } from 'react';

import { ReactComponent as BoyImg } from '~/assets/boy.svg';
import { ReactComponent as GirlImg } from '~/assets/girl.svg';
import { ReactComponent as DropDownImg } from '~/assets/shavron.svg';

import {
  getFindChildInfoStatusData,
  getModifyChildInfoStatusData,
} from '~/api/student/edit';

const LOGIN_INFO = [
  {
    key: 1,
    label: '아이디',
    subTitle: '기본 정보',
    placeholder: '아이디를 입력해주세요.',
    error:
      '아이디는 최소 4글자 최대 16글자의 영문 대,소문자 또는 숫자로 입력해 주세요.',
  },
];

const MANAGER_INFO = [
  {
    key: 1,
    label: '이름',
    placeholder: '이름을 입력해주세요.',
    error: '이름을 입력해주세요.',
  },
  {
    key: 2,
    label: '학급정보',
    error: '학급정보를 선택해주세요.',
  },
  {
    key: 3,
    label: '생년월일',
    error: '생년월일을 선택해주세요.',
  },
  {
    key: 4,
    label: '성별',
    error: '성별을 선택해주세요.',
  },
  {
    key: 5,
    label: '학생 특성',
    placeholder: '학생 특성을 30자 이내로 입력해주세요.',
  },
];

interface InputData {
  loginId: string;
  name: string;
  clazz: number;
  grade: number;
  number: number;
  birth: string;
  characteristic: string;
  gender: string;
}

interface Props {
  setActiveComponent: React.Dispatch<React.SetStateAction<string>>;
  userId?: number | null;
  handleModalClick: (id: string) => void;
}

const GRADE_INFO = ['1학년', '2학년', '3학년', '4학년', '5학년', '6학년'];

const CLAZZ_INFO = Array.from({ length: 10 }, (_, index) => `${index + 1}반`);

const NUMBER_INFO = Array.from({ length: 50 }, (_, index) => `${index + 1}번`);

const YEARS_INFO = Array.from({ length: 8 }, (_, index) => {
  const year = 2009 + index;
  return year.toString() + '년';
});

const MONTHS_INFO = Array.from(
  { length: 12 },
  (_, index) => `${(index + 1).toString().padStart(2, '0')}월`,
);

const DAYS_INFO = Array.from(
  { length: 31 },
  (_, index) => `${(index + 1).toString().padStart(2, '0')}일`,
);

export default function EditStudent({
  setActiveComponent,
  userId,
  handleModalClick,
}: Props) {
  const [loginIdInput, setLoginIdInput] = useState<InputData>({
    loginId: '',
    name: '',
    clazz: 0,
    grade: 0,
    number: 0,
    birth: '',
    characteristic: '',
    gender: '',
  });

  const [errorMessages, setErrorMessages] = useState({
    loginId: false,
    password: false,
    name: false,
    classInfo: false,
    birth: false,
    genderCheck: false,
  });

  const [isDropDown, setIsDropDown] = useState({
    clazz: false,
    grade: false,
    number: false,
    YYYY: false,
    MM: false,
    DD: false,
  });
  const [isClassData, setIsClassData] = useState({
    grade: '선택',
    clazz: '선택',
    number: '선택',
    YYYY: '선택',
    MM: '선택',
    DD: '선택',
  });
  const [birth, setBirth] = useState('');
  const [isModal, setIsModal] = useState(false);
  const [isLoginIdCheckModal, setIsLoginIdCheckModal] = useState('');

  useEffect(() => {
    if (!userId) {
      return;
    }

    getFindChildInfoStatusData({ userId })
      .then(({ data }) => {
        if (
          data.data.studentInfo !== null &&
          data.data.studentInfo !== undefined
        ) {
          const {
            loginId,
            name,
            clazz,
            grade,
            number,
            birth,
            characteristic,
            gender,
          } = data.data.studentInfo;

          setLoginIdInput((prevState) => ({
            ...prevState,
            loginId,
            password: '',
            name,
            clazz,
            grade: Number(grade),
            number,
            birth,
            characteristic,
            gender,
          }));

          setIsClassData((prev) => ({
            ...prev,
            grade: `${String(grade)}학년`,
            clazz: `${String(grade)}반`,
            number: `${String(grade)}번`,
          }));

          if (birth) {
            const YYYY = birth.substring(0, 4);
            const MM = birth.substring(4, 6);
            const DD = birth.substring(6, 8);

            setIsClassData((prev) => ({
              ...prev,
              YYYY: `${YYYY}년`,
              MM: `${MM}월`,
              DD: `${DD}일`,
            }));
          }
          handleGenderChange(gender);
        }
      })
      .catch(console.error);
  }, []);

  const handleLoginIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setLoginIdInput((prevState) => ({
      ...prevState,
      loginId: value,
    }));
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setLoginIdInput((prevState) => ({
      ...prevState,
      name: value,
    }));
  };

  const handleCharacteristicChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { value } = e.target;
    setLoginIdInput((prevState) => ({
      ...prevState,
      characteristic: value,
    }));
  };

  const handleGenderChange = (gender: string) => {
    setLoginIdInput((prevState) => ({
      ...prevState,
      gender: gender,
    }));
  };

  const handleNameBlur = () => {
    if (loginIdInput.name.trim().length === 0) {
      setErrorMessages((prevErrors) => ({
        ...prevErrors,
        name: true,
      }));
    } else {
      setErrorMessages((prevErrors) => ({
        ...prevErrors,
        name: false,
      }));
    }
  };

  const handleGradeClick = () => {
    setIsDropDown((prev) => ({
      ...prev,
      grade: !prev.grade,
    }));
  };

  const handleClazzClick = () => {
    setIsDropDown((prev) => ({
      ...prev,
      clazz: !prev.clazz,
    }));
  };

  const handleNumberClick = () => {
    setIsDropDown((prev) => ({
      ...prev,
      number: !prev.number,
    }));
  };

  const handleYYYYClick = () => {
    setIsDropDown((prev) => ({
      ...prev,
      YYYY: !prev.YYYY,
    }));
  };

  const handleMMClick = () => {
    setIsDropDown((prev) => ({
      ...prev,
      MM: !prev.MM,
    }));
  };

  const handleDDClick = () => {
    setIsDropDown((prev) => ({
      ...prev,
      DD: !prev.DD,
    }));
  };

  const handleGradeInfo = (value: string) => {
    setIsClassData((prev) => ({
      ...prev,
      grade: value,
    }));
    handleGradeClick();

    const numericGrade = parseInt(value);

    if (!isNaN(numericGrade)) {
      setLoginIdInput((prevState) => ({
        ...prevState,
        grade: numericGrade,
      }));
    }
  };

  const handleClazzInfo = (value: string) => {
    setIsClassData((prev) => ({
      ...prev,
      clazz: value,
    }));
    handleClazzClick();

    const numericClazz = parseInt(value);

    if (!isNaN(numericClazz)) {
      setLoginIdInput((prevState) => ({
        ...prevState,
        clazz: numericClazz,
      }));
    }
  };

  const handleNumberInfo = (value: string) => {
    setIsClassData((prev) => ({
      ...prev,
      number: value,
    }));
    handleNumberClick();

    const numbericNumber = parseInt(value);

    if (!isNaN(numbericNumber)) {
      setLoginIdInput((prevState) => ({
        ...prevState,
        number: numbericNumber,
      }));
    }
  };

  const handleYYYYInfo = (value: string) => {
    setIsClassData((prev) => ({
      ...prev,
      YYYY: value,
    }));
    handleYYYYClick();

    const numericYYYY = parseInt(value);
    const formattedYYYY = numericYYYY.toString().padStart(4, '0');

    setBirth(`${formattedYYYY}`);
  };

  const handleMMInfo = (value: string) => {
    setIsClassData((prev) => ({
      ...prev,
      MM: value,
    }));
    handleMMClick();

    const numericMM = parseInt(value);
    const formattedMM = numericMM.toString().padStart(2, '0');

    setBirth(`${birth.slice(0, 5)}-${formattedMM}`);
  };

  const handleDDInfo = (value: string) => {
    setIsClassData((prev) => ({
      ...prev,
      DD: value,
    }));
    handleDDClick();

    const numericDD = parseInt(value);
    const formattedDD = numericDD.toString().padStart(2, '0');

    const updatedBirth = `${birth.slice(0, 8)}-${formattedDD}`;
    setBirth(updatedBirth);

    setLoginIdInput((prevState) => ({
      ...prevState,
      birth: updatedBirth,
    }));
  };

  const handleLoginIdCheckModal = (status: string) => {
    setIsLoginIdCheckModal(status);
  };

  const handleAddStudentModal = () => {
    handleModalClick('');
    setIsModal(false);
  };

  const handleEditStudentInfo = () => {
    if (!userId) {
      return;
    }

    getModifyChildInfoStatusData({
      birth: loginIdInput.birth,
      characteristic: loginIdInput.characteristic,
      clazz: loginIdInput.clazz,
      gender: loginIdInput.gender,
      grade: loginIdInput.grade,
      name: loginIdInput.name,
      number: loginIdInput.number,
      userId,
    })
      .then(({ data }) => {
        if (data.data.isValid) {
          setIsLoginIdCheckModal('success');
        }
      })
      .catch(console.error);
  };

  const handleDeleteLoginId = () => {
    setLoginIdInput((prevState) => ({
      ...prevState,
      name: '',
    }));
  };

  const handleDeleteCharacteristic = () => {
    setLoginIdInput((prevState) => ({
      ...prevState,
      characteristic: '',
    }));
  };

  const handleEditInfo = () => {
    handleLoginIdCheckModal('');
    window.location.reload();
  };
  return (
    <>
      <Background></Background>
      <Wrapper>
        <Container>
          <Title>
            <TitleImg />
            학생정보 수정하기
          </Title>
          <LoginInfoContainer>
            {LOGIN_INFO.map((item) => (
              <div key={item.key}>
                {item.subTitle && <InfoSubTitle>{item.subTitle}</InfoSubTitle>}
                <InfoLabel>
                  {item.label}
                  <span>*</span>
                </InfoLabel>
                <InfoInputContainer>
                  <InfoInput
                    value={loginIdInput.loginId}
                    type={'text'}
                    maxLength={16}
                    placeholder={item.placeholder}
                    width={'680px'}
                    onChange={handleLoginIdChange}
                    bdColor={
                      item.label === '아이디' && errorMessages.loginId
                        ? '#FF4752'
                        : '#e2e2ea;'
                    }
                    disabled={item.label === '아이디'}
                  />
                </InfoInputContainer>
                {item.label === '아이디' && (
                  <>
                    {errorMessages.loginId === true ? (
                      <Error mgBottom="30px">{item.error}</Error>
                    ) : (
                      <Error mgBottom="30px"></Error>
                    )}
                  </>
                )}
              </div>
            ))}
          </LoginInfoContainer>
          <InfoSubTitle></InfoSubTitle>
          <ManagerInfoContainer>
            {MANAGER_INFO.map((item) => (
              <div key={item.key}>
                <InfoLabel>
                  {item.label}
                  {item.label !== '학생 특성' && <span>*</span>}
                </InfoLabel>
                <ManagerInputsContainer>
                  {item.label === '이름' || item.label === '학생 특성' ? (
                    <ManagerItemsContainer>
                      <div>
                        <ManagerInputs
                          type="text"
                          value={
                            item.label === '이름'
                              ? loginIdInput.name || ''
                              : loginIdInput.characteristic || ''
                          }
                          placeholder={item.placeholder}
                          width={item.label === '이름' ? '299px' : '680px'}
                          mgRight={item.label === '이름' ? '30px' : ''}
                          onChange={
                            item.label === '이름'
                              ? handleNameChange
                              : handleCharacteristicChange
                          }
                          bdColor={
                            item.label === '이름' && errorMessages.name
                              ? '#FF4752'
                              : '#e2e2ea;'
                          }
                          onBlur={handleNameBlur}
                          maxLength={item.label === '학생 특성' ? 30 : 10}
                        />
                        {loginIdInput.name.length > 0 &&
                          item.label === '이름' && (
                            <DeleteButton
                              onClick={handleDeleteLoginId}
                              right="54px"
                            />
                          )}
                      </div>
                      {item.label === '이름' && (
                        <>
                          {errorMessages.name === true ? (
                            <Error mgBottom="6px">{item.error}</Error>
                          ) : (
                            <Error mgBottom="6px"></Error>
                          )}
                        </>
                      )}
                      {loginIdInput.characteristic.length > 0 &&
                        item.label === '학생 특성' && (
                          <DeleteButton
                            onClick={handleDeleteCharacteristic}
                            right="67px"
                          />
                        )}
                      {item.label === '학생 특성' && (
                        <TextCountCheck>
                          {loginIdInput.characteristic === null
                            ? 0
                            : loginIdInput?.characteristic?.length}
                          /30
                        </TextCountCheck>
                      )}
                    </ManagerItemsContainer>
                  ) : item.label === '학급정보' || item.label === '생년월일' ? (
                    <ManagerItemsContainer>
                      <ManagerItemsButtonContainer>
                        <ManagerInfoButton
                          width="112px"
                          mgRight="4px"
                          bdColor={
                            (item.label === '학급정보' &&
                              errorMessages.classInfo &&
                              (loginIdInput.clazz === 0 ||
                                loginIdInput.grade === 0 ||
                                loginIdInput.number === 0)) ||
                            (item.label === '생년월일' &&
                              errorMessages.birth &&
                              (isClassData.YYYY.length === 2 ||
                                isClassData.MM.length === 2 ||
                                isClassData.DD.length === 2))
                              ? '#FF4752'
                              : '#e2e2ea;'
                          }
                          bgColor="#fff"
                          onClick={
                            item.label === '학급정보'
                              ? handleGradeClick
                              : handleYYYYClick
                          }
                        >
                          {item.label === '학급정보' ? (
                            <div>{isClassData.grade}</div>
                          ) : (
                            <div>{isClassData.YYYY}</div>
                          )}
                          <span>
                            <DropDownImg />
                          </span>
                        </ManagerInfoButton>
                        <ManagerInfoButton
                          width="112px"
                          mgRight="4px"
                          bdColor={
                            (item.label === '학급정보' &&
                              errorMessages.classInfo &&
                              (loginIdInput.clazz === 0 ||
                                loginIdInput.grade === 0 ||
                                loginIdInput.number === 0)) ||
                            (item.label === '생년월일' &&
                              errorMessages.birth &&
                              (isClassData.YYYY.length === 2 ||
                                isClassData.MM.length === 2 ||
                                isClassData.DD.length === 2))
                              ? '#FF4752'
                              : '#e2e2ea;'
                          }
                          bgColor="#fff"
                          onClick={
                            item.label === '학급정보'
                              ? handleClazzClick
                              : handleMMClick
                          }
                        >
                          {item.label === '학급정보' ? (
                            <div>{isClassData.clazz}</div>
                          ) : (
                            <div>{isClassData.MM}</div>
                          )}
                          <span>
                            <DropDownImg />
                          </span>
                        </ManagerInfoButton>
                        <ManagerInfoButton
                          bdColor={
                            (item.label === '학급정보' &&
                              errorMessages.classInfo &&
                              (loginIdInput.clazz === 0 ||
                                loginIdInput.grade === 0 ||
                                loginIdInput.number === 0)) ||
                            (item.label === '생년월일' &&
                              errorMessages.birth &&
                              (isClassData.YYYY.length === 2 ||
                                isClassData.MM.length === 2 ||
                                isClassData.DD.length === 2))
                              ? '#FF4752'
                              : '#e2e2ea;'
                          }
                          bgColor="#fff"
                          width="112px"
                          mgRight={item.label === '생년월일' ? '30px' : ''}
                          onClick={
                            item.label === '학급정보'
                              ? handleNumberClick
                              : handleDDClick
                          }
                        >
                          {item.label === '학급정보' ? (
                            <div>{isClassData.number}</div>
                          ) : (
                            <div>{isClassData.DD}</div>
                          )}
                          <span>
                            <DropDownImg />
                          </span>
                        </ManagerInfoButton>
                        {isDropDown.grade && item.label === '학급정보' && (
                          <DropDown left="0px">
                            {GRADE_INFO.map((item) => (
                              <button
                                key={item}
                                onClick={() => handleGradeInfo(item)}
                              >
                                <p>{item}</p>
                              </button>
                            ))}
                          </DropDown>
                        )}
                        {isDropDown.clazz && item.label === '학급정보' && (
                          <DropDown left="">
                            {CLAZZ_INFO.map((item) => (
                              <button
                                key={item}
                                onClick={() => handleClazzInfo(item)}
                              >
                                <p>{item}</p>
                              </button>
                            ))}
                          </DropDown>
                        )}
                        {isDropDown.number && item.label === '학급정보' && (
                          <DropDown left="232px">
                            {NUMBER_INFO.map((item) => (
                              <button
                                key={item}
                                onClick={() => handleNumberInfo(item)}
                              >
                                <p>{item}</p>
                              </button>
                            ))}
                          </DropDown>
                        )}
                        {isDropDown.YYYY && item.label === '생년월일' && (
                          <DropDown left="0px">
                            {YEARS_INFO.map((item) => (
                              <button
                                key={item}
                                onClick={() => handleYYYYInfo(item)}
                              >
                                <p>{item}</p>
                              </button>
                            ))}
                          </DropDown>
                        )}
                        {isDropDown.MM && item.label === '생년월일' && (
                          <DropDown left="116px">
                            {MONTHS_INFO.map((item) => (
                              <button
                                key={item}
                                onClick={() => handleMMInfo(item)}
                              >
                                <p>{item}</p>
                              </button>
                            ))}
                          </DropDown>
                        )}
                        {isDropDown.DD && item.label === '생년월일' && (
                          <DropDown left="233px">
                            {DAYS_INFO.map((item) => (
                              <button
                                key={item}
                                onClick={() => handleDDInfo(item)}
                              >
                                <p>{item}</p>
                              </button>
                            ))}
                          </DropDown>
                        )}
                      </ManagerItemsButtonContainer>
                      {item.label === '학급정보' && (
                        <>
                          {errorMessages.classInfo === true &&
                          (loginIdInput.clazz === 0 ||
                            loginIdInput.grade === 0 ||
                            loginIdInput.number === 0) ? (
                            <Error mgBottom="6px">{item.error}</Error>
                          ) : (
                            <Error mgBottom="6px"></Error>
                          )}
                        </>
                      )}
                      {item.label === '생년월일' && (
                        <>
                          {errorMessages.birth === true &&
                          (isClassData.YYYY.length === 2 ||
                            isClassData.MM.length === 2 ||
                            isClassData.DD.length === 2) ? (
                            <Error mgBottom="6px">{item.error}</Error>
                          ) : (
                            <Error mgBottom="6px"></Error>
                          )}
                        </>
                      )}
                    </ManagerItemsContainer>
                  ) : (
                    <ManagerItemsContainer>
                      <ManagerItemsButtonContainer>
                        <ManagerInfoGednerButton
                          width="168px"
                          mgRight="9px"
                          bdColor="#0062FF"
                          bgColor={
                            loginIdInput.gender === 'M' ? '#0062FF' : '#fff'
                          }
                          color={
                            loginIdInput.gender === 'M' ? '#fff' : '#0062FF'
                          }
                          onClick={() => handleGenderChange('M')}
                        >
                          <BoyImg
                            fill={
                              loginIdInput.gender === 'M' ? '#fff' : '#0062FF'
                            }
                          />
                          <div>남자</div>
                        </ManagerInfoGednerButton>
                        <ManagerInfoGednerButton
                          width="168px"
                          mgRight=""
                          bdColor="#FF4752"
                          bgColor={
                            loginIdInput.gender === 'F' ? '#FF4752' : '#fff'
                          }
                          color={
                            loginIdInput.gender === 'F' ? '#fff' : '#FF4752'
                          }
                          onClick={() => handleGenderChange('F')}
                        >
                          <GirlImg
                            fill={
                              loginIdInput.gender === 'F' ? '#fff' : '#FF4752'
                            }
                          />
                          <div>여자</div>
                        </ManagerInfoGednerButton>
                      </ManagerItemsButtonContainer>
                      {item.label === '성별' && (
                        <>
                          {errorMessages.genderCheck === true &&
                          loginIdInput.gender === '' ? (
                            <Error mgBottom="6px">{item.error}</Error>
                          ) : (
                            <Error mgBottom="6px"></Error>
                          )}
                        </>
                      )}
                    </ManagerItemsContainer>
                  )}
                </ManagerInputsContainer>
              </div>
            ))}
          </ManagerInfoContainer>

          <ButtonContainer>
            <AddStudentButton
              bgColor="#fff"
              color="#2c55fb"
              onClick={() => setActiveComponent('')}
            >
              취소
            </AddStudentButton>
            <AddStudentButton
              bgColor="#2c55fb"
              color="#fff"
              onClick={handleEditStudentInfo}
            >
              등록
            </AddStudentButton>
          </ButtonContainer>
        </Container>
      </Wrapper>
      {isModal && (
        <Modal>
          <ModalContainer>
            <h1>학생 등록하기를 완료하였습니다.</h1>
            <button onClick={handleAddStudentModal}>확인</button>
          </ModalContainer>
        </Modal>
      )}
      {isLoginIdCheckModal === 'duplication' ? (
        <Modal>
          <ModalContainer>
            <h1>중복된 아이디가 존재합니다.</h1>
            <button onClick={() => handleLoginIdCheckModal('')}>확인</button>
          </ModalContainer>
        </Modal>
      ) : isLoginIdCheckModal === 'NoData' ? (
        <Modal>
          <ModalContainer>
            <h1>아이디를 확인해주세요.</h1>
            <button onClick={() => handleLoginIdCheckModal('')}>확인</button>
          </ModalContainer>
        </Modal>
      ) : isLoginIdCheckModal === 'Success' ? (
        <Modal>
          <ModalContainer>
            <h1>사용 가능한 아이디 입니다.</h1>
            <button onClick={() => handleLoginIdCheckModal('')}>확인</button>
          </ModalContainer>
        </Modal>
      ) : (
        <></>
      )}
      {isLoginIdCheckModal === 'success' && (
        <Modal>
          <ModalContainer>
            <h1>학생 정보가 수정되었습니다.</h1>
            <button onClick={handleEditInfo}>확인</button>
          </ModalContainer>
        </Modal>
      )}
    </>
  );
}

const Wrapper = styled.div`
  position: absolute;
  min-width: 1920px;

  width: 100vw;
  height: 100vh;
  top: 0;
  left: 15px;
`;

const Modal = styled.div`
  position: absolute;
  top: 440px;
  left: 720px;
  z-index: 123;
`;

const ModalContainer = styled.div`
  width: 436px;
  height: 117px;
  padding: 32px;
  border-radius: 16px;
  background: #fff;
  color: #111827;
  text-align: center;
  box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.05);

  /* body/B02-18px-medium */

  font-size: 18px;

  font-weight: 500;
  line-height: 1.6em; /* 28.8px */
  h1 {
    margin-bottom: 32px;
  }
  button {
    width: 200px;
    height: 56px;
    border-radius: 10px;
    color: #fff;
    text-align: center;

    /* body/B03-16px-bold */

    font-size: 16px;

    font-weight: 700;
    line-height: 1.3em; /* 20.8px */
    background-color: #0062ff;
  }
`;

const Background = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 3;
`;

const Container = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 720px;
  height: calc(100vh - 120px);
  min-height: 960px;
  padding: 60px;
  border-radius: 20px 0px 0px 20px;
  background: #fff;
  z-index: 4;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  color: #000;

  /* heading/H02-24px-bold */

  font-size: 24px;

  font-weight: 700;
  line-height: 1.6em; /* 38.4px */
`;

const TitleImg = styled.div`
  width: 24px;
  height: 24px;
  background: url(/images/student.png) no-repeat center;
  background-size: cover;
  margin-right: 12px;
`;

const LoginInfoContainer = styled.div`
  padding-bottom: 19.25px;
  border-bottom: 1px solid #e2e2ea;
  margin-bottom: 69.25px;
`;

const InfoSubTitle = styled.div`
  margin-top: 69.25px;
  margin-bottom: 16px;
  color: #0062ff;

  /* body/B03-16px-medium */

  font-size: 16px;

  font-weight: 500;
  line-height: 1.3em; /* 20.8px */
`;

const InfoLabel = styled.div`
  margin-bottom: 10px;
  color: #000;

  font-size: 14px;

  font-weight: 500;
  line-height: 100%; /* 14px */

  span {
    color: #0062ff;
  }
`;

const InfoInputContainer = styled.div`
  display: flex;
  position: relative;
`;

const InfoInput = styled.input<{ bdColor: string }>`
  width: ${(props) => props.width};
  height: 26px;
  padding: 12px 22px;

  border-radius: 10px;
  border: 1px solid ${(props) => props.bdColor};

  color: #000;

  font-size: 16px;

  font-weight: 400;
  line-height: 1.6em; /* 25.6px */

  ::placeholder {
    color: #b5b5be;
  }

  :disabled {
    color: #b5b5be;

    /* body/B03-16px-medium */

    font-size: 16px;

    font-weight: 500;
    line-height: 1.3em; /* 20.8px */
  }
`;

const ManagerInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 720px;
`;

const ManagerInputs = styled.input<{ mgRight: string; bdColor: string }>`
  width: ${(props) => props.width};
  margin-right: ${(props) => props.mgRight};
  height: 26px;
  padding: 12px 22px;

  border-radius: 10px;
  border: 1px solid ${(props) => props.bdColor};

  color: #000;

  font-size: 16px;

  font-weight: 400;
  line-height: 1.6em; /* 25.6px */

  ::placeholder {
    color: #b5b5be;
  }
`;

const ManagerInputsContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
`;

const ManagerItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const TextCountCheck = styled.div`
  position: absolute;
  top: 17px;
  right: 20px;
  color: #b5b5be;
  text-align: right;

  /* body/B04-14px-medium */

  font-size: 14px;

  font-weight: 500;
  line-height: 1.6em; /* 22.4px */
`;

const ManagerItemsButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const ManagerInfoButton = styled.button<{
  mgRight: string;
  width: string;
  bdColor: string;
  bgColor: string;
}>`
  position: relative;
  display: flex;
  align-items: center;
  width: ${(props) => props.width};
  height: 52px;
  margin-right: ${(props) => props.mgRight};
  border: 1px solid black;
  border-radius: 10px;
  border: 1px solid ${(props) => props.bdColor};
  color: ${(props) => props.color};
  background-color: ${(props) => props.bgColor};

  & > div {
    margin-left: 20px;
  }

  & > span {
    position: absolute;
    top: 13px;
    right: 15px;
  }
`;

const ManagerInfoGednerButton = styled.button<{
  mgRight: string;
  width: string;
  bdColor: string;
  bgColor: string;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(props) => props.width};
  height: 52px;
  margin-right: ${(props) => props.mgRight};
  border: 1px solid black;
  border-radius: 10px;
  border: 1px solid ${(props) => props.bdColor};
  color: ${(props) => props.color};
  background-color: ${(props) => props.bgColor};

  & > div {
    margin-left: 12px;
  }
`;

const DropDown = styled.div<{ left: string }>`
  position: absolute;
  top: 52px;
  left: ${(props) => props.left};
  width: 112px;
  height: 240px;
  overflow-y: scroll;
  border-radius: 8px;
  background: #fff;

  /* shadow */
  box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.08);
  z-index: 2;

  & > button {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100px;
    height: 49px;
    border-bottom: 1px solid #e2e2ea;
    color: #000;

    font-size: 16px;

    font-weight: 400;
    p {
      margin-left: 20px;
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 175.25px;
`;

const AddStudentButton = styled.div<{ bgColor: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 56px;
  border-radius: 10px;
  border: 1px solid #2c55fb;
  background-color: ${(props) => props.bgColor};
  color: ${(props) => props.color};

  text-align: center;

  /* body/B03-16px-bold */

  font-size: 16px;

  font-weight: 700;
  line-height: 1.3em; /* 20.8px */

  :nth-of-type(1) {
    margin-right: 12px;
  }
`;

const Error = styled.div<{ mgBottom: string }>`
  margin-top: 6px;
  margin-bottom: ${(props) => props.mgBottom};
  height: 14px;
  color: #ff4646;

  font-size: 14px;

  font-weight: 500;
  line-height: 100%; /* 14px */
`;

const DeleteButton = styled.div<{ right: string }>`
  position: absolute;
  top: 18px;
  right: ${(props) => props.right};
  width: 16px;
  height: 16px;
  background: url(/images/delete.png) no-repeat center;
  background-size: cover;
  z-index: 999;
`;
