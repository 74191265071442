import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import { setCookie } from '~/api/base';
import { getManagerStatusData } from '~/api/manager/info';
import { getLoginStatusData } from '~/api/manager/login';

import { loginIdAtom, managerDataAtom } from '../stores/manager';

export default function useAuth() {
  const navigate = useNavigate();

  const [user, setUser] = useRecoilState(managerDataAtom);
  const [loginId, setLoginId] = useRecoilState(loginIdAtom);
  const [isLoginInfo, setIsLoginInfo] = useState({ id: '', password: '' });
  const [loginError, setLoginError] = useState({ id: false, password: false });

  const login = async () => {
    try {
      const loginData = await getLoginStatusData({
        loginId: isLoginInfo.id,
        loginPw: isLoginInfo.password,
      });

      if (loginData.data.status === 200) {
        setLoginId(isLoginInfo.id);

        setCookie('accessToken', loginData.data.data.accessToken);
        setCookie('refreshToken', loginData.data.data.refreshToken);

        const {
          data: { data: managerData },
        } = await getManagerStatusData({
          userId: loginData.data.data.userId,
        });

        setUser(managerData);

        navigate('/home/dashboard');
      } else if (
        [10020, 10011, 400, 11012, 20001].includes(loginData.data.status)
      ) {
        setLoginError((prev) => ({
          ...prev,
          id: true,
          password: true,
        }));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const logout = () => {
    setUser(null);
    navigate('/');
  };

  return {
    user,
    loginId,
    login,
    logout,
    isLoginInfo,
    setIsLoginInfo,
    loginError,
    setLoginError,
    setLoginId,
  };
}
