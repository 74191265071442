import { AxiosResponse } from 'axios';

import { GoodsRequestParams, GoodsResponse } from '~/types/goods/find';

import api from '~/api/base';

const BASE_URL = 'api/v1/goods/user/find';

export const getGoodsStatusData = (
  param: GoodsRequestParams,
): Promise<AxiosResponse<GoodsResponse>> =>
  api.post(`${BASE_URL}/status`, param);
