import styled from '@emotion/styled';

import { ReactComponent as Joycog97 } from '~/assets/joycog97.svg';

export default function PageOne() {
  return (
    <Wrapper>
      <Nav />
      <TitleContainer>
        <JoyCogImg>
          <Joycog97 />
        </JoyCogImg>
        <Title>초등학생을 위한</Title>
        <Title>인지측정 리포트</Title>

        <SubTitle>- 주의집중력, 충동조절능력 저하 가능성 측정</SubTitle>
        <SubTitle>- 4가지 인지능력 측정</SubTitle>
        <SubTitle>- 추천 솔루션 제공</SubTitle>
        <JoyCogReportImg />
      </TitleContainer>
      <BottomContainer>
        <Hospital1 />
        <Hospital2 />
        <Hospital3 />
        <Hospital4 />
        <Hospital5 />
      </BottomContainer>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Nav = styled.div`
  height: 68px;
`;

const JoyCogImg = styled.div`
  width: 140px;
  height: 40px;
  margin-left: 52px;
  margin-bottom: 7.65px;
`;

const TitleContainer = styled.div`
  width: 595px;
`;

const Title = styled.div`
  margin-left: 52px;

  color: #000;

  font-size: 36px;

  font-weight: 800;
  line-height: 1.5em; /* 54px */

  :nth-of-type(3) {
    margin-bottom: 10.57px;
  }
`;

const SubTitle = styled.div`
  margin-left: 52px;
  color: #000;

  font-size: 12px;

  font-weight: 600;
  line-height: 1.6em; /* 19.2px */
`;

const JoyCogReportImg = styled.div`
  width: 414px;
  height: 383.51px;
  margin-left: 100px;
  margin-top: 5.78px;
  margin-bottom: 61.49px;
  background: url(/images/ReportImg.png) no-repeat center;
  background-size: center;
`;

const BottomContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 595px;
  height: 100px;
  background: #5a79e9;
`;

const Hospital1 = styled.div`
  width: 90px;
  height: 12px;
  margin-right: 20px;
  background: url(/images/hospital1.png) no-repeat center;
  background-size: center;
`;

const Hospital2 = styled.div`
  width: 60px;
  height: 17px;
  margin-right: 20px;
  background: url(/images/hospital2.png) no-repeat center;
  background-size: center;
`;

const Hospital3 = styled.div`
  width: 98px;
  height: 13px;
  margin-right: 20px;
  background: url(/images/hospital3.png) no-repeat center;
  background-size: center;
`;

const Hospital4 = styled.div`
  width: 73px;
  height: 13px;
  margin-right: 20px;
  background: url(/images/hospital4.png) no-repeat center;
  background-size: center;
`;

const Hospital5 = styled.div`
  width: 91px;
  height: 16px;
  background: url(/images/hospital5.png) no-repeat center;
  background-size: center;
`;
