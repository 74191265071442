import styled from '@emotion/styled';

interface Props {
  transformedData?: { [key: string]: number | string };
  dateFilter: (value: string | Date | undefined | number) => string;
}

const HIGH_INATT = (
  <>
    것으로 보입니다. 과제와 상관없는 방해 요소에 주의를 뺏기지 않고 목표에
    집중할 수 있습니다. 아이는 비교적 오랜 시간 집중력을 유지하여 한번 시작한
    일을 끝까지 해냅니다. 세부 사항도 놓치지 않고 챙길 수 있습니다.
  </>
);

const NOMAL_INATT = (
  <>
    수준으로 보입니다. 과제와 상관없는 방해 요소에 주의를 뺏기지 않고 집중할 수
    있는 능력이 또래와 비슷합니다. 아이는 집중력을 유지하고 세부 사항을 놓치지
    않을 수 있습니다. 집중하는 시간을 점차 늘려가는 훈련을 한다면 주의력이 더욱
    향상될 수 있습니다.
  </>
);

const LOW_INATT = (
  <>
    것으로 보입니다. 주의가 산만한 경향이 있으며 간혹 멍하게 보일 때도 있습니다.
    아이는 해야 할 일을 끝까지 해내는 것을 어려워할 수 있습니다. 집중시간이
    또래에 비해 짧은 편이기 때문에 집중하는 시간을 점차 늘려가는 훈련이 도움이
    될 수 있습니다.
  </>
);

const LOWEST_INATT = (
  <>
    것으로 보입니다. 주의가 쉽게 산만해지는 경향이 있으며 종종 멍하게 보일 때가
    있습니다. 아이는 해야 할 일을 끝까지 해내는 것을 어려워 합니다. 또래에 비해
    쉽게 지루해 하기 때문에 집중하는 시간을 점차 늘려가는 훈련이 도움이 될 수
    있습니다.
  </>
);

const HIGH_SPEED = (
  <>
    것으로 보입니다. 환경으로부터 정보를 받아들이고 활용하는 속도가 빠른
    편입니다. 아이는 간단한 과제나 활동에서 빠르고 정확하게 반응할 수 있습니다.
    빠른 처리 속도는 읽기, 연산 활동의 밑바탕이 되기도 합니다.
  </>
);

const NOMAL_SPEED = (
  <>
    에 속하는 것으로 보입니다. 환경으로부터 정보를 받아들이고 활용하는 속도가
    또래와 비슷합니다. 아이는 간단한 과제나 활동에서 적절한 속도로 반응합니다.
    퍼즐 완성 시간을 점점 줄여나가는 놀이를 통해 처리속도를 더욱 향상시킬 수
    있습니다.
  </>
);

const LOW_SPEED = (
  <>
    것으로 보입니다. 환경으로부터 정보를 받아들이고 활용하는 데 시간이 다소
    걸립니다. 아이는 과제나 활동에서의 반응이 느린 편입니다. 시간적 압박은
    처리속도를 더욱 느려지게 할 수 있으니 충분한 시간을 제공하는 등 여유로운
    분위기를 만들어주어야 합니다.
  </>
);

const LOWEST_SPEED = (
  <>
    것으로 보입니다. 환경으로부터 정보를 받아들이고 활용하는 속도가 또래에 비해
    느린 편입니다. 어린이는 과제나 활동에서의 반응이 느립니다. 시간적 압박은
    처리속도를 더욱 느려지게 할 수 있으니 충분한 시간을 제공하는 등 여유로운
    분위기를 만들어주어야 합니다.
  </>
);

const HIGH_HYPERMUL = (
  <>
    것으로 보입니다. 미래의 보상을 위해 현재의 사고와 행동을 적절히 조절할 수
    있습니다. 어린이는 욕구가 좌절될 때도 인내할 수 있는 능력을 발휘합니다. 이는
    즉각적인 해결책이 없는 과제를 해결할 때 두드러질 수 있습니다.
  </>
);

const NOMAL_HYPERMUL = (
  <>
    수준에 속하는 것으로 보입니다. 행동에 수반되는 결과를 예상한 뒤 사고하고
    행동할 수 있으며, 자신의 행동 반경을 조절할 수 있습니다. 아이는 자기조절
    능력을 발휘합니다. 또, 차례를 지키는 등의 사회적 규칙을 준수할 수 있습니다.
  </>
);

const LOW_HYPERMUL = (
  <>
    것으로 보입니다. 행동이 생각보다 앞서는 편이며, 가만히 있는 것을 어려워할 수
    있습니다. 아이는 참을성이 부족하고 성급한 경향을 보일 때가 있습니다.
    에너지를 발산할 수 있는 운동을 즐기도록 하는 것이 도움이 됩니다.
  </>
);

const LOWEST_HYPERMUL = (
  <>
    것으로 보입니다. 행동이 생각을 앞서기 때문에 예기치 않은 상황이 생길 수
    있으며, 가만히 있는 것을 어려워합니다. 아이는 자기조절 능력이 부족하기
    때문에 친구들을 방해하거나 규칙을 지키지 않을 때가 있습니다. 에너지를 발산할
    수 있는 운동을 즐기도록 하는 것이 도움이 될 수 있습니다.
  </>
);
export default function PagThree({ transformedData, dateFilter }: Props) {
  return (
    <Wrapper>
      <Nav>
        <div>조이코그: 초등학생을 위한 인지측정 리포트</div>
        <div>측정일 : {dateFilter(transformedData?.screenDate)}</div>
      </Nav>
      <Title>인지능력 측정 결과</Title>
      <Container>
        <ReportResultImg />
        <ItemsContainer>
          <Bold>
            <IconImg url="/images/inatt.png" />
            주의력
          </Bold>
          <div>
            어린이의 주의력은{' '}
            <Data
              color={
                transformedData?.inatt === '낮음'
                  ? '#EF5C5C'
                  : transformedData?.inatt === '약간 낮음'
                  ? '#F29E00'
                  : transformedData?.inatt === '보통'
                  ? '#39B974'
                  : '#5079F3'
              }
            >
              {transformedData?.inatt === '낮음'
                ? '낮은'
                : transformedData?.inatt === '약간 낮음'
                ? '약간 낮은'
                : transformedData?.inatt === '보통'
                ? '보통'
                : '높은'}
            </Data>{' '}
            {transformedData?.inatt === '낮음'
              ? LOWEST_INATT
              : transformedData?.inatt === '약간 낮음'
              ? LOW_INATT
              : transformedData?.inatt === '보통'
              ? NOMAL_INATT
              : HIGH_INATT}
          </div>
          <Bold>주의력이란?</Bold>
          <div>
            외부 환경의 방해 요소를 통제하고, 특정 활동에 지속적으로 의식을
            집중시킬 수 있는 능력을 말합니다.
          </div>
        </ItemsContainer>
        <ItemsContainer>
          <Bold>
            <IconImg url="/images/hypermul.png" />
            충동조절 / 행동안정
          </Bold>
          <div>
            어린이의 충동조절/행동안정은{' '}
            <Data
              color={
                transformedData?.hypermul === '낮음'
                  ? '#EF5C5C'
                  : transformedData?.hypermul === '약간 낮음'
                  ? '#F29E00'
                  : transformedData?.hypermul === '보통'
                  ? '#39B974'
                  : '#5079F3'
              }
            >
              {' '}
              {transformedData?.hypermul === '낮음'
                ? '낮은'
                : transformedData?.hypermul === '약간 낮음'
                ? '약간 낮은'
                : transformedData?.hypermul === '보통'
                ? '보통'
                : '높은'}
            </Data>{' '}
            {transformedData?.hypermul === '낮음'
              ? LOWEST_HYPERMUL
              : transformedData?.hypermul === '약간 낮음'
              ? LOW_HYPERMUL
              : transformedData?.hypermul === '보통'
              ? NOMAL_HYPERMUL
              : HIGH_HYPERMUL}
          </div>
          <Bold>충동조절 / 행동안정이란?</Bold>
          <div>
            충동조절이란 당장의 욕구보다 미래의 보상을 생각하여 생각과 행동을
            통제할 수 있는 능력을 말합니다. 행동안정이란 상황에 맞게 움직임을
            조절할 수 있는 능력을 말합니다.
          </div>
        </ItemsContainer>
        <ItemsContainers>
          <Bold>
            <IconImg url="/images/speed.png" />
            처리속도
          </Bold>
          <div>
            어린이의 처리속도는{' '}
            <Data
              color={
                transformedData?.speed === '낮음'
                  ? '#EF5C5C'
                  : transformedData?.speed === '약간 낮음'
                  ? '#F29E00'
                  : transformedData?.speed === '보통'
                  ? '#39B974'
                  : '#5079F3'
              }
            >
              {transformedData?.speed === '낮음'
                ? '낮은'
                : transformedData?.speed === '약간 낮음'
                ? '약간 낮은'
                : transformedData?.speed === '보통'
                ? '보통'
                : '높은'}
            </Data>{' '}
            {transformedData?.speed === '낮음'
              ? LOWEST_SPEED
              : transformedData?.speed === '약간 낮음'
              ? LOW_SPEED
              : transformedData?.speed === '보통'
              ? NOMAL_SPEED
              : HIGH_SPEED}
          </div>
          <Bold>처리속도란?</Bold>
          <div>
            외부 환경으로부터 다양한 정보를 받아들여 빠르고 정확하게 처리하는
            능력을 말합니다.
          </div>
        </ItemsContainers>
      </Container>
      <LogoImg />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: 842px;

  & > h2 {
    margin-bottom: 9.75px;

    color: #000;

    font-size: 14px;

    font-weight: 800;
    line-height: 1.6em;
  }
`;

const Nav = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 595px;
  height: 46px;
  background: #5a79e9;
  color: #fff;

  font-size: 10px;

  font-weight: 600;
  line-height: 1.6em;

  & > div {
    :nth-of-type(1) {
      margin-right: 229px;
    }
  }
`;

const Title = styled.div`
  margin-top: 24px;
  margin-bottom: 10px;
  color: #000;

  font-size: 14px;

  font-weight: 800;
  line-height: 1.6em;
`;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 520px;
  border-radius: 10px;
  border: solid 0.5px #cbcbcb;
`;

const ReportResultImg = styled.div`
  width: 252px;
  height: 26px;
  margin-top: 25px;
  margin-bottom: 11px;
  background: url(/images/reportResult.png) no-repeat center;
  background-size: contain;
`;

const ItemsContainer = styled.div`
  width: 80%;
  padding-bottom: 17px;
  margin-bottom: 17px;
  border-bottom: 1px solid #ebebeb;
  color: #000;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.6em;
  letter-spacing: -0.5px;

  & > div {
    :nth-of-type(3) {
      margin-top: 7px;
    }
  }
`;

const IconImg = styled.div<{ url: string }>`
  width: 20px;
  height: 20px;
  margin-right: 4px;
  margin-bottom: 2px;
  background: url(${(props) => props.url}) no-repeat center;
  background-size: center;
`;

const Bold = styled.div`
  display: flex;
  align-items: center;
  margin-top: 7px;
  font-size: 13px;
  font-weight: bold;
  font-stretch: normal;
  color: #101010;
`;

const ItemsContainers = styled.div`
  width: 80%;
  margin-bottom: 25px;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;

  line-height: 1.67;
  letter-spacing: -0.12px;
  color: #101010;

  & > div {
    :nth-of-type(3) {
      margin-top: 7px;
    }
  }
`;

const Data = styled.span`
  color: ${(props) => props.color};
  font-size: 12px;
  font-weight: 600;
  line-height: 1.6em;
  letter-spacing: -0.4px;
`;
const LogoImg = styled.div`
  position: absolute;
  bottom: 10px;
  left: 253px;
  width: 90px;
  height: 36px;
  background: url(/images/JoyCog90.png) no-repeat center;
  background-size: contain;
`;
