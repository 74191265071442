import styled from '@emotion/styled';
import { useEffect, useState } from 'react';

import { PostFindData } from '~/types/post/find';
import { PostList } from '~/types/post/search';

import { ReactComponent as DiamondImg } from '~/assets/DIamond.svg';
import { ReactComponent as TicketImg } from '~/assets/Ticket.svg';
import { ReactComponent as Plus } from '~/assets/plus.svg';

import dateFilter from '~/utils/dateFilter/dateFilter';

import { getSendMailStatusData } from '~/api/post/find';
import { getMailSearchStatusData } from '~/api/post/search';

import useAuth from '~/hooks/useAuth';

import NewSendMail from '~/components/mailBox/sendMailBox/NewSendMail';
import NewSendMailDefault from '~/components/mailBox/sendMailBox/NewSendMailDefault';
import RecipientList from '~/components/mailBox/sendMailBox/RecipientList';

export default function Index() {
  const [isModal, setIsModal] = useState(false);
  const [pushList, setPushList] = useState<PostList[]>();
  const [isGroupId, setIsGroupId] = useState<number | undefined>();
  const [activeGroupId, setActiveGroupId] = useState<number | null>(null);
  const [data, setData] = useState<PostFindData>();
  const [searchPushList, setSearchPushList] = useState('');
  const [activeComponent, setActiveComponent] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const { user } = useAuth();

  useEffect(() => {
    const fetchDataForGroupId = async () => {
      if (!user) {
        return;
      }
      if (isGroupId !== undefined) {
        try {
          const pushFindData = await getSendMailStatusData({
            userId: user?.userId,
            groupId: isGroupId,
          });
          if (pushFindData?.data?.data?.postDetails?.childInfo) {
            setActiveGroupId(isGroupId);
            setData(pushFindData.data.data);
          } else {
            console.error(
              'childInfo is undefined or empty in the API response',
            );
          }
        } catch (error) {
          console.error(error);
        }
      }
    };

    fetchDataForGroupId();
  }, [isGroupId]);

  const fetchData = async () => {
    if (!user) {
      return;
    }
    try {
      const { data } = await getMailSearchStatusData({
        userId: user?.userId,
      });

      if (data?.data?.postList) {
        const reversedPushList = data.data.postList.reverse();
        setPushList(reversedPushList);

        if (reversedPushList?.length !== 0) {
          setIsGroupId(reversedPushList[0]?.groupId);
        }
      } else {
        console.error('postList is undefined or empty in the API response');
      }

      setIsLoading(true);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleModal = () => {
    setIsModal((prev) => !prev);
  };

  const handleListGroupId = (groupId: number | undefined) => {
    setIsGroupId(groupId);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchPushList(event.target.value);
  };

  const filteredPushList = pushList?.filter((item) =>
    item.subTitle?.toLowerCase().includes(searchPushList?.toLowerCase()),
  );

  const DetailNameList = () => {
    if (!data || !data.postDetails || !data.postDetails.childInfo) return null;

    const maxItemsToShow = 10;
    const totalItems = data.postDetails.childInfo.length;

    const mappedItems = data.postDetails.childInfo
      .slice(0, maxItemsToShow)
      .map((item, index) => {
        const isLastItem = index === maxItemsToShow - 1;
        const separator =
          isLastItem || index === Math.min(maxItemsToShow - 1, totalItems - 1)
            ? ''
            : ', ';

        return (
          <span key={item.childId}>
            <DetailNames>
              {item.name}({item.loginId}){separator}
            </DetailNames>
          </span>
        );
      });

    if (totalItems > maxItemsToShow) {
      return (
        <>
          {mappedItems}
          <span>... + {totalItems - maxItemsToShow}</span>
        </>
      );
    }

    return <>{mappedItems}</>;
  };

  const moveComponent = (id: string) => {
    setActiveComponent(id);
  };

  return (
    <Wrapper>
      {isLoading && (
        <>
          {pushList?.length === 0 ? (
            <>
              <TopContainer>
                <div>보낸 우편함</div>
                <TopContainerItems>
                  <ButtonSection>
                    <TopContainerImg />
                    <span>
                      학생에게 우편을 보내면, 해당 학생이 앱에 접속 후
                      우편함에서 우편을 확인할 수 있습니다.
                    </span>
                  </ButtonSection>
                  <button onClick={() => moveComponent('NewSendPushDefault')}>
                    <Plus />
                    <p>새 우편 보내기</p>
                  </button>
                </TopContainerItems>
              </TopContainer>
              <NoData>
                <Round></Round>
                <PushImg />
                <h1>보낸 우편이 아직 없습니다.</h1>
                <h2>
                  새 우편 보내기 버튼을 클릭하여 학생에게 우편을 보내보세요!
                </h2>
                <button onClick={() => moveComponent('NewSendPushDefault')}>
                  새 우편 보내기
                </button>
              </NoData>
            </>
          ) : (
            <>
              <TopContainer>
                <div>보낸 우편함</div>
                <button onClick={() => moveComponent('NewSendPushDefault')}>
                  <Plus />
                  <p>새 우편 보내기</p>
                </button>
              </TopContainer>
              <ItemsContainer>
                <ItemList>
                  <InputContainer>
                    <input
                      placeholder="찾을 우편의 제목을 입력해주세요"
                      type="text"
                      value={searchPushList}
                      onChange={handleSearchChange}
                    />
                    <SearchIcon />
                  </InputContainer>
                  <ListContainer />
                  <ItemListScrollContainer>
                    {filteredPushList?.map((item) => (
                      <div key={item.groupId}>
                        <List
                          onClick={() => handleListGroupId(item.groupId)}
                          active={activeGroupId === item.groupId}
                        >
                          <ListTitle active={activeGroupId === item.groupId}>
                            {item.nameList && (
                              <>
                                {item.nameList
                                  .split(', ')
                                  .slice(0, 3)
                                  .join(', ')}
                                {item.nameList.split(', ').length > 3 &&
                                  ` + ${item.nameList.split(', ').length - 3}`}
                              </>
                            )}
                          </ListTitle>
                          <ListInfo active={activeGroupId === item.groupId}>
                            {item.subTitle}
                          </ListInfo>
                          <ListItemsContainer>
                            <ListDate active={activeGroupId === item.groupId}>
                              {dateFilter(item.sendDate)}
                            </ListDate>
                            <ListItem>
                              {item.m002 === 'Y' && (
                                <Diamond>
                                  <DiamondImg />
                                  <div>다이아</div>
                                </Diamond>
                              )}
                              {item.m003 === 'Y' && (
                                <Ticket>
                                  <TicketImg />
                                  <div>이용권</div>
                                </Ticket>
                              )}
                            </ListItem>
                          </ListItemsContainer>
                        </List>
                      </div>
                    ))}
                  </ItemListScrollContainer>
                </ItemList>
                <ItemWrapper>
                  <ItemTitle>
                    <ItemName>{DetailNameList()}</ItemName>
                    <ItemDate>
                      {dateFilter(data?.postDetails.sendDate)}
                    </ItemDate>
                    <ItemIconButton onClick={handleModal}></ItemIconButton>
                    {isModal && (
                      <Modal>
                        <button onClick={() => moveComponent('RecipientList')}>
                          수신자 리스트 보기
                        </button>
                        <button onClick={() => moveComponent('NewSendPush')}>
                          해당 아동에게 새 우편 보내기
                        </button>
                      </Modal>
                    )}
                  </ItemTitle>
                  <ItemInfo>
                    <InfoTitle>{data?.postDetails.title}</InfoTitle>
                    <TextContainer>
                      <ItemInfoTitle>단문</ItemInfoTitle>
                      <InfoText>{data?.postDetails.subTitle}</InfoText>
                    </TextContainer>
                    <TextContainer>
                      <ItemInfoTitle>본문</ItemInfoTitle>
                      <InfoText>{data?.postDetails.content}</InfoText>
                    </TextContainer>
                    <TextContainer>
                      <ItemInfoTitle>다이아 첨부</ItemInfoTitle>
                      {data?.postDetails.m002 !== null ? (
                        <InfoText>첨부({data?.postDetails.m002}개)</InfoText>
                      ) : (
                        <InfoText>미첨부</InfoText>
                      )}
                    </TextContainer>
                  </ItemInfo>
                </ItemWrapper>
              </ItemsContainer>
            </>
          )}
          {activeComponent === 'RecipientList' && (
            <RecipientList
              setActiveComponent={setActiveComponent}
              childInfo={data?.postDetails.childInfo}
            />
          )}
          {activeComponent === 'NewSendPush' && (
            <NewSendMail
              setActiveComponent={setActiveComponent}
              childInfo={data?.postDetails?.childInfo}
            />
          )}
          {activeComponent === 'NewSendPushDefault' && (
            <NewSendMailDefault setActiveComponent={setActiveComponent} />
          )}
        </>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const TopContainer = styled.div`
  width: 1332px;
  height: 50px;
  display: flex;
  justify-content: space-between;
  margin: 24px 0;

  color: #000;
  font-size: 24px;

  font-weight: 700;
  line-height: 1.6em;

  button {
    width: 160px;
    height: 50px;
    border-radius: 10px;
    border: 1px solid #0062ff;
    background: #0062ff;

    color: #fff;
    font-size: 16px;

    font-weight: 500;
    line-height: 1.3em;
    margin-left: 12px;

    p {
      margin-left: 7px;
      display: inline;
    }
  }
`;

const TopContainerItems = styled.div`
  display: flex;
`;

const ButtonSection = styled.div`
  display: flex;
  align-items: center;
  color: #8c9299;
  font-size: 14px;

  font-weight: 500;
  background: #f6f6f6;
  border-radius: 8px;
  span {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    margin-right: 12px;
  }
`;

const TopContainerImg = styled.div`
  width: 24px;
  height: 24px;
  padding-left: 12px;
  padding-right: 4px;
  background: url(/images/question-circle.png) no-repeat center;
  background-size: center;
`;

const ItemsContainer = styled.div`
  display: flex;
`;

const ItemList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 450px;
  height: 818px;

  input {
    width: 410px;
    height: 58px;
    padding: 0;
    padding-left: 20px;
    border-radius: 10px;
    border: 1px solid #e2e2ea;
    margin-top: 12px;
    margin-bottom: 20px;

    ::placeholder {
      color: #8c9299;
      font-size: 14px;

      font-weight: 500;
      line-height: 1.6em;
    }
  }
`;

const InputContainer = styled.div`
  position: relative;
`;

const SearchIcon = styled.div`
  position: absolute;
  width: 17px;
  height: 17px;
  top: 34px;
  right: 20px;
  background: url(/images/search.png) no-repeat center;
  background-size: contain;
`;

const ItemWrapper = styled.div`
  width: 882px;
  height: 818px;
  border-left: 1px solid #e2e2ea;
  background: #f6f6f6;
`;

const ListContainer = styled.div`
  width: 100%;
  border-bottom: 1px solid #e2e2ea;
`;

const ItemListScrollContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
`;

const List = styled.button<{ active: boolean }>`
  display: flex;
  flex-direction: column;
  width: 418px;
  height: 111px;
  margin: 10px 0;
  border-radius: 16px;
  background: ${(props) => (props.active ? '#f0f6ff' : '#fff')};
`;

const ListTitle = styled.div<{ active: boolean }>`
  margin: 16px 16px 0 16px;
  color: ${(props) => (props.active ? '#0062FF' : '#000')};
  text-overflow: ellipsis;
  font-size: 16px;

  font-weight: 700;
  line-height: 1.6em;
`;

const ListInfo = styled.div<{ active: boolean }>`
  margin: 0 16px;
  color: ${(props) => (props.active ? '#0062FF' : '#000')};
  font-size: 14px;

  font-weight: 400;
  line-height: 1.6em;
`;

const ListDate = styled.div<{ active: boolean }>`
  margin: 12px 16px 16px 16px;
  color: ${(props) => (props.active ? '#0062FF' : '#b5b5be;')};
  font-size: 14px;

  font-weight: 400;
  line-height: 1.6em;
`;

const ItemTitle = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 834px;
  height: 90px;

  margin: 0 24px 24px 24px;
  border-bottom: 1px solid #e2e2ea;
`;

const ItemName = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 657px;
  height: 44px;
  margin-right: 24px;
  color: #65696d;
  font-size: 14px;

  font-weight: 500;
  line-height: 1.6em;
`;

const ItemDate = styled.div`
  width: 120px;
  height: 44px;
  margin-right: 16px;

  color: #8c9299;
  font-size: 14px;

  font-weight: 400;
  line-height: 1.6em;
`;

const ItemIconButton = styled.div`
  width: 24px;
  height: 24px;
  margin-bottom: 20px;
  background: url(/images/dots.png) no-repeat center;
  background-size: contain;
  cursor: pointer;
`;

const ItemInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 834px;
  margin: 0 24px;
  border-radius: 16px;
  border: 1px solid #e2e2ea;
  background: #fff;
`;

const InfoTitle = styled.div`
  width: 786px;
  height: 29px;
  padding: 16px 0;
  border-bottom: 1px solid #e2e2ea;

  color: #111827;

  font-size: 18px;

  font-weight: 500;
  line-height: 1.6em;
`;

const InfoText = styled.div`
  width: 626px;
  padding: 16px 0;

  color: #292d32;
  font-size: 14px;

  font-weight: 500;
  line-height: 1.6em;
  white-space: pre-line;
`;

const Modal = styled.div`
  position: absolute;
  right: -11px;
  top: 58px;
  width: 211px;
  height: 100px;
  background-color: #fff;
  border-radius: 8px;

  button {
    display: flex;
    width: 100%;
    padding: 14px 0 14px 26px;
    color: #000;
    font-size: 14px;

    font-weight: 500;
    line-height: 1.6em;

    :nth-of-type(1) {
      border-bottom: 1px solid #e2e2ea;
    }
  }
`;

const DetailNames = styled.span`
  margin-right: 5px;
`;

const NoData = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 1332px;
  height: 818px;
  border-radius: 16px;
  background: #f6f6f6;

  h1 {
    margin-top: 381.5px;
    margin-bottom: 15px;
    color: #65696d;
    font-size: 20px;

    font-weight: 700;
    line-height: 1.6em;
    letter-spacing: -0.3px;
  }

  h2 {
    margin-bottom: 124px;
    color: #65696d;
    font-size: 18px;

    font-weight: 500;
    line-height: 1.6em;
  }

  button {
    width: 480px;
    height: 56px;
    border-radius: 10px;
    background: #0062ff;

    color: #fff;
    font-size: 18px;

    font-weight: 700;
    line-height: 1.3em;
  }
`;

const Round = styled.div`
  position: absolute;
  top: 179.5px;
  left: 527px;
  width: 278px;
  height: 278px;
  border-radius: 278px;
  background: linear-gradient(
    180deg,
    rgba(52, 103, 255, 0.08) 0%,
    rgba(84, 81, 255, 0) 100%
  );
`;

const PushImg = styled.div`
  width: 111px;
  height: 77px;
  top: 265px;
  left: 602.66px;
  position: absolute;
  background: url(/images/ReceiveImg.png) no-repeat center;
  background-size: contain;
`;

const ListItemsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 386px;
`;

const ListItem = styled.div`
  display: flex;
`;

const Diamond = styled.div`
  width: 70px;
  height: 23px;
  margin-right: 5px;
  border-radius: 100px;
  background: linear-gradient(180deg, #3467ff 0%, #5451ff 100%);

  display: flex;
  align-items: center;
  justify-content: center;
  div {
    margin-left: 4px;
    color: #fff;

    font-size: 12px;

    font-weight: 400;
    line-height: 1.6em; /* 19.2px */
  }
`;

const Ticket = styled.div`
  width: 70px;
  height: 23px;
  margin-right: 5px;
  border-radius: 100px;
  background: linear-gradient(180deg, #3467ff 0%, #5451ff 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  div {
    margin-left: 4px;
    color: #fff;

    font-size: 12px;

    font-weight: 400;
    line-height: 1.6em; /* 19.2px */
  }
`;

const ItemInfoTitle = styled.div`
  margin-right: 10px;
  width: 150px;
  padding: 16px 0;
  display: flex;
  align-items: center;
  color: #65696d;

  /* body/B04-14px-medium */

  font-size: 14px;

  font-weight: 500;
  line-height: 1.6em; /* 22.4px */
`;

const TextContainer = styled.div`
  display: flex;
`;
