import styled from '@emotion/styled';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import moment from 'moment';
import { useEffect, useState } from 'react';

import { ReportResponse } from '~/types/student/search';

import { ReactComponent as PdfImg } from '~/assets/pdf.svg';

import { getReportStatusData } from '~/api/student/search';

import PageFive from '~/components/studentManagement/measurement/Report/PageFive';
import PageFour from '~/components/studentManagement/measurement/Report/PageFour';
import PageOne from '~/components/studentManagement/measurement/Report/PageOne';
import PageThree from '~/components/studentManagement/measurement/Report/PageThree';
import PageTwo from '~/components/studentManagement/measurement/Report/PageTwo';

interface Props {
  setActiveComponent: React.Dispatch<React.SetStateAction<string>>;
  childId?: number | null;
}

export default function Index({ setActiveComponent, childId }: Props) {
  const [reportData, setReportData] = useState<ReportResponse>();
  const [screenCount, setScreenCount] = useState<number>(0);
  const [screenDate, setScreenDate] = useState('');

  const convertToImg = async () => {
    const container = document.querySelector('.div_container');
    if (!container) {
      return [];
    }

    const pages = container.querySelectorAll('.div_paper');
    const images: string[] = [];

    for (let i = 0; i < pages.length; i++) {
      const canvas = await html2canvas(pages[i] as HTMLElement, {
        width: 595,
        height: 842,
        scale: 3,
      });

      const imageFile = canvas.toDataURL('image/png', 1);
      images.push(imageFile);
    }

    return images;
  };

  const convertToPdf = (images: string[], fileName: string) => {
    const doc = new jsPDF('p', 'mm', 'a4');
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();

    for (let i = 0; i < images.length; i++) {
      doc.addImage(images[i], 'JPEG', 0, 0, pageWidth, pageHeight);

      if (i !== images.length - 1) {
        doc.addPage();
      }
    }

    doc.save(fileName);
  };

  const pdfDateFilter = (value?: string | Date | null) => {
    const countryTime = value ? moment(value).format('YYMMDD') : '';
    return countryTime;
  };

  const handleButtonClick = async () => {
    const pdfFileName = `${reportData?.data.studentInfo.name}(${pdfDateFilter(
      reportData?.data.studentInfo.birth,
    )})의 인지능력리포트_${dateFilter(screenDate)}.pdf`;
    const images = await convertToImg();
    convertToPdf(images, pdfFileName);
  };

  useEffect(() => {
    getReportStatusData({ childId: childId as number })
      .then(({ data }) => {
        setReportData(data);

        if (data?.data?.report && data.data.report.length > 0) {
          setScreenCount(data.data.report[0].screenCount);
          setScreenDate(data.data.report[0].screenDate);
        }
      })
      .catch(console.error);
  }, []);

  const titleDateFilter = (value?: string | Date | null) => {
    const countryTime = value ? moment(value).format('YYYY-MM-DD') : '';
    return countryTime;
  };

  const dateFilter = (value: string | Date | undefined | number) => {
    const GMT = moment().format('Z');

    const [gmtSign, gmtValue] = GMT.split(':');
    const gmtHours = parseInt(gmtSign);
    const gmtMinutes = parseInt(gmtValue);

    const countryTime = moment
      .utc(value)
      .utcOffset(gmtHours * 60 + gmtMinutes)
      .format('YYYY.MM.DD');
    return countryTime;
  };

  const handleScreenCountCheck = (screenCount: number, screenDate: string) => {
    setScreenCount(screenCount);
    setScreenDate(screenDate);
  };

  const filteredReportData = reportData?.data?.report?.filter(
    (item) => item.screenCount === screenCount,
  );

  const transformedData: { [key: string]: string | number } = {};
  filteredReportData?.forEach((item) => {
    Object.entries(item).forEach(([key, value]) => {
      transformedData[key] = value;
    });
  });

  return (
    <Wrapper>
      <Container>
        <TitleInfo>
          <NameSecction>
            <Name>
              <div>{reportData?.data.studentInfo.name}</div>
              <GenderImgConatiner>
                <GenderImg
                  url={
                    reportData?.data.studentInfo.gender === 'F'
                      ? '/images/girl.png'
                      : '/images/boy.png'
                  }
                />
              </GenderImgConatiner>
            </Name>
            <Date>{titleDateFilter(reportData?.data.studentInfo.birth)}</Date>
          </NameSecction>
          <ClassInfoSecction>
            <ClassInfo>
              <div>{reportData?.data.studentInfo.classInfo}</div>
              <div>인지능력 측정 결과</div>
              <ScreenStatus
                color={
                  reportData?.data.studentInfo.screenResult === '양호함'
                    ? '#0CAF60'
                    : reportData?.data.studentInfo.screenResult === '관심 필요'
                    ? '#F0A95A'
                    : reportData?.data.studentInfo.screenResult === '주의 요망'
                    ? '#FF4752'
                    : ''
                }
                bgColor={
                  reportData?.data.studentInfo.screenResult === '양호함'
                    ? '#EFFCF6'
                    : reportData?.data.studentInfo.screenResult === '관심 필요'
                    ? '#FFFBEC'
                    : reportData?.data.studentInfo.screenResult === '주의 요망'
                    ? '#FFF2F3'
                    : ''
                }
              >
                {reportData?.data.studentInfo.screenResult === '양호함'
                  ? '양호단계'
                  : reportData?.data.studentInfo.screenResult === '관심 필요'
                  ? '관심단계'
                  : reportData?.data.studentInfo.screenResult === '주의 요망'
                  ? '주의단계'
                  : ''}
              </ScreenStatus>
            </ClassInfo>
            <Info>{reportData?.data.studentInfo.characteristic}</Info>
          </ClassInfoSecction>
          <ButtonSecction>
            <ChanginInfoButton
              onClick={() => setActiveComponent('EditStudent')}
            >
              학생정보 수정
            </ChanginInfoButton>
          </ButtonSecction>
        </TitleInfo>
        <ReportTitle>인지능력 리포트</ReportTitle>
        <ReportDateFilter>
          {reportData?.data.report?.map((item) => (
            <DateButton
              key={item.screenCount}
              onClick={() =>
                handleScreenCountCheck(item.screenCount, item.screenDate)
              }
              active={item.screenCount === screenCount}
            >
              {dateFilter(item.screenDate)}
            </DateButton>
          ))}
        </ReportDateFilter>
        <ReportContainer>
          <PdfButton onClick={handleButtonClick}>
            <PdfImg style={{ marginRight: '8px' }} />
            pdf 다운로드
          </PdfButton>
          <div>
            <div className="div_container">
              <div className="div_paper">
                <PageOne />
              </div>
              <div className="div_paper">
                <PageTwo
                  transformedData={transformedData}
                  reportData={reportData}
                  dateFilter={dateFilter}
                />
              </div>
              <div className="div_paper">
                <PageThree
                  transformedData={transformedData}
                  dateFilter={dateFilter}
                />
              </div>
              <div className="div_paper">
                <PageFour
                  transformedData={transformedData}
                  dateFilter={dateFilter}
                />
              </div>
              <div className="div_paper">
                <PageFive
                  transformedData={transformedData}
                  dateFilter={dateFilter}
                />
              </div>
            </div>
          </div>
        </ReportContainer>
        <button onClick={() => setActiveComponent('')}>닫기</button>
      </Container>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  min-width: 1920px;
  min-height: 1081px;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
  z-index: 9;
`;

const Container = styled.div`
  position: absolute;
  right: 0;
  display: flex;
  flex-direction: column;
  width: 940px;
  min-height: 960px;
  height: calc(100vh - 120px);
  padding: 60px 30px;
  border-radius: 16px 0 0 16px;
  background: #fff;
  color: #000;

  & > button {
    width: 200px;
    height: 56px;
    margin-left: 370px;
    margin-top: 40px;
    border-radius: 10px;
    background-color: #2c55fb;
    color: #fff;
  }
`;

const NameSecction = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 75px;
  padding-right: 40px;
  border-right: 1px solid #e2e2ea;
  margin-right: 40px;
`;

const Name = styled.div`
  display: flex;
  align-items: center;

  & > div {
    :nth-of-type(1) {
      margin-right: 8px;
      color: #000;

      /* heading/H02-24px-bold */

      font-size: 24px;

      font-weight: 700;
      line-height: 1.6em; /* 38.4px */
    }
  }
`;

const Date = styled.div`
  width: 104px;
  height: 21px;
  margin-top: 16px;
  color: #65696d;

  /* body/B03-16px-medium */

  font-size: 16px;

  font-weight: 500;
  line-height: 1.3em; /* 20.8px */
`;

const TitleInfo = styled.div`
  display: flex;
  width: 860px;
  height: 77px;
  padding: 24px 40px;
  border-radius: 16px;
  margin-bottom: 40px;
  background: #fff;

  /* shadow */
  box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.08);
`;

const ReportDateFilter = styled.div`
  display: flex;
  align-items: center;
`;

const GenderImgConatiner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 33px;
  height: 33px;
  border-radius: 10px;
  background: #f6f6f6;
`;

const GenderImg = styled.div<{ url: string }>`
  width: 14px;
  height: 20px;
  background: url(${(props) => props.url}) no-repeat center;
  background-size: center;
`;

const ClassInfoSecction = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ClassInfo = styled.div`
  display: flex;
  align-items: center;
  height: 30px;
  color: #65696d;

  /* body/B03-16px-medium */

  font-size: 16px;

  font-weight: 500;
  line-height: 1.3em; /* 20.8px */

  & > div {
    :nth-of-type(1) {
      padding-right: 12px;
      margin-right: 12px;
      border-right: 1px solid #b5b5be;
    }

    :nth-of-type(2) {
      margin-right: 12px;
    }
  }
`;

const Info = styled.div`
  display: flex;
  align-items: center;
  width: 431px;
  height: 21px;
  padding: 8px 20px;
  border-radius: 2px;
  margin-top: 10px;
  margin-right: 24px;
  border: 1px solid #e2e2ea;
  background: #f6f6f6;

  color: #292d32;

  /* body/B03-16px-medium */

  font-size: 16px;

  font-weight: 500;
`;

const ButtonSecction = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ChanginInfoButton = styled.button`
  width: 120px;
  height: 56px;
  border-radius: 10px;
  background: #0062ff;
  color: #fff;
  text-align: center;

  /* body/B03-16px-bold */

  font-size: 16px;

  font-weight: 700;
  line-height: 1.3em; /* 20.8px */
`;

const ScreenStatus = styled.div<{ bgColor: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.color};
  background-color: ${(props) => props.bgColor};
  width: 84px;
  height: 30px;
  border-radius: 100px;
`;

const ReportTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 194px;
  height: 46px;
  border-radius: 100px;
  margin-bottom: 12px;
  background: #f0f6ff;

  color: #0062ff;

  font-size: 18px;

  font-weight: 700;
`;

const ReportContainer = styled.div`
  position: relative;
  display: flex;

  justify-content: center;
  height: 591px;
  overflow-y: scroll;
  border: 1px solid #e2e2ea;
`;

const PdfButton = styled.button`
  position: absolute;
  top: 24px;
  left: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 124px;
  height: 34px;
  border-radius: 6px;
  border: 1px solid #2c55fb;
  background: #fff;

  color: #2c55fb;

  /* body/B04-14px-medium */

  font-size: 14px;

  font-weight: 500;
  line-height: 1.6em; /* 22.4px */
`;

const DateButton = styled.button<{ active: boolean }>`
  width: 120px;
  height: 40px;
  border-bottom: 2px solid ${(props) => (props.active ? '#2C55FB' : '#fff')};
  overflow: hidden;
  color: ${(props) => (props.active ? '#2C55FB' : '#8C9299')};

  font-size: 14px;

  font-weight: 700;
  line-height: 1.6em; /* 22.4px */
`;
