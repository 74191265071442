import styled from '@emotion/styled';
import { useEffect, useState } from 'react';

import { Activity } from '~/types/activeLog/activeLog';

import { getActiveLogStatusData } from '~/api/activeLog/activeLog';

import useAuth from '~/hooks/useAuth';

import Pagenate from '~/components/nav/Paginate';

const DATA = [
  { key: 1, label: '일시' },
  { key: 2, label: '로그인 IP' },
  { key: 3, label: '접속 국가' },
  { key: 4, label: '접속 경로' },
  { key: 5, label: '결과' },
];

export default function LoginLog() {
  const [loginLogData, setLoginLogData] = useState<Activity[]>([]);
  const { user } = useAuth();

  useEffect(() => {
    if (!user) {
      return;
    }
    getActiveLogStatusData({ userId: user.userId })
      .then(({ data }) => {
        setLoginLogData(data.data.activityList.reverse());
      })
      .catch(console.error);
  }, []);
  return (
    <Wrapper>
      <TitleWrapper>
        {DATA.map((item) => (
          <TitleContainer key={item.key}>
            <div>{item.label}</div>
          </TitleContainer>
        ))}
      </TitleWrapper>
      <Pagenate items={loginLogData} itemsPerPage={11} />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: #fff;
  width: 1592px;
  height: 850px;
  padding-top: 20px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
`;

const TitleWrapper = styled.div`
  display: flex;
  margin: 0 24px;
  border-radius: 10px;
  background: #fafafa;
`;

const TitleContainer = styled.div`
  color: #000;
  text-align: center;

  /* body/B03-16px-medium */

  font-size: 16px;

  font-weight: 500;
  line-height: 1.3em; /* 20.8px */

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 1544px;

    :nth-of-type(1) {
      width: 308px;
      height: 56px;
    }
    :nth-of-type(2) {
      width: 308px;
      height: 56px;
    }
    :nth-of-type(3) {
      width: 308px;
      height: 56px;
    }
    :nth-of-type(4) {
      width: 308px;
      height: 56px;
    }
    :nth-of-type(5) {
      width: 308px;
      height: 56px;
    }
  }
`;
