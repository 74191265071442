import styled from '@emotion/styled';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Modal from '~/utils/portal/components/Modal';

import { getEditPasswordStatusData } from '~/api/manager/edit';

import useAuth from '~/hooks/useAuth';
import useInput from '~/hooks/useInput';

export default function ChangePasswordPage() {
  const { user, loginId } = useAuth();
  const [value, setValue, handleChange] = useInput({
    checkPassword: '',
    newPassword: '',
    password: '',
  });
  const [validationCheck, setValidationCheck] = useState({
    checkPassword: false,
    newPassword: false,
    password: false,
  });

  const [showPw, setShowPw] = useState({
    checkPassword: false,
    newPassword: false,
    password: false,
  });

  const [isModal, setIsModal] = useState('');

  const navigate = useNavigate();

  const handleValueChange = (id: string, value: string) => {
    handleChange(id, value);
  };

  const handleDeletevalue = (id: string) => {
    setValue((prevValue) => ({
      ...prevValue,
      [id]: '',
    }));
  };

  const handleBlurPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { value } = e.target;

    if (value.length < 8) {
      setValidationCheck((prevErrors) => ({
        ...prevErrors,
        password: true,
      }));
      return;
    } else {
      setValidationCheck((prevErrors) => ({
        ...prevErrors,
        password: false,
      }));
    }
  };

  const handleBlurNewPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { value } = e.target;

    if (value.length < 8) {
      setValidationCheck((prevErrors) => ({
        ...prevErrors,
        newPassword: true,
      }));
      return;
    } else {
      setValidationCheck((prevErrors) => ({
        ...prevErrors,
        newPassword: false,
      }));
    }
  };

  const handleBlurCheckPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { value } = e.target;

    if (value.length < 8) {
      setValidationCheck((prevErrors) => ({
        ...prevErrors,
        checkPassword: true,
      }));
      return;
    } else {
      setValidationCheck((prevErrors) => ({
        ...prevErrors,
        checkPassword: false,
      }));
    }

    validationCheck1();
  };

  const validationCheck1 = () => {
    if (value.newPassword !== value.checkPassword) {
      setValidationCheck((prevErrors) => ({
        ...prevErrors,
        checkPassword: true,
      }));

      return;
    } else {
      setValidationCheck((prevErrors) => ({
        ...prevErrors,
        checkPassword: false,
      }));
    }
  };

  const handleChangePassword = () => {
    if (value.password?.length === 0) {
      setValidationCheck((prevErrors) => ({
        ...prevErrors,
        password: true,
      }));
    }

    if (value.newPassword?.length === 0) {
      setValidationCheck((prevErrors) => ({
        ...prevErrors,
        newPassword: true,
      }));
    }

    if (value.checkPassword?.length === 0) {
      setValidationCheck((prevErrors) => ({
        ...prevErrors,
        checkPassword: true,
      }));
    }

    validationCheck1();

    if (
      validationCheck.checkPassword ||
      validationCheck.newPassword ||
      validationCheck.password
    ) {
      return;
    }

    getEditPasswordStatusData({
      checkPassword: value.checkPassword as string,
      managerId: Number(user?.managerId),
      newPassword: value.newPassword as string,
      password: value.password as string,
    })
      .then(({ data }) => {
        if (data.status === 10011 || data.status === 20008) {
          return;
        }
        if (data.status === 11012 || data.status === 20002) {
          setValidationCheck((prevErrors) => ({
            ...prevErrors,
            password: true,
          }));
          return;
        } else {
          setValidationCheck((prevErrors) => ({
            ...prevErrors,
            password: false,
          }));
        }

        if (data.status === 20068) {
          setValidationCheck((prevErrors) => ({
            ...prevErrors,
            newPassword: true,
          }));
          return;
        } else {
          setValidationCheck((prevErrors) => ({
            ...prevErrors,
            newPassword: false,
          }));
        }

        if (data.status === 20069) {
          setValidationCheck((prevErrors) => ({
            ...prevErrors,
            checkPassword: true,
          }));
          return;
        } else {
          setValidationCheck((prevErrors) => ({
            ...prevErrors,
            checkPassword: false,
          }));
        }

        if (data.status === 10023 || data.status === 10024) {
          setValidationCheck((prevErrors) => ({
            ...prevErrors,
            checkPassword: true,
            newPassword: true,
          }));
          return;
        } else {
          setValidationCheck((prevErrors) => ({
            ...prevErrors,
            password: false,
            checkPassword: false,
            newPassword: false,
          }));
          setIsModal('success');
        }
      })
      .catch(console.error);
  };

  const handleShowPw = (id: string) => {
    setShowPw((prev) => ({
      ...prev,
      [id]: !prev[id as keyof typeof prev],
    }));
  };

  const handleSuccessProfileChange = () => {
    setIsModal('');
    navigate('/home/dashboard');
  };

  return (
    <Wrapper>
      <Header>
        <div>아이디</div>
        <span>{loginId}</span>
        <div>소속 기관명</div>
        <span>{user?.agencyName}</span>
      </Header>
      <Container>
        <h1>비밀번호 변경</h1>
        <div>
          <div>
            <h3>현재 비밀번호</h3>
            <ButtonInputContainer>
              <Input
                maxLength={16}
                type={showPw.password ? 'text' : 'password'}
                mgBottom="10px"
                width="748px"
                mgRight="28px"
                name="password"
                value={value.password || ''}
                bdColor={!validationCheck.password ? '#e2e2ea;' : '#FF4752'}
                onChange={(e) => handleValueChange('password', e.target.value)}
                onBlur={handleBlurPassword}
              />
              {value.password && value.password.length > 0 && (
                <DeleteIcon
                  right="80px"
                  onClick={() => handleDeletevalue('password')}
                />
              )}
              {showPw.password ? (
                <ShowPassword onClick={() => handleShowPw('password')} />
              ) : (
                <NonShowPassword onClick={() => handleShowPw('password')} />
              )}
            </ButtonInputContainer>
            {!validationCheck.password ? (
              <Error />
            ) : (
              <Error>비밀번호가 올바르지 않습니다. 다시 입력해주세요.</Error>
            )}
          </div>
        </div>
        <div>
          <div>
            <h3>새 비밀번호</h3>
            <ButtonInputContainer>
              <Input
                maxLength={16}
                type={showPw.newPassword ? 'text' : 'password'}
                mgBottom="10px"
                width="748px"
                mgRight="28px"
                name="newPassword"
                value={value.newPassword || ''}
                bdColor={!validationCheck.newPassword ? '#e2e2ea;' : '#FF4752'}
                onChange={(e) =>
                  handleValueChange('newPassword', e.target.value)
                }
                onBlur={handleBlurNewPassword}
              />
              {value.newPassword && value.newPassword.length > 0 && (
                <DeleteIcon
                  right="80px"
                  onClick={() => handleDeletevalue('newPassword')}
                />
              )}
              {showPw.newPassword ? (
                <ShowPassword onClick={() => handleShowPw('newPassword')} />
              ) : (
                <NonShowPassword onClick={() => handleShowPw('newPassword')} />
              )}
            </ButtonInputContainer>
            {!validationCheck.newPassword ? (
              <Error />
            ) : (
              <Error>
                비밀번호는 최소 8글자 최대 16글자의 영문 대,소문자와 숫자
                조합으로 입력해 주세요.
              </Error>
            )}
          </div>
        </div>
        <div>
          <div>
            <h3>새 비밀번호 확인</h3>
            <ButtonInputContainer>
              <Input
                maxLength={16}
                type={showPw.checkPassword ? 'text' : 'password'}
                mgBottom="10px"
                width="748px"
                mgRight="28px"
                name="checkPassword"
                value={value.checkPassword || ''}
                bdColor={
                  !validationCheck.checkPassword ? '#e2e2ea;' : '#FF4752'
                }
                onChange={(e) =>
                  handleValueChange('checkPassword', e.target.value)
                }
                onBlur={handleBlurCheckPassword}
              />
              {value.checkPassword && value.checkPassword.length > 0 && (
                <DeleteIcon
                  right="80px"
                  onClick={() => handleDeletevalue('checkPassword')}
                />
              )}
              {showPw.checkPassword ? (
                <ShowPassword onClick={() => handleShowPw('checkPassword')} />
              ) : (
                <NonShowPassword
                  onClick={() => handleShowPw('checkPassword')}
                />
              )}
            </ButtonInputContainer>
            {!validationCheck.checkPassword ? (
              <Error />
            ) : (
              <Error>
                비밀번호와 비밀번호 확인이 일치하지 않습니다. 다시 입력해
                주세요.
              </Error>
            )}
          </div>
        </div>
        <ButtonContainer>
          <Button
            color="#2C55FB"
            bgColor=""
            left="220px"
            onClick={handleSuccessProfileChange}
          >
            취소
          </Button>
          <Button
            color="#FFF"
            bgColor="#2C55FB"
            left="432px"
            onClick={handleChangePassword}
          >
            저장
          </Button>
        </ButtonContainer>
      </Container>
      {isModal === 'success' && (
        <Modal
          title="비밀번호가 수정되었습니다."
          onClose={handleSuccessProfileChange}
        ></Modal>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  height: calc(100vh - 81px);
  background: #fafafa;
  @media (max-height: 1080px) {
    min-height: 999px;
  }
  @media (max-width: 1651px) {
    width: 1652px;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 330px;
  height: 194px;
  margin: 30px 40px 0 30px;
  border-radius: 12px;
  background: #fff;

  /* shadow */
  box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.08);

  & > div {
    color: #8c9299;
    text-align: center;

    /* body/B03-16px-medium */

    font-size: 16px;

    font-weight: 500;
    line-height: 1.3em; /* 20.8px */
  }

  & > span {
    color: #111827;
    text-align: center;

    /* heading/H02-20px-bold */

    font-size: 20px;

    font-weight: 700;
    line-height: 1.6em; /* 32px */
    letter-spacing: -0.3px;

    :nth-of-type(1) {
      margin-bottom: 20px;
    }
  }
`;

const Container = styled.div`
  width: 788px;
  height: 866px;
  padding: 32px;
  margin-top: 30px;
  border-radius: 12px;
  background: #fff;

  /* shadow */
  box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.08);

  & > h1 {
    height: 40px;
    margin-bottom: 20px;
    color: #000;

    /* heading/H02-20px-bold */

    font-size: 20px;

    font-weight: 700;
    line-height: 1.6em; /* 32px */
    letter-spacing: -0.3px;
  }

  & > div {
    display: flex;
  }

  h3 {
    margin-bottom: 6px;
    color: #000;

    /* body/B04-14px-medium */

    font-size: 14px;

    font-weight: 500;
    line-height: 1.6em; /* 22.4px */
  }
`;

const Input = styled.input<{
  width: string;
  mgBottom: string;
  mgRight: string;
  bdColor: string;
}>`
  width: ${(props) => props.width};
  margin-bottom: ${(props) => props.mgBottom};
  margin-right: ${(props) => props.mgRight};
  height: 18px;
  padding: 15px 19px;
  border-radius: 10px;
  border: 1px solid ${(props) => props.bdColor};

  :disabled {
    color: #b5b5be;

    /* body/B03-16px-medium */

    font-size: 16px;

    font-weight: 500;
    line-height: 1.3em; /* 20.8px */
  }

  :focus {
    border-radius: 10px;
    border: 1px solid #3467ff;
    background: #fff;
  }
`;

const Error = styled.div`
  height: 22px;
  margin-bottom: 30px;
  color: #ff4752;

  /* body/B04-14px-medium */

  font-size: 14px;

  font-weight: 500;
  line-height: 1.6em; /* 22.4px */
`;

const ButtonContainer = styled.div`
  margin-top: 324px;
  margin-left: 188px;
`;

const Button = styled.button<{ left: string; bgColor: string }>`
  left: ${(props) => props.left};
  width: 200px;
  height: 60px;
  border-radius: 10px;
  border: 1px solid #2c55fb;
  color: ${(props) => props.color};
  background: ${(props) => props.bgColor};

  &:nth-of-type(1) {
    margin-right: 12px;
  }
`;

const ButtonInputContainer = styled.div`
  display: flex;
  position: relative;
`;

const DeleteIcon = styled.button<{ right: string }>`
  position: absolute;
  right: ${(props) => props.right};
  top: 18px;
  width: 16px;
  height: 16px;
  background: url(/images/delete.png) no-repeat center;
  background-size: contain;
`;

const ShowPassword = styled.div`
  top: 16px;
  right: 52px;
  position: absolute;
  width: 20px;
  height: 20px;
  background: url(/images/see.png) no-repeat center;
`;

const NonShowPassword = styled.div`
  top: 16px;
  right: 52px;
  position: absolute;
  width: 20px;
  height: 20px;
  background: url(/images/Nonsee.png) no-repeat center;
`;
