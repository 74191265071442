import { AxiosResponse } from 'axios';

import {
  RegisterStudentRequestParams,
  RegisterStudentResponse,
} from '~/types/student/register';

import api from '~/api/base';

const BASE_URL = 'api/v1/student/register';

export const getAddStudentStatusData = (
  param: RegisterStudentRequestParams,
): Promise<AxiosResponse<RegisterStudentResponse>> =>
  api.post(`${BASE_URL}/student`, param);
