import styled from '@emotion/styled';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { NavLink } from 'react-router-dom';

import { getCheckPasswordStatusData } from '~/api/manager/check';

import useAuth from '~/hooks/useAuth';

interface Props {
  isNavDropdown: boolean;
  handleNavDropDownClick: () => void;
}

const Index = ({ isNavDropdown, handleNavDropDownClick }: Props) => {
  const location = useLocation();
  const { user, logout } = useAuth();

  const [title, setTitle] = useState('dashboard');

  useEffect(() => {
    const pathname = location.pathname;

    if (pathname.includes('mail-box')) {
      setTitle('우편함');
    } else if (pathname.includes('student-management')) {
      setTitle('소속 학생 관리');
    } else if (pathname.includes('institution-information')) {
      setTitle('소속 기관 정보');
    } else if (pathname.includes('profile')) {
      setTitle('프로필 수정');
    } else if (pathname.includes('password')) {
      setTitle('비밀번호 변경');
    } else if (pathname.includes('activity')) {
      setTitle('활동로그');
    } else {
      setTitle('대시보드');
    }
  }, [location]);

  useEffect(() => {
    getCheckPasswordStatusData({ managerId: 0, password: '' });
  }, []);

  return (
    <Wrapper>
      <Title>{title}</Title>
      <Menu>
        <MenuItemContainer onClick={handleNavDropDownClick}>
          <MenuItemsInfo>
            <div>{user?.name} 선생님</div>
            <span>{user?.agencyName}</span>
          </MenuItemsInfo>
          <DropdownImg />
        </MenuItemContainer>
        {isNavDropdown && (
          <DropdownBackground onClick={handleNavDropDownClick}>
            <NavDropdownWrapper>
              <StyledDropdownLink to={'check-profile'}>
                내 프로필
              </StyledDropdownLink>
              <StyledDropdownLink to={'check-password'}>
                비밀번호 변경
              </StyledDropdownLink>
              <StyledDropdownLink to={'activity-log'}>
                활동로그
              </StyledDropdownLink>
              <button onClick={logout}>로그아웃</button>
            </NavDropdownWrapper>
          </DropdownBackground>
        )}
      </Menu>
    </Wrapper>
  );
};

export default Index;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  height: 80px;
  background-color: #fff;
  border-bottom: 1px solid #e7e7e7;

  @media (max-width: 1651px) {
    width: 1652px;
  }
`;

const Title = styled.div`
  width: 176px;
  height: 29px;
  margin-left: 40px;
  display: flex;
  align-items: center;

  color: #1d1d21;
  font-size: 18px;

  font-weight: 700;
`;

const Menu = styled.div`
  width: 244px;
  height: 60px;
  margin-right: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MenuItemContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  margin-left: 20px;
  margin-right: 23px;
`;

const MenuItemsInfo = styled.div`
  width: 120px;

  & > div {
    color: #000;

    /* body/B04-14px_bold */

    font-size: 14px;

    font-weight: 700;
    line-height: 1.6em; /* 22.4px */
  }

  & > span {
    color: #8c9299;

    /* body/B05-14px-regular */

    font-size: 14px;

    font-weight: 400;
    line-height: 1.6em; /* 22.4px */
  }
`;

const DropdownImg = styled.div`
  width: 3px;
  height: 23px;
  padding-left: 40px;
  padding-right: 27px;
  background: url(/images/dots.png) no-repeat center;
  background-size: contain;
  cursor: pointer;
`;

const DropdownBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background-color: transparent;
  width: 100vw;
  height: 100vh;
  z-index: 1;
`;

const NavDropdownWrapper = styled.div`
  position: absolute;
  top: 92px;
  right: 52px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 170px;
  height: 200px;
  background: #fff;
  border-radius: 10px;
  z-index: 3;
  background: #fff;
  box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.08);

  & > button {
    width: 170px;
    height: 50px;
    border-bottom: 1px solid #e2e2ea;
    border-radius: 10px;
    font-size: 14px;

    line-height: 1.6em;
    text-align: left;
    padding-left: 26px;

    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom: 0px;

    :hover {
      background-color: #2c55fb;
      color: #fff;
    }
  }
`;

const StyledDropdownLink = styled(NavLink)`
  display: flex;
  align-items: center;
  width: 144px;
  height: 50px;
  border-bottom: 1px solid #e2e2ea;
  border-radius: 10px;
  font-size: 14px;

  line-height: 1.6em;
  padding-left: 26px;
  color: #000;

  &.active {
    background-color: #2c55fb;
    color: #fff;
  }

  :hover {
    background-color: #2c55fb;
    color: #fff;
  }

  :nth-of-type(1) {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  :nth-of-type(2) {
    border-radius: 0px;
  }
  :nth-of-type(3) {
    border-radius: 0px;
  }
`;
