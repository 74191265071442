import { AxiosResponse } from 'axios';

import { PushFindRequestParams, PushFindResponse } from '~/types/push/find';

import api from '~/api/base';

const BASE_URL = 'api/v1/web/push/find';

export const getPushFindStatusData = (
  param: PushFindRequestParams,
): Promise<AxiosResponse<PushFindResponse>> =>
  api.post(`${BASE_URL}/data`, param);
