import { AxiosResponse } from 'axios';

import {
  PostSearchRequestParams,
  PostSearchResponse,
} from '~/types/post/search';

import api from '~/api/base';

const BASE_URL = 'api/v1/web/post/search';

export const getMailSearchStatusData = (
  param: PostSearchRequestParams,
): Promise<AxiosResponse<PostSearchResponse>> =>
  api.post(`${BASE_URL}/list`, param);
