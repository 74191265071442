import { AxiosResponse } from 'axios';

import {
  InfoManagerRequestParams,
  InfoManagerResponse,
} from '~/types/manager/info';

import api from '~/api/base';

const BASE_URL = 'api/v1/manager/info';

export const getManagerStatusData = (
  param: InfoManagerRequestParams,
): Promise<AxiosResponse<InfoManagerResponse>> =>
  api.post(`${BASE_URL}/manager`, param);
