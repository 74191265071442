import styled from '@emotion/styled';
import { useEffect, useState } from 'react';
import { NavLink, Outlet } from 'react-router-dom';

import { SearchHeaderResponse } from '~/types/student/search';

import { getSearchHeaderStatusData } from '~/api/student/search';

import useAuth from '~/hooks/useAuth';

const INFO_ITEMS = [
  { key: 1, id: 'student', label: '관리 학생', to: './student/measurement' },
  { key: 2, id: 'student1', label: '관리 학생', to: './student/enhancement' },
  {
    key: 3,
    id: 'studentReceived',
    label: '받은 연결 요청',
    to: './student-received',
  },
  {
    key: 4,
    id: 'studentSent',
    label: '보낸 연결 요청',
    to: './student-request',
  },
  {
    key: 5,
    id: 'createStudent',
    label: '생성 학생 (앱 미접속)',
    to: './create-student',
  },
];

export default function StudentManagement() {
  const [isData, setIsData] = useState<SearchHeaderResponse>();
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useAuth();

  useEffect(() => {
    if (!user) {
      return;
    }

    getSearchHeaderStatusData({ managerId: user?.userId })
      .then(({ data }) => {
        setIsData(data);
        setIsLoading(true);
      })
      .catch(console.error);
  }, []);

  const pathname = location.pathname;

  return (
    <Wrapper>
      {isLoading ? (
        <>
          {isData && (
            <>
              <InfoWrapper>
                {pathname.includes('/student/measurement') ? (
                  <div>
                    <StyledNavLink to={INFO_ITEMS[0].to}>
                      <div>{INFO_ITEMS[0].label}</div>
                      <div>{isData?.data?.totalStudentCount}명</div>
                    </StyledNavLink>
                  </div>
                ) : pathname.includes('/student/enhancement') ? (
                  <div>
                    <StyledNavLink to={INFO_ITEMS[1].to}>
                      <div>{INFO_ITEMS[1].label}</div>
                      <div>{isData?.data?.totalStudentCount}명</div>
                    </StyledNavLink>
                  </div>
                ) : (
                  <div>
                    <StyledNavLink to={INFO_ITEMS[0].to}>
                      <div>{INFO_ITEMS[0].label}</div>
                      <div>{isData?.data?.totalStudentCount}명</div>
                    </StyledNavLink>
                  </div>
                )}
                {INFO_ITEMS.map((item) => (
                  <div key={item.key}>
                    {item.key !== 1 && item.key !== 2 && (
                      <StyledNavLink to={item.to}>
                        <div>{item.label}</div>
                        <div>
                          {item.key === 3
                            ? isData?.data?.responseRelationCount
                            : item.key === 4
                            ? isData?.data?.requestRelationCount
                            : isData?.data?.notConnectCount}
                          {item.to === './a' || item.to === './c' ? '명' : '건'}
                        </div>
                      </StyledNavLink>
                    )}
                  </div>
                ))}
              </InfoWrapper>
              <Outlet />
            </>
          )}
        </>
      ) : (
        <InfoWrapper />
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  min-height: calc(100vh - 81px);
  background: #fafafa;
  @media (max-width: 1651px) {
    width: 1652px;
  }
`;

const InfoWrapper = styled.div`
  display: flex;
  height: 90px;
  margin: 30px 30px 0 30px;
`;

const StyledNavLink = styled(NavLink)`
  display: flex;
  flex-direction: column;
  width: 208px;
  height: 58px;
  padding: 16px 24px;
  gap: 6.5px;
  border-radius: 10px;
  margin-right: 16px;
  background: #fff;
  border: 1px solid #fff;

  & > div {
    :nth-of-type(1) {
      color: #000;

      /* body/B03-16px-medium */

      font-size: 16px;

      font-weight: 500;
      line-height: 1.3em; /* 20.8px */
    }

    :nth-of-type(2) {
      color: #000;

      /* body/B02-18px-bold */

      font-size: 18px;

      font-weight: 700;
      line-height: 1.3em; /* 23.4px */
    }
  }

  &.active {
    border-radius: 10px;
    border: 1px solid #2c55fb;
    background: #f0f6ff;

    & > div {
      color: #2c55fb;
    }
  }
`;
