import styled from '@emotion/styled';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { AuthResponse, authenticaionDetailData } from '~/types/auth/auth';
import { LicenseResponse } from '~/types/manager/valid';

import { ReactComponent as BeforeLeftImg } from '~/assets/Beforeleft.svg';
import { ReactComponent as TitleImg } from '~/assets/dia.svg';

import Modal from '~/utils/portal/components/Modal';

import { getSignupStatusData } from '~/api/manager/register';
import {
  getIdCheckStatusData,
  getLisenceStatusData,
} from '~/api/manager/valid';

import useInput from '~/hooks/useInput';
import useValidation from '~/hooks/useValidation';

import SignupAccountDetailInfo from '~/components/signup/SignupAccountDetailInfo';
import SignupAccountInfo from '~/components/signup/SignupAccountInfo';
import SignupAgree from '~/components/signup/SignupAgree';
import SignupCode from '~/components/signup/SignupCode';
import SignupPassCheck from '~/components/signup/SignupPassCheck';
import SignupSuccess from '~/components/signup/SignupSuccess';

const Index = () => {
  const navigate = useNavigate();

  const [signupPageCount, setSignupPageCount] = useState<number>(1);

  const [value, setValue, handleChange] = useInput({
    licenseKey: '',
    loginId: '',
    password: '',
    second_password: '',
    name: '',
    email: null,
    phone: null,
    info: '',
  });
  const { validationCheck, setValidationCheck, validateInput } =
    useValidation();
  const [isModal, setIsModal] = useState('');
  const [isLicenseCheckButtonClicked, setIsLicenseCheckButtonClicked] =
    useState(false);
  const [isIdCheckButtonClicked, setIsIdCheckButtonClicked] = useState(false);
  const [authData, setAuthData] = useState<authenticaionDetailData>();
  const [licenseData, setLicenseData] = useState<LicenseResponse>();
  const [pgData, setPgData] = useState<AuthResponse>();
  const [isChecked, setIsChecked] = useState({
    useCheck: false,
    infoCheck: false,
    password: false,
    second_password: false,
    loginId: false,
  });

  const handlePageCheckButton = () => {
    if (signupPageCount === 1) {
      navigate('/');
    } else if (signupPageCount === 2) {
      setSignupPageCount(1);
    } else if (signupPageCount === 3) {
      setSignupPageCount(2);
    } else if (signupPageCount === 4) {
      setSignupPageCount(3);
    } else if (signupPageCount === 5) {
      setSignupPageCount(4);
    }
  };

  const handleNextPageButton = () => {
    if (signupPageCount === 1) {
      if (isChecked.infoCheck === false || isChecked.useCheck === false) {
        setIsModal('agreeCheck');
        return;
      } else {
        setSignupPageCount(signupPageCount + 1);
      }
    }

    if (signupPageCount === 3) {
      if (validationCheck.licenseKey === false) {
        handleLicenseCheck();
        setIsLicenseCheckButtonClicked(true);
        return;
      } else {
        setSignupPageCount(signupPageCount + 1);
      }
    }

    if (signupPageCount === 4) {
      if (!isIdCheckButtonClicked) {
        handleIdCheck();
      }

      if (
        value.password === undefined ||
        value.password === null ||
        value.password.length < 8
      ) {
        setIsChecked((prevErrors) => ({
          ...prevErrors,
          password: true,
        }));
      }

      if (
        value.second_password === undefined ||
        value.second_password === null ||
        value.second_password.length < 8
      ) {
        setIsChecked((prevErrors) => ({
          ...prevErrors,
          second_password: true,
        }));
      }

      if (isChecked.password || isChecked.second_password) {
        return;
      }

      if (
        (value.password === null || value.password === '') &&
        (value.second_password === null || value.second_password === '')
      ) {
        return;
      }

      if (value.second_password !== value.password) {
        setIsChecked((prevErrors) => ({
          ...prevErrors,
          second_password: true,
        }));
        return;
      } else {
        setSignupPageCount(signupPageCount + 1);
      }
    }

    if (signupPageCount === 6) {
      navigate('/');
    }
  };

  const handlePasswordBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const inputValue = e.target.value;

    const passwordPattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,16}$/;
    const isValidPassword = passwordPattern.test(inputValue);

    setIsChecked((prevErrors) => ({
      ...prevErrors,
      password: !isValidPassword,
    }));
  };

  const handleSecondPasswordBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const inputValue = e.target.value;

    const passwordPattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,16}$/;
    const isValidPassword = passwordPattern.test(inputValue);

    setIsChecked((prevErrors) => ({
      ...prevErrors,
      second_password: !isValidPassword,
    }));
  };

  const handleSignupButton = () => {
    if (value.email === '') {
      setValue((prevValue) => ({
        ...prevValue,
        email: null,
      }));
    } else if (value.phone === '') {
      setValue((prevValue) => ({
        ...prevValue,
        phone: null,
      }));
    }
    getSignupStatusData({
      authPhone: authData?.userPhone as string,
      contactNumber: value.phone,
      email: value.email,
      introduct: value.info as string,
      licenseKey: value.licenseKey as string,
      loginId: value.loginId as string,
      loginPw: value.password as string,
      checkPw: value.second_password as string,
      name: authData?.userName as string,
    })
      .then(({ data }) => {
        if (data.status === 19110) {
          setValidationCheck((prevErrors) => ({
            ...prevErrors,
            info: true,
          }));
          return;
        }

        if (data.status === 19111) {
          setValidationCheck((prevErrors) => ({
            ...prevErrors,
            phone: true,
          }));
          return;
        }

        if (data.data.isValid) {
          setSignupPageCount(6);
        }
        if (data?.data?.isValid === undefined) {
          return;
        }
      })
      .catch(console.error);
  };

  const handleLicenseCheck = () => {
    getLisenceStatusData({
      licenseKey: value.licenseKey as string | undefined,
    })
      .then(({ data }) => {
        setLicenseData(data);
        if (data.status === 10701) {
          setValidationCheck((prevErrors) => ({
            ...prevErrors,
            licenseKey: false,
          }));
          setIsModal('LicensExpired');
          return;
        }
        if (data.status === 10703) {
          setValidationCheck((prevErrors) => ({
            ...prevErrors,
            licenseKey: false,
          }));
          setIsModal('licenseKeyCheck');

          return;
        } else if (data?.data?.isValid) {
          setValidationCheck((prevErrors) => ({
            ...prevErrors,
            licenseKey: true,
          }));
          setIsModal('License');
          setIsLicenseCheckButtonClicked(true);
        }
      })
      .catch(console.error);
  };

  const handleIdCheck = () => {
    getIdCheckStatusData({ loginId: value.loginId as string | undefined })
      .then(({ data }) => {
        if (data?.status === 20000) {
          setIsChecked((prevErrors) => ({
            ...prevErrors,
            loginId: true,
          }));

          return;
        }

        if (data.data.isValid === false) {
          setIsChecked((prevErrors) => ({
            ...prevErrors,
            loginId: true,
          }));
          setIsModal('LoginId');

          return;
        } else {
          setIsChecked((prevErrors) => ({
            ...prevErrors,
            loginId: false,
          }));
          setIsModal('LoginIdSuccess');
          setIsIdCheckButtonClicked(true);
        }
      })
      .catch(console.error);
  };

  const handleValueChange = (id: string, value: string) => {
    handleChange(id, value);
    validateInput(id, value);

    if (id === 'phone') {
      if (value === '') {
        setValidationCheck((prevErrors) => ({
          ...prevErrors,
          phone: false,
        }));
      }
    } else if (id === 'email') {
      if (value === '') {
        setValidationCheck((prevErrors) => ({
          ...prevErrors,
          email: false,
        }));
      }
    }
  };

  const handleLicensekyModal = () => {
    setIsModal('');
    setSignupPageCount(4);
  };

  return (
    <Wrapper>
      {signupPageCount !== 6 && (
        <BeforeButton onClick={handlePageCheckButton}>
          <BeforeLeftImg />
          <span>이전으로</span>
        </BeforeButton>
      )}
      <TitleContainer>
        <Title>
          <TitleImg />
          <span>Admin</span>
          <TitleImg />
        </Title>
        {signupPageCount === 1 && (
          <SignupAgree
            handleNextPageButton={handleNextPageButton}
            setIsChecked={setIsChecked}
            isChecked={isChecked}
            handlePageCheckButton={handlePageCheckButton}
          />
        )}
        {signupPageCount === 2 && (
          <SignupPassCheck
            pgData={pgData}
            setPgData={setPgData}
            setSignupPageCount={setSignupPageCount}
            setAuthData={setAuthData}
          />
        )}
        {signupPageCount === 3 && (
          <SignupCode
            handleNextPageButton={handleNextPageButton}
            value={value}
            handleChange={handleChange}
            validationCheck={validationCheck}
            handleLicenseCheck={handleLicenseCheck}
            isLicenseCheckButtonClicked={isLicenseCheckButtonClicked}
            setValue={(id, newValue) =>
              setValue((prevValue) => ({ ...prevValue, [id]: newValue }))
            }
          />
        )}
        {signupPageCount === 4 && (
          <SignupAccountInfo
            handleValueChange={handleValueChange}
            handleNextPageButton={handleNextPageButton}
            value={value}
            handleChange={handleChange}
            handleIdCheck={handleIdCheck}
            setValue={(id, newValue) =>
              setValue((prevValue) => ({ ...prevValue, [id]: newValue }))
            }
            isChecked={isChecked}
            setIsChecked={setIsChecked}
            handlePasswordBlur={handlePasswordBlur}
            handleSecondPasswordBlur={handleSecondPasswordBlur}
          />
        )}

        {signupPageCount === 5 && (
          <SignupAccountDetailInfo
            authData={authData}
            handleValueChange={handleValueChange}
            value={value}
            validationCheck={validationCheck}
            handleIdCheck={handleIdCheck}
            handleSignupButton={handleSignupButton}
            setValue={(id, newValue) =>
              setValue((prevValue) => ({ ...prevValue, [id]: newValue }))
            }
          />
        )}

        {signupPageCount === 6 && (
          <SignupSuccess handleNextPageButton={handleNextPageButton} />
        )}
      </TitleContainer>
      {isModal === 'agreeCheck' && (
        <Modal
          title="필수 약관에 동의해주세요"
          onClose={() => setIsModal('')}
        />
      )}
      {isModal === 'licenseKeyCheck' && (
        <Modal
          title="기관코드를 확인해주세요."
          onClose={() => setIsModal('')}
        />
      )}
      {isModal === 'LoginId' && (
        <Modal
          title={`${value.loginId}은 이미 사용 중인 아이디입니다. 다른 아이디를 입력해 주세요.`}
          onClose={() => setIsModal('')}
        />
      )}
      {isModal === 'LoginIdSuccess' && (
        <Modal
          title={`${value.loginId}은 사용할 수 있는 아이디 입니다.`}
          onClose={() => setIsModal('')}
        />
      )}
      {isModal === 'detailInfo' && (
        <Modal
          title="이름과 소개는 필수 입력 정보 입니다."
          onClose={() => setIsModal('')}
        />
      )}
      {isModal === 'LicensExpired' && (
        <Modal
          title="라이센스가 만료되었습니다."
          onClose={() => setIsModal('')}
        />
      )}
      {isModal === 'License' && (
        <ModalWrapper>
          <ModalContainer>
            <div>{`${licenseData?.data.agencyName} 소속으로 가입을 진행합니다.`}</div>
            <button onClick={handleLicensekyModal}>확인</button>
          </ModalContainer>
        </ModalWrapper>
      )}
    </Wrapper>
  );
};

export default Index;

const Wrapper = styled.div`
  position: relative;

  height: 100vh;
  background: #f0f6ff;
  @media (max-height: 1080px) {
    min-height: 999px;
  }
  @media (max-width: 1651px) {
    width: 1652px;
  }
`;

const BeforeButton = styled.button`
  position: absolute;
  top: 80px;
  left: 80px;
  padding: 12px 24px;
  border-radius: 100px;
  border: 1px solid #0062ff;

  color: #0062ff;
  text-align: center;

  /* body/B03-16px-medium */

  font-size: 16px;

  font-weight: 500;

  & > span {
    margin-left: 12px;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 50px;
  margin-top: 80px;
  margin-bottom: 58px;
  border-radius: 100px;
  background: #fff;

  & > span {
    margin: 0px 10px;
    color: #0062ff;
    text-align: center;

    font-size: 18px;

    font-weight: 500;
  }
`;

const ModalWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
`;

const ModalContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 500px;
  padding: 32px;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);

  & > div {
    color: #111827;
    text-align: center;

    /* body/B02-18px-medium */

    font-size: 18px;

    font-weight: 500;
    line-height: 1.6em; /* 28.8px */
  }

  & > button {
    width: 200px;
    height: 56px;
    margin-top: 32px;
    background-color: #2c55fb;
    border-radius: 10px;
    color: #fff;
  }
`;
