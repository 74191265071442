import styled from '@emotion/styled';

import { ReportResponse } from '~/types/student/search';

import ReportChart from './ReportChart';

interface Props {
  transformedData?: { [key: string]: number | string };
  reportData?: ReportResponse;
  dateFilter: (value: string | Date | undefined | number) => string;
}

const GOOD = (
  <>
    설문과 과제수행 데이터를 분석한 결과
    <br />
    주의집중력 . 충동조절능력이
    <br />
    높은 것으로 판단됩니다.
    <br />
    인지강화 프로그램을 꾸준히 이용하여
    <br />
    인지능력을 향상시켜주세요.
  </>
);

const INTEREST = (
  <>
    설문과 과제수행 데이터를 분석한 결과
    <br />
    주의집중력 . 충동조절능력
    <br />
    저하가 우려됩니다.
    <br />
    힉업 및 일상생활에 어려움을 겪는다면
    <br />
    의료기관 방문을 고려해보세요.
  </>
);

const CAUTION = (
  <>
    설문과 과제수행 데이터를 분석한 결과
    <br />
    주의집중력 . 충동조절능력이
    <br />
    낮은 것으로 판단됩니다.
    <br />
    가급적 빠른 시일내에 의료기관에 방문하여
    <br />
    전문가의 상담을 받아보세요.
  </>
);

export default function PageTwo({
  transformedData,
  reportData,
  dateFilter,
}: Props) {
  return (
    <Wrapper>
      <Nav>
        <div>조이코그: 초등학생을 위한 인지측정 리포트</div>
        <div>측정일 : {dateFilter(transformedData?.screenDate)}</div>
      </Nav>
      <Title>
        {reportData?.data.studentInfo.name}
        <span>
          의<br />
          인지능력측정 리포트입니다.
        </span>
      </Title>
      <SubTitle>측정 결과</SubTitle>
      <ResultContainer>
        <div>
          <LeftTitle>
            주의집중력 · 충동조절능력
            <br />
            <Pertcent
              color={
                (transformedData?.probability as number) <= 45.9
                  ? '#39B974'
                  : (transformedData?.probability as number) >= 70
                  ? '#EF5C5C'
                  : '#F29E00'
              }
            >
              저하 가능성은{' '}
              {Math.floor(parseFloat(transformedData?.probability as string)) ||
                0}
              %{' '}
              {(transformedData?.probability as number) <= 10
                ? '이하'
                : (transformedData?.probability as number) >= 85
                ? '이상'
                : ''}
            </Pertcent>
            입니다.
          </LeftTitle>
          <ChartContainer>
            <ReportChart transformedData={transformedData} />
          </ChartContainer>
        </div>
        <Border />
        <div>
          <RightTitle>
            {(transformedData?.probability as number) <= 45.9
              ? GOOD
              : (transformedData?.probability as number) >= 70
              ? CAUTION
              : INTEREST}
          </RightTitle>
          <RightInfo>
            <InfoTitle>
              <InfoImg />
              유의사항
            </InfoTitle>
          </RightInfo>
          <InfoText>
            본 평가는 아동의 건강한 성장에 도움을 주기 위한
            <br />
            것입니다. 의학적 진단이 아니므로 질병의 유무를
            <br />
            판단할 수 없습니다.
          </InfoText>
        </div>
      </ResultContainer>
      <InfoContainer>
        <Info mgTop="23px">
          <span>이렇게 측정되었어요</span>
          <InfoImgText mgBottom="5px">
            <InfoImgs mgTop="2px" />
            <div>
              <span>인지능력 측정 결과</span>
              는 자사의 독자적인 인지 측정 알고리즘을 기반으로 4가지 인지요소
              <br />
              (주의력, 처리속도, 충동조절/행동 안정, 기억력)에 대해 중점적으로
              분석한 결과입니다.
            </div>
          </InfoImgText>
          <InfoImgText mgBottom="20px">
            <InfoImgs mgTop="2px" />
            <div>
              <span>측정 결과</span>
              는 자사의 AI 기술을 바탕으로 아동의 주의집중력・충동조절능력을
              분석한
              <br />
              결과입니다.
            </div>
          </InfoImgText>
        </Info>
        <Info mgTop="">
          <span>결과의 정확도가 떨어질 수 있는 경우</span>
          <InfoImgText mgBottom="5px">
            <InfoImgs mgTop="2px" />
            <div>
              설문 및 4가지 인지능력 측정 과제를 성실히 수행하지 않은 경우
            </div>
          </InfoImgText>
          <InfoImgText mgBottom="5px">
            <InfoImgs mgTop="2px" />
            <div>주변 환경으로 인해 집중이 어려운 경우</div>
          </InfoImgText>
          <InfoImgText mgBottom="24px">
            <InfoImgs mgTop="2px" />
            <div>ADHD 등의 이유로 약물을 복용중인 경우</div>
          </InfoImgText>
        </Info>
      </InfoContainer>
      <LogoImg />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 842px;
`;

const Nav = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 595px;
  height: 46px;
  background: #5a79e9;
  color: #fff;

  font-size: 10px;

  font-weight: 600;
  line-height: 1.6em; /* 16px */

  & > div {
    :nth-of-type(1) {
      margin-right: 229px;
    }
  }
`;

const Title = styled.div`
  margin-top: 30px;
  margin-left: 38px;
  margin-bottom: 24px;
  color: #000;

  font-size: 18px;

  font-weight: 800;
  line-height: 1.4em; /* 25.2px */

  & > span {
    color: #000;

    font-size: 18px;

    font-weight: 400;
    line-height: 1.4em;
  }
`;

const SubTitle = styled.div`
  text-align: center;
  margin-bottom: 10px;
  color: #000;

  font-size: 14px;

  font-weight: 800;
  line-height: 1.6em; /* 22.4px */
`;

const ResultContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 520px;
  height: 240px;
  margin-left: 38px;
  margin-bottom: 20px;
  border-radius: 10px;
  border: 0.5px solid #cbcbcb;
`;

const LeftTitle = styled.div`
  text-align: center;
  color: #000;
  font-size: 15px;
  font-weight: 400;
  line-height: 26px; /* 152.941% */
  letter-spacing: -0.4px;
`;

const Pertcent = styled.span`
  color: ${(props) => props.color};

  font-size: 15px;

  font-weight: 700;
  line-height: 26px;
  letter-spacing: -0.4px;
`;

const ChartContainer = styled.div`
  margin-top: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Border = styled.div`
  height: 188px;
  margin: 0 25px;
  border-left: 1px solid #ebebeb;
`;

const RightTitle = styled.div`
  margin-bottom: 23px;
  color: #000;

  font-size: 12px;

  font-weight: 400;
  line-height: 1.6em; /* 19.2px */
  letter-spacing: -0.4px;
`;

const RightInfo = styled.div``;

const InfoTitle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  color: #000;

  font-size: 11px;

  font-weight: 600;
  line-height: 1.5em; /* 16.5px */
`;

const InfoImg = styled.div`
  width: 12px;
  height: 12px;
  margin-right: 4.17px;
  background: url(/images/red!.png) no-repeat center;
  background-size: contain;
`;

const InfoText = styled.div`
  color: #000;
  font-size: 10px;

  font-weight: 400;
  line-height: 1.5em; /* 15px */
  letter-spacing: -0.4px;
`;

const InfoContainer = styled.div`
  width: 520px;
  margin-left: 38px;
  border-radius: 10px;
  background: #f8f8f8;
`;

const Info = styled.div<{ mgTop: string }>`
  margin-top: ${(props) => props.mgTop};
  margin-left: 31px;

  & > span {
    display: block;
    margin-bottom: 8px;
    color: #000;
    font-size: 12px;
    font-weight: 600;
    line-height: 1.6em; /* 19.2px */
  }
`;

const InfoImgText = styled.div<{ mgBottom: string }>`
  display: flex;
  margin-bottom: ${(props) => props.mgBottom};
  color: #000;
  font-size: 11px;
  font-weight: 500;
  line-height: 1.6em;
  letter-spacing: -0.4px;

  & > div {
    & > span {
      color: #5a79ea;
      font-size: 11px;
      font-weight: 500;
      line-height: 1.6em;
      letter-spacing: -0.4px;
    }
  }
`;

const InfoImgs = styled.div<{ mgTop: string }>`
  width: 12px;
  height: 12px;
  margin-right: 4px;
  background: url(/images/ReportCheck.png) no-repeat center;
  background-size: contain;
  margin-top: ${(props) => props.mgTop};
`;

const LogoImg = styled.div`
  position: absolute;
  bottom: 10px;
  left: 253px;
  width: 90px;
  height: 36px;
  background: url(/images/JoyCog90.png) no-repeat center;
  background-size: contain;
`;
