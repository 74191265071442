import {
  AccountPasswordRequestParams,
  AccountPasswordResponse,
} from '~/types/manager/pass';

import api from '~/api/base';

const BASE_URL = 'api/v1/manager/edit';

export const getAccountPasswordStatusData = (
  param: AccountPasswordRequestParams,
) => api.post<AccountPasswordResponse>(`${BASE_URL}/pass`, param);
