import { AxiosResponse } from 'axios';

import {
  SearchChildRequestParams,
  SearchChildResponse,
  SearchListRequestParams,
  SearchListResponse,
} from '~/types/push/search';

import api from '~/api/base';

const BASE_URL = 'api/v1/web/push/search';

export const getSearchChildStatusData = (
  param: SearchChildRequestParams,
): Promise<AxiosResponse<SearchChildResponse>> =>
  api.post(`${BASE_URL}/child/token`, param);

export const getSearchListStatusData = (
  param: SearchListRequestParams,
): Promise<AxiosResponse<SearchListResponse>> =>
  api.post(`${BASE_URL}/list`, param);
