import styled from '@emotion/styled';
import React, { useRef } from 'react';

interface Props {
  title?: string;
  subTitle?: string;
  onClose?: () => void;
}

const Modal = ({ title, subTitle, onClose }: Props) => {
  const modalRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: React.MouseEvent<HTMLDivElement>) => {
    if (event.target === event.currentTarget) {
      onClose?.();
    }
  };

  return (
    <Overlay onClick={handleClickOutside}>
      <Content ref={modalRef}>
        <TextContainer>
          <TextBox>
            <div>{title}</div>
            <div>{subTitle}</div>
          </TextBox>
          <div>
            <Button onClick={onClose}>확인</Button>
          </div>
        </TextContainer>
      </Content>
    </Overlay>
  );
};

const Overlay = styled.div`
  z-index: 99;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Content = styled.div`
  width: 500px;
  padding: 32px;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
`;

const TextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const TextBox = styled.div`
  color: #111827;
  text-align: center;
  margin-bottom: 32px;

  /* body/B02-18px-medium */

  font-size: 18px;

  font-weight: 500;
  line-height: 1.6em; /* 28.8px */
`;

const Button = styled.button`
  width: 170px;
  height: 60px;
  border-radius: 16px;
  background: #0062ff;
  color: #fff;
  text-align: center;

  /* body/B03-16px-bold */

  font-size: 16px;

  font-weight: 700;
  line-height: 1.3em; /* 20.8px */
`;

export default Modal;
