import styled from '@emotion/styled';
import { useEffect, useState } from 'react';

import { Cognitive } from '~/types/dash/cognitive';

import { getDashCognitiveStatusData } from '~/api/dash/cognitive';

import useAuth from '~/hooks/useAuth';

import Force from '~/components/dashboard/Force';
import ForceLog from '~/components/dashboard/ForceLog';
import Service from '~/components/dashboard/Service';
import Student from '~/components/dashboard/Student';

const DashBoardPage = () => {
  const { user: managerAtomData } = useAuth();

  const [dashboardData, setDashboardData] = useState<Cognitive>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!managerAtomData?.userId) {
      return;
    }

    getDashCognitiveStatusData({ userId: managerAtomData?.userId })
      .then(({ data }) => {
        setDashboardData(data?.data?.cognitive);
        setIsLoading(true);
      })
      .catch(console.error);
  }, [managerAtomData]);

  return (
    <Wrapper>
      <Header>
        {dashboardData?.managerName} 선생님
        <span>{dashboardData?.introduct}</span>
      </Header>
      <Container>
        <LeftSecction>
          <Student dashboardData={dashboardData} />
          <Force dashboardData={dashboardData} isLoading={isLoading} />
        </LeftSecction>
        <RightSecction>
          <Service userId={managerAtomData?.userId} />
          <ForceLog
            userId={managerAtomData?.userId}
            dashboardData={dashboardData}
            isLoading={isLoading}
          />
        </RightSecction>
      </Container>
    </Wrapper>
  );
};

export default DashBoardPage;

const Wrapper = styled.div`
  height: calc(100vh - 81px);
  background: #fafafa;
  @media (max-height: 1080px) {
    min-height: 999px;
  }
  @media (max-width: 1651px) {
    width: 1652px;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-top: 30px;
  margin-left: 46px;
  color: #000;

  /* heading/H02-24px-bold */

  font-size: 24px;

  font-weight: 700;
  line-height: 1.6em; /* 38.4px */

  & > span {
    margin-left: 20px;
    color: #000;

    /* body/B03-16px-medium */

    font-size: 16px;

    font-weight: 500;
  }
`;

const Container = styled.div`
  display: flex;
`;

const LeftSecction = styled.div`
  margin: 24px 30px 30px 30px;
`;

const RightSecction = styled.div`
  margin: 24px 30px 30px 0;
`;
