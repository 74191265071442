import { AxiosResponse } from 'axios';

import {
  ForceWeekReportRequestParams,
  ForceWeekReportResponse,
} from '~/types/student/force';

import api from '~/api/base';

const BASE_URL = 'api/v1/student/force';

export const getForceWeekReportStatusData = (
  param: ForceWeekReportRequestParams,
): Promise<AxiosResponse<ForceWeekReportResponse>> =>
  api.post(`${BASE_URL}/week/report`, param);
