import { AxiosResponse } from 'axios';

import { PostFindRequestParams, PostFindResponse } from '~/types/post/find';

import api from '~/api/base';

const BASE_URL = 'api/v1/web/post/find';

export const getSendMailStatusData = (
  param: PostFindRequestParams,
): Promise<AxiosResponse<PostFindResponse>> =>
  api.post(`${BASE_URL}/detail`, param);
