import { AxiosResponse } from 'axios';

import {
  SurveyRequestParams,
  SurveyStudentResponse,
} from '~/types/student/survey';

import api from '~/api/base';

const BASE_URL = 'api/v1/student';

export const getEditSurveyStatusData = (
  param: SurveyRequestParams,
): Promise<AxiosResponse<SurveyStudentResponse>> =>
  api.post(`${BASE_URL}/edit/survey`, param);

export const getRegisterSurveyStatusData = (
  param: SurveyRequestParams,
): Promise<AxiosResponse<SurveyStudentResponse>> =>
  api.post(`${BASE_URL}/register`, param);
