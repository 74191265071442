import styled from '@emotion/styled';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { AuthResponse, authenticaionDetailData } from '~/types/auth/auth';

import { ReactComponent as JoyCog77 } from '~/assets/JoyCog77.svg';

import Modal from '~/utils/portal/components/Modal';

import {
  getPgResultSearchStatusData,
  getPgTestStatusData,
} from '~/api/auth/auth';
import { getAccountPasswordStatusData } from '~/api/manager/pass';
import { getManagerIdStatusData } from '~/api/manager/profile';
import { getIdCheckStatusData } from '~/api/manager/valid';

import useInput from '~/hooks/useInput';

const FindIdPw = () => {
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const accountParams = searchParams.get('check');

  const [value, , handleChange] = useInput({
    account: '',
    password: '',
    checkPassword: '',
  });
  const [pgData, setPgData] = useState<AuthResponse>();
  const [authData, setAuthData] = useState<authenticaionDetailData>();
  const [isModal, setIsModal] = useState('');
  const [loginId, setLoginId] = useState('');
  const [isPassword, setIsPassword] = useState({
    checkPassword: false,
    password: false,
  });

  const handleIdButton = () => {
    navigate('/findidpw?check=1');
  };

  const handlePwButton = () => {
    navigate('/findidpw?check=2');
  };

  const handleCheckIdButton = () => {
    getAccountPasswordStatusData({
      checkPassword: value.checkPassword as string,
      loginId: value.account as string,
      password: value.password as string,
    })
      .then(({ data }) => {
        if (data.status === 20002) {
          setIsPassword((prev) => ({ ...prev, password: true }));
          return;
        } else {
          setIsPassword((prev) => ({ ...prev, password: false }));
        }

        if (data.status === 10024) {
          setIsPassword((prev) => ({
            ...prev,
            checkPassword: true,
          }));
          return;
        } else {
          setIsPassword((prev) => ({
            ...prev,
            checkPassword: false,
          }));
        }

        if (data.status === 10023) {
          setIsPassword((prev) => ({
            ...prev,
            password: true,
            checkPassword: true,
          }));

          return;
        } else {
          setIsPassword((prev) => ({
            ...prev,
            password: false,
            checkPassword: false,
          }));
        }

        if (data.status === 20001) {
          navigate('/findidpw?check=2');
          window.location.reload();
          return;
        }

        if (data.data.isValid) {
          setIsModal('successChangePassword');
        }
      })
      .catch(console.error);
  };

  const handleCheckPwButton = (id: string) => {
    if (id === 'password') {
      getIdCheckStatusData({ loginId: value.account as string })
        .then(({ data }) => {
          if (data?.status === 20000) {
            setIsModal('idCheck');
            return;
          } else if (data.data.isValid === true) {
            setIsModal('idCheck');
            return;
          } else {
            callSa('password');
          }
        })
        .catch(console.error);
    } else {
      callSa('id');
    }
  };

  const handleValueChange = (id: string, value: string) => {
    handleChange(id, value);
  };

  useEffect(() => {
    getPgTestStatusData({
      directAgency: null,
      reqSvcCd: 'SIMPLE',
      userBirth: null,
      userName: null,
      userPhone: null,
    })
      .then(({ data }) => {
        setPgData(data);
      })
      .catch(console.error);
  }, []);

  const popupCenter = (id: string): Window | null => {
    const _width = 400;
    const _height = 620;
    const xPos = document.body.offsetWidth / 2 - _width / 2;

    window.addEventListener('message', (event) => {
      if (event.source !== windowRef) return;

      if (id === 'password') {
        getPgResultSearchStatusData({
          mtxId: pgData?.data.authentication.mtxId as string,
          loginId: value.account as string,
        })
          .then(({ data }) => {
            if (data.status === 10600) {
              return;
            }
            if (data.data.authentication.resultCode === '0000') {
              setAuthData(data.data.authentication);
              setIsModal('AuthId');
              navigate('/findidpw?check=4');
            } else {
              setIsModal('failAuth');
            }
          })
          .catch(console.error);
      } else {
        getPgResultSearchStatusData({
          mtxId: pgData?.data.authentication.mtxId as string,
          loginId: null,
        })
          .then(({ data }) => {
            if (data.data.authentication.resultCode === '0000') {
              setAuthData(data.data.authentication);

              getManagerIdStatusData({
                authPhone: data.data.authentication.userPhone,
                name: data.data.authentication.userName,
              })
                .then(({ data }) => {
                  setLoginId(data.data.loginId);
                })
                .catch(console.error);
              navigate('/findidpw?check=3');
              setIsModal('AuthPassword');
            } else {
              setIsModal('failAuth');
            }
          })
          .catch(console.error);
      }
    });

    const windowRef = window.open(
      '',
      'sa_popup',
      `width=${_width}, height=${_height}, left=${xPos}, menubar=yes, status=yes, titlebar=yes, resizable=yes`,
    );

    return windowRef;
  };

  const callSa = async (id: string) => {
    const windowRef = popupCenter(id);
    if (windowRef) {
      const saForm = window.document.querySelector('form[name="saForm"]');
      if (saForm instanceof HTMLFormElement) {
        saForm.setAttribute('target', 'sa_popup');
        saForm.setAttribute('method', 'post');
        saForm.setAttribute('action', 'https://sa.inicis.com/auth');
        saForm.submit();
      }
    }
  };

  const handleSuccessChangePassword = () => {
    setIsModal('');
    navigate('/');
  };

  const handleReload = () => {
    window.location.reload();
  };

  return (
    <>
      <LogoNav>
        <Link to="/">
          <div>
            <JoyCog77
              style={{
                marginTop: '31px',
                marginLeft: '80px',
                marginBottom: '27px',
              }}
            />
          </div>
        </Link>
      </LogoNav>
      <Container>
        <Title>계정 찾기</Title>
        {/* 아이디 찾기 */}
        {accountParams === '1' && (
          <ItemContainer>
            <ItemBox>
              <ButtonContainer>
                <Link to="/findidpw?check=1">
                  <FindId
                    idWidth={'262px'}
                    bbColor={'#2c55fb'}
                    color={'#2c55fb'}
                    bbPx="2px"
                  >
                    아이디 찾기
                  </FindId>
                </Link>
                <Link to="/findidpw?check=2">
                  <FindPw
                    bbColor={'#e7e7e7'}
                    color={'black'}
                    bbPx="1px"
                    pwWidth={'262px'}
                  >
                    비밀번호 재설정
                  </FindPw>
                </Link>
              </ButtonContainer>
              <div>휴대폰 본인인증을 통해</div>
              <div>아이디를 확인합니다.</div>
              <AccessButton onClick={() => handleCheckPwButton('id')}>
                휴대폰 인증하기
              </AccessButton>
            </ItemBox>
          </ItemContainer>
        )}
        {/* 비밀번호 재설정 */}
        {accountParams === '2' && (
          <ItemContainer>
            <ItemBox>
              <ButtonContainer>
                <Link to="/findidpw?check=1">
                  <FindId
                    idWidth={'262px'}
                    bbColor={'#e7e7e7'}
                    color={'black'}
                    bbPx="1px"
                  >
                    아이디 찾기
                  </FindId>
                </Link>
                <Link to="/findidpw?check=2">
                  <FindPw
                    bbColor={'#2c55fb'}
                    color={'#2c55fb'}
                    bbPx="2px"
                    pwWidth={'262px'}
                  >
                    비밀번호 재설정
                  </FindPw>
                </Link>
              </ButtonContainer>
              <div>가입한 아이디를 입력해주세요.</div>
              <div>휴대폰 본인인증을 통해 비밀번호를 변경합니다.</div>
              <IdAccessContainer>
                <div>아이디</div>
                <Input
                  bdColor="#e2e2ea;"
                  type="text"
                  placeholder="아이디를 입력해주세요."
                  value={value.account || ''}
                  onChange={(e) => handleValueChange('account', e.target.value)}
                />
              </IdAccessContainer>
              <AccessButton onClick={() => handleCheckPwButton('password')}>
                휴대폰 인증하기
              </AccessButton>
            </ItemBox>
          </ItemContainer>
        )}
        {accountParams === '3' && (
          <ItemContainer>
            <ItemBox>
              <ButtonContainer>
                <FindId
                  idWidth={'525px'}
                  onClick={handleIdButton}
                  bbColor={'#2c55fb'}
                  color={'#2c55fb'}
                  bbPx="2px"
                >
                  아이디 찾기
                </FindId>
              </ButtonContainer>
              <div>
                {authData?.userName}
                님의 아이디는
              </div>
              <div>
                <span>{loginId} </span>
                입니다.
              </div>
              <Link to="/">
                <LoginButton>로그인 하기</LoginButton>
              </Link>
              <ResetPwButton onClick={handlePwButton}>
                비밀번호 재설정
              </ResetPwButton>
            </ItemBox>
          </ItemContainer>
        )}
        {accountParams === '4' && (
          <ItemContainer>
            <ItemBox>
              <ButtonContainer>
                <FindPw
                  onClick={handlePwButton}
                  bbColor={'#2c55fb'}
                  color={'#2c55fb'}
                  bbPx="2px"
                  pwWidth={'525px'}
                >
                  비밀번호 재설정
                </FindPw>
              </ButtonContainer>
              <div>비밀번호를 변경해주세요.</div>
              <IdAccessContainer>
                <div>새 비밀번호</div>
                <Input
                  type="password"
                  placeholder="새 비밀번호를 입력해주세요."
                  value={value.password || ''}
                  bdColor={isPassword.password ? '#FF4752' : '#e2e2ea;'}
                  onChange={(e) =>
                    handleValueChange('password', e.target.value)
                  }
                />
                {isPassword.password ? (
                  <p>
                    비밀번호는 최소 8글자 최대 16글자의 영문 대,소문자와 숫자
                    조합으로 입력해 주세요.
                  </p>
                ) : (
                  <p />
                )}
              </IdAccessContainer>
              <IdAccessContainer>
                <div>새 비밀번호 확인</div>
                <Input
                  type="password"
                  placeholder="새 비밀번호를 한번 더 입력해주세요."
                  bdColor={isPassword.checkPassword ? '#FF4752' : '#e2e2ea;'}
                  value={value.checkPassword || ''}
                  onChange={(e) =>
                    handleValueChange('checkPassword', e.target.value)
                  }
                />
                {isPassword.checkPassword ? (
                  <p>
                    비밀번호와 비밀번호 확인이 일치하지 않습니다. 다시 입력해
                    주세요.
                  </p>
                ) : (
                  <p />
                )}
              </IdAccessContainer>
              <AccessButton onClick={handleCheckIdButton}>
                비밀번호 재설정하기
              </AccessButton>
            </ItemBox>
          </ItemContainer>
        )}
        <TextContainer>
          <span>가입했던 계정이 기억나지 않을 경우</span>
          <div>
            <span>biz@emotiv.kr</span>
            <span>로 문의주시기 바랍니다.</span>
          </div>
        </TextContainer>
      </Container>
      <form name="saForm" hidden>
        <input
          type="text"
          name="mid"
          value={pgData?.data.authentication.mid ?? 0}
          readOnly
        />
        mid
        <br />
        <input
          type="text"
          name="reqSvcCd"
          value={pgData?.data.authentication.reqSvcCd ?? 0}
          readOnly
        />
        reqSvcCd
        <br />
        <input
          type="text"
          name="mTxId"
          value={pgData?.data.authentication.mtxId ?? 0}
          readOnly
        />
        mTxId
        <br />
        <input
          type="text"
          name="authHash"
          value={pgData?.data.authentication.authHash ?? 0}
          readOnly
        />
        authHash
        <br />
        <input
          type="text"
          name="flgFixedUser"
          value={pgData?.data.authentication.flgFixedUser ?? 0}
          readOnly
        />
        flgFixedUser
        <br />
        <input
          type="text"
          name="userName"
          value={pgData?.data.authentication.userName ?? 0}
          readOnly
        />
        userName
        <br />
        <input type="text" name="userPhone" value="" readOnly />
        userPhone
        <br />
        <input type="text" name="userBirth" value="" readOnly />
        userBirth
        <br />
        <input type="text" name="userHash" value="" readOnly />
        userHash
        <br />
        <input
          name="reservedMsg"
          value={pgData?.data.authentication.reservedMsg ?? 0}
          readOnly
        />
        reservedMsg
        <br />
        <input type="text" name="directAgency" value="" readOnly />
        directAgency
        <br />
        <input
          type="text"
          name="successUrl"
          value={pgData?.data.authentication.successUrl ?? 0}
          readOnly
        />
        successUrl
        <br />
        <input
          type="text"
          name="failUrl"
          value={pgData?.data.authentication.failUrl ?? 0}
          readOnly
        />
        failUrl
        <br />
      </form>
      {isModal === 'idCheck' && (
        <Modal
          title="입력하신 정보와 일치하는 계정을 찾을 수 없습니다."
          subTitle="다시 확인해주세요."
          onClose={() => setIsModal('')}
        ></Modal>
      )}
      {isModal === 'failAuth' && (
        <Modal
          title="등록된 휴대폰 정보와 일치하지 않습니다."
          onClose={handleReload}
        ></Modal>
      )}
      {isModal === 'noAuthData' && (
        <Modal
          title="본인인증 결과가 존재하지 않습니다."
          onClose={() => setIsModal('')}
        ></Modal>
      )}
      {isModal === 'successChangePassword' && (
        <Modal
          title="비밀번호 재설정이 완료되었습니다."
          subTitle="다시 로그인해주세요."
          onClose={handleSuccessChangePassword}
        ></Modal>
      )}
      {isModal === 'AuthId' && (
        <Modal
          title="본인 인증에 성공했습니다."
          onClose={() => setIsModal('')}
        ></Modal>
      )}
      {isModal === 'AuthPassword' && (
        <Modal
          title="본인 인증에 성공했습니다."
          onClose={() => setIsModal('')}
        ></Modal>
      )}
    </>
  );
};

export default FindIdPw;

const LogoNav = styled.div`
  border-bottom: 1px solid#e7e7e7;
`;

const Container = styled.div`
  width: 100vw;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
  margin-bottom: 90px;
  color: #171819;
  font-size: 32px;

  font-weight: 700;
  line-height: 1.3em;
  text-transform: capitalize;
`;

const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
`;

const ItemBox = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 525px;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.05);

  div {
    &:nth-of-type(2) {
      margin-top: 41px;
    }
    color: #111827;
    text-align: center;
    font-size: 18px;

    font-weight: 500;
    line-height: 1.6em;
  }

  span {
    color: #2c55fb;
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
`;

const FindId = styled.button<{
  bbColor: string;
  bbPx: string;
  idWidth: string;
}>`
  border-bottom: ${(props) => props.bbPx} solid ${(props) => props.bbColor};
  width: ${(props) => props.idWidth};
  height: 70px;
  color: ${(props) => props.color};
  text-align: center;
  text-overflow: ellipsis;
  font-size: 18px;

  font-weight: 500;
  line-height: 1.6em;
`;

const FindPw = styled.button<{
  bbColor: string;
  bbPx: string;
  pwWidth: string;
}>`
  border-bottom: ${(props) => props.bbPx} solid ${(props) => props.bbColor};
  width: ${(props) => props.pwWidth};
  height: 70px;
  color: ${(props) => props.color};
  text-align: center;
  text-overflow: ellipsis;
  font-size: 18px;

  font-weight: 500;
  line-height: 1.6em;
`;

const TextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #65696d;
  text-align: center;
  font-size: 16px;

  font-weight: 400;
  line-height: 1.5em;
  div {
    span {
      :nth-of-type(1) {
        border-bottom: 1px solid #65696d;
      }
    }
  }
`;

const AccessButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 480px;
  height: 56px;
  margin-top: 31px;
  margin-bottom: 40px;
  border-radius: 10px;
  background: #2c55fb;

  color: #fff;
  text-align: center;
  font-size: 18px;

  font-weight: 700;
  line-height: 1.5em;
  letter-spacing: 0.3px;

  a {
    color: #fff;
    text-align: center;
    font-size: 18px;

    font-weight: 700;
    line-height: 1.3em;
  }
`;

const IdAccessContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  div {
    margin-top: 30px;
    margin-bottom: 10px;
    color: #000;

    font-size: 14px;

    font-weight: 500;
    line-height: 100%; /* 14px */
  }

  & > p {
    height: 21px;
    margin-top: 5px;
    margin-bottom: 14px;
    color: #ff4752;

    font-size: 13px;

    font-weight: 500;
    line-height: 1.6em; /* 20.8px */
  }
`;

const Input = styled.input<{ bdColor: string }>`
  width: 440px;
  height: 56px;
  padding: 0 20px;
  border-radius: 10px;
  border: 1px solid ${(props) => props.bdColor};

  ::placeholder {
    color: #c7c7c7;
    font-size: 14px;

    font-weight: 400;
    line-height: 1.6em;
  }

  :focus {
    border: 1px solid #4469ff;
    color: #171819;
    font-size: 14px;

    font-weight: 400;
    line-height: 1.6em;
  }
`;

const LoginButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 480px;
  height: 56px;
  margin-top: 31px;
  margin-bottom: 12px;
  border-radius: 10px;
  background: #2c55fb;

  color: #fff;
  text-align: center;
  font-size: 18px;

  font-weight: 700;
  line-height: 1.5em;
  letter-spacing: 0.3px;
  a {
    color: #fff;
  }
`;
const ResetPwButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 480px;
  height: 56px;
  margin-bottom: 40px;
  border-radius: 10px;
  background: #fff;
  border-radius: 10px;
  border: 1px solid #2c55fb;
  color: #3467ff;
  text-align: center;
  font-size: 18px;

  font-weight: 700;
  line-height: 1.5em;
  letter-spacing: 0.3px;
`;
