import { Dispatch, SetStateAction, useCallback, useState } from 'react';

type InputValue = {
  [key: string]: string | null;
};

type ChangeHandler = (id: string, value: string) => void;

const useInput = (
  initalValue: InputValue,
): [InputValue, Dispatch<SetStateAction<InputValue>>, ChangeHandler] => {
  const [value, setValue] = useState<InputValue>(initalValue);

  const handleChange: ChangeHandler = useCallback((id, inputValue) => {
    setValue((prevValue) => ({
      ...prevValue,
      [id]: inputValue,
    }));
  }, []);

  return [value, setValue, handleChange];
};

export default useInput;
