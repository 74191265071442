import styled from '@emotion/styled';
import { NavLink, Outlet } from 'react-router-dom';

import { ReactComponent as ReceiveMailIcon } from '~/assets/ReceiveMailIcon.svg';
import { ReactComponent as SendMailIcon } from '~/assets/SendMailIcon.svg';
import { ReactComponent as SendPushIcon } from '~/assets/SendPushIcon.svg';

export default function MailBoxPage() {
  return (
    <Wrapper>
      <ButtonWrapper>
        <ButtonContainer>
          <StyledNavLink to={'./send-mail'}>
            <SendMailIcon />
            <div>보낸 우편함</div>
          </StyledNavLink>
        </ButtonContainer>
        <ButtonContainer>
          <StyledNavLink to={'./receive-mail'}>
            <ReceiveMailIcon />
            <div>받은 우편함</div>
          </StyledNavLink>
        </ButtonContainer>
        <ButtonContainer>
          <StyledNavLink to={'./send-push'}>
            <SendPushIcon />
            <div>보낸 푸시함</div>
          </StyledNavLink>
        </ButtonContainer>
      </ButtonWrapper>
      <ComponentsWrapper>
        <Outlet />
      </ComponentsWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  height: calc(100vh - 81px);
  background: #fafafa;
  @media (max-height: 1080px) {
    min-height: 999px;
  }
  @media (max-width: 1651px) {
    width: 1652px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 200px;
  height: 246px;
  margin: 30px 24px 0 24px;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.05);
`;

const ComponentsWrapper = styled.div`
  width: 1380px;
  height: 940px;
  margin: 30px 24px 30px 0;
  border-radius: 16px;
  background: #fff;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 152px;
  height: 46px;
  border-radius: 8px;
  color: #292d32;
  font-size: 16px;

  font-weight: 700;
  line-height: 1.3em;

  &:nth-of-type(1) {
    margin: 24px 0 30px 0;
  }

  &:nth-of-type(3) {
    margin: 30px 0 24px 0;
  }
`;

const StyledNavLink = styled(NavLink)`
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 8px;
  width: 100%;
  height: 100%;

  color: #171819;
  background: #fff;

  & svg {
    fill: #000;
  }

  &.active {
    color: #0062ff;
    border-radius: 8px;
    background: #f0f6ff;

    & svg {
      fill: #0062ff;
    }
  }

  div {
    margin-left: 12px;
  }
`;
