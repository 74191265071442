import { css } from '@emotion/react';
import styled from '@emotion/styled';
import ReactCalendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

interface DropdownState {
  result: boolean;
  calendar: boolean;
}

interface Props {
  isDate: Date;
  setIsDate: React.Dispatch<React.SetStateAction<Date>>;
  setIsDropdownOpen: React.Dispatch<React.SetStateAction<DropdownState>>;
}

export default function CalendarComponent({
  setIsDate,
  isDate,
  setIsDropdownOpen,
}: Props) {
  const handleSetHoveredDate = (date: Date | null) => {
    setIsDate(date as Date);
  };

  const handleHover = (date: Date | null) => {
    handleSetHoveredDate(date);
    setIsDropdownOpen((prevState) => ({
      ...prevState,
      calendar: false,
    }));
  };

  const isHoveredDate = (date: Date) =>
    isDate !== null && date.toDateString() === (isDate as Date).toDateString();

  const getWeekStart = (date: Date) => {
    const dayOfWeek = date.getDay();
    return new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate() - (dayOfWeek - 1),
    );
  };

  const getWeekEnd = (date: Date) => {
    const dayOfWeek = date.getDay();
    return new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate() + (7 - dayOfWeek),
    );
  };

  const rowStartDate = isDate ? getWeekStart(isDate) : null;
  const rowEndDate = isDate ? getWeekEnd(isDate) : null;

  const tileClassName = ({ date, view }: { date: Date; view: string }) => {
    if (view === 'month') {
      if (isDate && rowStartDate && rowEndDate) {
        if (date >= rowStartDate && date <= rowEndDate) {
          return 'react-calendar__tile--hover';
        }
        if (date.toDateString() === (isDate?.toDateString() ?? '')) {
          return 'react-calendar__tile--clicked';
        }
      }
    }
    return '';
  };

  return (
    <Wrapper>
      <Title>날짜 선택</Title>
      <CalendarWrapper>
        <StyledCalendar
          value={isDate}
          onChange={(e) => setIsDate(e as Date)}
          next2Label={null}
          prev2Label={null}
          tileContent={({ date }) => (
            <HoverMarker isHovered={isHoveredDate(date)} />
          )}
          tileClassName={tileClassName}
          onClickDay={(date) => handleHover(date)}
          formatDay={(locale, date) =>
            date.toLocaleString('en', { day: 'numeric' })
          }
        />
      </CalendarWrapper>
    </Wrapper>
  );
}

const HoverMarker = styled.div<{ isHovered: boolean }>`
  border-radius: 50%;
  background: ${(props) =>
    props.isHovered ? 'rgba(0, 98, 255, 0.2)' : 'none'};
`;

const Wrapper = styled.div`
  z-index: 3;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  position: absolute;
  width: 320px;
  padding: 30px 0;
  top: 58px;
  right: 0px;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 5px 40px 0px rgba(0, 0, 0, 0.1);
`;

const Title = styled.div`
  text-align: left;
  width: 272px;
  margin-left: 26px;
  margin-bottom: 24px;
  color: #000;

  font-size: 16px;

  font-weight: 700;
  line-height: 1.3em;
`;

const CalendarWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const StyledCalendar = styled(ReactCalendar)`
  padding: 0 10px;
  border: none;

  ${css`
    .react-calendar__navigation {
      height: 40px;
    }

    abbr {
      text-decoration: none;
      font-size: 14px;

      font-weight: 400;

      :focus {
        color: #fff;
      }
    }

    .react-calendar__month-view__days__day--weekend {
      color: #000;
    }
    .react-calendar__tile {
    }

    .react-calendar__month-view__days__day--neighboringMonth {
      color: #b5b5be;
    }

    .react-calendar__tile--now {
      background: none;
    }
    .react-calendar__tile--active {
      background: #0062ff;
      color: white;
    }

    .react-calendar__tile,
    .react-calendar__month-view__days__day {
    }

    .react-calendar__tile--hover {
      color: white;
      background: #0062ff;
    }

    .react-calendar__tile--clicked {
      background: #0062ff;
      color: white;
    }
  `}
`;
