import { AxiosResponse } from 'axios';

import {
  PasswordRequestParams,
  PasswordResponse,
  ProfileRequestParams,
  ProfileResponse,
} from '~/types/manager/edit';

import api from '~/api/base';

const BASE_URL = 'api/v1/manager/edit';

export const getEditPasswordStatusData = (
  param: PasswordRequestParams,
): Promise<AxiosResponse<PasswordResponse>> =>
  api.post(`${BASE_URL}/password`, param);

export const getEditProfileStatusData = (param: ProfileRequestParams) =>
  api.post<ProfileResponse>(`${BASE_URL}/profile`, param);
