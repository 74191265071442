import styled from '@emotion/styled';
import Chart from 'chart.js/auto';
import { useEffect, useRef } from 'react';

import { Cognitive } from '~/types/dash/cognitive';

interface Props {
  dashboardData?: Cognitive;
}
const AllStudentChart = ({ dashboardData }: Props) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    if (canvasRef.current) {
      const ctx = canvasRef.current.getContext('2d');

      const red =
        dashboardData?.measurementStudentCount === 0
          ? 1
          : dashboardData?.attentionStudentCount;
      const yellow = dashboardData?.needAttentionStudentCount;
      const green = dashboardData?.normalStudentCount;

      if (ctx) {
        const backgroundColor =
          dashboardData?.measurementStudentCount === 0
            ? ['#F6F6F6', '', '']
            : ['#FF4752', '#F0A95A', '#0CAF60'];

        const chart = new Chart(ctx, {
          type: 'doughnut',
          data: {
            datasets: [
              {
                data: [red, yellow, green],
                backgroundColor: backgroundColor,
              },
            ],
          },
          options: {
            cutout: 65,
            rotation: 4140,
            plugins: {
              legend: {
                display: false,
              },
              tooltip: {
                enabled: false,
              },
            },
            elements: {
              arc: {
                borderRadius: 15,
                borderWidth: 10,
              },
            },
          },
        });

        return () => {
          chart.destroy();
        };
      }
    }
  }, [dashboardData]);

  return (
    <Wrapper>
      <canvas ref={canvasRef}></canvas>
      <Title>측정 학생 수</Title>
      <TitleData>{dashboardData?.measurementStudentCount}</TitleData>
    </Wrapper>
  );
};

export default AllStudentChart;

const Wrapper = styled.div`
  position: relative;
  margin-left: 43.5px;
  margin-right: 100px;
  width: 240px;
  height: 242px;
`;

const Title = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 30px;
  top: 89px;
  left: 70px;

  color: #939393;

  /* body/B05-14px-regular */

  font-size: 14px;

  font-weight: 400;
  line-height: 1.6em; /* 22.4px */
`;

const TitleData = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  top: 109px;
  left: 95px;
  color: #474747;
  text-align: center;

  /* heading/H01-32px-bold */

  font-size: 32px;

  font-weight: 700;
  line-height: 1.3em; /* 41.6px */
  text-transform: capitalize;
`;
