import { css } from '@emotion/react';
import styled from '@emotion/styled';
import moment from 'moment';
import ReactCalendar, { CalendarProps } from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

interface Props {
  selectedDate: Date | undefined;
  setSelectedDate: React.Dispatch<React.SetStateAction<Date | undefined>>;
}

export default function ServiceCalendar({
  selectedDate,
  setSelectedDate,
}: Props) {
  const handleDateChange: CalendarProps['onChange'] = (value) => {
    if (value instanceof Date) {
      setSelectedDate(value);
    }
  };

  return (
    <CalendarWrapper>
      <StyledCalendar
        next2Label={null}
        prev2Label={null}
        value={selectedDate}
        onChange={handleDateChange}
        formatDay={(locale, date) => moment(date).format('DD')}
      />
    </CalendarWrapper>
  );
}

const CalendarWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const StyledCalendar = styled(ReactCalendar)`
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 320px;

  ${css`
    .react-calendar__navigation {
    }

    abbr {
      border-radius: 50%;
      text-decoration: none;
      font-size: 14px;

      font-weight: 400;
    }

    .react-calendar__navigation__label {
      margin: 0 70px;
      color: #000;
      text-align: center;
      pointer-events: none;

      /* body/B03-16px-bold */
      font-size: 16px;

      font-weight: 700;
      line-height: 1.3em; /* 20.8px */
    }

    .react-calendar__month-view__days__day--weekend {
      color: #000;
    }

    .react-calendar__month-view__days__day--neighboringMonth {
      color: #b5b5be;
    }

    .react-calendar__tile--now {
      background: none;
    }

    .react-calendar__tile--active {
      background: #0062ff;
      color: white;
    }

    .react-calendar__month-view__weekdays__weekday {
      flex-basis: 45px !important;
      padding: 0.5em;
      border-radius: 50%;
    }

    .react-calendar__tile--hover {
      color: white;
      background: #0062ff;
    }
    .react-calendar__tile {
      flex-basis: 45px !important;
      height: 45px;
      padding: 0.5em;
      border-radius: 50%;
    }

    .initial-closing-date:not(.react-calendar__tile--active) {
      background-color: rebeccapurple;
      color: white;
    }
  `}
`;
