import { AxiosResponse } from 'axios';

import {
  DashCognitiveParams,
  DashCognitiveResponse,
} from '~/types/dash/cognitive';

import api from '~/api/base';

const BASE_URL = 'api/v1/dash/find';

export const getDashCognitiveStatusData = (
  param: DashCognitiveParams,
): Promise<AxiosResponse<DashCognitiveResponse>> =>
  api.post(`${BASE_URL}/cognitive`, param);
