import { AxiosResponse } from 'axios';

import {
  IdCheckRequestParams,
  IdCheckResponse,
  LicenseRequestParams,
  LicenseResponse,
} from '~/types/manager/valid';

import api from '~/api/base';

const BASE_URL = 'api/v1/manager/valid';

export const getLisenceStatusData = (
  param: LicenseRequestParams,
): Promise<AxiosResponse<LicenseResponse>> =>
  api.post(`${BASE_URL}/license`, param);

export const getIdCheckStatusData = (
  param: IdCheckRequestParams,
): Promise<AxiosResponse<IdCheckResponse>> => api.post(`${BASE_URL}/id`, param);
