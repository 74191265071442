import styled from '@emotion/styled';
import React from 'react';

export default function TermsOfUsePage() {
  return (
    <Wrapper>
      <h1>이용약관</h1>
      <div>
        제1장 (총칙)
        <br />
        제1조 (목적)
        <br />
        이 약관은 ㈜이모티브(이하 “회사”라 합니다)가 제공하는 서비스의 이용과
        관련하여 회원이 서비스를 이용하는 데 필요한 권리, 의무 및 책임사항,
        이용조건 및 절차 등 기본적인 사항을 규정함을 목적으로 합니다.
        <br />
        제2조 (용어의 정의)
        <br />
        1. 이 약관에서 사용 되는 용어의 정의는 다음과 같습니다.
        <br />
        ① ‘회사’는 ‘콘텐츠’ 산업과 관련된 경제활동을 영위하는 자로서 ‘콘텐츠’ 및
        제반 서비스를 제공하는 자를 말합니다.
        <br />
        ② ‘회원’은 ‘회사’와 이용계약을 체결하고 계정을 부여받은 자로서 ‘회사’의
        정보를 지속적으로 제공받고 ‘회사’가 제공하는 서비스를 지속적으로 이용할
        수 있는 자를 말합니다.
        <br />
        ③ ‘콘텐츠’는 온라인과 모바일 기기로 이용할 수 있도록 회사가 제공하는
        서비스와 관련하여 디지털 방식으로 제작한 유료 또는 무료의 내용물 일체를
        의미합니다.
        <br />
        ④ ‘서비스’는 회사가 개발한 웹과 앱을 통해 회원에게 제공하는 학생 등록 및
        관리(정보 조회, 우편 및 푸시 발송), 인지능력 측정(검사), 인지능력 강화
        프로그램 등(이하 JoyCog)을 말합니다.
        <br />
        ⑤ ‘유료서비스’는 ‘회사’가 유료로 제공하는 ‘콘텐츠’ 및 제반 서비스를
        말합니다.
        <br />
        2. 이 약관에서 사용하는 용어의 정의는 제1항 각 호에서 정하는 것을
        제외하고는 관계 법령 및 개인정보처리방침, 회사가 별도로 정한 지침과 기타
        일반적인 상관례를 따릅니다.
        <br />
        제3조 (약관의 효력 범위)
        <br />
        1. 약관은 서비스를 이용하는 모든 회원에 대하여 효력이 있습니다.
        <br />
        2. 이 약관은 회원이 회사로부터 약관에 동의하여 회사와 이용계약을 체결한
        날로부터 이용계약을 해지하는 시점까지 적용되는 것을 원칙으로 합니다.
        다만, 이 약관의 일부 조항은 약관의 적용이 종료한 이후에도 효력이 계속될
        수 있습니다.
        <br />
        제4조 (회사의 기본정보)
        <br />
        회사는 다음 각 호의 사항을 회원이 알아보기 쉽도록 웹사이트 내에
        표시합니다.
        <br />
        1. 상호 및 대표자의 성명
        <br />
        2. 영업장 소재지 주소
        <br />
        3. 전화번호, 전자우편주소
        <br />
        4. 사업자 등록번호
        <br />
        5. 통신판매업 신고번호
        <br />
        6. 개인정보처리방침
        <br />
        7. 서비스 이용약관
        <br />
        제5조 (약관의 명시 및 개정)
        <br />
        1. 회사는 회원이 쉽게 알 수 있도록, 회사의 서비스 초기화면에 상설메뉴로
        이 약관의 내용을 게시합니다.
        <br />
        2. 회사는 『약관의 규제에 관한 법률』, 『정보통신망 이용촉진 및 정보
        보호 등에 관한 법률』 등 관련 법령을 위반하지 않는 범위에서 이 약관 및
        개별 서비스 약관(제 6조에 정함)과 기타 서비스 정책을 수시로 개정 또는
        변경할 수 있습니다.
        <br />
        3. 회사는 약관을 개정하는 경우 적용 일자 및 개정 사유를 개정 전 약관과
        함께 적용 일자 7일 전(이용자에게 불리하거나 중대한 사항의 변경은 30일
        이전)부터 서비스 공지사항에 게시하는 방법으로 회원에게 통지합니다. 다만,
        회원에게 불리한 내용의 약관 개정의 경우에는 서비스 공지 외에 로그인 시
        동의창, 휴대전화 메시지(SMS, LMS 등) 등의 방법으로 회원에게 추가
        통지합니다.
        <br />
        4. 회사는 전항에 따라 개정 약관을 공지 또는 통지하면서 그와 함께 회원이
        개정 약관의 효력 발생일까지 그에 대한 거부의 의사표시를 하지 않으면,
        개정 약관에 승낙한 것으로 간주하겠다는 내용을 공지할 수 있습니다. 이러한
        공지가 이루어진 경우, 회원이 개정 약관의 효력 발생일까지 그에 대한 거부
        의사를 표시하지 않거나 탈퇴하지 않으면, 그 회원은 청약철회 등 개정
        약관에 정한 모든 내용에 대하여 동의한 것으로 간주됩니다.
        <br />
        5. 회사는 개정 약관의 적용에 동의하지 않는 회원에 대하여 개정 약관의
        내용을 적용할 수 없습니다. 단, 이 경우 회사 및 회원은 이용계약을 해지할
        수 있습니다.
        <br />
        6. 개정된 약관은 원칙적으로 그 효력 발생일(적용 일자)로부터 유효합니다.
        <br />
        제6조 (개별 서비스 약관 및 약관 외 준칙)
        <br />
        1. 회사는 서비스의 구체적인 내용에 따라 회원의 동의를 얻어 개별 서비스에
        대한 약관 및 이용조건(이하 “개별 서비스 약관”이라 합니다)을 별도로 정할
        수 있습니다.
        <br />
        2. 제1항의 개별 서비스 이용에 관하여는 해당 개별 서비스 약관이 이 약관에
        우선하여 적용됩니다.
        <br />
        3. 이 약관에 정하지 않은 사항에 대하여는 개별 서비스 약관 및
        개인정보처리방침, 관련 법령 또는 상관례를 따릅니다.
        <br />
        제7조 (정보의 통지)
        <br />
        1. 회사는 회원이 제공한 휴대폰 번호로 SMS 또는 LMS를 전송하는 방법을
        통하여 회원에게 정보를 통지할 수 있습니다.
        <br />
        2. 회사는 불특정 다수의 회원에 대한 통지의 경우, 서비스 공지사항 또는
        게시판에 7일 이상의 기간 동안 그 통지를 게시하는 방법으로 제1항의 통지를
        갈음할 수 있습니다. 다만, 특정 회원의 거래와 관련하여 중대한 영향을
        미치는 사항에 대하여는 제1항에 따라 개별적으로 통지를 하여야 합니다.
        <br />
        제2장 (서비스 이용)
        <br />
        제8조 (회원가입)
        <br />
        1. 회원가입은 약관의 내용에 동의하여 회원이 되고자 하는 자(이하
        “가입신청자”라 합니다)가 회사에 가입신청을 하고, 회사가 이러한 신청에
        대하여 가입을 승낙함으로써 체결됩니다. 한편, 비회원은 회사가 제공하는
        서비스를 이용함으로써 이 약관에 따른 이용계약을 체결하는 것에 동의한
        것으로 봅니다.
        <br />
        2. 회사는 가입신청자가 제1항의 가입신청을 하는 경우 원칙적으로 이를
        승낙합니다. 다만, 회사는 다음 각 호의 경우 가입신청의 승낙을 거절할 수
        있으며, 사후에 회원에게 다음 각 호의 사유가 존재함을 안 경우 그 회원과의
        이용계약을 해지할 수 있습니다.
        <br />
        ① 가입신청자가 타인의 명의를 도용하여 가입신청을 한 경우
        <br />
        ② 가입신청과 관련하여 허위 또는 부정확한 정보를 기재하거나, 회사가
        제시하는 내용을 기재하지 않은 경우
        <br />
        ③ 가입신청자가 이전에 본 항 각 호의 사유로 인하여 회사에 의해 이용계약이
        해지된 적이 있는 경우. 단, 회사로부터 회원 재가입에 대한 승낙을 얻은
        경우는 예외로 함<br />
        ④ 기타 제반 규정 위반 등 가입신청자의 귀책 사유로 인하여 회사가
        가입신청의 승낙을 거절할 만한 객관적인 사유가 존재하는 경우
        <br />
        3. 회사는 서비스 관련 설비의 여유가 없거나, 기술상 또는 업무상 문제가
        있을 시 가입신청의 승낙을 유보할 수 있습니다. 이 경우, 회사는 문제를
        해결하고 가입신청의 승낙을 재개할 수 있도록 최선을 다합니다.
        <br />
        4. 제2항에 따라 회원가입신청의 승낙을 거절 또는 유보하는 경우, 회사는
        해당 사실을 가입신청자에게 통지하여야 합니다.
        <br />
        5. 회사가 서비스 가입신청 절차상에서 가입신청자에게 가입완료 사실을
        통지함으로써 이용계약이 성립하며, 그에 따라 해당 가입신청자는 회원의
        지위에서 회사가 제공하는 서비스를 이용할 수 있습니다.
        <br />
        제9조 (서비스의 내용)
        <br />
        1. 회사가 제공하는 서비스의 내용은 다음 각 호와 같습니다.
        <br />
        ① 선생님 Web
        <br />
        (1) 학생 관리(등록 및 연결, 정보 조회, 우편/푸시/이용권/재화 발송)
        <br />
        (2) 기관 조회
        <br />
        (3) 인지능력 측정 이용권 구매
        <br />
        (4) 인지능력 강화 결과 조회
        <br />
        (5) 대시보드 및 인지능력 리포트
        <br />
        (6) 기타 회사가 추가 개발하거나 다른 회사와의 제휴 계약 등을 통해
        이용자에게 제공하는 일체의 서비스
        <br />
        ② 학생 JoyCog App
        <br />
        (1) 인지능력 측정
        <br />
        (2) 인지능력 강화
        <br />
        (3) 인벤토리, 마을 시스템
        <br />
        (4) 대시보드
        <br />
        (5) 기타 회사가 추가 개발하거나 다른 회사와의 제휴 계약 등을 통해
        이용자에게 제공하는 일체의 서비스
        <br />
        제10조 (서비스 이용요금)
        <br />
        1. 이용요금은 서비스의 내용에 따라 무료로 제공됩니다. 그러나 일부
        서비스(기능) 또는 콘텐츠의 경우 유료로 제공될 수 있습니다.
        <br />
        2. 회사는 유료 서비스 등의 가격 및 지급방법, 지급시기 등 구체적인 사항을
        정하여 서비스 공지사항 또는 게시판을 통해 게시합니다.
        <br />
        3. 이용요금에 관한 사항(기존에 무료로 제공 되던 서비스의 유료화 등을
        포함)은 회사의 정책에 따라 변경될 수 있습니다.
        <br />
        4. 회사는 서비스 이용요금에 관한 사항을 변경하는 경우, 변경일로부터
        적어도 30일 이전까지 앱 또는 사이트 게시, 이메일 또는 서면을 통한
        방법으로 회원에게 해당 사항을 통지합니다. 위 변경 내용의 효력은 통지상에
        명기한 변경일로부터 유효하게 발생하며, 위 변경에 동의하지 않는 회원은
        이용계약을 해지할 수 있습니다.
        <br />
        5. 회원은 회사와의 별도 합의를 통해 본 조항과 다른 내용으로 이용요금에
        관한 사항을 정할 수 있습니다. 회사와 회원 사이에 별도 체결된 계약 내용은
        서비스 공지사항 또는 게시판에 게시된 내용에 대하여 효력상 우선합니다.
        <br />
        6. 회원이 서비스를 이용하는 과정에서 통신 사업자 등과의 거래 관계에서
        발생하는 데이터 통신 요금은 제1항의 이용 요금과는 별개의 것이며, 회사는
        위 데이터 통신 요금과 관련하여 어떠한 의무 또는 책임도 지지 않음을
        분명히 합니다.
        <br />
        제11조 (서비스의 제공)
        <br />
        1. 회사는 관련 법령상 허용되는 그리고 기술적으로 가능한 범위 내에서
        제9조의 서비스를 제공합니다.
        <br />
        2. 서비스를 이용하는 국가 및 회원의 단말기 모델에 따라 회사가 제공하는
        서비스의 내용 또는 범위가 달라질 수 있습니다. 일부 서비스의 경우 회원이
        이용하는 단말에서 특정 기능의 접근 권한이 필요하며, 회원이 접근 권한을
        불허하는 경우 관련 서비스의 이용이 제한될 수 있습니다.
        <br />
        3. 회원은 원칙적으로 단말기가 통신 네트워크에 연결된 상태에서 서비스의
        기능을 정상적으로 이용할 수 있습니다. 단말기가 통신 네트워크에 연결되지
        않은 경우, 일부 서비스의 이용이 제한될 수 있습니다.
        <br />
        4. 회사는 업무상 또는 기술상 특별한 지장이 없는 한 연중무휴 1일 24시간
        이용자에게 서비스를 제공합니다. 다만 회사는 정기 점검 등의 필요가 있는
        경우 일정 기간 동안 서비스의 제공을 제한할 수 있으며, 회사는 이러한
        사실을 회원에게 사전 통지합니다.
        <br />
        5. 회사는 서비스 이용 가능 시간을 별도로 정할 수 있으며, 그 내용을
        이용자에게 사전에 통지합니다. 회사가 통제할 수 없는 사유로 발생한 서비스
        중단에 대하여 사전 통지가 불가능한 경우에는 이를 사후에 통지할 수
        있습니다.
        <br />
        6. 회사는 내부 정책 및 각 유형에 따라 회원을 등급별로 구분하고, 제공하는
        서비스의 내용 및 메뉴, 이용시간, 이용횟수 등을 세분하여 그 이용에 차등을
        둘 수 있으며, 회사는 이러한 사실을 해당 회원에게 사전 통지합니다.
        <br />
        제12조 (서비스의 이용 제한)
        <br />
        1. 회사는 회원이 제16조의 의무 및 기타 이 약관상 의무를 위반하는 경우,
        해당 회원에 대하여 다음 각 호의 이용제한 조치를 단계적으로 취할 수
        있습니다.
        <br />
        ① 시정요구
        <br />
        ② 일시정지
        <br />
        ③ 영구이용정지
        <br />
        2. 본 조의 이용제한 조치에 관한 조건 및 세부내용은 회사의 이용제한정책
        및 개별 서비스 상의 운영정책을 통하여 정합니다.
        <br />
        제13조 (서비스의 변경 및 중단, 종료)
        <br />
        1. 회사는 다음 각 호의 경우 회원에 대하여 제공하는 서비스의 전부 또는
        일부를 일시적으로 변경하거나 중단할 수 있습니다.
        <br />
        ① 설비의 보수점검, 교체 등 공사로 인하여 부득이한 경우
        <br />
        ② 정전, 제반 설비의 장애 또는 이용량의 폭주 등으로 정상적인 서비스
        이용에 지장이 있는 경우
        <br />
        ③ 국가기관 또는 정부조직(방송통신위원회, 한국정보보호진흥원 등),
        수사기관, 법원 등의 행정 또는 사법적 처분 등에 따라 법률상 서비스의
        제공을 제한 또는 중단하여야 하는 경우
        <br />
        ④ 기타 회사의 운영상, 기술상 필요 등 상당한 이유가 있는 경우
        <br />
        2. 회사는 다음 각 호의 경우 서비스의 제공을 영구적으로 종료할 수
        있습니다.
        <br />
        ① 국가기관 또는 정부조직(방송통신위원회, 한국정보보호진흥원 등),
        수사기관, 법원 등의 행정 또는 사법적 처분 등에 따라 법률상 서비스의
        제공을 종료하여야 하는 경우
        <br />
        ② 기타 경영상, 운영 정책상, 기술상 사유 등으로 인하여 회사가 서비스
        제공을 계속할 수 없는 상당한 이유가 있는 경우
        <br />
        3. 본 조항에 따라 서비스를 변경 또는 중단(제2항의 종료를 포함)하는 경우,
        회사는 사전에 다음 각 호의 사항을 제7조에 정한 방법으로 회원에게
        통지하여야 합니다. 다만, 사전에 통지할 수 없는 부득이한 사유가 있는 경우
        회사는 서비스의 제공을 변경 또는 중단하고 나서 사후에 회원에게 통지할 수
        있습니다.
        <br />
        ① 서비스 변경(또는 중단) 사유
        <br />
        ② 서비스 변경일(또는 중단일)
        <br />
        ③ 변경될 서비스의 내용(서비스 변경의 경우)
        <br />
        4. 본 조항에 따른 서비스 변경 또는 중단과 관련하여, 회사는 관련 법령
        또는 개별 계약상의 근거가 없는 이상 회원에 대하여 별도의 배상 또는 보상
        책임을 지지 않습니다. 다만, 유료 서비스를 이용한 회원의 경우, 회사는
        서비스 변경 또는 중단으로 인한 서비스 미사용 부분 관련 이용요금을
        회원에게 환불합니다.
        <br />
        제14조 (이용 데이터의 보관)
        <br />
        1. 로그 보관 목적본 서비스는 보안 및 안정성을 유지하고 서비스 이용 시
        발생한 문제를 파악하고 대응하기 위해 서버 로그를 수집하고 보관합니다.
        <br />
        2. 로그 보관 기간서비스 이용 기록, 접속 로그, 기기 정보 등의 서버 로그는
        30일까지 보관됩니다. 다만, 법령의 규정에 따라 서비스 제공자가 보관하여야
        하는 경우에는 해당 법령에서 정한 기간 동안 보관됩니다.
        <br />
        3. 개인정보 보호서버 로그는 본인 확인, 민원 처리, 서비스 제공 등 목적
        이외의 용도로 사용되지 않으며, 외부에 제공되지 않습니다. 또한, 서비스
        제공자는 개인정보보호법 등 관련 법령을 준수하며, 이용자의 개인정보를
        적절히 보호하기 위해 최선의 노력을 다할 것입니다.
        <br />
        제15조 (광고 기타 정보의 제공)
        <br />
        1. 회사는 서비스 이용과 관련한 정보를 제7조에 정한 방법으로 회원에게
        제공할 수 있습니다. 회원은 관련 법령에 따른 거래 관련 정보 및 고객문의
        등에 대한 답변 등을 제외하고는 언제든지 회사가 제공하는 정보가 포함된
        이메일의 수신을 거절할 수 있습니다.
        <br />
        2. 회사는 서비스 화면, 웹사이트, 앱, 이메일 등을 통하여 회원에게 광고 등
        정보를 제공할 수 있습니다. 회원은 회사에 대한 의사표시로서 광고가 포함된
        이메일에 대한 수신을 거절할 수 있습니다.
        <br />
        3. 회원은 회사가 제공하는 정보 및 광고가 포함된 이메일에 대한 수신을
        동의한 상태에서 이용계약 해지(탈퇴)를 하지 않고, 앱을 삭제하거나
        단말기의 공장초기화를 진행한 경우, 앱 삭제 혹은 공장초기화 이전의 수신
        동의는 계속 유효합니다.
        <br />
        제3장 (계약 당사자의 권리 의무)
        <br />
        제16조 (회사의 일반 의무)
        <br />
        1. 회사는 회원에 대한 서비스 제공과 관련하여 관계 법령 및 미풍양속에
        반하는 행위를 하지 않으며, 계속적이고 안정적인 서비스 제공을 위하여
        최선을 다합니다.
        <br />
        2. 회사는 회원이 안전하게 서비스를 이용할 수 있도록 회원의
        개인정보(신용정보, 민감정보 포함)를 보호하기 위하여 최선을 다하며,
        개인정보처리방침을 공시하고 준수합니다.
        <br />
        3. 회사는 서비스 이용과 관련한 회원의 불만 또는 피해구제 요청을 적절하게
        처리할 수 있도록 필요한 인력 및 시스템을 구축하고 유지할 수 있도록
        최선을 다합니다.
        <br />
        4. 회사는 서비스 이용과 관련하여 회원이 정당한 의견 또는 불만을 제기하는
        경우, 이메일 등을 통하여 회원에게 처리 과정 및 결과를 신속하게
        전달합니다.
        <br />
        제17조 (이용자의 일반 의무)
        <br />
        1. 회원은 서비스 이용과 관련하여 다음 각 호의 행위를 하여서는 아니
        됩니다.
        <br />
        ① 가입신청 또는 회원정보 변경 시 허위 내용을 등록하는 행위
        <br />
        ② 타인의 개인정보, 아이디 및 비밀번호 등 서비스 이용 정보 등을 도용하는
        행위
        <br />
        ③ 회사의 사전 승낙 없이 회사가 서비스에 게시한 정보를 변경 또는 삭제하는
        행위
        <br />
        ④ 회사 및 타인의 지식재산권, 인격권, 명예권 기타 권리를 침해하는 행위
        <br />
        ⑤ 서비스 정보통신망에 장애를 유발시킬 수 있는 내용의 정보, 문장, 도형
        등을 회사가 관리·운영하는 정보통신망에 유포하는 행위
        <br />
        ⑥ 서비스 운영을 고의로 방해하거나 서비스의 안정적 운영을 방해할 수 있는
        정보를 전송하는 행위
        <br />
        ⑦ 제공되는 서비스 및 콘텐츠를 본 약관에 명시된 목적 이외의 용도로
        사용하는 행위
        <br />
        ⑧ 기타 미풍양속에 위반하거나 부당한 행위
        <br />
        2. 회원은 서비스 이용과 관련하여 관계 법령, 약관, 세부이용지침, 서비스
        이용안내 및 회사가 통지한 공지사항 등을 준수하여야 하며, 기타 회사의
        업무를 방해하는 행위를 하여서는 안됩니다.
        <br />
        3. 회원은 회사의 사전 승낙 없이 회사가 제공하는 서비스를 이용하여 회사의
        영업과 동종 또는 유사한 영업을 영위할 수 없습니다.
        <br />
        4. 회사의 사전 승낙 없이 회원이 행한 제3항의 동종·유사 영업에 대하여
        회사는 어떠한 책임도 지지 않으며, 위 영업으로 인하여 회사에게 손해가
        발생한 경우 해당 회원은 회사가 입은 손해를 전액 배상하여야 합니다.
        <br />
        5. 회원이 본 조항의 의무를 위반하는 경우, 회사는 관계 법령상 허용되는
        범위 내에서 그에 대한 (i) 관련 콘텐츠의 삭제, (ii) 서비스 이용제한,
        (iii) 기타 필요한 조치를 취할 수 있습니다.
        <br />
        제18조 (이용자 계정 정보 관리에 대한 의무 및 책임)
        <br />
        1. 회원의 계정 정보(비밀번호, 이름, 휴대전화 번호 등을 포함)에 대한 관리
        책임은 회원 본인에게 있으며, 회원은 자신의 계정 정보를 제3자가
        이용하도록 하여서는 안됩니다.
        <br />
        2. 회원은 제3자가 자신의 계정 정보 등 개인정보를 이용하고 있음을 인지한
        경우 즉시 비밀번호를 변경하고 이 사실을 회사에 통지한 후 회사의 안내에
        따라야 합니다.
        <br />
        3. 제2항에 반하여 회원이 회사에게 제3자의 개인정보 이용 사실 등을
        통지하지 않거나, 회사의 안내에 따르지 않음으로 인해 발생한 불이익에 대해
        회사는 어떠한 책임도 지지 않습니다.
        <br />
        제19조 (권리의 귀속)
        <br />
        1. 회사가 회원에게 제공하는 서비스, 웹사이트, 앱, 소프트웨어, 기타 자료,
        소유권 및 특허권을 비롯한 일체의 지식재산권은 회사에 귀속됩니다.
        <br />
        2. 이용결과물에 대한 권리는 저작자인 회원 본인에게 귀속됩니다. 다만,
        회사는 이용결과물의 개인 정보를 식별할 수 없도록 가공한 후 비상업적
        용도로 이용할 수 있으며, 회원으로부터 별도 동의를 득하여 이용결과물을
        상업적 용도로 이용할 수 있습니다.
        <br />
        3. 본 약관에서 명시적으로 허용하는 범위 내에서 회원은 회사가 제공하는
        정보 및 자료 등을 이용할 수 있으며, 서비스 이용과 관련하여 회사의
        지식재산권을 침해하여서는 아니 됩니다.
        <br />
        제4장 (계약 해지)
        <br />
        제20조 (이용계약의 해지)
        <br />
        1. 회원은 언제든지 회사와의 이용계약을 해지할 수 있으며, 동일 휴대전화
        번호 또는 계정으로 가입할 수 없습니다. 회사는 회원의 해지 요청이 있는
        경우 지체 없이 그에 따른 조치를 취하여야 합니다.
        <br />
        2. 해지 사유가 발생하는 경우 회사 또는 회원은 상대방에게 이메일 내지
        서면 통지를 통하여 이용계약을 해지할 수 있습니다.
        <br />
        3. 회사는 회원에게 다음 각 호의 사유가 존재하는 경우 이메일 내지 서면을
        통한 사전 통지 후 이용계약을 해지할 수 있습니다.
        <br />
        ① 제8조 제2항의 승낙 거절 사유가 존재하는 경우
        <br />
        ② 제16조에 규정된 회원의 의무를 위반하는 경우
        <br />
        ③ 기타 회사가 해당 회원에 대한 서비스의 제공을 거부할 만한 합리적 사유가
        존재하는 경우
        <br />
        4. 회사 또는 회원은 다음 각 호의 사유가 존재하는 경우 상대방에 대한
        이메일 내지 서면을 통한 통지 후 서비스 이용계약을 해지할 수 있습니다.
        <br />
        ① 당사자 일방이 은행거래정지, 강제집행, 회생절차 개시 또는 파산선고 등의
        부도 사유가 발생한 경우
        <br />
        ② 당사자 일방이 기타 이 약관을 중대하게 위반하여 서비스 이용계약의
        유지가 사실상 불가능한 경우
        <br />
        5. 회원이 현행법 위반 및 고의 또는 중대한 과실로 회사에 손해를 입힌
        경우, 회사는 사전 통지 없이 해당 회원과의 이용계약을 해지할 수 있습니다.
        <br />
        6. 제2항과 제5항에 따라 이용계약이 해지되는 경우, 회사는 제7조에 정한
        방법으로 회원에게 다음 각 호의 사항을 통보합니다.
        <br />
        ① 계약 해지사유
        <br />
        ② 계약 해지일
        <br />
        7. 회원이 이용계약을 해지(탈퇴)하는 경우, 회사는 해당 회원 관련
        정보(서비스 이용기록 포함)를 지체 없이 파기합니다.
        <br />
        8. 이용계약을 해지하는 경우 기존의 모든 데이터를 복구할 수 없는 형태로
        파기하며, 동일한 계정으로 회원가입을 할 수 없습니다.
        <br />
        제21조 (계약해지의 효과)
        <br />
        1. 이용계약의 효력은 회사가 회원에게 이용계약 해지 통보를 한 때에
        소멸하며, 회원은 이후 서비스를 이용할 수 없습니다.
        <br />
        2. 본 조항의 이용계약 해지에 따라, 회사는 관련 법령 및 개인정보처리방침,
        민감정보 등 회사가 보유한 회원의 모든 개인정보 및 데이터, 회원이
        설정하였던 서비스에 대한 개별 설정 등 일체의 정보를 삭제합니다. 단,
        제18조에 정한 바에 따른 회사 소유의 정보 또는 회사가 이용할 수 있는
        이용결과물 관련 정보는 예외로 합니다.
        <br />
        3. 본 조의 해지는 제21조의 손해배상에 영향을 미치지 않습니다.
        <br />
        제5장 기타
        <br />
        제22조 (손해배상)
        <br />
        1. 회사는 회원이 서비스를 이용함에 있어 회사의 고의 또는 중대한 과실로
        인하여 입은 손해를 배상하여야 합니다.
        <br />
        2. 회원이 본 약관을 위반하여 회사에 손해를 입힌 경우, 회원은 이에 대하여
        고의 또는 과실이 없음을 입증하지 못하는 이상 회사가 입은 손해를
        배상하여야 합니다.
        <br />
        제23조 (양도의 제한)
        <br />
        회원은 약관 및 이용계약에 따라 가지는 권리 및 계약상 지위를 제3자에게
        양도, 판매, 담보 제공 등 처분할 수 없습니다.
        <br />
        제24조 (개인정보 등의 보호)
        <br />
        1. 회사는 이용계약을 위하여 회원이 제공한 개인정보를 회사의 서비스
        운영을 위한 목적 이외의 용도로 사용하거나 회원의 동의 없이 제3자에게
        제공하지 않습니다. 단, 다음 각 호의 경우는 예외로 합니다.
        <br />
        ① 법령에 근거하여 회원 정보 이용과 제3자에 대한 정보제공이 허용되는 경우
        <br />
        ② 기타 회사의 약관 및 정책에 따라 회원의 동의를 구한 경우
        <br />
        2. 개인정보의 보호 및 사용에 대해서는 관련 법령 및 회사의
        개인정보처리방침이 적용됩니다. 회사가 서비스상 또는 공지사항, 게시판을
        통하여 게재, 공시하는 개인정보처리방침은 약관의 일부를 구성하며 회원은
        그 내용에 동의합니다.
        <br />
        3. 회사는 수집된 개인정보 처리 등의 업무를 스스로 수행하는 것을 원칙으로
        하나, 필요한 경우 업무의 일부 또는 전부를 제3자에게 위탁할 수 있습니다.
        이와 관련한 제반 사항은 개인정보처리방침을 통하여 회원에게 고지,
        안내합니다.
        <br />
        4. 회사는 회원의 개인정보를 보호하기 위하여 『정보통신망 이용촉진 및
        정보보호 등에 관한 법률』 및 『개인정보 보호법』 등 관계 법령에서 정하는
        바를 준수합니다.
        <br />
        5. 회사는 회원 또는 회원이 소지한 물건의 위치정보를 보호하기 위하여
        『위치정보의 보호 및 이용 등에 관한 법률』 등 관계 법령에서 정하는 바를
        준수합니다.
        <br />
        6. 회사는 회원의 귀책 사유로 인하여 노출된 회원의 계정 정보를 포함한
        모든 정보에 대해서 일체의 책임을 지지 않습니다.
        <br />
        제25조 (면책)
        <br />
        1. 회사는 서비스를 있는 그대로(As-Is) 제공하며, 서비스의 품질 또는 성능,
        적합성, 적시성, 안전성, 무오류성 등에 대하여 어떠한 주장이나 보증도 하지
        않습니다.
        <br />
        2. 회사는 서비스의 제공 및 이용과 관련하여 회원과 다른 회원 사이에
        발생한 분쟁(이용결과물의 지식재산권 침해 관련 분쟁 포함)에 대하여 어떠한
        책임도 지지 않습니다.
        <br />
        3. 회사는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할
        수 없는 경우에는 서비스 제공에 관한 책임이 면제됩니다.
        <br />
        4. 회사는 회원에게 책임 있는 사유로 인한 서비스 이용 장애에 대하여는
        책임을 지지 않습니다.
        <br />
        5. 회사는 회원 간 또는 회원과 제3자 사이에서 이루어진 거래 등에 대하여
        어떠한 책임도 지지 않습니다.
        <br />
        6. 회사는 관련 법령에 특별한 규정이 없는 이상 무료로 제공되는 서비스와
        관련하여 회원에 대하여 법적 책임을 지지 않습니다.
        <br />
        제25조 (분쟁의 해결)
        <br />
        1. 본 약관 및 이용계약에 관한 분쟁은 대한민국법을 준거법으로 합니다.
        <br />
        2. 본 약관 및 서비스 이용과 관련하여 회사와 회원 사이에 분쟁이 발생하여
        소송이 제기되는 경우에는 민사소송법에 따라 관할법원을 정합니다.
        <br />
        이용약관 버전 번호: 3.2
        <br />
        이용약관 변경공고일자: 2023년 8월 11일
        <br />
        이용약관 시행일자: 2023년 9월 1일
        <br />
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow-y: scroll;

  & > h1 {
    width: 700px;
    margin-top: 90px;
    margin-bottom: 27px;
    color: #111827;

    font-size: 24px;

    font-weight: 700;
    line-height: 1.6em; /* 38.4px */
  }

  & > div {
    margin-bottom: 90px;
    width: 700px;
    color: #111827;

    font-size: 16px;

    font-weight: 500;
    line-height: 1.6em; /* 25.6px */
  }
`;
