import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { useState } from 'react';
import ReactPaginate from 'react-paginate';

import { Employee } from '~/types/agencyInfo/agencyInfo';

interface PostsProps {
  items?: Employee[];
  itemsPerPage: number;
}

const Posts: React.FC<PostsProps> = ({ items, itemsPerPage }) => {
  const [currentPage, setCurrentPage] = useState(0);

  let pageCount = 0;

  if (items && Array.isArray(items) && itemsPerPage > 0) {
    pageCount = Math.ceil(items.length / itemsPerPage);
  }

  const handlePageChange = (selectedItem: { selected: number }) => {
    setCurrentPage(selectedItem.selected);
  };

  const offset = currentPage * itemsPerPage;
  const currentItems =
    items && Array.isArray(items)
      ? items.slice(offset, offset + itemsPerPage)
      : [];

  return (
    <>
      <ItemsWrapper>
        {!!currentItems &&
          currentItems?.map((item) => (
            <ItemsContainer key={item?.userId}>
              <ItemId>{item?.loginId}</ItemId>
              <Item width="164px">{item?.name} 선생님</Item>
              <Item width="184px">{item?.contactNumber}</Item>
              <Item width="324px">{item?.email}</Item>
              <Item width="500px">{item?.introduction}</Item>
              <Item width="112px">{item?.studentCount}명</Item>
            </ItemsContainer>
          ))}
      </ItemsWrapper>
      <PaginateButton>
        <Paginate
          previousLabel={'<'}
          nextLabel={'>'}
          breakLabel={'...'}
          breakClassName={'break-me'}
          pageCount={pageCount}
          marginPagesDisplayed={1}
          pageRangeDisplayed={4}
          onPageChange={handlePageChange}
          containerClassName={'pagination'}
          activeClassName={'active'}
        />
      </PaginateButton>
    </>
  );
};

export default Posts;

const ItemsWrapper = styled.div`
  height: 630px;
`;

const PaginateButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 24px;
  padding-bottom: 18px;
`;

const Paginate = styled(ReactPaginate)`
  display: flex;
  align-items: center;
  justify-content: center;

  ${css`
    li {
      color: #171819;
      font-size: 12px;

      font-weight: 400;
      line-height: 1.6em;
      cursor: pointer;
    }

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
    }

    li:not(.previous, .next, .break-me):hover {
      color: #2c55fb;
      background: #f0f6ff;
      border-radius: 8px;
    }
    li.active {
      border-radius: 8px;
      color: #2c55fb;
      background: #f0f6ff;
    }

    li.previous {
      border-radius: 8px;
      color: #c7c7c7;
      border: 1px solid #c7c7c7;
      margin-right: 24px;

      &:hover {
        color: #2c55fb;
        border: 1px solid #2c55fb;
      }
    }

    li.next {
      color: #c7c7c7;
      border: 1px solid #c7c7c7;
      border-radius: 8px;
      margin-left: 24px;

      &:hover {
        color: #2c55fb;
        border: 1px solid #2c55fb;
      }
    }
  `}
`;

const ItemsContainer = styled.div`
  display: flex;
  padding: 0 24px;
  border-bottom: 1px solid #f1f2f4;
`;

const ItemId = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 164px;
  padding-left: 16px;
  height: 63px;
  color: #000;

  /* body/B04-14px-medium */
  font-size: 14px;

  font-weight: 500;
  line-height: 1.6em; /* 22.4px */
`;

const Item = styled.div<{ width: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(props) => props.width};
  padding-left: 16px;
  height: 63px;
  color: #000;

  /* body/B04-14px-medium */
  font-size: 14px;

  font-weight: 500;
  line-height: 1.6em; /* 22.4px */
`;
