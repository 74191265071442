import styled from '@emotion/styled';

interface Props {
  transformedData?: { [key: string]: number | string };
  dateFilter: (value: string | Date | undefined | number) => string;
}

const HIGH_MEMORY = (
  <>
    것으로 보입니다. 외부 환경으로부터의 정보를 저장하고 이를 활용하는 능력이
    우수한 편입니다. 어린이는 여러 단계로 이루어진 활동을 잘 수행할 수 있습니다.
    또, 복잡한 사고 문제를 풀기 위해 새로 배운 내용을 활용할 수 있습니다.
  </>
);

const NOMAL_MEMORY = (
  <>
    에 속하는 것으로 보입니다. 외부 환경으로부터의 정보를 저장하고 이를 적절히
    활용할 수 있습니다. 어린이는 여러 단계로 이루어진 활동을 기억하고 수행할 수
    있습니다. 필요한 물건을 잊지 않고 챙길 수 있습니다.
  </>
);

const LOW_MEMORY = (
  <>
    것으로 보입니다. 외부 환경으로부터의 정보를 저장하여 활용하는 데 어려움을
    겪을 가능성이 있습니다. 어린이는 일상생활에서 해야 할 일을 잊어버리거나
    물건을 잃어버리기도 합니다. 기억해야 할 목록을 메모판으로 꾸미는 등 시각적
    도구를 활용하는 것이 기억력 향상에 도움이 될 수 있습니다.
  </>
);

const LOWEST_MEMORY = (
  <>
    것으로 보입니다. 외부 환경으로부터의 정보를 저장하여 활용하는 데 어려움을
    겪을 수 있습니다. 어린이는 일상생활에서 해야 할 일을 잊어버리거나 물건을
    잃어버리는 일이 잦습니다. 기억해야 할 목록을 메모판으로 꾸미는 등 시각적
    도구를 활용하는 것이 기억력 향상에 도움이 될 수 있습니다.
  </>
);

export default function PagFour({ transformedData, dateFilter }: Props) {
  return (
    <Wrapper>
      <Nav>
        <div>조이코그: 초등학생을 위한 인지측정 리포트</div>
        <div>측정일 : {dateFilter(transformedData?.screenDate)}</div>
      </Nav>
      <Container>
        <ItemsContainer>
          <Bold>
            <IconImg url="/images/memory.png" />
            기억력
          </Bold>
          <div>
            어린이의 기억력은{' '}
            <Data
              color={
                transformedData?.memory === '낮음'
                  ? '#EF5C5C'
                  : transformedData?.memory === '약간 낮음'
                  ? '#F29E00'
                  : transformedData?.memory === '보통'
                  ? '#39B974'
                  : '#5079F3'
              }
            >
              {transformedData?.memory === '낮음'
                ? '낮은'
                : transformedData?.memory === '약간 낮음'
                ? '약간 낮은'
                : transformedData?.memory === '보통'
                ? '보통'
                : '높은'}
            </Data>{' '}
            {transformedData?.memory === '낮음'
              ? LOWEST_MEMORY
              : transformedData?.memory === '약간 낮음'
              ? LOW_MEMORY
              : transformedData?.memory === '보통'
              ? NOMAL_MEMORY
              : HIGH_MEMORY}
          </div>
          <Bold>기억력이란?</Bold>
          <div>
            머릿속에 정보를 보유하고 그 정보를 활용할 수 있는 능력을 말합니다.
          </div>
        </ItemsContainer>
      </Container>
      <Title>추천 솔루션</Title>
      <SolutionContainer>
        <NumberItemsContainer>
          <Bold>
            <IconImgNumber url="/images/1.png" />
            <div>주의력이 부족해요</div>
          </Bold>
          <div>
            주의력은 꾸준한 훈련을 통해 향상될 수 있습니다.
            <br />
            1. 자녀가 집중할 수 있는 시간을 알아봅니다. <br />
            2. 그 시간만큼 집중하는 연습을 합니다. <br />
            3. 집중한 뒤에는 쉬는 시간을 가집니다. <br />
            4. 집중하는 시간을 조금씩 늘려가면서 연습합니다. <br />
            5. 시간 늘리기를 성공한다면 어린이가 좋아하는 보상을 주면서
            반복합니다.
          </div>
          <div>Tip!</div>
          <div>
            과제를 하는 공간은 단순하고 깨끗하게 조성해주세요. 방해 요인을 줄여
            주의 분산을 방지할 수 있습니다.
          </div>
        </NumberItemsContainer>
        <NumberItemsContainer>
          <Bold>
            <IconImgNumber url="/images/2.png" />
            <div>충동적이에요</div>
          </Bold>
          <div>
            `{`잠시 멈춤`}` 신호를 통해 충동성 조절을 연습할 수 있습니다.
            <br />
            1. 어린이가 충동적인 행동을 할 때 `{`잠시 멈춤`}` 할 수 있는 신호를
            정합니다. <br />
            (예. 어깨 가볍게 누르기)
            <br />
            2. 멈춤이 필요한 상황에서 신호를 사용합니다.
            <br />
            3. 신호를 보고 아이가 멈추려는 행동을 보인다면 아낌없이
            칭찬해주세요.
          </div>
          <div>Tip!</div>
          <p>
            서로를 지치게 하는 꾸지람보다 `{`잠시 멈춤`}` 신호를 사용하는 것이
            더욱 효과적입니다.
          </p>
        </NumberItemsContainer>
      </SolutionContainer>
      <LogoImg />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: 842px;

  & > h2 {
    margin-bottom: 9.75px;

    color: #000;

    font-size: 14px;

    font-weight: 800;
    line-height: 1.6em; /* 22.4px */
  }
`;

const Nav = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 595px;
  height: 46px;
  background: #5a79e9;
  color: #fff;

  font-size: 10px;

  font-weight: 600;
  line-height: 1.6em; /* 16px */

  & > div {
    :nth-of-type(1) {
      margin-right: 229px;
    }
  }
`;

const Container = styled.div`
  width: 520px;
  margin-top: 24px;
  margin-bottom: 20px;
  border-radius: 10px;
  border: solid 0.5px #cbcbcb;
`;

const ItemsContainer = styled.div`
  margin-top: 22px;
  margin-left: 35px;
  margin-right: 35px;

  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;

  line-height: 1.67;
  letter-spacing: -0.12px;
  color: #101010;
  & > div {
    :nth-of-type(1) {
      display: flex;
      align-items: center;
      margin-bottom: 7px;
      height: 16px;
    }
    :nth-of-type(2) {
      margin-bottom: 7px;
    }

    :nth-of-type(4) {
      margin-bottom: 28px;
    }
  }
`;

const IconImg = styled.div<{ url: string }>`
  width: 20px;
  height: 20px;
  margin-bottom: 3px;
  margin-right: 4px;
  background: url(${(props) => props.url}) no-repeat center;
  background-size: contain;
`;

const Bold = styled.div`
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: bold;
  font-stretch: normal;
  color: #101010;
  & > div {
    padding-top: 2px;
  }
`;

const Title = styled.div`
  margin-bottom: 10px;
  color: #000;

  font-size: 14px;

  font-weight: 800;
  line-height: 1.6em; /* 22.4px */
`;

const SolutionContainer = styled.div`
  width: 520px;
  border-radius: 10px;
  border: 0.5px solid #cbcbcb;
`;

const LogoImg = styled.div`
  position: absolute;
  bottom: 10px;
  left: 253px;
  width: 90px;
  height: 36px;
  background: url(/images/JoyCog90.png) no-repeat center;
  background-size: contain;
`;

const IconImgNumber = styled.div<{ url: string }>`
  width: 15px;
  height: 15px;
  margin-right: 4px;
  background: url(${(props) => props.url}) no-repeat center;
  background-size: contain;
`;

const NumberItemsContainer = styled.div`
  width: 394px;
  margin-top: 17px;
  margin-left: 35px;

  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;

  line-height: 1.67;
  letter-spacing: -0.12px;
  color: #101010;

  :nth-of-type(1) {
    border-bottom: 1px solid #ebebeb;
    padding-bottom: 17px;
  }
  & > div {
    :nth-of-type(1) {
      display: flex;
      align-items: center;
      margin-bottom: 7px;
      height: 16px;
    }
    :nth-of-type(2) {
      margin-bottom: 7px;
    }
    :nth-of-type(3) {
      color: #5a79e9;

      font-size: 12px;

      font-weight: 600;
      line-height: 1.6em; /* 19.2px */
    }
  }

  & > p {
    margin-bottom: 25px;
  }
`;

const Data = styled.span`
  color: ${(props) => props.color};
  font-size: 12px;
  font-weight: 600;
  line-height: 1.6em;
  letter-spacing: -0.4px;
`;
