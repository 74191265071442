import styled from '@emotion/styled';
import moment from 'moment';
import { useEffect, useState } from 'react';

import { ServeyResponse } from '~/types/student/search';

import Modal from '~/utils/portal/components/Modal';

import { getServeyStatusData } from '~/api/student/search';
import {
  getEditSurveyStatusData,
  getRegisterSurveyStatusData,
} from '~/api/student/survey';

import useAuth from '~/hooks/useAuth';

interface Props {
  setActiveComponent: React.Dispatch<React.SetStateAction<string>>;
  childId: number | null;
}

export default function Survey({ setActiveComponent, childId }: Props) {
  const [surveyData, setSurveyData] = useState<ServeyResponse>();
  const [radioValues, setRadioValues] = useState<Array<number | null>>(
    Array.from({ length: 4 }, () => null),
  );
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [answerValue, setAnswerValue] = useState<any>([]);
  const [isModal, setIsModal] = useState('');
  const { user } = useAuth();

  const handleRadioChange = (index: number, value: number | null) => {
    const updatedRadioValues = [...radioValues];
    updatedRadioValues[index] = value;
    setRadioValues(updatedRadioValues);
  };

  const handleAnswerValueChange = (index: number, value: number | null) => {
    const updateAnswerValue = [...answerValue];
    updateAnswerValue[index] = value;
    setAnswerValue(updateAnswerValue);
  };

  useEffect(() => {
    getServeyStatusData({ childId: childId as number })
      .then(({ data }) => {
        setSurveyData(data);
        setAnswerValue(data.data.survey.map((item) => item.answerValue));
      })
      .catch(console.error);
  }, []);

  const handleEditSurvey = () => {
    if (radioValues === null) {
      return;
    }
    getEditSurveyStatusData({
      answer: answerValue as number[],
      childId: childId as number,
      managerId: Number(user?.managerId),
    })
      .then(({ data }) => {
        if (data.data.isValid) {
          setIsModal('edit');
        }
      })
      .catch(console.error);
  };

  const handleRegisterSurvey = () => {
    getRegisterSurveyStatusData({
      answer: radioValues as number[],
      childId: childId as number,
      managerId: Number(user?.managerId),
    })
      .then(({ data }) => {
        if (data.data.isValid) {
          setIsModal('register');
        }
      })
      .catch(console.error);
  };

  const dateFilter = (value?: string | Date | null) => {
    const countryTime = value ? moment(value).format('YYYY-MM-DD') : '';
    return countryTime;
  };

  const handleSurveyCheck = () => {
    setIsModal('');
    window.location.reload();
  };

  return (
    <Wrapper>
      <Container>
        <TitleInfo>
          <NameSecction>
            <Name>
              <div>{surveyData?.data.studentInfo.name}</div>
              <GenderImgConatiner>
                <GenderImg
                  url={
                    surveyData?.data.studentInfo.gender === 'F'
                      ? '/images/girl.png'
                      : '/images/boy.png'
                  }
                />
              </GenderImgConatiner>
            </Name>
            <Date>{dateFilter(surveyData?.data.studentInfo.birth)}</Date>
          </NameSecction>
          <ClassInfoSecction>
            <ClassInfo>
              <div>{surveyData?.data.studentInfo.classInfo}</div>
              <div>인지능력 측정 결과</div>
              <ScreenStatus
                color={
                  surveyData?.data.studentInfo.screenResult === '양호함'
                    ? '#0CAF60'
                    : surveyData?.data.studentInfo.screenResult === '관심 필요'
                    ? '#F0A95A'
                    : surveyData?.data.studentInfo.screenResult === '주의 요망'
                    ? '#FF4752'
                    : ''
                }
                bgColor={
                  surveyData?.data.studentInfo.screenResult === '양호함'
                    ? '#EFFCF6'
                    : surveyData?.data.studentInfo.screenResult === '관심 필요'
                    ? '#FFFBEC'
                    : surveyData?.data.studentInfo.screenResult === '주의 요망'
                    ? '#FFF2F3'
                    : ''
                }
              >
                {surveyData?.data.studentInfo.screenResult === '양호함'
                  ? '양호단계'
                  : surveyData?.data.studentInfo.screenResult === '관심 필요'
                  ? '관심단계'
                  : surveyData?.data.studentInfo.screenResult === '주의 요망'
                  ? '주의단계'
                  : ''}
              </ScreenStatus>
            </ClassInfo>
            <Info>{surveyData?.data.studentInfo.characteristic}</Info>
          </ClassInfoSecction>
          <ButtonSecction>
            <ChanginInfoButton
              onClick={() => setActiveComponent('EditStudent')}
            >
              학생정보 수정
            </ChanginInfoButton>
          </ButtonSecction>
        </TitleInfo>
        <SurveyTitle>설문조사</SurveyTitle>
        <SurveyWrapper>
          <SurveyContainer>
            <SurveyInfo>
              <div>전혀 그렇지 않다</div>
              <div>가끔 그렇다</div>
              <div>자주 그렇다</div>
              <div>매우 자주 그렇다</div>
            </SurveyInfo>

            {surveyData?.data.survey.map((item) => (
              <div key={item.questionId}>
                <SurveyItems>
                  <SurveyItemsInfo>
                    {item.questionId}. {item.questionKey}
                  </SurveyItemsInfo>
                  {item.answerValue !== null ? (
                    <form>
                      {Array.from({ length: 4 }, (_, radioIndex) => (
                        <input
                          key={radioIndex}
                          type="radio"
                          name={`survey-${item.questionId - 1}`}
                          checked={
                            answerValue[item.questionId - 1] === radioIndex
                          }
                          onChange={() =>
                            handleAnswerValueChange(
                              item.questionId - 1,
                              radioIndex,
                            )
                          }
                        />
                      ))}
                    </form>
                  ) : (
                    <form>
                      {Array.from({ length: 4 }, (_, radioIndex) => (
                        <input
                          key={radioIndex}
                          type="radio"
                          name={`survey-${item.questionId - 1}`}
                          checked={
                            radioValues[item.questionId - 1] === radioIndex
                          }
                          onChange={() =>
                            handleRadioChange(item.questionId - 1, radioIndex)
                          }
                        />
                      ))}
                    </form>
                  )}
                </SurveyItems>
              </div>
            ))}
          </SurveyContainer>
        </SurveyWrapper>
        <div>
          <Button
            onClick={() => setActiveComponent('')}
            bgColor="#FFFFFF"
            color="#2C55FB"
          >
            취소
          </Button>
          {surveyData?.data.survey[0].answerValue !== null ? (
            <Button bgColor="#2C55FB" color="#fff" onClick={handleEditSurvey}>
              저장
            </Button>
          ) : (
            <Button
              bgColor="#2C55FB"
              color="#fff"
              onClick={handleRegisterSurvey}
            >
              등록하기
            </Button>
          )}
        </div>
      </Container>
      {isModal === 'register' && (
        <Modal
          onClose={handleSurveyCheck}
          title="설문 조사가 완료되었습니다."
        ></Modal>
      )}
      {isModal === 'edit' && (
        <Modal
          onClose={handleSurveyCheck}
          title="설문 조사 수정이 완료되었습니다."
        ></Modal>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  min-width: 1920px;
  min-height: 1081px;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
  z-index: 9;
`;

const Container = styled.div`
  position: absolute;
  right: 0;
  display: flex;
  flex-direction: column;
  width: 940px;
  min-height: 960px;
  height: calc(100vh - 120px);
  padding: 60px 30px;
  border-radius: 16px 0 0 16px;
  background: #fff;
  color: #000;
`;

const NameSecction = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 75px;
  padding-right: 40px;
  border-right: 1px solid #e2e2ea;
  margin-right: 40px;
`;

const Name = styled.div`
  display: flex;
  align-items: center;

  & > div {
    :nth-of-type(1) {
      margin-right: 8px;
      color: #000;

      /* heading/H02-24px-bold */

      font-size: 24px;

      font-weight: 700;
      line-height: 1.6em; /* 38.4px */
    }
  }
`;

const Date = styled.div`
  width: 104px;
  height: 21px;
  margin-top: 16px;
  color: #65696d;

  /* body/B03-16px-medium */

  font-size: 16px;

  font-weight: 500;
  line-height: 1.3em; /* 20.8px */
`;

const TitleInfo = styled.div`
  display: flex;
  width: 860px;
  height: 77px;
  padding: 24px 40px;
  border-radius: 16px;
  margin-bottom: 40px;
  background: #fff;

  /* shadow */
  box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.08);
`;

const GenderImgConatiner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 33px;
  height: 33px;
  border-radius: 10px;
  background: #f6f6f6;
`;

const GenderImg = styled.div<{ url: string }>`
  width: 14px;
  height: 20px;
  background: url(${(props) => props.url}) no-repeat center;
  background-size: center;
`;

const ClassInfoSecction = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ClassInfo = styled.div`
  display: flex;
  align-items: center;
  height: 30px;
  color: #65696d;

  /* body/B03-16px-medium */

  font-size: 16px;

  font-weight: 500;
  line-height: 1.3em; /* 20.8px */

  & > div {
    :nth-of-type(1) {
      padding-right: 12px;
      margin-right: 12px;
      border-right: 1px solid #b5b5be;
    }

    :nth-of-type(2) {
      margin-right: 12px;
    }
  }
`;

const Info = styled.div`
  display: flex;
  align-items: center;
  width: 430px;
  height: 21px;
  padding: 8px 20px;
  border-radius: 2px;
  margin-top: 10px;
  margin-right: 24px;
  border: 1px solid #e2e2ea;
  background: #f6f6f6;

  color: #292d32;

  /* body/B03-16px-medium */

  font-size: 16px;

  font-weight: 500;
`;

const ButtonSecction = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ChanginInfoButton = styled.button`
  width: 120px;
  height: 56px;
  border-radius: 10px;
  background: #0062ff;
  color: #fff;
  text-align: center;

  /* body/B03-16px-bold */

  font-size: 16px;

  font-weight: 700;
  line-height: 1.3em; /* 20.8px */
`;

const ScreenStatus = styled.div<{ bgColor: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.color};
  background-color: ${(props) => props.bgColor};
  width: 84px;
  height: 30px;
  border-radius: 100px;
`;

const SurveyTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 143px;
  height: 46px;
  border-radius: 100px;
  margin-bottom: 12px;
  background: #f0f6ff;

  color: #0062ff;

  font-size: 18px;

  font-weight: 700;
`;

const SurveyWrapper = styled.div`
  width: 940px;
  height: 641px;

  background-color: #fff;
`;

const SurveyContainer = styled.div`
  width: 870px;
  height: 617px;
  padding: 12px 24px;
  border-radius: 2px;
  border: 1px solid #e2e2ea;
  background: #fff;
  box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.08);
  overflow-y: scroll;
`;

const SurveyInfo = styled.div`
  display: flex;
  justify-content: flex-end;
  border-bottom: 1px solid #e2e2ea;

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #65696d;

    /* body/B04-14px-medium */

    font-size: 14px;

    font-weight: 500;
    line-height: 1.6em; /* 22.4px */
    width: 100px;
    height: 40px;
  }
`;

const SurveyItems = styled.div`
  display: flex;
  align-items: center;
  height: 50px;
  border-bottom: 1px solid #e2e2ea;

  & > form {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 400px;
    & > input {
      width: 16px;
      height: 16px;
    }
  }
`;

const SurveyItemsInfo = styled.div`
  display: flex;
  align-items: center;
  width: 470px;
  height: 40px;
`;

const Button = styled.button<{ bgColor: string }>`
  width: 200px;
  height: 56px;
  border-radius: 10px;
  border: 1px solid #2c55fb;
  color: ${(props) => props.color};
  background-color: ${(props) => props.bgColor};

  /* body/B03-16px-bold */

  font-size: 16px;

  font-weight: 700;
  line-height: 1.3em; /* 20.8px */

  :nth-of-type(1) {
    margin-top: 40px;
    margin-left: 264px;
    margin-right: 12px;
  }
`;
