import styled from '@emotion/styled';
import { Dispatch, SetStateAction } from 'react';

import { ReactComponent as JoyCog140 } from '~/assets/JoyCog140.svg';

interface Props {
  handleValueChange: (id: string, value: string) => void;
  handleNextPageButton: () => void;
  value: { [key: string]: string | undefined | null };
  handleChange: (id: string, value: string) => void;
  handleIdCheck: () => void;
  setValue: (id: string, value: string | null) => void;
  isChecked: {
    useCheck: boolean;
    infoCheck: boolean;
    password: boolean;
    second_password: boolean;
    loginId: boolean;
  };
  setIsChecked: Dispatch<
    SetStateAction<{
      useCheck: boolean;
      infoCheck: boolean;
      password: boolean;
      second_password: boolean;
      loginId: boolean;
    }>
  >;
  handleSecondPasswordBlur: React.FocusEventHandler<HTMLInputElement>;
  handlePasswordBlur: React.FocusEventHandler<HTMLInputElement>;
}

// J1076420300610

export default function SignupAccountInfo({
  handleValueChange,
  handleNextPageButton,
  value,
  handleChange,
  handleIdCheck,
  setValue,
  setIsChecked,
  isChecked,
  handleSecondPasswordBlur,
  handlePasswordBlur,
}: Props) {
  const handleDeleteLoginId = () => {
    setValue('loginId', null);
  };

  const handleDeletePassword = () => {
    setValue('password', null);
  };

  const handleDeleteSecondPassword = () => {
    setValue('second_password', null);
  };

  const handleIdFocus = () => {
    setIsChecked((prevErrors) => ({
      ...prevErrors,
      loginId: false,
    }));
  };

  const handlePasswordFocus = () => {
    setIsChecked((prevErrors) => ({
      ...prevErrors,
      password: false,
    }));
  };

  const handleSecondPasswordFocus = () => {
    setIsChecked((prevErrors) => ({
      ...prevErrors,
      second_password: false,
    }));
  };

  return (
    <>
      <Wrapper>
        <JoyCog140
          style={{
            marginTop: '60px',
            marginBottom: '24px',
          }}
        />
        <Title>조이코그 관리자 회원가입</Title>
        <InputContainer>
          <div>
            아이디<span>*</span>
          </div>
          <InputBox>
            <Input
              maxLength={16}
              width={'273px'}
              bdColor={!isChecked.loginId ? '#e2e2ea;' : '#FF4752'}
              type="text"
              placeholder="아이디를 입력해주세요"
              onChange={(e) => handleChange('loginId', e.target.value)}
              value={value.loginId ?? ''}
              onFocus={handleIdFocus}
            />
            {value.loginId && (
              <DeleteButton onClick={handleDeleteLoginId} right="180px" />
            )}
            <button onClick={handleIdCheck}>중복확인</button>
          </InputBox>
          {!isChecked.loginId ? (
            <Error />
          ) : (
            <Error>
              아이디는 최소 4글자 최대 16글자의 영문 대,소문자 또는 숫자로
              입력해 주세요.
            </Error>
          )}

          <div>
            비밀번호<span>*</span>
          </div>
          <InputBox>
            <Input
              maxLength={16}
              width={'434px'}
              bdColor={isChecked.password ? '#FF4752' : '#e2e2ea;'}
              type="password"
              placeholder="비밀번호를 입력해주세요"
              value={value.password ?? ''}
              onChange={(e) => handleValueChange('password', e.target.value)}
              onBlur={handlePasswordBlur}
              onFocus={handlePasswordFocus}
            />
            {value.password && (
              <DeleteButton onClick={handleDeletePassword} right="22px" />
            )}
          </InputBox>
          {isChecked.password ? (
            <Error>
              비밀번호는 최소 8글자 최대 16글자의 영문 대,소문자와 숫자 조합으로
              입력해 주세요.
            </Error>
          ) : (
            <Error />
          )}
          <div>
            비밀번호 확인<span>*</span>
          </div>
          <InputBox>
            <Input
              maxLength={16}
              width={'434px'}
              bdColor={isChecked.second_password ? '#FF4752' : '#e2e2ea;'}
              type="password"
              placeholder="비밀번호를 한번 더 입력해주세요"
              value={value.second_password || ''}
              onChange={(e) =>
                handleValueChange('second_password', e.target.value)
              }
              onBlur={handleSecondPasswordBlur}
              onFocus={handleSecondPasswordFocus}
            />
            {value.second_password && (
              <DeleteButton onClick={handleDeleteSecondPassword} right="22px" />
            )}
          </InputBox>
          {isChecked.second_password ? (
            <Error>
              비밀번호와 비밀번호 확인이 일치하지 않습니다. 다시 입력해 주세요.
            </Error>
          ) : (
            <Error />
          )}
        </InputContainer>
        <button onClick={handleNextPageButton}>다음</button>
      </Wrapper>
      <Reserved>© 2023 eMotiv. All rights reserved.</Reserved>
    </>
  );
}

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 720px;
  height: 800px;
  border-radius: 40px;
  background: #fff;

  box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.08);

  span {
    color: #0062ff;

    font-size: 14px;

    font-weight: 500;
    line-height: 100%;
  }

  & > button {
    width: 480px;
    height: 56px;
    margin-top: 142px;
    color: #fff;
    background-color: #0062ff;
    border-radius: 10px;
    border: 1px solid #0062ff;

    /* body/B02-18px-bold */
    font-size: 18px;

    font-weight: 700;
    line-height: 1.3em; /* 23.4px */
  }
`;

const Title = styled.h1`
  margin-bottom: 30px;
  color: #000;

  font-size: 32px;

  font-weight: 700;
  line-height: 1.3em; /* 41.6px */
  text-transform: capitalize;
`;

const Reserved = styled.div`
  margin-top: 30px;
  color: #65696d;
  text-align: center;

  /* body/B04-14px-medium */

  font-size: 14px;

  font-weight: 500;
  line-height: 1.6em; /* 22.4px */
`;

const InputContainer = styled.div`
  width: 480px;

  & > div {
    width: 100%;
    margin-bottom: 10px;
  }
`;

const InputBox = styled.div`
  position: relative;

  & > button {
    width: 140px;
    height: 53px;
    margin-left: 20px;
    border-radius: 10px;
    border: 1px solid #2c55fb;

    color: #2c55fb;
    text-align: center;

    /* body/B03-16px-medium */

    font-size: 16px;

    font-weight: 500;
    line-height: 1.3em; /* 20.8px */
  }
`;

const DeleteButton = styled.div<{ right: string }>`
  top: 18px;
  right: ${(props) => props.right};
  position: absolute;
  width: 16px;
  height: 16px;
  background: url(/images/delete.png) no-repeat center;
  background-size: contain;
`;

const Error = styled.div`
  margin-top: 6px;
  margin-bottom: 12px;
  width: 100px;
  height: 22px;
  color: #ff4752;

  /* body/B04-14px-medium */

  font-size: 14px;

  font-weight: 500;
  line-height: 1.6em; /* 22.4px */
`;

const Input = styled.input<{ bdColor: string }>`
  width: ${(props) => props.width};
  height: 26px;
  padding: 13px 22px;
  border-radius: 10px;
  border: 1px solid ${(props) => props.bdColor};

  ::placeholder {
    color: #b5b5be;

    font-size: 16px;

    font-weight: 400;
    line-height: 1.6em; /* 25.6px */
  }

  :focus {
    border-radius: 10px;
    border: 1px solid #0062ff;
  }
`;
