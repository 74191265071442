import { AxiosResponse } from 'axios';

import {
  ReadReportRequestParams,
  ReadReportResponse,
} from '~/types/student/read';

import api from '~/api/base';

const BASE_URL = 'api/v1/student/read';

export const getReadReportStatusData = (
  param: ReadReportRequestParams,
): Promise<AxiosResponse<ReadReportResponse>> =>
  api.post(`${BASE_URL}/report`, param);
