import { AxiosResponse } from 'axios';

import {
  SendMessageRequestParams,
  SendMessageResponse,
} from '~/types/push/send';

import api from '~/api/base';

const BASE_URL = 'api/v1/web/push/send';

export const getSendMessageStatusData = (
  param: SendMessageRequestParams,
): Promise<AxiosResponse<SendMessageResponse>> =>
  api.post(`${BASE_URL}/message`, param);
