import { css } from '@emotion/react';
import styled from '@emotion/styled';
import ReactCalendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

interface Props {
  handleDropDown(): void;
  setHoveredDate(date: Date | null): void;
  hoveredDate: Date | null;
}

export default function ForceLogCalendar({
  handleDropDown,
  setHoveredDate,
  hoveredDate,
}: Props) {
  const handleHover = (date: Date | null) => {
    setHoveredDate(date);
    handleDropDown();
  };

  const isHoveredDate = (date: Date) =>
    hoveredDate !== null &&
    date.toDateString() === (hoveredDate as Date).toDateString();

  const getWeekStart = (date: Date) => {
    const dayOfWeek = date.getDay();
    return new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate() - (dayOfWeek - 1),
    );
  };

  const getWeekEnd = (date: Date) => {
    const dayOfWeek = date.getDay();
    return new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate() + (7 - dayOfWeek),
    );
  };

  const rowStartDate = hoveredDate ? getWeekStart(hoveredDate) : null;
  const rowEndDate = hoveredDate ? getWeekEnd(hoveredDate) : null;

  const tileClassName = ({ date, view }: { date: Date; view: string }) => {
    if (view === 'month') {
      if (hoveredDate && rowStartDate && rowEndDate) {
        if (date >= rowStartDate && date <= rowEndDate) {
          return 'react-calendar__tile--hover';
        }
        if (date.toDateString() === (hoveredDate?.toDateString() ?? '')) {
          return 'react-calendar__tile--clicked';
        }
      }
    }
    return '';
  };

  return (
    <Wrapper>
      <CalendarWrapper>
        <StyledCalendar
          next2Label={null}
          prev2Label={null}
          tileContent={({ date }) => (
            <HoverMarker isHovered={isHoveredDate(date)} />
          )}
          tileClassName={tileClassName}
          onClickDay={(date) => handleHover(date)}
          formatDay={(locale, date) =>
            date.toLocaleString('en', { day: 'numeric' })
          }
        />
      </CalendarWrapper>
    </Wrapper>
  );
}

const HoverMarker = styled.div<{ isHovered: boolean }>`
  border-radius: 50%;
  background: ${(props) =>
    props.isHovered ? 'rgba(0, 98, 255, 0.2)' : 'none'};
`;

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  position: absolute;
  width: 300px;
  padding: 5px 0;
  top: 50px;
  right: 0px;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 5px 40px 0px rgba(0, 0, 0, 0.1);
`;

const CalendarWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const StyledCalendar = styled(ReactCalendar)`
  padding: 0 10px;
  border: 0;
  width: 280px;

  ${css`
    .react-calendar__navigation {
      height: 40px;
    }

    abbr {
      text-decoration: none;
      font-size: 14px;

      font-weight: 400;

      :focus {
        color: #fff;
      }
    }

    .react-calendar__month-view__days__day--weekend {
      color: #000;
    }
    .react-calendar__tile {
    }

    .react-calendar__month-view__days__day--neighboringMonth {
      color: #b5b5be;
    }

    .react-calendar__tile--now {
      background: none;
    }
    .react-calendar__tile--active {
      background: #0062ff;
      color: white;
    }

    .react-calendar__tile,
    .react-calendar__month-view__days__day {
    }

    .react-calendar__tile--hover {
      color: white;
      background: #0062ff;
    }

    .react-calendar__tile--clicked {
      background: #0062ff;
      color: white;
    }
  `}
`;
