import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';

import {
  RequestDataList,
  RequestStudentsResponse,
} from '~/types/student/request';

import Modal from '~/utils/portal/components/Modal';

import {
  getRequestStudentsRelationStatusData,
  getRequestStudentsStatusData,
} from '~/api/student/request';

import useAuth from '~/hooks/useAuth';

import StudentRequestList from '~/components/studentManagement/StudentRequest/StudentRequestList';
import StudentRequestModal from '~/components/studentManagement/StudentRequest/StudentRequestModal';

export default function Index() {
  const [modalComponent, setModalComponent] = useState('');
  const [isData, setIsData] = useState<RequestStudentsResponse>();
  const [isDate, setIsDate] = useState<Date>(new Date());
  const [isLoading, setIsLoading] = useState(false);
  const [checkedSendId, setCheckedSendId] = useState<RequestDataList[]>([]);
  const [allCheck, setAllCheck] = useState(false);
  const { user } = useAuth();
  const [modalText, setModalText] = useState('');

  const handleModalClick = (id: string) => {
    if (checkedSendId.length === 0) {
      setModalText('NonCheck');
    } else {
      setModalComponent(id);
    }
  };

  const findUserById = (sendId: number): RequestDataList | undefined => {
    return isData?.data?.requestList?.find((user) => user.sendId === sendId);
  };

  const handleSelectAllChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const isChecked = event.target.checked;
    setAllCheck(isChecked);

    if (isChecked) {
      const updatedSendIds: RequestDataList[] = isData?.data?.requestList || [];
      setCheckedSendId(updatedSendIds);
    } else {
      setCheckedSendId([]);
    }
  };

  const handleUserCheckboxChange =
    (sendId: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const isChecked = event.target.checked;

      if (isChecked) {
        const userToAdd = findUserById(sendId);
        if (userToAdd) {
          setCheckedSendId((prevSendIds) => [...prevSendIds, userToAdd]);
        }
      } else {
        setCheckedSendId((prevSendIds) =>
          prevSendIds.filter((user) => user.sendId !== sendId),
        );
      }

      const allChecked =
        checkedSendId.length === (isData?.data?.requestList?.length || 0);
      setAllCheck(allChecked);
    };

  useEffect(() => {
    if (isData?.data?.requestList.length === 0) {
      setAllCheck(false);
    } else {
      const allChecked =
        checkedSendId.length === (isData?.data?.requestList?.length || 0);
      setAllCheck(allChecked);
    }
  }, [checkedSendId, isData?.data?.requestList]);

  const handleAllCheck = () => {
    setCheckedSendId([]);
  };

  const handlesendIdFilted = () => {
    const sendIds = checkedSendId.map((user) => user.sendId);

    return sendIds;
  };

  const handleApiSendButton = () => {
    if (!user) {
      return;
    }
    const sendIdsToRelation = handlesendIdFilted();
    getRequestStudentsRelationStatusData({
      sendIds: sendIdsToRelation,
      sendStatus: 'R',
      userId: user.userId,
    })
      .then(() => {
        setModalText('Delete');
        setModalComponent('');
      })
      .catch(console.error);
  };

  useEffect(() => {
    if (!user) {
      return;
    }
    getRequestStudentsStatusData({
      managerId: user.userId,
    })
      .then(({ data }) => {
        setIsData(data);
        setIsLoading(true);
      })
      .catch(console.error);
  }, [isDate, modalText]);

  return (
    <Wrapper>
      <TitleContainer>
        <div>학생에게 보낸 연결 요청 목록</div>
        {isLoading && (
          <>
            {isData?.data.requestList.length !== 0 && (
              <div>
                <TitleButton
                  bgColor="#0062ff"
                  color="#fff"
                  onClick={() => handleModalClick('StudentRequestModal')}
                >
                  선택한 요청 다시 보내기
                </TitleButton>
              </div>
            )}
          </>
        )}
      </TitleContainer>
      <StudentRequestList
        isData={isData}
        isDate={isDate}
        isLoading={isLoading}
        setIsDate={setIsDate}
        handleUserCheckboxChange={handleUserCheckboxChange}
        checkedSendId={checkedSendId}
        handleAllCheck={handleAllCheck}
        allCheck={allCheck}
        handleSelectAllChange={handleSelectAllChange}
      />
      {modalComponent === 'StudentRequestModal' && (
        <StudentRequestModal
          isData={isData}
          setModalComponent={setModalComponent}
          handleUserCheckboxChange={handleUserCheckboxChange}
          checkedSendId={checkedSendId}
          handleAllCheck={handleAllCheck}
          allCheck={allCheck}
          handleSelectAllChange={handleSelectAllChange}
          handleApiSendButton={handleApiSendButton}
        />
      )}
      {modalText === 'NonCheck' && (
        <Modal
          title={'선택한 요청이 없습니다.'}
          onClose={() => setModalText('')}
        />
      )}
      {modalText === 'Delete' && (
        <Modal
          title={'재요청을 완료 했습니다.'}
          onClose={() => setModalText('')}
        />
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 1544px;
  height: 786px;
  margin: 20px 30px 30px 30px;
  padding: 20px 24px 24px 24px;
  border-radius: 12px;
  background: #fff;
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;

  & > div {
    color: #000;
    font-size: 24px;

    font-weight: 700;
    line-height: 1.6em;
    display: flex;
  }
`;

const TitleButton = styled.button<{ bgColor: string }>`
  display: flex;
  align-items: center;
  padding: 15px 25px;
  gap: 10px;
  border-radius: 10px;
  border: 1px solid #0062ff;
  color: ${(props) => props.color};
  background-color: ${(props) => props.bgColor};
  text-align: center;
  font-size: 16px;

  font-weight: 500;
`;
