import axios from 'axios';

import { getTokenStatusData } from './token/token';

export const ACCESS_TOKEN_EXPIRATION = 30004;
export const REFRESH_TOKEN_EXPIRATION = 30005;

interface CookieOptions {
  path?: string;
  expires?: Date | string;
  [key: string]: string | Date | undefined | boolean;
}

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

instance.interceptors.request.use(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (config: any) => {
    return {
      ...config,
      headers: {
        Authorization: `Bearer ${parseCookie(document.cookie)['accessToken']}`,
      },
    };
  },
  (error) => {
    return Promise.reject(error);
  },
);

instance.interceptors.response.use(
  async (response) => {
    // 엑세스 토큰이(30004) or 리프레시 토큰(30005) 만료 => 토큰, 리프레시 토큰 세팅
    if (
      [ACCESS_TOKEN_EXPIRATION, REFRESH_TOKEN_EXPIRATION].includes(
        response.data.status,
      )
    ) {
      try {
        const { data } = await getTokenStatusData({
          refreshToken: parseCookie(document.cookie)['refreshToken'],
        });

        setCookie('accessToken', data.data.jwtToken.accessToken);
        setCookie('refreshToken', data.data.jwtToken.refreshToken);

        window.location.reload();
      } catch (err) {
        console.error(err);
        alert('토큰이 만료되었습니다.');
        window.location.href = '/';
      }
    }

    return response;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export default instance;

const parseCookie = (str: string) =>
  str
    .split(';')
    .map((v) => v.split('='))
    .reduce((acc: { [key: string]: string }, v) => {
      if (v.length >= 2) {
        acc[decodeURIComponent(v[0].trim())] = decodeURIComponent(v[1].trim());
      }

      return acc;
    }, {});

export const setCookie = (
  name: string,
  value: string,
  options?: CookieOptions,
) => {
  options = {
    path: '/',
    ...options,
  };

  if (options.expires instanceof Date) {
    options.expires = options.expires.toUTCString();
  }

  let updatedCookie =
    encodeURIComponent(name) + '=' + encodeURIComponent(value);

  for (const optionKey in options) {
    updatedCookie += '; ' + optionKey;
    const optionValue = options[optionKey];
    if (optionValue !== true) {
      updatedCookie += '=' + optionValue;
    }
  }
  document.cookie = updatedCookie;
};
