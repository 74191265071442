import moment from 'moment';

const dateFilter = (value: string | Date | undefined | null) => {
  const GMT = moment().format('Z');

  const [gmtSign, gmtValue] = GMT.split(':');
  const gmtHours = parseInt(gmtSign);
  const gmtMinutes = parseInt(gmtValue);

  const countryTime = moment
    .utc(value)
    .utcOffset(gmtHours * 60 + gmtMinutes)
    .format('YYYY.MM.DD HH:mm');
  return countryTime;
};

export default dateFilter;
