import FindIdPw from '~/components/findidPw/FindIdPw';

const FindIdPwPage = () => {
  return (
    <>
      <FindIdPw />
    </>
  );
};

export default FindIdPwPage;
