import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

import { Cognitive } from '~/types/dash/cognitive';

import { ReactComponent as DiamondImg } from '~/assets/DashDIamond.svg';

import AllStudentChart from '~/components/dashboard/AllStudentChart';
import StudentChart from '~/components/dashboard/StudentChart';

interface Props {
  dashboardData?: Cognitive;
  isLoading: boolean;
}

export default function Force({ dashboardData, isLoading }: Props) {
  return (
    <>
      {isLoading && (
        <Wrapper>
          <Title>인지능력측정 현황</Title>
          <GoodsContainer>
            <Diamond>
              <ImgConatiner>
                <DiamondImg />
              </ImgConatiner>
              <h1>다이아</h1>
              <h2>{dashboardData?.m002}개</h2>
            </Diamond>
          </GoodsContainer>
          <SubTitle>측정 결과</SubTitle>
          {dashboardData?.totalStudentCount === 0 ? (
            <NodataContainer>
              <h1>아직 인지능력을 측정한 학생이 없습니다.</h1>
              <p>
                설문조사 실시 후 인지능력측정 이용권을 전달하여
                <br />
                학생의 인지능력측정 결과를 확인해보세요.
              </p>
              <Link to="/home/student-management/student/measurement">
                <button>학생 관리 화면으로 이동</button>
              </Link>
            </NodataContainer>
          ) : (
            <>
              <ChartConatiner>
                <AllStudentChart dashboardData={dashboardData} />
                <StudentChart dashboardData={dashboardData} />
              </ChartConatiner>
              <InfoContainer>
                <ForceCountContainer>
                  <ColorIcon
                    bgColor="linear-gradient(180deg, #3467ff 0%, #5451ff 100%)"
                    mgLeft=""
                  />
                  <h1>측정 학생</h1>
                  <h2>{dashboardData?.measurementStudentCount}</h2>
                  <ColorIcon bgColor="#E2EDF9" mgLeft="22px" />
                  <h1>미측정 학생</h1>
                  <h2>{dashboardData?.nonMeasurementStudentCount}</h2>
                </ForceCountContainer>
                <ForceCountContainer>
                  <ColorIcon bgColor="#FF4752" mgLeft="111px" />
                  <h1>주의단계</h1>
                  <h2>{dashboardData?.attentionStudentCount}</h2>
                  <ColorIcon bgColor="#F0A95A" mgLeft="22px" />
                  <h1>관심단계</h1>
                  <h2>{dashboardData?.needAttentionStudentCount}</h2>
                  <ColorIcon bgColor="#0CAF60" mgLeft="22px" />
                  <h1>양호단계</h1>
                  <h2>{dashboardData?.normalStudentCount}</h2>
                </ForceCountContainer>
              </InfoContainer>
            </>
          )}
        </Wrapper>
      )}
    </>
  );
}

const Wrapper = styled.div`
  width: 721px;
  height: 478px;
  padding: 24px 30px;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.08);
`;

const Title = styled.div`
  margin-bottom: 20px;
  color: #000;

  /* heading/H02-20px-bold */

  font-size: 20px;

  font-weight: 700;
  line-height: 1.6em; /* 32px */
  letter-spacing: -0.3px;
`;

const GoodsContainer = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  border-bottom: 1px solid #e2e2ea;

  h1 {
    margin: 0 12px;
    color: #000;

    /* body/B03-16px-medium */

    font-size: 16px;

    font-weight: 500;
  }

  h2 {
    color: #000;

    /* body/B03-16px-bold */

    font-size: 16px;

    font-weight: 700;
  }
`;

const Diamond = styled.div`
  display: flex;
  align-items: center;
  width: 355px;
`;

const ImgConatiner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15px;
  height: 19px;

  padding: 2px 12px;
  border-radius: 100px;
  background: linear-gradient(180deg, #3467ff 0%, #5451ff 100%);
`;

const SubTitle = styled.div`
  color: #000;

  /* body/B03-16px-bold */

  font-size: 16px;

  font-weight: 700;
  margin: 20px 0;
`;

const ChartConatiner = styled.div`
  display: flex;
  margin-bottom: 45px;
`;

const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ForceCountContainer = styled.div`
  display: flex;

  h1 {
    margin-right: 6px;
    color: #292d32;

    /* body/B05-14px-regular */

    font-size: 14px;

    font-weight: 400;
  }
  h2 {
    color: #292d32;

    /* body/B04-14px_bold */

    font-size: 14px;

    font-weight: 700;
  }
`;

const ColorIcon = styled.div<{ bgColor: string; mgLeft: string }>`
  width: 14px;
  height: 14px;
  margin-right: 6px;
  border-radius: 100px;
  margin-left: ${(props) => props.mgLeft};
  background: ${(props) => props.bgColor};
`;

const NodataContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & > h1 {
    margin-top: 75px;
    margin-bottom: 4px;
    color: #65696d;
    text-align: center;

    /* heading/H06-18px-bold */

    font-size: 18px;

    font-weight: 700;
    line-height: 1.6em; /* 28.8px */
  }

  & > p {
    margin-bottom: 20px;
    color: #65696d;
    text-align: center;

    font-size: 14px;

    font-weight: 500;
    line-height: 1.6em; /* 22.4px */
  }

  & > a {
    & > button {
      width: 200px;
      height: 56px;
      border-radius: 10px;
      background-color: #2c55fb;

      color: #fff;
      text-align: center;

      /* body/B03-16px-bold */

      font-size: 16px;

      font-weight: 700;
      line-height: 1.3em; /* 20.8px */
    }
  }
`;
