import styled from '@emotion/styled';

interface Props {
  setActiveComponent: React.Dispatch<React.SetStateAction<string>>;
}

export default function StudentManagementSendPushModal({
  setActiveComponent,
}: Props) {
  return (
    <Wrapper>
      <Container>
        <div>푸시 전송을 완료하였습니다.</div>
        <button onClick={() => setActiveComponent('')}>확인</button>
      </Container>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  min-width: 1920px;
  min-height: 1080px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
  z-index: 9;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 500px;
  height: 181px;
  border-radius: 16px;
  background: #fff;
  color: #000;

  font-size: 18px;

  font-weight: 500;
  line-height: 1.6em;

  div {
    margin-top: 30px;
    margin-bottom: 32px;
  }

  button {
    width: 200px;
    height: 56px;
    border-radius: 10px;
    background: #0062ff;

    color: #fff;

    font-size: 16px;

    font-weight: 700;
    line-height: 1.3em; /* 20.8px */
  }
`;
