import { AxiosResponse } from 'axios';

import {
  NewStudentConnectParams,
  NewStudentConnectResponse,
} from '~/types/student/connect';

import api from '~/api/base';

const BASE_URL = 'api/v1/student/connect';

export const getNewStudentConnectStatusData = (
  param: NewStudentConnectParams,
): Promise<AxiosResponse<NewStudentConnectResponse>> =>
  api.post(`${BASE_URL}/new`, param);
