import styled from '@emotion/styled';
import { SyncLoader } from 'react-spinners';

export default function Loading() {
  return (
    <Wrapper>
      <SyncLoader color="blue" />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;
