import styled from '@emotion/styled';
import { useEffect, useRef, useState } from 'react';

import { GoodsData } from '~/types/goods/find';
import { ChildInfo } from '~/types/post/find';
import { SearchChildData } from '~/types/push/search';

import Modal from '~/utils/portal/components/Modal';

import { getGoodsStatusData } from '~/api/goods/find';
import { getPostSendStatusData } from '~/api/post/send';
import { getSearchChildStatusData } from '~/api/push/search';

import useAuth from '~/hooks/useAuth';

import SendPushList from '~/components/mailBox/sendMailBox/SendMailList';
import SendMailModal from '~/components/mailBox/sendMailBox/SendMailModal';

const NewPushData = [
  { key: 1, label: '제목', placeholder: '제목을 입력해주세요' },
  {
    key: 2,
    label: '단문',
    placeholder: '단문을 입력해주세요.',
  },
];

interface NewSendPushProps {
  setActiveComponent: React.Dispatch<React.SetStateAction<string>>;
}

interface PushData {
  content: string;
  title: string;
  subTitle: string;
  receiverIdList: [];
}

interface GoodsItem {
  itemCode: string;
  itemCount: number;
}

export default function NewSendMailDefault({
  setActiveComponent,
}: NewSendPushProps) {
  const [isData, setIsData] = useState<ChildInfo[]>([]);
  const [isPushListData, setIsPushListData] = useState<SearchChildData>();
  const [isMailData, setIsMailData] = useState<PushData>({
    content: '',
    title: '',
    subTitle: '',
    receiverIdList: [],
  });

  const [isListModal, setIsListModal] = useState(false);
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [isModal, setIsModal] = useState('');
  const [isErrorMessage, setIsErrorMessage] = useState({
    name: false,
    title: false,
    content: false,
    subTitle: false,
  });
  const [isGoodsData, setIsGoodsData] = useState<GoodsData | undefined>();

  const [isDiaAttached, setIsDiaAttached] = useState(false);
  const [ticketCount, setTicketCount] = useState<number>(1);
  const [editing, setEditing] = useState(false);
  const { user } = useAuth();
  const refCount = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!user) {
      return;
    }

    getGoodsStatusData({ userId: user?.userId })
      .then(({ data }) => {
        setIsGoodsData(data.data);
      })
      .catch(console.error);
  }, []);

  const handleRemoveChild = (userId: number) => {
    if (isData) {
      const updatedChildInfo = isData.filter((item) => item.userId !== userId);
      setIsData(updatedChildInfo);
    }
  };

  const handleAddChild = (userId: number) => {
    if (isData && isPushListData?.childInfo) {
      const childToAdd = isPushListData.childInfo.find(
        (item) => item.userId === userId,
      );

      if (childToAdd) {
        const isUserIdPresent = isData.some((item) => item.userId === userId);

        if (!isUserIdPresent && Array.isArray(isData)) {
          setIsData((prevData: ChildInfo[] | undefined) => [
            ...(prevData || []),
            childToAdd as unknown as ChildInfo,
          ]);
        }
      }
    }
  };

  const handlePushMessage = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setIsMailData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleTitleValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    setIsMailData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleContentValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    setIsMailData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleTitleBlur = () => {
    if (isMailData.title.length === 0) {
      setIsErrorMessage((prevErrors) => ({
        ...prevErrors,
        title: true,
      }));
    } else {
      setIsErrorMessage((prevErrors) => ({
        ...prevErrors,
        title: false,
      }));
    }
  };

  const handleSubTitleBlur = () => {
    if (isMailData.subTitle.length === 0) {
      setIsErrorMessage((prevErrors) => ({
        ...prevErrors,
        subTitle: true,
      }));
    } else {
      setIsErrorMessage((prevErrors) => ({
        ...prevErrors,
        subTitle: false,
      }));
    }
  };

  const handleListModal = () => {
    setIsListModal((prev) => !prev);
  };

  useEffect(() => {
    if (!user) {
      return;
    }

    getSearchChildStatusData({ userId: user?.userId })
      .then(({ data }) => {
        setIsPushListData(data.data);
      })
      .catch(console.error);
  }, [user]);

  const handleTicketCount = (action: string) => {
    if (ticketCount !== null) {
      if (action === 'minus') {
        if (ticketCount > 1) {
          setTicketCount(ticketCount - 1);
        }
      } else if (action === 'plus') {
        if (ticketCount < 999) {
          setTicketCount(ticketCount + 1);
        }
      }
    }
  };

  useEffect(() => {
    if (editing && refCount.current) {
      refCount.current.focus();
    }
  }, [editing]);

  const handleFocus = (inputRef: React.RefObject<HTMLInputElement>) => {
    if (inputRef.current !== null) {
      inputRef.current.focus();
    }
  };

  const handleValueClick = () => {
    setEditing(true);
  };

  const handleInputBlur = () => {
    setEditing(false);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = parseInt(event.target.value);
    if (isNaN(value)) {
      value = 1;
    } else if (value < 1) {
      value = 1;
    } else if (value > 999) {
      value = 999;
    }
    setTicketCount(value);
  };

  const handlePostMailButton = () => {
    const userList = isData.map((item) => item.userId);
    const diaCount = ticketCount;

    if (userList?.length === 0) {
      setIsErrorMessage((prevErrors) => ({
        ...prevErrors,
        name: true,
      }));
      return;
    } else {
      setIsErrorMessage((prevErrors) => ({
        ...prevErrors,
        name: false,
      }));
    }
    if (isMailData.title.length === 0) {
      setIsErrorMessage((prevErrors) => ({
        ...prevErrors,
        title: true,
      }));
      return;
    } else {
      setIsErrorMessage((prevErrors) => ({
        ...prevErrors,
        title: false,
      }));
    }

    if (isMailData.subTitle.length === 0) {
      setIsErrorMessage((prevErrors) => ({
        ...prevErrors,
        subTitle: true,
      }));
      return;
    } else {
      setIsErrorMessage((prevErrors) => ({
        ...prevErrors,
        subTitle: false,
      }));
    }

    if (userList?.length * diaCount > (isGoodsData?.m002 as number)) {
      setIsModal('fail');
      return;
    }
    let goodsList: GoodsItem[] | null = null;
    if (isDiaAttached) {
      goodsList = [
        {
          itemCode: 'M002',
          itemCount: diaCount,
        },
      ];
    }

    getPostSendStatusData({
      attachment: goodsList,
      callerId: user?.userId as number,
      content: isMailData.content,
      receiverList: userList,
      subTitle: isMailData.subTitle,
      title: isMailData.title,
    })
      .then(({ data }) => {
        if (data?.data?.isValid === true) {
          setIsModal('success');
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <>
      <Background></Background>
      <Wrapper>
        <Container>
          <Title>
            <Icon />
            <div>새 우편 보내기</div>
          </Title>
          <Items>
            <Labels>
              수신자
              <span>*</span>
            </Labels>
            <NameContainer
              borderColor={
                isErrorMessage.name === true ? '#FF4752' : '#e2e2ea;'
              }
            >
              <ChildContainer>
                {isData?.map((item) => (
                  <Child key={item.userId}>
                    <div>
                      {item.name}(
                      {item.loginId && item.loginId.length > 6
                        ? `${item.loginId.substring(0, 6)}...`
                        : item.loginId}
                      )
                    </div>
                    <DeleteIcon
                      onClick={() =>
                        item.userId && handleRemoveChild(item.userId)
                      }
                    />
                  </Child>
                ))}
              </ChildContainer>
              <PlusIcon onClick={handleListModal} />
            </NameContainer>
            {isErrorMessage.name === true ? (
              <Error>수신자를 등록해 주세요.</Error>
            ) : (
              <Error></Error>
            )}
            {NewPushData.map((item) => (
              <div key={item.key}>
                <Labels>
                  {item.label}
                  <span>*</span>
                </Labels>
                <Input
                  type="text"
                  onBlur={
                    item.label === '제목' ? handleTitleBlur : handleSubTitleBlur
                  }
                  placeholder={item.placeholder}
                  maxLength={30}
                  name={item.label === '제목' ? 'title' : 'subTitle'}
                  onChange={
                    item.label === '제목'
                      ? handleTitleValue
                      : handleContentValue
                  }
                  borderColor={
                    item.label === '제목' && isErrorMessage.title === true
                      ? '#FF4752'
                      : item.label === '단문' &&
                        isErrorMessage.subTitle === true
                      ? '#FF4752'
                      : '#e2e2ea;'
                  }
                />
                {item.label === '제목' && (
                  <div>
                    {isErrorMessage.title === true ? (
                      <Error>제목을 입력해주세요.</Error>
                    ) : (
                      <Error></Error>
                    )}
                  </div>
                )}
                {item.label === '단문' && (
                  <div>
                    {isErrorMessage.subTitle === true ? (
                      <Error>단문을 입력해주세요.</Error>
                    ) : (
                      <Error></Error>
                    )}
                  </div>
                )}
              </div>
            ))}
            <Labels>내용</Labels>
            <TextArea
              onChange={handlePushMessage}
              maxLength={300}
              name="content"
              id="textArea"
              placeholder="우편 내용을 입력해주세요.&#10;내용을 작성하지 않는 경우, 열리지 않는 우편 형태로 전송됩니다.&#10;내용은 최대 300자까지 입력 가능합니다."
            ></TextArea>
            <p></p>
          </Items>

          <ItemsButtonContainer mgTop="40px">
            <div>
              다이아 첨부<span>*</span>
            </div>
            <ItemsContainer>
              <ItemsButton
                url={
                  isDiaAttached
                    ? '/images/toggleBlue.png'
                    : '/images/toggleWhite.png'
                }
                onClick={() => setIsDiaAttached((prev) => !prev)}
              ></ItemsButton>
              {isDiaAttached ? (
                <ItemStatus>첨부</ItemStatus>
              ) : (
                <ItemStatus>미첨부</ItemStatus>
              )}
            </ItemsContainer>
          </ItemsButtonContainer>
          {isDiaAttached && (
            <ItemsButtonContainer mgTop="30px">
              <div>
                첨부할 다이아 수<span>*</span>
                <DiaCount>
                  현재 보유 다이아는 {isGoodsData?.m002}개입니다.
                </DiaCount>
              </div>
              <ItemsContainer></ItemsContainer>
              <div>
                <TicketCountButton>
                  <button onClick={() => handleTicketCount('minus')}>
                    <MinusImg />
                  </button>
                  {editing ? (
                    <input
                      type="number"
                      value={ticketCount !== null ? ticketCount : ''}
                      onFocus={() => handleFocus(refCount)}
                      onChange={handleInputChange}
                      onBlur={handleInputBlur}
                      min="1"
                      max="999"
                      ref={refCount}
                    />
                  ) : (
                    <ValueSpan
                      onClick={handleValueClick}
                    >{`${ticketCount}${'개'}`}</ValueSpan>
                  )}
                  <button onClick={() => handleTicketCount('plus')}>
                    <PlusImg />
                  </button>
                </TicketCountButton>
              </div>
            </ItemsButtonContainer>
          )}
          <ButtonContainer>
            <Button
              onClick={() => setActiveComponent('')}
              bgColor="#fff"
              color="#2c55fb"
            >
              취소
            </Button>
            <Button
              bgColor="#2c55fb"
              color="#fff"
              onClick={handlePostMailButton}
            >
              보내기
            </Button>
          </ButtonContainer>
        </Container>
        {isListModal && (
          <SendPushList
            isPushListData={isPushListData}
            isData={isData}
            setIsData={setIsData}
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
            handleRemoveChild={handleRemoveChild}
            handleAddChild={handleAddChild}
            handleListModal={handleListModal}
          />
        )}
      </Wrapper>
      {isModal === 'success' && (
        <SendMailModal setActiveComponent={setActiveComponent} />
      )}
      {isModal === 'fail' && (
        <Modal
          title="보유한 다이아가 부족합니다."
          onClose={() => setIsModal('')}
        ></Modal>
      )}
    </>
  );
}

const Wrapper = styled.div`
  position: absolute;
  min-width: 1920px;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
`;

const Background = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
`;

const Container = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 720px;
  height: calc(100vh - 120px);
  min-height: 960px;
  padding: 60px;
  border-radius: 20px 0px 0px 20px;
  background: #fff;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 58px;
  color: #000;
  font-size: 24px;

  font-weight: 700;
  line-height: 1.6em;
`;

const Icon = styled.div`
  width: 24px;
  height: 24px;
  margin-right: 12px;
  background: url(/images/Mail.png) no-repeat center;
  background-size: contain;
`;

const Items = styled.div`
  width: 720px;

  color: #000;
  font-size: 14px;

  font-weight: 500;
  line-height: 1.6em;

  span {
    color: #0062ff;
  }

  p {
    width: 720px;
    height: 1px;
    margin-top: 30px;
    background: #e2e2ea;
  }
`;

const Input = styled.input<{ borderColor: string }>`
  width: 695px;
  height: 52px;
  padding: 0;
  padding-left: 22px;
  border-radius: 10px;
  border: 1px solid ${(props) => props.borderColor};
  margin-bottom: 6px;

  color: #000;
  font-size: 16px;

  font-weight: 400;
  line-height: 1.6em;

  ::placeholder {
    color: #b5b5be;
    font-size: 16px;

    font-weight: 400;
    line-height: 1.6em;
  }
`;

const Labels = styled.div`
  margin-top: 2px;
  margin-bottom: 9px;
`;

const ChildContainer = styled.div`
  display: flex;
  overflow-x: scroll;
  width: 588px;
  margin-left: 20px;

  ::-webkit-scrollbar {
    height: 4px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 100px;
    background: #e2e2ea;
  }
`;

const Child = styled.div`
  display: flex;
  align-items: center;
  height: 25px;
  margin: 13.5px 10px 5px 0;
  border-radius: 100px;
  border: 1px solid #d1d1d1;
  background: #fff;

  color: #000;
  font-size: 12px;

  font-weight: 400;
  line-height: 1.6em;

  & > div {
    display: flex;
    align-items: center;
    width: 110px;
    height: 19px;
    margin-left: 12px;
    margin-right: 4px;
  }
`;

const DeleteIcon = styled.button`
  width: 14px;
  height: 14px;
  background: url(/images/delete.png) no-repeat center;
  background-size: contain;
  margin-right: 12px;
`;

const NameContainer = styled.div<{ borderColor: string }>`
  position: relative;
  width: 717px;
  height: 54px;
  margin-bottom: 6px;
  border-radius: 10px;
  border: 1px solid ${(props) => props.borderColor};
`;

const ButtonContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 60px;
  width: 720px;
  height: 56px;
`;

const PlusIcon = styled.button`
  position: absolute;
  top: 21px;
  right: 0;
  width: 12px;
  height: 12px;
  background: url(/images/blackPlus.png) no-repeat center;
  background-size: contain;
  margin-right: 20px;
  z-index: 99;
`;

const Button = styled.button<{ bgColor: string }>`
  width: 200px;
  height: 56px;
  border-radius: 10px;
  border: 1px solid #2c55fb;
  color: ${(props) => props.color};
  background-color: ${(props) => props.bgColor};

  font-size: 16px;

  font-weight: 700;
  line-height: 1.3em;

  :nth-of-type(1) {
    margin-right: 12px;
  }
`;

const Error = styled.div`
  height: 22px;
  color: #ff4752;
  font-size: 14px;

  font-weight: 500;
  line-height: 1.6em; /* 22.4px */
`;

const TextArea = styled.textarea`
  border-radius: 10px;
  border: 1px solid #e2e2ea;
  width: 670px;
  height: 78px;
  padding: 20px;
  resize: none;

  ::placeholder {
    color: #b5b5be;

    font-size: 16px;

    font-weight: 400;
    line-height: 1.6em; /* 25.6px */
  }
`;

const ItemsButtonContainer = styled.div<{ mgTop: string }>`
  display: flex;
  justify-content: space-between;
  margin-top: ${(props) => props.mgTop};

  & > div {
    & > span {
      color: #2c55fb;
    }
  }
`;

const ItemsButton = styled.div<{ url: string }>`
  width: 54px;
  height: 33px;
  background: url(${(props) => props.url}) no-repeat center;
  background-size: contain;
`;

const DiaCount = styled.div`
  color: #8c9299;

  /* body/B04-14px-medium */

  font-size: 14px;

  font-weight: 500;
  line-height: 1.6em; /* 22.4px */
`;

const ItemsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
`;

const ItemStatus = styled.div`
  margin-left: 12px;
`;

const TicketCountButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 21px;
  padding: 12px 24px;
  border-radius: 100px;
  border: 1px solid #0062ff;
  background: #fff;

  input {
    width: 100px;
    height: 38px;
    padding: 0;
    outline: none;
    border: none;

    color: #0062ff;
    text-align: center;
    font-size: 16px;

    font-weight: 500;
    line-height: 1.3em; /* 20.8px */
  }
`;

const ValueSpan = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 3px;
  height: 38px;
  width: 100px;
  color: #0062ff;
  font-size: 16px;

  font-weight: 500;
  line-height: 1.3em; /* 20.8px */
`;

const PlusImg = styled.div`
  width: 16px;
  height: 16px;
  background: url(/images/plus.png) no-repeat center;
  background-size: contain;
`;

const MinusImg = styled.div`
  width: 16px;
  height: 2px;
  background: url(/images/minus.png) no-repeat center;
  background-size: cover;
`;
