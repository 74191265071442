import { AxiosResponse } from 'axios';

import {
  AgencyInfoDetailParams,
  AgencyInfoDetailResponse,
  AgencyInfoEmployeeParams,
  EmployeeListResponse,
} from '~/types/agencyInfo/agencyInfo';

import api from '~/api/base';

const BASE_URL = 'api/v1/agencyInfo/find';

export const getAgencyInfoDetailStatusData = (
  param: AgencyInfoDetailParams,
): Promise<AxiosResponse<AgencyInfoDetailResponse>> =>
  api.post(`${BASE_URL}/detail`, param);

export const getAgencyInfoEmployeelStatusData = (
  param: AgencyInfoEmployeeParams,
): Promise<AxiosResponse<EmployeeListResponse>> =>
  api.post(`${BASE_URL}/employee`, param);
