import styled from '@emotion/styled';
import { useEffect, useState } from 'react';

import {
  AgencyInfoDetailResponse,
  EmployeeListResponse,
} from '~/types/agencyInfo/agencyInfo';

import {
  getAgencyInfoDetailStatusData,
  getAgencyInfoEmployeelStatusData,
} from '~/api/agencyInfo/agencyInfo';

import useAuth from '~/hooks/useAuth';

import Pagenate from '~/components/institutionInformation/Paginate';

const INFO_ITEMS = [
  { key: 1, label: '기관명' },
  { key: 2, label: '기관 코드' },
  { key: 3, label: '기관 소속 교직원' },
  { key: 4, label: '기관 소속 관리 학생' },
];

const DATA = [
  { key: 1, label: 'ID' },
  { key: 2, label: '이름' },
  { key: 3, label: '연락처' },
  { key: 4, label: '이메일' },
  { key: 5, label: '소개' },
  { key: 6, label: '관리 학생 수' },
];

export default function InstitutionInformation() {
  const [infoDetailData, setInfoDeTailData] =
    useState<AgencyInfoDetailResponse>();
  const [employeeData, setEmployeeData] = useState<EmployeeListResponse>();

  const { user } = useAuth();

  useEffect(() => {
    getAgencyInfoDetailStatusData({
      licenseId: Number(user?.licenseId),
    })
      .then(({ data }) => {
        setInfoDeTailData(data);
        console.log('1', data);
      })
      .catch(console.error);
  }, []);

  useEffect(() => {
    getAgencyInfoEmployeelStatusData({
      licenseId: Number(user?.licenseId),
    })
      .then(({ data }) => {
        setEmployeeData(data);
        console.log('2', data);
      })
      .catch(console.error);
  }, []);

  const itemsPerPage = 10;
  return (
    <Wrapper>
      <InfoWrapper>
        {INFO_ITEMS.map((item) => (
          <InfoContainer key={item.key}>
            <InfoLabels>{item.label}</InfoLabels>
            {item.key === 1 && (
              <InfoLabelsData>
                {infoDetailData?.data?.agencyName}
              </InfoLabelsData>
            )}
            {item.key === 2 && (
              <InfoLabelsData>
                {infoDetailData?.data?.licenseKey}
              </InfoLabelsData>
            )}
            {item.key === 3 && (
              <InfoLabelsData>
                {infoDetailData?.data?.agencyEmployeeCount}명
              </InfoLabelsData>
            )}
            {item.key === 4 && (
              <InfoLabelsData>
                {infoDetailData?.data?.agencyStudentCount}명
              </InfoLabelsData>
            )}
          </InfoContainer>
        ))}
      </InfoWrapper>
      <ItemsWrapper>
        <Title>교직원 목록</Title>
        <TitleWrapper>
          {DATA.map((item) => (
            <div key={item.key}>
              {item.key === 1 && <IdTitle>{item.label}</IdTitle>}
              {item.key !== 1 && (
                <SubTitle
                  width={
                    item.label === '연락처'
                      ? '184px'
                      : item.label === '이메일'
                      ? '324px'
                      : item.label === '소개'
                      ? '500px'
                      : item.label === '관리 학생 수'
                      ? '112px'
                      : '164px'
                  }
                >
                  {item.label}
                </SubTitle>
              )}
            </div>
          ))}
        </TitleWrapper>
        <Pagenate
          items={employeeData?.data?.employeeList}
          itemsPerPage={itemsPerPage}
        />
      </ItemsWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  height: calc(100vh - 81px);
  background: #fafafa;
  @media (max-height: 1080px) {
    min-height: 999px;
  }
  @media (max-width: 1651px) {
    width: 1652px;
  }
`;

const InfoWrapper = styled.div`
  display: flex;
  margin: 30px 30px 0 30px;
`;

const InfoContainer = styled.div`
  display: flex;
  width: 208px;
  height: 58px;
  padding: 16px 24px;
  margin-right: 26px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  border-radius: 10px;
  background: #fff;
`;

const InfoLabels = styled.div`
  color: #000;
  /* body/B03-16px-medium */

  font-size: 16px;

  font-weight: 500;
  line-height: 1.3em; /* 20.8px */
`;

const InfoLabelsData = styled.div`
  color: #000;

  /* body/B02-18px-bold */

  font-size: 18px;

  font-weight: 700;
  line-height: 1.3em; /* 23.4px */
`;

const ItemsWrapper = styled.div`
  width: 1603px;
  height: 850px;
  margin: 24px;
  border-radius: 16px;
  background: #fff;
`;

const TitleWrapper = styled.div`
  display: flex;
  width: 1555px;
  margin-left: 24px;
  border-radius: 10px;
  background: #fafafa;
`;

const IdTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 16px;
  width: 164px;
  height: 56px;
  color: #000;

  /* body/B05-14px-regular */

  font-size: 14px;

  font-weight: 400;
  line-height: 1.6em; /* 22.4px */
`;

const SubTitle = styled.div<{ width: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 16px;
  width: ${(props) => props.width};
  height: 56px;
  color: #000;

  /* body/B05-14px-regular */

  font-size: 14px;

  font-weight: 400;
  line-height: 1.6em; /* 22.4px */
`;

const Title = styled.div`
  padding: 18px 24px 24px 24px;
  height: 38px;
  color: #171819;
  font-size: 24px;

  font-weight: 700;
  line-height: 1.6em;
`;
