import { AxiosResponse } from 'axios';

import {
  ReportRequestParams,
  ReportResponse,
  SearchCognitiveRequestParams,
  SearchCognitiveResponse,
  SearchHeaderRequestParams,
  SearchHeaderResponse,
  SearchManagementRequestParams,
  SearchManagementResponse,
  SearchStudentConnectionRequestParams,
  SearchStudentConnectionResponse,
  ServeyRequestParams,
  ServeyResponse,
} from '~/types/student/search';

import api from '~/api/base';

const BASE_URL = 'api/v1/student/search';

export const getSearchManagementStatusData = (
  param: SearchManagementRequestParams,
): Promise<AxiosResponse<SearchManagementResponse>> =>
  api.post(`${BASE_URL}/management`, param);

export const getSearchHeaderStatusData = (
  param: SearchHeaderRequestParams,
): Promise<AxiosResponse<SearchHeaderResponse>> =>
  api.post(`${BASE_URL}/header`, param);

export const getSearchStudentConnectionStatusData = (
  param: SearchStudentConnectionRequestParams,
): Promise<AxiosResponse<SearchStudentConnectionResponse>> =>
  api.post(`${BASE_URL}/list`, param);

export const getSearchCognitiveStatusData = (
  param: SearchCognitiveRequestParams,
): Promise<AxiosResponse<SearchCognitiveResponse>> =>
  api.post(`${BASE_URL}/cognitive`, param);

export const getReportStatusData = (
  param: ReportRequestParams,
): Promise<AxiosResponse<ReportResponse>> =>
  api.post(`${BASE_URL}/report`, param);

export const getServeyStatusData = (
  param: ServeyRequestParams,
): Promise<AxiosResponse<ServeyResponse>> =>
  api.post(`${BASE_URL}/survey`, param);
