import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { ChangeEvent, useState } from 'react';
import ReactPaginate from 'react-paginate';

import { SearchCognitiveStudentList } from '~/types/student/search';

import { ReactComponent as ReportImg } from '~/assets/report.svg';

import dateFilter from '~/utils/dateFilter/dateFilter';

import { getReadReportStatusData } from '~/api/student/read';

import EditStudent from '~/components/studentManagement/measurement/EditStudent ';
import Report from '~/components/studentManagement/measurement/Report/Index';
import Survey from '~/components/studentManagement/measurement/Survey';

interface PostsProps {
  itemsPerPage: number;
  handleUserCheckboxChange: (
    userId: number,
  ) => (event: ChangeEvent<HTMLInputElement>) => void;
  checkedUserIds: SearchCognitiveStudentList[];
  moveComponent(id: string): void;
  setCheckedUserIds: (data: SearchCognitiveStudentList[]) => void;
  filteredUsers: SearchCognitiveStudentList[];
  setActiveComponent: React.Dispatch<React.SetStateAction<string>>;
  activeComponent: string;
  handleModalClick: (id: string) => void;
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
}

const Posts: React.FC<PostsProps> = ({
  itemsPerPage,
  handleUserCheckboxChange,
  checkedUserIds,
  moveComponent,
  setCheckedUserIds,
  filteredUsers,
  setActiveComponent,
  activeComponent,
  handleModalClick,
  currentPage,
  setCurrentPage,
}) => {
  const [childId, setChildId] = useState<number | null>(null);
  const [userId, setUserId] = useState<number | null>();

  const pageCount: number = Math.ceil(filteredUsers?.length / itemsPerPage);

  const handlePageChange = (selectedItem: { selected: number }) => {
    setCurrentPage(selectedItem.selected);
  };

  const offset = currentPage * itemsPerPage;
  const currentItems = filteredUsers?.slice(offset, offset + itemsPerPage);

  const handleSendPush = (item: SearchCognitiveStudentList) => {
    moveComponent('StudentManagementSendPush');
    setCheckedUserIds([item]);
  };

  const handleSendMail = (item: SearchCognitiveStudentList) => {
    moveComponent('StudentManagementSendMail');
    setCheckedUserIds([item]);
  };

  const handleReadReportStatus = (
    reportId: number,
    childId: number,
    userId: number,
  ) => {
    setChildId(childId);
    setUserId(userId);
    getReadReportStatusData({ reportId })
      .then(({ data }) => {
        {
          data.data.isValid && setActiveComponent('Report');
        }
      })
      .catch(console.error);
  };

  const handleSurveyStatus = (childId: number, userId: number) => {
    setChildId(childId);
    setUserId(userId);
    setActiveComponent('Survey');
  };

  return (
    <>
      {currentItems.length !== 0 && (
        <>
          <ItemsWrapper>
            {currentItems?.map((item) => {
              const isChecked = checkedUserIds.some(
                (user) => user.userId === item.userId,
              );

              return (
                <Items
                  key={item.userId}
                  bgColor={isChecked ? '#f0f6ff' : '#fff'}
                >
                  <ItemsInput>
                    <Input
                      type="checkbox"
                      name="userId"
                      checked={checkedUserIds.some(
                        (user) => user.userId === item.userId,
                      )}
                      onChange={handleUserCheckboxChange(item.userId)}
                    />
                    <div>{item.loginId}</div>
                  </ItemsInput>
                  <div>{item.name}</div>
                  {item.gender === 'F' ? (
                    <div>
                      <Gender>
                        <GenderImg url="/images/girl.png" />
                      </Gender>
                    </div>
                  ) : item.gender === 'M' ? (
                    <div>
                      <Gender>
                        <GenderImg url="/images/boy.png" />
                      </Gender>
                    </div>
                  ) : (
                    <div>-</div>
                  )}
                  <div>{item.classInfo}</div>
                  <div>
                    <ScreenResult
                      color={
                        item.screenResult === '양호함'
                          ? '#0CAF60'
                          : item.screenResult === '관심 필요'
                          ? '#F0A95A'
                          : item.screenResult === '주의 요망'
                          ? '#FF4752'
                          : '#65696D'
                      }
                      bgColor={
                        item.screenResult === '양호함'
                          ? '#EFFCF6'
                          : item.screenResult === '관심 필요'
                          ? '#FFFBEC'
                          : item.screenResult === '주의 요망'
                          ? '#FFF2F3'
                          : '#e2e2ea;'
                      }
                    >
                      {item.screenResult === '양호함'
                        ? '양호단계'
                        : item.screenResult === '관심 필요'
                        ? '관심단계'
                        : item.screenResult === '주의 요망'
                        ? '주의단계'
                        : '미실시'}
                    </ScreenResult>
                  </div>
                  {item.screenDate !== null ? (
                    <div>{dateFilter(item.screenDate)}</div>
                  ) : (
                    <div>-</div>
                  )}
                  <div>{item.screenCount}회</div>
                  <div>
                    {item.screenStatus === '설문조사 미완료' ? (
                      <ScreenStatus
                        onClick={() =>
                          handleSurveyStatus(item.childId, item.userId)
                        }
                      >
                        설문 작성
                      </ScreenStatus>
                    ) : (
                      <ScreenStatusUpdate>
                        <div>완료</div>
                        <button
                          onClick={() =>
                            handleSurveyStatus(item.childId, item.userId)
                          }
                        >
                          수정
                        </button>
                      </ScreenStatusUpdate>
                    )}
                  </div>
                  <ButtonContainer>
                    {item.screenCount !== 0 ? (
                      <Button
                        bdColor="#0062ff"
                        color={item.viewYn === 'Y' ? '#0062ff' : '#fff'}
                        bgColor={item.viewYn === 'Y' ? '#fff' : '#0062FF'}
                        onClick={() =>
                          handleReadReportStatus(
                            item.reportId,
                            item.childId,
                            item.userId,
                          )
                        }
                      >
                        <ReportImg
                          fill={item.viewYn === 'Y' ? '#0062ff' : '#fff'}
                        />
                        <p>리포트 보기</p>
                      </Button>
                    ) : (
                      <button></button>
                    )}
                    <Button
                      bdColor="#0062ff"
                      color="#0062ff"
                      bgColor="#fff"
                      onClick={() => handleSendMail(item)}
                    >
                      <ButtonImg url="/images/Mail.png" />
                      우편 보내기
                    </Button>
                    <Button
                      bdColor="#0062ff"
                      color="#0062ff"
                      bgColor="#fff"
                      onClick={() => handleSendPush(item)}
                    >
                      <ButtonImg url="/images/new.png" />
                      푸시 보내기
                    </Button>
                  </ButtonContainer>
                </Items>
              );
            })}
          </ItemsWrapper>
          <PaginateButton>
            <Paginate
              previousLabel={'<'}
              nextLabel={'>'}
              breakLabel={'...'}
              breakClassName={'break-me'}
              pageCount={pageCount}
              marginPagesDisplayed={1}
              pageRangeDisplayed={4}
              onPageChange={handlePageChange}
              containerClassName={'pagination'}
              activeClassName={'active'}
            />
          </PaginateButton>
        </>
      )}
      {activeComponent === 'Survey' && (
        <Survey setActiveComponent={setActiveComponent} childId={childId} />
      )}
      {activeComponent === 'Report' && (
        <Report setActiveComponent={setActiveComponent} childId={childId} />
      )}
      {activeComponent === 'EditStudent' && (
        <EditStudent
          setActiveComponent={setActiveComponent}
          userId={userId}
          handleModalClick={handleModalClick}
        />
      )}
    </>
  );
};

export default Posts;

const PaginateButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
`;

const Paginate = styled(ReactPaginate)`
  display: flex;
  align-items: center;
  justify-content: center;

  ${css`
    li {
      color: #171819;
      font-size: 12px;

      font-weight: 400;
      line-height: 1.6em;
      cursor: pointer;
    }

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
    }

    li:not(.previous, .next, .break-me):hover {
      color: #2c55fb;
      background: #f0f6ff;
      border-radius: 8px;
    }
    li.active {
      border-radius: 8px;
      color: #2c55fb;
      background: #f0f6ff;
    }

    li.previous {
      border-radius: 8px;
      color: #c7c7c7;
      border: 1px solid #c7c7c7;
      margin-right: 24px;

      &:hover {
        color: #2c55fb;
        border: 1px solid #2c55fb;
      }
    }

    li.next {
      color: #c7c7c7;
      border: 1px solid #c7c7c7;
      border-radius: 8px;
      margin-left: 24px;

      &:hover {
        color: #2c55fb;
        border: 1px solid #2c55fb;
      }
    }
  `}
`;
const ItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 528px;

  color: #292d32;

  font-size: 14px;

  font-weight: 500;
  line-height: 1.6em;
`;

const Items = styled.div<{ bgColor: string }>`
  display: flex;
  border-bottom: 1px solid #f1f2f4;
  height: 63px;
  width: 1544px;
  background: ${(props) => props.bgColor};

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    font-size: 14px;

    font-weight: 400;

    :nth-of-type(1) {
      width: 100px;
    }
    :nth-of-type(2) {
      width: 100px;
    }
    :nth-of-type(3) {
      width: 160px;
    }
    :nth-of-type(4) {
      width: 140px;
    }
    :nth-of-type(5) {
      width: 180px;
    }
    :nth-of-type(6) {
      width: 100px;
    }
    :nth-of-type(7) {
      width: 180px;
    }
    :nth-of-type(8) {
      width: 364px;
    }
  }
`;

const Input = styled.input`
  width: 16px;
  height: 16px;
  margin-left: 16px;
  margin-right: 12px;
`;

const ItemsInput = styled.span`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 200px;
  height: 56px;
`;

const Gender = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 33px;
  height: 33px;
  border-radius: 10px;
  background: #f6f6f6;
`;

const GenderImg = styled.div<{ url: string }>`
  width: 14px;
  height: 20px;
  background: url(${(props) => props.url}) no-repeat center;
  background-size: center;
`;

const ButtonContainer = styled.div`
  & > button {
    width: 100px;
    margin-right: 15px;
  }
`;

const Button = styled.button<{ bgColor: string; bdColor: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 34px;
  color: ${(props) => props.color};
  font-size: 14px;

  font-weight: 500;
  border-radius: 6px;
  border: 1px solid ${(props) => props.bdColor};
  background: ${(props) => props.bgColor};

  :nth-of-type(1) {
    margin-right: 15px;
  }

  :nth-of-type(2) {
    margin-right: 15px;
  }

  & > p {
    margin-top: 1px;
    margin-left: 4px;
  }
`;

const ButtonImg = styled.div<{ url: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14px;
  height: 20px;
  margin-right: 4px;
  background: url(${(props) => props.url}) no-repeat center;
  background-size: center;
`;

const ScreenResult = styled.div<{ bgColor: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 84px;
  height: 30px;
  border-radius: 100px;
  font-size: 14px;

  font-weight: 700;
  color: ${(props) => props.color};
  background: ${(props) => props.bgColor};

  & > span {
    color: #000;
    font-size: 14px;

    font-weight: 400;
  }
`;

const ScreenStatus = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 132px;
  height: 30px;
  border-radius: 6px;
  background: #fff2f3;
  color: #ff4752;
  font-size: 14px;

  font-weight: 400;
  cursor: pointer;
`;

const ScreenStatusUpdate = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 132px;
  height: 30px;

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 21px;
    color: #0062ff;
    font-size: 14px;

    font-weight: 700;
    line-height: 1.6em; /* 22.4px */
    letter-spacing: -0.3px;
  }

  & > button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 95px;
    height: 30px;
    border-radius: 6px;
    background: #f0f6ff;
    color: #0062ff;

    /* body/B05-14px-regular */

    font-size: 14px;

    font-weight: 400;
    line-height: 1.6em; /* 22.4px */
  }
`;
