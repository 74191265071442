import { Global, css } from '@emotion/react';
import emotionReset from 'emotion-reset';

const GlobalStyle = () => {
  return (
    <Global
      styles={css`
        ${emotionReset}

        body {
          font-family: 'Pretendard', 'Noto Sans', sans-serif !important;
        }

        a {
          text-decoration: none;
          outline: none;

          &:visited {
            color: black;
          }
        }
        input {
          border-style: solid;
          outline: none;
        }

        input::-webkit-inner-spin-button {
          appearance: none;
          -moz-appearance: none;
          -webkit-appearance: none;
        }

        input[type='submit'],
        input[type='button'],
        button {
          padding: 0;
          border: none;
          background: none;
          font: inherit;
          color: inherit;
          line-height: normal;
          cursor: pointer;
          outline: none;
          appearance: none;
          box-shadow: none;
        }

        //Todo 한글일때 폰트
        @font-face {
          font-family: 'Pretendard Variable';
          font-weight: 45 920;
          font-style: normal;
          font-display: swap;
          src: url('./woff2/PretendardVariable.woff2')
            format('woff2-variations');
        }

        @font-face {
          font-family: 'Pretendard';
          font-weight: 800;
          font-display: swap;
          src: local('Pretendard ExtraBold'),
            url('./woff2/Pretendard-ExtraBold.woff2') format('font-woff2'),
            url('./woff/Pretendard-ExtraBold.woff') format('font-woff');
        }

        @font-face {
          font-family: 'Pretendard';
          font-weight: 700;
          font-display: swap;
          src: local('Pretendard Bold'),
            url('./woff2/Pretendard-Bold.woff2') format('font-woff2'),
            url('./woff/Pretendard-Bold.woff') format('font-woff');
        }

        @font-face {
          font-family: 'Pretendard';
          font-weight: 600;
          font-display: swap;
          src: local('Pretendard SemiBold'),
            url('./woff2/Pretendard-SemiBold.woff2') format('font-woff2'),
            url('./woff/Pretendard-SemiBold.woff') format('font-woff');
        }

        @font-face {
          font-family: 'Pretendard';
          font-weight: 500;
          font-display: swap;
          src: local('Pretendard Medium'),
            url('./woff2/Pretendard-Medium.woff2') format('font-woff2'),
            url('./woff/Pretendard-Medium.woff') format('font-woff');
        }

        @font-face {
          font-family: 'Pretendard';
          font-weight: 400;
          font-display: swap;
          src: local('Pretendard Regular'),
            url('./woff2/Pretendard-Regular.woff2') format('font-woff2'),
            url('./woff/Pretendard-Regular.woff') format('font-woff');
        }
      `}
    />
  );
};

export default GlobalStyle;
