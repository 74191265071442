import { AxiosResponse } from 'axios';

import {
  DashStudentsParams,
  DashStudentsResponse,
} from '~/types/dash/students';

import api from '~/api/base';

const BASE_URL = 'api/v1/dash/search';

export const getDashStudentsStatusData = (
  param: DashStudentsParams,
): Promise<AxiosResponse<DashStudentsResponse>> =>
  api.post(`${BASE_URL}/students`, param);
