import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';

import { SearchStudentConnectionResponse } from '~/types/student/search';

import { ReactComponent as BoyImg } from '~/assets/boy.svg';
import { ReactComponent as GirlImg } from '~/assets/girl.svg';
import { ReactComponent as TitleImg } from '~/assets/studentConnect.svg';

import { getNewStudentConnectStatusData } from '~/api/student/connect';
import { getSearchStudentConnectionStatusData } from '~/api/student/search';

import useAuth from '~/hooks/useAuth';

interface Props {
  handleModalClick: (id: string) => void;
}

interface ButtonProps {
  onClick: () => void;
  disabled?: boolean;
}

const STUDENT_DATA = [
  { key: 1, label: 'ID' },
  { key: 2, label: '이름' },
  { key: 3, label: '성별' },
  { key: 4, label: '학급정보' },
  { key: 5, label: '연결하기' },
];

export default function NewStudentConnection({ handleModalClick }: Props) {
  const [isListData, setIsListData] =
    useState<SearchStudentConnectionResponse>();
  const [connectionStatus, setConnectionStatus] = useState<{
    [userId: number]: boolean;
  }>({});
  const [fildtedUser, setFiltedUser] = useState('');
  const { user } = useAuth();

  useEffect(() => {
    if (!user) {
      return;
    }
    getSearchStudentConnectionStatusData({
      licenseId: Number(user.licenseId),
      userId: user.userId,
    })
      .then(({ data }) => {
        setIsListData(data);
      })
      .catch(console.error);
  }, []);

  const handleStudentConnect = (userId: number) => {
    if (!user) {
      return;
    }
    getNewStudentConnectStatusData({ fromId: user.userId, toId: userId })
      .then(({ data }) => {
        setConnectionStatus((prevStatus) => ({
          ...prevStatus,
          [userId]: data.data.isValid,
        }));
      })
      .catch(console.error);
  };

  const handleSearchInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setFiltedUser(event.target.value);
  };

  return (
    <>
      <Background></Background>
      <Wrapper>
        <Container>
          <Title>
            <TitleImg width="25px" height="22px" />
            <p>학생 연결하기</p>
          </Title>
          <InputContainer>
            <input
              type="text"
              placeholder="찾을 학생의 ID 또는 이름을 입력해주세요."
              value={fildtedUser}
              onChange={handleSearchInputChange}
            />
            <InputImg />
          </InputContainer>
          <LabelContainer>
            {STUDENT_DATA.map((item) => (
              <div key={item.key}>{item.label}</div>
            ))}
          </LabelContainer>
          <ListWrapper>
            {isListData?.data.studentList
              .filter(
                (item) =>
                  item.loginId.includes(fildtedUser) ||
                  item.name.includes(fildtedUser),
              )
              .map((item) => (
                <ListContainer key={item.userId}>
                  <div>{item.loginId}</div>
                  <div>{item.name}</div>
                  <div>
                    {item.gender === 'M' ? (
                      <div>
                        <BoyImg fill="grey" />
                      </div>
                    ) : (
                      <div>
                        <GirlImg fill="grey" />
                      </div>
                    )}
                  </div>
                  <div>
                    {item.grade}학년 {item.clazz}반 {item.number}번
                  </div>

                  {item.userId in connectionStatus &&
                  connectionStatus[item.userId] ? (
                    <div>
                      <SuccessContainer>
                        <SuccessImg />
                        <p>요청 완료</p>
                      </SuccessContainer>
                    </div>
                  ) : (
                    <div>
                      <Button
                        onClick={() => handleStudentConnect(item.userId)}
                        disabled={item.userId in connectionStatus}
                      >
                        <TitleImg width="12px" height="12px" />
                        <p>연결하기</p>
                      </Button>
                    </div>
                  )}
                </ListContainer>
              ))}
          </ListWrapper>
          <CloseButtonContainer>
            <CloseButton onClick={() => handleModalClick('')}>완료</CloseButton>
          </CloseButtonContainer>
        </Container>
      </Wrapper>
    </>
  );
}

const Wrapper = styled.div`
  position: absolute;
  min-width: 1920px;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
`;

const Background = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 3;
`;

const Container = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 720px;
  height: calc(100vh - 120px);
  min-height: 960px;
  padding: 60px;
  border-radius: 20px 0px 0px 20px;
  background: #fff;
  z-index: 4;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  color: #000;

  /* heading/H02-24px-bold */

  font-size: 24px;

  font-weight: 700;
  line-height: 1.6em; /* 38.4px */

  & > p {
    margin-left: 12px;
  }
`;

const InputContainer = styled.div`
  position: relative;

  & > input {
    width: 680px;
    height: 22px;
    padding: 16px 20px;
    margin: 37px 0;
    border-radius: 10px;
    border: 1px solid #e2e2ea;
    color: #000;

    /* body/B04-14px-medium */

    font-size: 14px;

    font-weight: 500;
    line-height: 1.6em; /* 22.4px */

    ::placeholder {
      color: #8c9299;
    }
  }
`;

const InputImg = styled.div`
  position: absolute;
  top: 55.66px;
  right: 20px;
  width: 17px;
  height: 17px;
  background: url(/images/search.png) no-repeat center;
  background-size: cover;
`;

const ListWrapper = styled.div`
  height: 630px;
  overflow-y: scroll;
  margin-bottom: 39px;
`;

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  width: 700px;
  height: 56px;
  border-radius: 10px;
  background: #fafafa;

  & > div {
    padding: 16px;
    :nth-of-type(1) {
      width: 148px;
    }
    :nth-of-type(2) {
      width: 68px;
    }
    :nth-of-type(3) {
      width: 68px;
      text-align: center;
    }
    :nth-of-type(4) {
      width: 128px;
      text-align: center;
    }
    :nth-of-type(5) {
      width: 128px;
      text-align: center;
    }
  }
`;

const ListContainer = styled.div`
  display: flex;
  align-items: center;
  width: 700px;
  border-bottom: 1px solid #f1f2f4;

  & > div {
    align-items: center;

    padding: 16px;
    :nth-of-type(1) {
      width: 148px;
    }
    :nth-of-type(2) {
      width: 68px;
    }
    :nth-of-type(3) {
      width: 68px;
      display: flex;
      align-items: center;
      justify-content: center;
      & > div {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 33px;
        height: 33px;
        border-radius: 10px;
        background: #f6f6f6;
      }
    }
    :nth-of-type(4) {
      width: 128px;
      text-align: center;
    }
    :nth-of-type(5) {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 160px;
      text-align: center;
      padding: 14.5px 0;
    }
  }
`;

const Button = styled.div<ButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 34px;
  border-radius: 6px;
  border: 1px solid #0062ff;
  background: #fff;
  cursor: pointer;

  & > p {
    margin-left: 4px;
    color: #0062ff;

    /* body/B04-14px-medium */

    font-size: 14px;

    font-weight: 500;
    line-height: 1.6em; /* 22.4px */
  }
`;

const SuccessContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 34px;
  border-radius: 6px;
  border: 1px solid #fff;
  background: #f0f6ff;

  & > p {
    margin-left: 4px;
    height: 12px;
    color: #0062ff;

    /* body/B04-14px-medium */

    font-size: 14px;

    font-weight: 500;
  }
`;

const SuccessImg = styled.div`
  width: 12px;
  height: 12px;
  background: url(/images/blueCheck.png) no-repeat center;
  background-size: cover;
`;

const CloseButtonContainer = styled.div`
  width: 700px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const CloseButton = styled.div`
  width: 200px;
  height: 56px;
  border-radius: 10px;
  background-color: #0062ff;
  display: flex;
  align-items: center;
  justify-content: center;

  color: #fff;
  text-align: center;

  /* body/B03-16px-bold */

  font-size: 16px;

  font-weight: 700;
  line-height: 1.3em; /* 20.8px */
`;
