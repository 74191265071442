import styled from '@emotion/styled';
import moment from 'moment';
import { useEffect, useState } from 'react';

import { Cognitive } from '~/types/dash/cognitive';
import { force1 } from '~/types/dash/detail';
import { DashStudentsResponse } from '~/types/dash/students';

import { ReactComponent as RunnerImg } from '~/assets/Carrot.svg';
import { ReactComponent as ShutterImg } from '~/assets/Color.svg';
import { ReactComponent as BoyImg } from '~/assets/boy.svg';
import { ReactComponent as GirlImg } from '~/assets/girl.svg';

import { getDashDetailStatusData } from '~/api/dash/detail';
import { getDashStudentsStatusData } from '~/api/dash/students';

import ForceLogCalendar from '~/components/dashboard/ForceLogCalendar';
import NewStudentRegistration from '~/components/studentManagement/NewStudentRegistration';

interface Props {
  userId?: number;
  dashboardData?: Cognitive;
  isLoading: boolean;
}

interface NewStudentRegistrationProps {
  someProp: string;
  handleModalClick: (id: string) => void;
}

interface Components {
  [key: string]: React.ComponentType<NewStudentRegistrationProps>;
}

export default function ForceLog({ userId, dashboardData, isLoading }: Props) {
  const [isStudentData, setIsStudentData] = useState<DashStudentsResponse>();
  const [selectedChildId, setSelectedChildId] = useState<number | null>(null);
  const [isDetailData, setIsDetailData] = useState<force1>();
  const [isDropDown, setIsDropDown] = useState(false);
  const [hoveredDate, setHoveredDate] = useState<Date>(new Date());
  const [activeChildId, setActiveChildId] = useState<number | null>(null);
  const [modalComponent, setModalComponent] = useState('');

  const modalComponents: Components = {
    newStudentRegistration: NewStudentRegistration,
  };

  const ModalComponent = modalComponents[modalComponent];

  useEffect(() => {
    if (!userId) {
      return;
    }

    getDashStudentsStatusData({
      userId,
    })
      .then(({ data }) => {
        setIsStudentData(data);
        setSelectedChildId(data?.data?.studentList[0]?.childId);
        setActiveChildId(data?.data?.studentList[0]?.childId);
      })
      .catch(console.error);
  }, [userId]);

  useEffect(() => {
    const fetchDetailData = async () => {
      if (selectedChildId) {
        const searchDate = dateFilter(hoveredDate);
        try {
          const { data } = await getDashDetailStatusData({
            childId: selectedChildId,
            searchDate,
          });
          setIsDetailData(data.data);
        } catch (error) {
          console.error(error);
        }
      }
    };

    fetchDetailData();
  }, [selectedChildId, hoveredDate]);

  const getWeekOfMonth = (date: Date): string => {
    const year = moment(date).year();
    const month = moment(date).month();
    const firstDayOfMonth = moment(date).startOf('month').day();
    const weekNumber = Math.ceil((moment(date).date() + firstDayOfMonth) / 7);

    const firstDayOfWeek = moment(date)
      .startOf('month')
      .add((weekNumber - 1) * 7, 'days')
      .format('MM.DD');
    const lastDayOfWeek = moment(date)
      .startOf('month')
      .add(weekNumber * 7 - 1, 'days')
      .format('MM.DD');

    return `${year}년 ${
      month + 1
    }월 ${weekNumber}주차(${firstDayOfWeek} ~ ${lastDayOfWeek})`;
  };

  const handleChildClick = (childId: number) => {
    setSelectedChildId(childId);
  };

  useEffect(() => {
    setActiveChildId(selectedChildId);
  }, [selectedChildId]);

  const dateFilter = (value: string | Date | undefined | null) => {
    const countryTime = value
      ? moment(value).format('YYYY-MM-DD HH:mm:ss')
      : '';
    return countryTime;
  };

  const handleDropDown = () => {
    setIsDropDown((prev) => !prev);
  };

  const handleSetHoveredDate = (date: Date | null) => {
    setHoveredDate(date as Date);
  };

  const handleModalClick = (id: string) => {
    setModalComponent(id);
  };

  return (
    <>
      {isLoading && (
        <Wrapper>
          <TitleContainer>
            <Title>인지능력강화 이용현황</Title>
            {dashboardData?.totalStudentCount !== 0 ? (
              <CalendarContainer>
                <DateDropDown onClick={handleDropDown}>
                  {getWeekOfMonth(hoveredDate)}
                  <DropDownImg></DropDownImg>
                </DateDropDown>
                {isDropDown && (
                  <ForceLogCalendar
                    handleDropDown={handleDropDown}
                    hoveredDate={hoveredDate}
                    setHoveredDate={handleSetHoveredDate}
                  />
                )}
              </CalendarContainer>
            ) : (
              <CalendarContainer />
            )}
          </TitleContainer>
          {dashboardData?.totalStudentCount !== 0 ? (
            <ItemsContainer>
              <ListContainer>
                {isStudentData?.data?.studentList.map((item) => (
                  <List
                    key={item.childId}
                    onClick={() => handleChildClick(item.childId)}
                    active={activeChildId === item.childId}
                  >
                    {item.gender === 'M' ? (
                      <BoyImg
                        fill={
                          activeChildId === item.childId ? '#FFFFFF' : '#65696D'
                        }
                      />
                    ) : (
                      <GirlImg
                        fill={
                          activeChildId === item.childId ? '#FFFFFF' : '#65696D'
                        }
                      />
                    )}
                    <ListInfo active={activeChildId === item.childId}>
                      <h1>{item.name}</h1>
                      <h2>
                        {item.grade}학년 {item.clazz}반 {item.number}번
                      </h2>
                    </ListInfo>
                  </List>
                ))}
              </ListContainer>
              <div>
                {selectedChildId !== null && (
                  <div>
                    {isStudentData?.data?.studentList.map((item) => {
                      if (item.childId === selectedChildId) {
                        return (
                          <DetailCotainer key={item.childId}>
                            <DetailStudent>
                              <DetailGender>
                                {item.gender && <GirlImg fill="grey" />}
                              </DetailGender>
                              <DetailName>{item.name}</DetailName>
                              <DetailCalss>
                                {item.grade}학년 {item.clazz}반{item.number}번
                              </DetailCalss>
                            </DetailStudent>
                            <DetailWeekResult>
                              <WeekTitle>주간 통합 훈련 결과</WeekTitle>
                              {isDetailData !== undefined && (
                                <ResultCheck
                                  bgColor={
                                    isDetailData?.force.trainingTime >= 125
                                      ? 'linear-gradient(180deg, #3467FF 0%, #5451FF 100%)'
                                      : isDetailData?.force.trainingTime === 0
                                      ? '#65696D'
                                      : '#F0A95A'
                                  }
                                >
                                  {isDetailData?.force.trainingTime >= 125
                                    ? '목표 달성'
                                    : isDetailData.force.trainingTime === 0
                                    ? '미실시'
                                    : '진행중'}
                                </ResultCheck>
                              )}
                            </DetailWeekResult>
                            {isDetailData && (
                              <>
                                <Time>
                                  <div>훈련 시간</div>
                                  <span>
                                    {isDetailData?.force.trainingTime >= 125
                                      ? 125
                                      : isDetailData?.force.trainingTime ?? 0}
                                    분 / 125분
                                  </span>
                                </Time>
                                <ChartContainer>
                                  <DefaultChart></DefaultChart>
                                  <Chart
                                    width={`${
                                      isDetailData?.force.trainingTime * 4
                                    }px`}
                                    bgColor={
                                      isDetailData?.force.trainingTime >= 125
                                        ? '#3467FF'
                                        : isDetailData.force.trainingTime === 0
                                        ? '#65696D'
                                        : '#F0A95A'
                                    }
                                    trainingTime={
                                      isDetailData?.force.trainingTime
                                    }
                                  />
                                </ChartContainer>
                              </>
                            )}
                            <GameResultTitle>훈련 성취도</GameResultTitle>
                            <GameResult>
                              <ShutterWrapper>
                                <ShutterContainer>
                                  <RunnerImg />
                                </ShutterContainer>
                                <p>캐롯러너</p>
                                <span>{isDetailData?.force.carrotCount}점</span>
                              </ShutterWrapper>
                              <ShutterWrapper>
                                <ShutterContainer>
                                  <ShutterImg />
                                </ShutterContainer>
                                <p>컬러슈터</p>
                                <span>{isDetailData?.force.colorCount}점</span>
                              </ShutterWrapper>
                            </GameResult>
                          </DetailCotainer>
                        );
                      }
                      return null;
                    })}
                  </div>
                )}
              </div>
            </ItemsContainer>
          ) : (
            <NodataContainer>
              <h1>관리 학생이 없습니다.</h1>
              <p>새로운 학생을 등록 또는 연결해보세요.</p>
              <button
                onClick={() => handleModalClick('newStudentRegistration')}
              >
                학생 등록하기
              </button>
            </NodataContainer>
          )}
          {modalComponent && (
            <ModalComponent
              someProp="example"
              handleModalClick={handleModalClick}
            />
          )}
        </Wrapper>
      )}
    </>
  );
}

const Wrapper = styled.div`
  width: 721px;
  height: 352px;
  padding: 24px 30px;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.08);
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Title = styled.div`
  color: #000;

  font-size: 20px;

  font-weight: 700;
  line-height: 1.6em; /* 32px */
  letter-spacing: -0.3px;
`;

const CalendarContainer = styled.div`
  position: relative;
  z-index: 5;
`;

const DateDropDown = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 260px;
  height: 8px;
  padding: 16px 20px;
  margin-bottom: 12px;
  border-radius: 10px;
  border: 1px solid #e2e2ea;
`;

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 224px;
  height: 290px;
  border-right: 1px solid #e2e2ea;
  overflow-y: scroll;
`;

const DropDownImg = styled.div`
  position: absolute;
  right: 17.5px;
  width: 12px;
  height: 8px;
  background: url(/images/shavron.png) no-repeat center;
  background-size: contain;
`;

const List = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;
  height: 45.6px;
  width: 160px;
  padding: 8px 20px;
  margin-bottom: 12px;
  border-radius: 12px;
  background: ${(props) =>
    props.active
      ? 'linear-gradient(180deg, #3467FF 0%, #5451FF 100%)'
      : '#F6F6F6'};
  cursor: pointer;
`;

const ListInfo = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  color: ${(props) => (props.active ? '#fff' : '#292d32')};

  h1 {
    /* body/B04-14px_bold */

    font-size: 14px;

    font-weight: 700;
    line-height: 1.6em; /* 22.4px */
  }

  h2 {
    font-size: 12px;

    font-weight: 400;
    line-height: 1.6em; /* 19.2px */
  }
`;

const ItemsContainer = styled.div`
  display: flex;
  width: 100%;
`;

const DetailCotainer = styled.div`
  padding: 30px 24px;
`;

const DetailStudent = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 40px;
`;

const DetailGender = styled.div`
  width: 33px;
  height: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background: #f6f6f6;
`;

const DetailName = styled.div`
  margin: 0 13px;
  color: #000;
  font-size: 18px;

  font-weight: 700;
`;

const DetailCalss = styled.div`
  color: #000;
  font-size: 14px;

  font-weight: 400;
`;

const WeekTitle = styled.div`
  color: #000;
  font-size: 18px;

  font-weight: 700;
  line-height: 1.6em; /* 28.8px */
`;

const GameResultTitle = styled.div`
  color: #000;
  font-size: 18px;

  font-weight: 700;
  line-height: 1.6em; /* 28.8px */
  margin-bottom: 10px;
`;

const DetailWeekResult = styled.div`
  display: flex;
  align-items: center;
`;

const ResultCheck = styled.div<{ bgColor: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 23px;
  margin-left: 12px;
  border-radius: 100px;
  background: ${(props) => props.bgColor};

  color: #fff;
  font-size: 12px;
  font-weight: 700;
`;

const Time = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 449px;
  margin-top: 4px;
  margin-bottom: 10px;
`;

const ChartContainer = styled.div`
  position: relative;
  margin-top: 10px;
`;

const DefaultChart = styled.div`
  width: 450px;
  height: 16px;
  border-radius: 8px;
  background: #f6f6f6;
  margin-bottom: 26px;
`;

const Chart = styled.div<{
  trainingTime: number;
  bgColor: string;
  width: string;
}>`
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 8px;
  margin-bottom: 46px;
  width: ${(props) => props.width};
  height: 16px;
  background-color: ${(props) => props.bgColor};
  z-index: 3;
  max-width: 470px;
`;

const GameResult = styled.div`
  display: flex;
`;

const ShutterWrapper = styled.div`
  width: 220px;
  display: flex;
  align-items: center;

  & > p {
    margin: 0 8px;
    color: #000;

    font-size: 14px;

    font-weight: 400;
    line-height: 1.6em; /* 22.4px */
  }

  & > span {
    color: #000;

    font-size: 14px;

    font-weight: 700;
    line-height: 1.6em; /* 22.4px */
  }
`;

const ShutterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 8px;
  background: #292d32;
`;

const NodataContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & > h1 {
    margin-top: 75px;
    margin-bottom: 4px;
    color: #65696d;
    text-align: center;

    /* heading/H06-18px-bold */

    font-size: 18px;

    font-weight: 700;
    line-height: 1.6em; /* 28.8px */
  }

  & > p {
    margin-bottom: 20px;
    color: #65696d;
    text-align: center;

    font-size: 14px;

    font-weight: 500;
    line-height: 1.6em; /* 22.4px */
  }

  & > button {
    width: 200px;
    height: 56px;
    border-radius: 10px;
    background-color: #2c55fb;

    color: #fff;
    text-align: center;

    /* body/B03-16px-bold */

    font-size: 16px;

    font-weight: 700;
    line-height: 1.3em; /* 20.8px */
  }
`;
