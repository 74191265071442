import styled from '@emotion/styled';
import { useEffect, useState } from 'react';

import { SearchChildData } from '~/types/push/search';
import { weekPlayInfoData } from '~/types/student/force';

import { getSearchChildStatusData } from '~/api/push/search';
import { getSendMessageStatusData } from '~/api/push/send';

import useAuth from '~/hooks/useAuth';

import SendPushList from '~/components/studentManagement/enhancement/StudentManagementSendPush/StudentManagementSendPushList';
import SendPushModal from '~/components/studentManagement/enhancement/StudentManagementSendPush/StudentManagementSendPushModal';

const NewPushData = [
  { key: 1, label: '제목', placeholder: '제목을 입력해주세요' },
  {
    key: 2,
    label: '내용',
    placeholder:
      '푸시 내용을 입력해주세요. 내용은 최대 30자까지 입력 가능합니다.',
  },
];

interface NewSendPushProps {
  data: weekPlayInfoData[] | undefined;
  setActiveComponent: React.Dispatch<React.SetStateAction<string>>;
}

interface PushData {
  content: string;
  title: string;
  receiverIdList: [] | undefined;
}

export default function Index({ data, setActiveComponent }: NewSendPushProps) {
  const [isData, setIsData] = useState(data);
  const [isPushListData, setIsPushListData] = useState<SearchChildData>();
  const [isPushData, setIsPushData] = useState<PushData>({
    content: '',
    title: '',
    receiverIdList: [],
  });
  const [isListModal, setIsListModal] = useState(false);
  const [isModal, setIsModal] = useState(false);
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [isErrorMessage, setIsErrorMessage] = useState({
    name: false,
    title: false,
    info: false,
  });
  const { user } = useAuth();

  const handleRemoveChild = (userId: number) => {
    if (isData) {
      const updatedChildInfo = isData.filter((item) => item.userId !== userId);
      setIsData(updatedChildInfo);
    }
  };

  const handleAddChild = (userId: number) => {
    if (isData && isPushListData?.childInfo) {
      const childToAdd = isPushListData.childInfo.find(
        (item) => item.userId === userId,
      );

      if (childToAdd) {
        const isUserIdPresent = isData.some((item) => item.userId === userId);

        if (!isUserIdPresent && Array.isArray(isData)) {
          setIsData((prevData: weekPlayInfoData[] | undefined) => [
            ...(prevData || []),
            childToAdd as unknown as weekPlayInfoData,
          ]);
        }
      }
    }
  };

  const handlePushMessage = () => {
    if (!user) {
      return;
    }
    const userList = (isData ?? [])
      .map((item) => item.userId)
      .filter(Boolean) as number[];
    setIsPushData((prevState) => ({
      ...prevState,
      userList: userList,
    }));

    if (isData?.length === 0) {
      setIsErrorMessage((prevErrors) => ({
        ...prevErrors,
        name: true,
      }));
      return;
    } else {
      setIsErrorMessage((prevErrors) => ({
        ...prevErrors,
        name: false,
      }));
    }

    getSendMessageStatusData({
      content: isPushData.content,
      pushType: 'TEXT',
      senderId: user.userId,
      title: isPushData.title,
      receiverIdList: userList,
    })
      .then(({ data }) => {
        if (data.status === 20053) {
          if (isPushData.title.length === 0) {
            setIsErrorMessage((prevErrors) => ({
              ...prevErrors,
              title: true,
            }));
          } else {
            setIsErrorMessage((prevErrors) => ({
              ...prevErrors,
              title: false,
            }));
          }
          if (isPushData.content.length === 0) {
            setIsErrorMessage((prevErrors) => ({
              ...prevErrors,
              info: true,
            }));
          } else {
            setIsErrorMessage((prevErrors) => ({
              ...prevErrors,
              info: false,
            }));
          }
        } else {
          setIsErrorMessage((prevErrors) => ({
            ...prevErrors,
            name: false,
            title: false,
            info: false,
          }));
          setIsModal(true);
        }
      })
      .catch(console.error);
  };

  const handleTitleValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setIsPushData((prevState) => ({
      ...prevState,
      title: value,
    }));
  };

  const handleContentValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setIsPushData((prevState) => ({
      ...prevState,
      content: value,
    }));
  };

  const handleTitleBlur = () => {
    if (isPushData.title.trim().length === 0) {
      setIsErrorMessage((prevErrors) => ({
        ...prevErrors,
        title: true,
      }));
    } else {
      setIsErrorMessage((prevErrors) => ({
        ...prevErrors,
        title: false,
      }));
    }
  };

  const handleContentBlur = () => {
    if (isPushData.content.trim().length === 0) {
      setIsErrorMessage((prevErrors) => ({
        ...prevErrors,
        info: true,
      }));
    } else {
      setIsErrorMessage((prevErrors) => ({
        ...prevErrors,
        info: false,
      }));
    }
  };

  const handleListModal = () => {
    setIsListModal((prev) => !prev);
  };

  useEffect(() => {
    if (!user) {
      return;
    }
    getSearchChildStatusData({ userId: user.userId })
      .then(({ data }) => {
        setIsPushListData(data.data);
      })
      .catch(console.error);
  }, [user]);

  return (
    <>
      <Background></Background>
      <Wrapper>
        <Container>
          <Title>
            <Icon />
            <div>새 푸시 보내기</div>
          </Title>
          <Items>
            <Labels>
              수신자
              <span>*</span>
            </Labels>
            <NameContainer
              borderColor={
                isErrorMessage.name === true ? '#FF4752' : '#e2e2ea;'
              }
            >
              <ChildContainer>
                {isData?.map((item) => (
                  <Child key={item.userId}>
                    <div>
                      {item.name}(
                      {item.loginId && item.loginId.length > 6
                        ? `${item.loginId.substring(0, 6)}...`
                        : item.loginId}
                      )
                    </div>
                    <DeleteIcon
                      onClick={() =>
                        item.userId && handleRemoveChild(item.userId)
                      }
                    />
                  </Child>
                ))}
              </ChildContainer>
              <PlusIcon onClick={handleListModal} />
            </NameContainer>
            {isErrorMessage.name === true ? (
              <Error>수신자를 등록해 주세요.</Error>
            ) : (
              <Error></Error>
            )}
            {NewPushData.map((item) => (
              <div key={item.key}>
                <Labels>
                  {item.label}
                  <span>*</span>
                </Labels>
                <Input
                  type="text"
                  onBlur={
                    item.label === '제목' ? handleTitleBlur : handleContentBlur
                  }
                  placeholder={item.placeholder}
                  maxLength={30}
                  name={item.label === '제목' ? 'content' : 'title'}
                  onChange={
                    item.label === '제목'
                      ? handleTitleValue
                      : handleContentValue
                  }
                  borderColor={
                    item.label === '제목' && isErrorMessage.title === true
                      ? '#FF4752'
                      : item.label === '내용' && isErrorMessage.info === true
                      ? '#FF4752'
                      : '#e2e2ea;'
                  }
                />
                {item.label === '제목' && (
                  <div>
                    {isErrorMessage.title === true ? (
                      <Error>제목을 입력해주세요.</Error>
                    ) : (
                      <Error></Error>
                    )}
                  </div>
                )}
                {item.label === '내용' && (
                  <div>
                    {isErrorMessage.info === true ? (
                      <Error>내용을 입력해주세요.</Error>
                    ) : (
                      <Error></Error>
                    )}
                  </div>
                )}
              </div>
            ))}
          </Items>
          <ButtonContainer>
            <Button
              onClick={() => setActiveComponent('')}
              bgColor="#fff"
              color="#2c55fb"
            >
              취소
            </Button>
            <Button onClick={handlePushMessage} bgColor="#2c55fb" color="#fff">
              보내기
            </Button>
          </ButtonContainer>
        </Container>
        {isListModal && (
          <SendPushList
            isPushListData={isPushListData}
            isData={isData}
            setIsData={setIsData}
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
            handleRemoveChild={handleRemoveChild}
            handleAddChild={handleAddChild}
            handleListModal={handleListModal}
          />
        )}
      </Wrapper>
      {isModal && <SendPushModal setActiveComponent={setActiveComponent} />}
    </>
  );
}

const Wrapper = styled.div`
  position: absolute;
  min-width: 1920px;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 4;
`;

const Background = styled.div`
  z-index: 3;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
`;

const Container = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 720px;
  height: calc(100vh - 120px);
  min-height: 960px;
  padding: 60px;
  border-radius: 20px 0px 0px 20px;
  background: #fff;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 58px;
  color: #000;
  font-size: 24px;

  font-weight: 700;
  line-height: 1.6em;
`;

const Icon = styled.div`
  width: 24px;
  height: 24px;
  margin-right: 12px;
  background: url(/images/Union.png) no-repeat center;
  background-size: contain;
`;

const Items = styled.div`
  width: 720px;
  height: 746px;

  color: #000;
  font-size: 14px;

  font-weight: 500;
  line-height: 1.6em;

  span {
    color: #0062ff;
  }
`;

const Input = styled.input<{ borderColor: string }>`
  width: 695px;
  height: 52px;
  padding: 0;
  padding-left: 22px;
  border-radius: 10px;
  border: 1px solid ${(props) => props.borderColor};
  margin-bottom: 6px;

  color: #000;
  font-size: 16px;

  font-weight: 400;
  line-height: 1.6em;

  ::placeholder {
    color: #b5b5be;
    font-size: 16px;

    font-weight: 400;
    line-height: 1.6em;
  }
`;

const Labels = styled.div`
  margin-top: 2px;
  margin-bottom: 9px;
`;

const ChildContainer = styled.div`
  display: flex;
  overflow-x: scroll;
  width: 588px;
  margin-left: 20px;

  ::-webkit-scrollbar {
    height: 4px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 100px;
    background: #e2e2ea;
  }
`;

const Child = styled.div`
  display: flex;
  align-items: center;
  height: 25px;
  margin: 13.5px 10px 5px 0;
  border-radius: 100px;
  border: 1px solid #d1d1d1;
  background: #fff;

  color: #000;
  font-size: 12px;

  font-weight: 400;
  line-height: 1.6em;

  & > div {
    display: flex;
    align-items: center;
    width: 110px;
    height: 19px;
    margin-left: 12px;
    margin-right: 4px;
  }
`;

const DeleteIcon = styled.button`
  width: 14px;
  height: 14px;
  background: url(/images/delete.png) no-repeat center;
  background-size: contain;
  margin-right: 12px;
`;

const NameContainer = styled.div<{ borderColor: string }>`
  position: relative;
  width: 717px;
  height: 54px;
  margin-bottom: 6px;
  border-radius: 10px;
  border: 1px solid ${(props) => props.borderColor};
`;

const ButtonContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 60px;
  width: 720px;
  height: 56px;
`;

const PlusIcon = styled.button`
  position: absolute;
  top: 21px;
  right: 0;
  width: 12px;
  height: 12px;
  background: url(/images/blackPlus.png) no-repeat center;
  background-size: contain;
  margin-right: 20px;
  z-index: 99;
`;

const Button = styled.button<{ bgColor: string }>`
  width: 200px;
  height: 56px;
  border-radius: 10px;
  border: 1px solid #2c55fb;
  color: ${(props) => props.color};
  background-color: ${(props) => props.bgColor};

  font-size: 16px;

  font-weight: 700;
  line-height: 1.3em;

  :nth-of-type(1) {
    margin-right: 12px;
  }
`;

const Error = styled.div`
  height: 22px;
  color: #ff4752;
  font-size: 14px;

  font-weight: 500;
  line-height: 1.6em; /* 22.4px */
`;
