import styled from '@emotion/styled';
import { useLocation, useNavigate } from 'react-router-dom';

import { ReactComponent as JoyCog105 } from '~/assets/JoyCog140.svg';
import { ReactComponent as DashboardImg } from '~/assets/dashboard.svg';
import { ReactComponent as GroupImg } from '~/assets/group.svg';
import { ReactComponent as SendImg } from '~/assets/send.svg';
import { ReactComponent as StudentImg } from '~/assets/student.svg';

export const MENU_LIST = [
  {
    key: 1,
    id: 'dashBoard',
    label: '대시보드',
    to: 'dashboard',
    icon: <DashboardImg />,
  },
  {
    key: 2,
    id: 'studentManagement',
    label: '소속 학생 관리',
    to: 'student-management/student/measurement',
    icon: <StudentImg />,
  },
  {
    key: 3,
    id: 'institutionInformation',
    label: '소속 기관 정보',
    to: 'institution-information',
    icon: <GroupImg />,
  },
  {
    key: 4,
    id: 'mailBox',
    label: '우편함',
    to: 'mail-box/send-mail',
    icon: <SendImg />,
  },
  {
    key: 5,
    id: 'check-profile',
    label: '프로필 수정',
    to: 'check-profile',
  },
  {
    key: 6,
    id: 'myProfile',
    label: '프로필 수정',
    to: 'change-profile',
  },
  {
    key: 7,
    id: 'check-password',
    label: '비밀번호 변경',
    to: 'check-password',
  },
  {
    key: 8,
    id: 'change-password',
    label: '비밀번호 변경',
    to: 'change-password',
  },
  { key: 9, id: 'activityLog', label: '활동로그', to: 'activity-log' },
];

const Index = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const pathName = location.pathname;

  return (
    <Wrapper>
      <button onClick={() => navigate('dashboard')}>
        <JoyCog105 style={{ marginTop: '57px' }} />
      </button>

      {MENU_LIST.map((item) => (
        <div key={item.key}>
          {item.id !== 'check-profile' &&
            item.id !== 'myProfile' &&
            item.id !== 'change-password' &&
            item.id !== 'check-password' &&
            item.id !== 'activityLog' && (
              <Items
                active={pathName.includes(item.to.slice(0, 7)) ? true : false}
                onClick={() => navigate(item.to)}
              >
                <div>{item.icon}</div>
                <div>{item.label}</div>
              </Items>
            )}
        </div>
      ))}
    </Wrapper>
  );
};

export default Index;

const Items = styled.div<{ active: boolean }>`
  width: 220px;
  height: 46px;
  padding-left: 14px;
  margin-bottom: 16px;

  display: flex;
  align-items: center;

  border-radius: 8px;
  color: ${(props) => (props.active ? '#fff' : ' #171819')};
  background: ${(props) =>
    props.active ? 'linear-gradient(90deg, #3467ff 0%, #5451ff 100%)' : '#fff'};
  & svg {
    fill: ${(props) => (props.active ? '#fff' : ' #171819')};
  }

  & > div {
    :nth-of-type(2) {
      margin-left: 12px;

      font-size: 16px;

      font-weight: 700;
      line-height: 1.3em; /* 20.8px */
    }
  }

  cursor: pointer;
`;

const Wrapper = styled.div`
  height: 100vh;

  display: flex;
  flex-direction: column;

  padding: 0 24px;
  background: #fff;
  border-right: 1px solid #e7e7e7;

  @media (max-height: 1079px) {
    height: 1080px;
  }

  & > button {
    margin-bottom: 190px;
  }
`;
