import styled from '@emotion/styled';
import { useState } from 'react';
import { NavLink, Outlet } from 'react-router-dom';

import NewStudentConnection from '~/components/studentManagement/NewStudentConnection';
import NewStudentRegistration from '~/components/studentManagement/NewStudentRegistration';

const INFO_ITEMS = [
  { key: 1, id: 'measurement', label: '인지능력 측정', to: './measurement' },
  {
    key: 2,
    id: 'enhancement',
    label: '인지능력 강화',
    to: './enhancement',
  },
];

interface Components {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: React.ComponentType<any>;
}

const modalComponents: Components = {
  newStudentConnection: NewStudentConnection,
  newStudentRegistration: NewStudentRegistration,
};

export default function Index() {
  const [modalComponent, setModalComponent] = useState('');

  const ModalComponent = modalComponents[modalComponent];
  const handleModalClick = (id: string) => {
    setModalComponent(id);
  };

  return (
    <Wrapper>
      <TitleContainer>
        <div>관리 학생 목록</div>
        <div>
          <TitleButton
            color="#0062ff"
            bgColor="#fff"
            onClick={() => handleModalClick('newStudentConnection')}
          >
            <ButtonImg url="/images/link.png" />새 학생 연결하기
          </TitleButton>
          <TitleButton
            bgColor="#0062ff"
            color="#fff"
            onClick={() => handleModalClick('newStudentRegistration')}
          >
            <ButtonImg url="/images/whitePlus.png" />
            학생 등록하기
          </TitleButton>
        </div>
      </TitleContainer>
      <ItemsContainer>
        {INFO_ITEMS.map((item) => (
          <StyledNavLink key={item.key} to={item.to}>
            <div>{item.label}</div>
          </StyledNavLink>
        ))}
      </ItemsContainer>
      {modalComponent && <ModalComponent handleModalClick={handleModalClick} />}
      <Outlet />
    </Wrapper>
  );
}

const StyledNavLink = styled(NavLink)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 54px;
  color: #000;
  background-color: #fff;
  border-radius: 100px;
  font-size: 18px;

  font-weight: 500;

  &.active {
    color: #0062ff;
    font-weight: 700;
    font-size: 18px;
    line-height: 1.6em;
    background-color: #f0f6ff;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 1544px;
  height: 786px;
  margin: 20px 30px 30px 30px;
  padding: 20px 24px 24px 24px;
  border-radius: 12px;
  background: #fff;
`;

const TitleButton = styled.button<{ bgColor: string }>`
  position: relative;
  display: flex;
  align-items: center;
  padding: 15px 25px;
  gap: 10px;
  border-radius: 10px;
  border: 1px solid #0062ff;
  color: ${(props) => props.color};
  background-color: ${(props) => props.bgColor};
  text-align: center;
  font-size: 16px;

  font-weight: 500;
`;
const ButtonImg = styled.div<{ url: string }>`
  width: 12px;
  height: 12px;
  background: url(${(props) => props.url}) no-repeat center;
  background-size: center;
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  & > div {
    color: #000;
    font-size: 24px;

    font-weight: 700;
    line-height: 1.6em;
    display: flex;

    & > button {
      :nth-of-type(1) {
        margin-right: 20px;
      }
    }
  }
`;

const ItemsContainer = styled.div`
  display: flex;
  margin-bottom: 24px;
`;
