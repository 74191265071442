import styled from '@emotion/styled';
import React from 'react';

export default function PrivacyPolicyPage() {
  return (
    <Wrapper>
      <h1>개인정보 처리방침</h1>
      <div>
        ㈜이모티브 (이하 ‘회사’) 은(는) 「정보통신망 이용촉진 및 정보보호에 관한
        법률」 및 「개인정보보호법」에 따라 이용자의 개인정보를 보호하고 이와
        관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이
        개인정보 처리방침을 수립·공개합니다. 본 개인정보처리방침은 회사가
        제공하는 ‘JoyCog’ 웹 사이트 및 어플리케이션(이하 ‘서비스’) 에 적용되며
        다음과 같은 내용을 포함합니다.
        <br />
        제1조 (수집하는 개인정보의 항목)
        <br />
        1. 수집하는 개인정보의 항목 회사는 서비스 제공을 위해 최소한의
        개인정보를 수집하고 있습니다.
        <br />
        회원 가입 시 또는 서비스 이용 과정, C/S 처리 과정에서 서비스 이용 기록,
        접속 로그, 쿠키, 접속 IP, 결제 기록, 부정 이용 기록이 등이 자동으로
        생성되어 수집될 수 있습니다.
        <br />
        가. JoyCog 웹 사이트 가입 시 필수 항목으로 휴대전화 번호, 비밀번호,
        이름, 이메일, 서비스 가입 내역을 수집합니다. 이는 이용자 식별, 고지사항
        전달, 서비스 이용 및 상담 등 원활한 의사소통 경로 확보, 부정이용 방지
        등을 목적으로 수집됩니다.
        <br />
        나. JoyCog 웹 사이트 본인 인증 시 필수 항목으로 이름, 성별, 생년월일,
        휴대전화번호, 통신사업자, 내/외국인 여부, 암호화된 이용자 확인값(CI),
        중복가입확인정보(DI)를 수집합니다. 이는 이용자 식별 및 부정이용 방지를
        위해 수집됩니다.
        <br />
        다. 신용카드 결제 시 필수 항목으로 카드 번호(일부)와 카드사명 등을
        수집합니다. 이는 정량화 데이터 분석을 위해 수집됩니다.
        <br />
        라. JoyCog 아동 계정 생성 시 필수 항목으로 이름과 학급정보, 생년월일,
        성별, 비밀번호 등을 수집합니다. 이는 인지능력측정과 인지능력강화 정량화
        데이터 분석을 위해 수집됩니다.
        <br />
        2. 수집방법 개인정보를 수집하는 경우에는 원칙적으로 사전에 이용자에게
        해당 사실을 고지 후 동의를 구하고 있으며, 아래와 같은 방법을 통해
        개인정보를 수집합니다.
        <br />
        가. 회원가입 및 서비스 이용 과정에서 이용자가 개인정보 수집에 대해
        동의를 하고 직접 정보를 입력하는 경우
        <br />
        나. 제휴사로부터 개인정보를 제공받은 경우
        <br />
        다. 고객과의 상담 시 웹페이지, 메일, 전화, 챗봇 등<br />
        3. 민감정보 수집 및 이용
        <br />
        가. 회사는 민감정보를 맞춤 서비스 제공, 서비스 이용에 대한 분석 및 통계
        제공, 연구 및 통계분석자료 활용 등의 목적을 위해 취급합니다. 취급한
        민감정보는 해당 목적 이외의 용도로는 사용 되지 않으며, 이용 목적이
        변경될 시에는 사전 동의를 구할 예정입니다.
        <br />
        나. 수집되는 민감정보는 주의력 저하/과다 행동 인지 능력 측정에 대한 평가
        척도 기록, 강화 컨텐츠 이용 기록입니다.
        <br />
        다. 수집된 민감정보는 탈퇴 시 폐기됩니다.(단, 탈퇴 시 개인 식별 항목
        폐기 후 통계 자료로 활용됩니다.)
        <br />
        라. 동의 거부의 권리
        <br />
        ‘JoyCog’의 민감정보 수집 및 이용에 대한 동의 거부권이 있으며, 동의 거부
        시에는 ‘JoyCog’ 서비스 이용이 불가능합니다.
        <br />
        ‘JoyCog’가 사용자의 인지 데이터를 기반으로 서비스를 제공하기 위해
        이용자의 민감정보(건강정보)를 수집, 이용하는 것에 동의한다면 개인정보
        처리 방침 동의 항목을 체크해주시기 바랍니다.
        <br />
        제2조 (개인정보의 수집 및 이용목적)
        <br />
        회사는 다음의 목적을 위하여 개인정보를 수집합니다. 수집 목적과
        합리적으로 관련된 범위에서는 법령에 따라 이용자의 동의 없이 개인정보를
        이용하거나 제3자에게 제공할 수 있습니다. 이때 ‘당초 수집 목적과 관련성이
        있는지, 수집한 정황 또는 처리 관행에 비추어 볼 때 개인정보의 추가적인
        이용 또는 제공에 대한 예측 가능성이 있는지, 이용자의 이익을 부당하게
        침해 하는지, 가명처리 또는 암호화 등 안전성 확보에 필요한 조치를
        하였는지’를 종합적으로 고려합니다.
        <br />
        회사는 수집한 개인정보를 특정 개인을 알아볼 수 없도록 가명처리하여
        통계작성, 과학적 연구, 공익적 기록보존 등을 위하여 처리할 수 있습니다.
        이 때 가명정보는 재 식별 되지 않도록 추가정보와 분리하여 별도
        저장・관리하고 필요한 기술적・관리적 보호조치를 취합니다.
        <br />
        1. 회원가입 및 관리
        <br />
        서비스 이용과 회원제 서비스 이용에 따른 본인확인, 개인 식별, 불량회원의
        부정 이용 방지와 비인가 사용 방지, 가입 의사 확인, 연령확인, 만 14세
        미만 아동 개인정보 수집 시 법정대리인 동의 여부 확인, 분쟁 조정을 위한
        기록보존, 고지사항 전달의 목적으로 개인정보를 처리합니다.
        <br />
        2. 서비스 제공
        <br />
        콘텐츠 제공, 맞춤 서비스 제공, 구매 및 요금 결제, 금융거래 본인 인증 및
        금융 서비스를 목적으로 개인정보를 처리합니다.
        <br />
        3. 마케팅 및 광고에 활용
        <br />
        신규 서비스(제품) 개발 및 맞춤 서비스 제공, 이벤트 및 광고성 정보 제공,
        서비스의 유효성 확인, 접속 빈도 파악 또는 회원의 서비스 이용에 대한
        통계를 목적으로 개인정보를 처리합니다.
        <br />
        제3조 (개인정보의 보유 및 이용 기간)
        <br />
        1. 회사 내부방침에 의한 정보 보유 사유
        <br />
        회사에서 서비스를 제공하는 동안 회원의 개인정보는 서비스 제공을 위해
        이용하게 됩니다. 다만 회원이 회사에서 지정한 절차에 따라 탈퇴(ID를
        삭제)한 경우 해당 정보를 지체 없이 파기합니다. 다만, 회사는 불량회원의
        부정한 이용의 재발을 방지하기 위해, 이용계약 해지일로부터 1년 간 해당
        회원의 [이름, 기관코드, 아이디, 비밀번호, 전화번호, 이메일 주소]를
        보유할 수 있습니다.
        <br />
        2. 관련 법령에 의한 정보 보유 사유
        <br />
        관련 법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 아래와 같이
        관련 법령에서 정한 일정한 기간 동안 회원정보를 보관합니다.
        <br />
        가. 표시/광고에 관한 기록 : 6개월 (전자상거래 등에서의 소비자보호에 관한
        법률)
        <br />
        나. 계약 또는 청약철회 등에 관한 기록 : 5년 (전자상거래 등에서의
        소비자보호에 관한 법률)
        <br />
        다. 대금결제 및 재화 등의 공급에 관한 기록 : 5년 (전자상거래 등에서의
        소비자보호에 관한 법률)
        <br />
        라. 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년 (전자상거래 등에서의
        소비자보호에 관한 법률)
        <br />
        마. 신용정보의 수집/처리 및 이용 등에 관한 기록 : 3년 (신용정보의 이용
        및 보호에 관한 법률)
        <br />
        바. 로그 기록 : 3개월 (통신비밀보호법)
        <br />
        개인정보의 열람 및 수정에 대해 회원은 언제든지 자신의 개인정보에 대한
        열람 및 수정을 요청할 수 있습니다.
        <br />
        제4조 (개인정보의 파기절차 및 방법)
        <br />
        회사는 원칙적으로 개인정보 수집 및 이용목적이 달성되고 보유 및 이용
        기간이 경과된 후에는 해당 정보를 파기합니다.
        <br />
        1. 파기절차
        <br />
        회원이 회원가입을 위해 입력한 정보는 목적이 달성된 후 내부방침 및 기타
        관련 법령에 의한 사유에 따라(위 제3조 2항 참조) 일정 기간 보관 후
        파기됩니다.
        <br />
        2. 파기방법
        <br />
        수집 및 이용 목적의 달성 또는 회원 탈퇴 등 파기 사유가 발생한 경우
        개인정보의 형태를 고려하여 파기방법을 정합니다. 전자적 파일 형태인 경우
        복구 및 재생되지 않도록 안전하게 삭제하고, 그 밖에 기록물, 인쇄물, 서면
        등의 경우 분쇄하거나 소각하여 파기합니다.
        <br />
        제5조 (개인정보처리 위탁)
        <br />
        회사는 전문적인 고객지원 및 서비스 제공을 위해 아래와 같이 개인정보 처리
        업무를 외부 업체에 위탁하여 운영하고 있습니다. 위탁계약 시
        개인정보보호에 안전을 기하기 위하여 개인정보보호 관련 지시 엄수,
        개인정보에 관한 유출금지 및 사고 시 책임부담 등을 명확히 규정하고
        위탁계약 내용에 포함합니다.
        <br />
        1. AWS
        <br />
        가. 개인정보 업무를 수탁받는 자(개인정보를 이전받는 법인) : AWS(Amazon
        Web Services Inc.)
        <br />
        나. 이전되는 자의 개인정보 항목 : 휴대전화번호, 서비스 이용 기록 및
        수집된 개인정보
        <br />
        다. 개인정보를 이전받는 자의 개인정보 이용목적 : 개인정보 보관기간 동안
        개인정보가 보관된 클라우드 서버 운영 및 관리
        <br />
        라. 개인정보를 이전받는 자의 개인정보 보유/이용기간 : 현재 회사가 이용
        중인 클라우드 서비스 이용 변경 시까지
        <br />
        마. 개인정보 이전일시 및 방법 : 서비스 이용 시점에 네트워크를 통한 전송
        <br />
        바. 개인정보를 이전받는 법인의 정보관리책임자의 연락처 : 국내대리인
        제너럴에이전트 주식회사(대표 김영수) / AWS Korea Privacy / 주소: 서울
        종로구 새문안로5가길 28 / TEL: 02-735-6888 / e-mail:
        aws-korea-privacy@amazon.com
        <br />
        제6조 (이용자 및 법정대리인의 권리와 그 행사방법)
        <br />
        1. 이용자는 회사에 대해 언제든지 개인정보 열람·정정·삭제·처리정지 요구
        등의 권리를 행사할 수 있습니다.
        <br />
        2. 제1항에 따른 권리 행사는 회사에 대해 「개인정보보호법」 시행령 제41조
        제1항에 따라 서면, 전자우편, FAX 등을 통하여 할 수 있으며 회사는 이에
        대해 지체 없이 조치하겠습니다.
        <br />
        3. 제1항에 따른 권리 행사는 이용자의 법정대리인이나 위임을 받은 자 등
        대리인을 통해 진행할 수 있습니다. 이 경우 “개인정보 처리 방법에 관한
        고시(제2020-7호)” 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.
        <br />
        4. 개인정보 열람 및 처리정지 요구는 「개인정보보호법」 제35조 제4항,
        제37조 제2항에 의하여 이용자의 권리가 제한될 수 있습니다.
        <br />
        5. 개인정보의 정정 및 삭제 요구는 법령에 따라(위 제3조 2항 참조)
        개인정보 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수
        없습니다.
        <br />
        6. 회사는 이용자의 권리에 따른 열람 요구, 정정·삭제 요구, 처리 정지 요구
        시 요구한 자가 본인이거나 정당한 대리인인지 확인합니다.
        <br />
        제7조 (개인정보의 자동 수집 장치의 설치·운영 및 그 거부에 관한 사항)
        <br />
        당사는 이용자의 정보를 수시로 저장하고 찾아내는 ‘쿠키(cookie)‘를
        사용합니다. 쿠키란 당사 웹 사이트를 운영하는데 이용되는 서버가 귀하의
        브라우저에 보내는 아주 작은 텍스트 파일로서 이용자의 PC에 저장됩니다.
        <br />
        1. 쿠키 사용 목적
        <br />
        가. 개인화되고 맞춤화된 서비스를 제공하기 위해서 이용자의 정보를
        저장하고 수시로 불러오는 쿠키를 사용합니다. 이용자가 웹사이트에 방문할
        경우 웹 사이트 서버는 이용자의 디바이스에 저장되어 있는 쿠키를 읽어
        이용자의 환경설정을 유지하고 맞춤화된 서비스를 제공하게 됩니다. 쿠키는
        이용자가 웹 사이트를 방문할 때, 웹 사이트 사용을 설정한대로 접속하고
        편리하게 사용할 수 있도록 돕습니다. 또한, 이용자의 웹사이트 방문 기록,
        이용 형태를 통해서 최적화된 광고 등 맞춤형 정보를 제공하기 위해
        활용됩니다.
        <br />
        나. 이용자는 쿠키 설치에 대한 선택권을 가지고 있으며, 쿠키 설정을 통해
        쿠키 허용 및 거부를 할 수 있습니다.
        <br />
        2. 쿠키 설정 거부 방법 가. 웹브라우저 상단의 ‘설정 &gt; 개인정보보호
        &gt; 쿠키 및 기타 사이트 데이터’ 경로에서 쿠키 설정을 통해 쿠키 거부를
        할 수 있습니다.
        <br />
        나. 다만, 쿠키 설치를 거부할 경우 웹 사용이 불편해지며, 로그인이 필요한
        일부 서비스 이용이 어려울 수 있습니다.
        <br />
        제8조(개인정보 보호를 위한 안전성 확보 조치)
        <br />
        회사는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.
        <br />
        1. 정기적인 자체 감사 실시
        <br />
        개인정보 취급 관련 안정성 확보를 위해 정기적(분기 1회)으로 자체 감사를
        실시하고 있습니다.
        <br />
        2. 내부관리계획의 수립 및 시행
        <br />
        개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고
        있습니다.
        <br />
        3. 개인정보의 암호화
        <br />
        이용자의 개인정보와 비밀번호는 암호화되어 저장 및 관리되고 있어, 본인만
        알 수 있으며 중요한 데이터는 파일 및 전송 데이터를 암호화하거나 파일
        잠금 기능을 사용하는 등 별도 보안 기능을 사용하고 있습니다.
        <br />
        4. 개인정보에 대한 접근 제한
        <br />
        개인정보를 처리하는 데이터베이스시스템에 대한 접근 권한의 부여, 변경,
        말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고
        있으며 침입 차단 시스템을 이용하여 외부로부터 무단 접근을 통제하고
        있습니다.
        <br />
        제9조 (개인정보 보호 책임자)
        <br />
        회사는 회원의 개인정보를 보호하고, 개인정보와 관련한 불만을 처리하기
        위하여 아래와 같이 관련 부서 및 개인정보보호책임자를 지정하고 있습니다.
        회원의 개인정보와 관련한 문의사항이 있으시면 아래의
        개인정보보호책임자에게 연락 주시기 바랍니다.
        <br />▶ 개인정보보호책임자
        <br />
        성명: 민정상 직책: 대표 연락처: 02-508-2813 이메일: info@emotiv.kr
        <br />
        제10조 (개인정보처리방침 변경에 관한 사항)
        <br />
        법령이나 서비스의 변경사항을 반영하기 위한 목적 등으로 개인정보
        처리방침을 수정할 수 있습니다. 개인정보 처리방침이 변경되는 경우 최소
        7일 전 변경 사항을 사전에 안내 하겠습니다.
        <br />
        제11조 (가명정보 처리에 관한 사항)
        <br />
        1. 회사는 개인정보보호법 제28조의 2, 제28조의 3에 근거하여 가명정보를
        처리하고 있습니다.
        <br />
        2. 회사는 수집한 개인정보를 추가 정보가 없이는 특정 개인을 알아볼 수
        없도록 가명처리하여 통계작성, 과학적 연구 등을 위하여 이용할 수
        있습니다. 이 때 가명정보는 개인정보보호법 제28조의 4에 근거하여 안전한
        조치를 취하고 있습니다.
        <br />
        3. 회사는 가명정보를 제3자에게 제공하는 경우, 업무 처리 목적에 필요한
        가명정보를 제공합니다. 이 경우 가명정보 재식별 금지, 가명정보 재제공
        또는 재위탁 제한 등 안전성 확보를 위한 준수사항이 반영된 문서(서약서,
        계약서 등)을 작성하고 있습니다.
        <br />
        제12조 (개인정보에 관한 민원 서비스)
        <br />
        기타 개인정보침해에 대한 신고나 상담이 필요하신 경우 아래 기관에
        문의하시기 바랍니다.
        <br />
        1. 개인정보분쟁조정위원회: (국번없이) 1833-6972 (www.kopico.go.kr)
        <br />
        2. 개인정보침해신고센터: (국번없이) 118 (privacy.kisa.or.kr)
        <br />
        3. 대검찰청: (국번없이) 1301 (www.spo.go.kr)
        <br />
        4. 경찰청: (국번없이) 182 (ecrm.cyber.go.kr)
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow-y: scroll;

  & > h1 {
    width: 700px;
    margin-top: 90px;
    margin-bottom: 27px;
    color: #111827;

    font-size: 24px;

    font-weight: 700;
    line-height: 1.6em; /* 38.4px */
  }

  & > div {
    margin-bottom: 90px;
    width: 700px;
    color: #111827;

    font-size: 16px;

    font-weight: 500;
    line-height: 1.6em; /* 25.6px */
  }
`;
