import { AxiosResponse } from 'axios';

import { DashServiceParams, DashServiceResponse } from '~/types/dash/service';

import api from '~/api/base';

const BASE_URL = 'api/v1/dash/find';

export const getDashServiceStatusData = (
  param: DashServiceParams,
): Promise<AxiosResponse<DashServiceResponse>> =>
  api.post(`${BASE_URL}/service`, param);
