import {
  FindChildInfoRequestParams,
  FindChildInfoResponse,
  ModifyChildInfoRequestParams,
  ModifyChildInfoResponse,
} from '~/types/student/edit';

import api from '~/api/base';

const BASE_URL = 'api/v1/student';

export const getFindChildInfoStatusData = (param: FindChildInfoRequestParams) =>
  api.post<FindChildInfoResponse>(`${BASE_URL}/find/student`, param);

export const getModifyChildInfoStatusData = (
  param: ModifyChildInfoRequestParams,
) => api.post<ModifyChildInfoResponse>(`${BASE_URL}/edit/student`, param);
