import styled from '@emotion/styled';
import { useEffect, useState } from 'react';

import { AttachmentVo } from '~/types/post/read';
import { ReceiveMailData } from '~/types/post/receive';

import { ReactComponent as ReceiveMailIcon } from '~/assets/envelope-open.svg';
import { ReactComponent as ReceiveCloseMailIcon } from '~/assets/envelope.svg';

import dateFilter from '~/utils/dateFilter/dateFilter';

import { getReadMailStatusData } from '~/api/post/read';
import { getReceiveMailStatusData } from '~/api/post/receive';

import useAuth from '~/hooks/useAuth';

import Loading from '~/pages/Loading';

export default function ReceiveMailBox() {
  const [isData, setIsData] = useState<ReceiveMailData>();
  const [itemStates, setItemStates] = useState<{ [postId: number]: boolean }>(
    {},
  );
  const [searchKeyword, setSearchKeyword] = useState('');
  const [isModal, setIsModal] = useState('');
  const [isReceiveItem, setIsReceiveItem] = useState<AttachmentVo[]>();
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useAuth();
  useEffect(() => {
    if (!user) {
      return;
    }

    const fetchReceiveMailStatusData = async () => {
      try {
        const { data } = await getReceiveMailStatusData({
          userId: user?.userId,
        });
        setIsData(data.data);
        setIsLoading(true);
      } catch (error) {
        console.error(error);
      }
    };

    fetchReceiveMailStatusData();
  }, [isReceiveItem]);

  const toggleItemState = (postId: number) => {
    setItemStates((prev) => ({
      ...prev,
      [postId]: !prev[postId],
    }));
  };

  const handleGetAllItems = async () => {
    if (!user) {
      return;
    }

    try {
      const { data } = await getReadMailStatusData({
        receiveType: 'A',
        userId: user?.userId,
        postId: null,
      });
      setIsReceiveItem(data?.data?.attachmentVo);
      setIsModal('All');
    } catch (error) {
      console.error(error);
    }
  };

  const handleReceiveItem = async (postId: number) => {
    if (!user) {
      return;
    }

    try {
      const { data } = await getReadMailStatusData({
        receiveType: 'S',
        userId: user?.userId,
        postId: postId,
      });
      setIsReceiveItem(data?.data?.attachmentVo);
      setIsModal('item');
    } catch (error) {
      console.error(error);
    }
  };

  const splitContentIntoChunks = (content: string) => {
    const chunkSize = 60;
    const regex = new RegExp(`.{1,${chunkSize}}`, 'g');
    return content.replace(regex, (chunk) => chunk + '\n');
  };

  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchKeyword(e.target.value);
  };

  const handleModalClick = () => {
    setIsModal('');
  };

  return (
    <Wrapper>
      {isLoading ? (
        <>
          {isData?.receivePost?.length !== 0 ? (
            <>
              <Title>
                <TitleText>받은 우편함</TitleText>
                <button onClick={handleGetAllItems}>
                  <ButtonImg></ButtonImg>
                  <ButtonText>첨부된 선물 모두 받기</ButtonText>
                </button>
              </Title>
              <InputContainer>
                <input
                  type="text"
                  placeholder="찾을 우편의 제목을 입력해주세요"
                  value={searchKeyword}
                  onChange={handleSearchInputChange}
                />
                <InputImg />
              </InputContainer>
              <ItemsWrapper>
                {isData?.receivePost?.map((item) => {
                  if (!searchKeyword || item.title.includes(searchKeyword)) {
                    return (
                      <ItemsContainerBorder
                        key={item.postId}
                        color={item.postStatus === 'M' ? '#fff' : '#F6F6F6'}
                      >
                        <ItemsContainer
                          onClick={() => toggleItemState(item.postId)}
                        >
                          {itemStates[item.postId] ? (
                            <ReceiveMailIcon
                              fill={
                                item.postStatus === 'M' ? '#0062FF' : '#B5B5BE'
                              }
                            />
                          ) : (
                            <ReceiveCloseMailIcon
                              fill={
                                item.postStatus === 'M' ? '#000' : '#B5B5BE'
                              }
                            />
                          )}
                          <ItemsTitleContainer>
                            <ItemsTitle
                              color={
                                itemStates[item.postId] &&
                                item.postStatus === 'M'
                                  ? '#0062FF'
                                  : item.postStatus === 'C'
                                  ? '#65696D'
                                  : '#000'
                              }
                            >
                              {item.title}
                            </ItemsTitle>
                            <ItemsSubTitle
                              color={
                                itemStates[item.postId] &&
                                item.postStatus === 'M'
                                  ? '#000'
                                  : '#65696D'
                              }
                            >
                              {item.subTitle}
                            </ItemsSubTitle>
                          </ItemsTitleContainer>
                          <ItemsDate
                            color={
                              itemStates[item.postId] && item.postStatus === 'M'
                                ? '#0062FF'
                                : item.postStatus === 'C'
                                ? '#65696D'
                                : '#000'
                            }
                          >
                            <span>{dateFilter(item.sendDate)}</span>
                            <ItemsToggleImg
                              url={
                                itemStates[item.postId]
                                  ? '/images/shavronUp.png'
                                  : '/images/shavron.png'
                              }
                            />
                          </ItemsDate>
                        </ItemsContainer>
                        {itemStates[item.postId] && (
                          <ItemsContent>
                            <ContentList>
                              <ContentTitle
                                color={
                                  itemStates[item.postId] &&
                                  item.postStatus === 'M'
                                    ? '#000'
                                    : '#65696D'
                                }
                              >
                                {splitContentIntoChunks(item.content)}
                              </ContentTitle>
                              <Containt>
                                {item.m001 !== 0 &&
                                item.m002 !== 0 &&
                                item.m003 !== 0 ? (
                                  <div>
                                    첨부된 골드 {item.m001}개와 이용권
                                    {item.m003}
                                    장과 다이아 {item.m002}개를 받았습니다.
                                  </div>
                                ) : item.m001 === 0 &&
                                  item.m002 !== 0 &&
                                  item.m003 !== 0 ? (
                                  <div>
                                    첨부된 이용권 {item.m003}장과 다이아
                                    {item.m002}
                                    개를 받았습니다.
                                  </div>
                                ) : item.m001 === 0 &&
                                  item.m002 === 0 &&
                                  item.m003 !== 0 ? (
                                  <div>
                                    첨부된 이용권 {item.m003}장을 받았습니다.
                                  </div>
                                ) : item.m001 === 0 &&
                                  item.m002 !== 0 &&
                                  item.m003 === 0 ? (
                                  <div>
                                    첨부된 다이아 {item.m002}개를 받았습니다.
                                  </div>
                                ) : item.m001 !== 0 &&
                                  item.m002 === 0 &&
                                  item.m003 === 0 ? (
                                  <div>
                                    첨부된 골드 {item.m001}개를 받았습니다.
                                  </div>
                                ) : (
                                  <div></div>
                                )}
                                <ContentDate
                                  mgLeft={
                                    item.receiptDate !== null ? '27px' : ''
                                  }
                                >
                                  {item.receiptDate !== null &&
                                    dateFilter(item.receiptDate)}
                                </ContentDate>
                              </Containt>
                            </ContentList>

                            {item.postStatus === 'M' && (
                              <ReceiveButton
                                onClick={() => handleReceiveItem(item.postId)}
                              >
                                <ReceiveImg />
                                선물 받기
                              </ReceiveButton>
                            )}
                          </ItemsContent>
                        )}
                      </ItemsContainerBorder>
                    );
                  } else {
                    return null;
                  }
                })}
              </ItemsWrapper>
              {isModal === 'All' && (
                <>
                  {isReceiveItem?.[0] !== undefined ? (
                    <Modal>
                      <div>
                        <h1>우편을 모두 수령했습니다.</h1>
                        <h2>
                          첨부된
                          <span> 다이아 {isReceiveItem[0].itemCount}개</span>를
                          받았습니다.
                        </h2>
                        <button onClick={handleModalClick}>확인</button>
                      </div>
                    </Modal>
                  ) : (
                    <Modal>
                      <div>
                        <h1>첨부된 선물을 모두 받았습니다.</h1>
                        <h2>수령할 아이템이 없습니다.</h2>
                        <button onClick={handleModalClick}>확인</button>
                      </div>
                    </Modal>
                  )}
                </>
              )}
              {isModal === 'item' && (
                <>
                  {isReceiveItem?.[0] && (
                    <Modal>
                      <div>
                        <h1>우편을 수령했습니다.</h1>
                        <h2>
                          첨부된
                          <span> 다이아 {isReceiveItem[0].itemCount}개</span>를
                          받았습니다.
                        </h2>
                        <button onClick={handleModalClick}>확인</button>
                      </div>
                    </Modal>
                  )}
                </>
              )}
            </>
          ) : (
            <NodataWrapper>
              <Title>
                <TitleText>받은 우편함</TitleText>
              </Title>
              <NoDataContainer>
                <div>
                  <NoDataImg></NoDataImg>
                  <span>받은 우편이 아직 없습니다.</span>
                  <div>
                    운영자가 보내는 소식과 선물을 이곳에서 확인하실 수 있습니다.
                  </div>
                </div>
              </NoDataContainer>
            </NodataWrapper>
          )}
        </>
      ) : (
        <Loading />
      )}
    </Wrapper>
  );
}

const Modal = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);

  div {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 436px;
    height: 143px;
    padding: 32px;
    background-color: #fff;
    border-radius: 16px;

    color: #000;
    font-size: 18px;

    font-weight: 700;
    line-height: 1.3em;
  }

  h2 {
    color: #000;

    font-size: 18px;

    font-weight: 500;
    line-height: 1.6em;
    margin-bottom: 32px;
  }

  span {
    color: #0062ff;
    font-size: 18px;

    font-weight: 700;
    line-height: 1.3em;
  }

  button {
    width: 200px;
    height: 50px;
    border-radius: 10px;
    background: #0062ff;
    color: #fff;

    font-size: 16px;

    font-weight: 700;
    line-height: 1.3em; /* 20.8px */
  }
`;

const NodataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const NoDataContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 440px;
  height: 372px;
  margin: 0 24px 24px 24px;
  padding: 223px 446px;
  border-radius: 16px;
  background: #f6f6f6;

  & > div {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 278px;
    height: 278px;
    border-radius: 278px;
    background: linear-gradient(
      180deg,
      rgba(52, 103, 255, 0.08) 0%,
      rgba(84, 81, 255, 0) 100%
    );

    span {
      margin-top: 23.5px;
      margin-bottom: 15px;
      color: #65696d;
      text-align: center;
      font-size: 20px;

      font-weight: 700;
      line-height: 1.6em;
      letter-spacing: -0.3px;
    }

    div {
      color: #65696d;
      text-align: center;
      font-size: 18px;

      font-weight: 500;
      line-height: 1.6em;
      width: 480px;
    }
  }
`;

const NoDataImg = styled.div`
  width: 111px;
  height: 92px;
  margin-top: 81px;
  margin-left: 70px;
  margin-right: 96px;
  background: url(/images/ReceiveImg.png) no-repeat center;
  background-size: contain;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 940px;
`;

const Title = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 36px;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 206px;
    height: 50px;
    margin: 24px 24px 0 0;
    border-radius: 10px;
    border: 1px solid #0062ff;
    background: #0062ff;
  }
`;

const TitleText = styled.div`
  margin: 24px 0 0 24px;
  font-size: 24px;

  font-weight: 700;
  line-height: 1.6em;
`;

const ButtonImg = styled.div`
  width: 12px;
  height: 12px;
  margin-right: 7px;
  background: url(/images/whiteCheck.png) no-repeat center;
  background-size: contain;
`;

const ButtonText = styled.div`
  color: #fff;
  font-size: 16px;

  font-weight: 500;
  line-height: 1.3em;
`;

const InputContainer = styled.div`
  position: relative;
  padding-bottom: 20px;
  border-bottom: 1px solid #e2e2ea;

  input {
    width: 1263px;
    height: 26px;
    padding: 16px 47px 16px 20px;
    border-radius: 10px;
    border: 1px solid #e2e2ea;

    color: #000;
    font-size: 14px;

    font-weight: 500;
    line-height: 1.6em;

    ::placeholder {
      color: #8c9299;
      font-size: 14px;

      font-weight: 500;
      line-height: 1.6em;
    }
  }
`;

const InputImg = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  width: 17px;
  height: 17px;
  background: url(/images/search.png) no-repeat center;
  background-size: contain;
`;

const ItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 732px;
  width: 1332px;
  margin-bottom: 24px;
  overflow-y: scroll;
  overflow-x: hidden;
`;

const ItemsContainerBorder = styled.div`
  border-bottom: 1px solid #e2e2ea;
  background-color: ${(props) => props.color};
`;

const ItemsContainer = styled.div`
  display: flex;
  width: 1284px;
  height: 51px;
  padding: 16px 24px 6px 24px;
  cursor: pointer;
`;

const ItemsTitleContainer = styled.div`
  width: 1045px;
  margin-left: 24px;

  font-size: 14px;

  font-weight: 700;
  line-height: 1.6em;
  div {
    :nth-of-type(1) {
      height: 26px;
    }
    :nth-of-type(2) {
      height: 19px;
    }
  }
`;

const ItemsSubTitle = styled.div`
  color: ${(props) => props.color};
`;

const ItemsTitle = styled.div`
  color: ${(props) => props.color};
`;

const ItemsToggleImg = styled.div<{ url: string }>`
  width: 10px;
  height: 20px;
  margin-left: 15px;
  background: url(${(props) => props.url}) no-repeat center;
  background-size: contain;
`;

const ItemsDate = styled.div`
  display: flex;
  margin-left: 24px;
  width: 167px;
  margin-top: 16px;
  color: ${(props) => props.color};
  font-size: 14px;

  font-weight: 400;
  line-height: 1.6em;
  span {
    width: 123px;
  }
`;

const ItemsContent = styled.div`
  display: flex;
  position: relative;
  width: 1284px;
  padding: 0px 24px 16px 24px;
  color: #000;
  font-size: 14px;

  font-weight: 500;
  line-height: 1.6em;
`;

const ContentList = styled.div`
  margin-left: 48px;
`;

const ContentTitle = styled.div`
  color: ${(props) => props.color};
`;

const Containt = styled.div`
  display: flex;
  margin-top: 12px;
  padding: 6px 16px;
  border-radius: 10px;
  background: #f0f6ff;

  div {
    color: #0062ff;
    font-size: 14px;

    font-weight: 700;
  }
`;

const ContentDate = styled.span<{ mgLeft: string }>`
  margin-left: ${(props) => props.mgLeft};
  color: #0062ff;
  font-size: 14px;

  font-weight: 400;
`;

const ReceiveButton = styled.button`
  display: flex;
  align-items: center;
  position: absolute;
  top: 17px;
  right: 77px;
  width: 100px;
  height: 34px;
  border-radius: 6px;
  border: 1px solid #0062ff;
  color: #fff;

  background-color: #0062ff;
  font-size: 14px;

  font-weight: 500;
`;

const ReceiveImg = styled.div`
  width: 14px;
  height: 14px;
  margin-left: 15.5px;
  margin-right: 6px;
  background: url(/images/whiteCheck.png) no-repeat center;
  background-size: contain;
`;
