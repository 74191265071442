import styled from '@emotion/styled';
import { useEffect, useState } from 'react';

import { PushFindData } from '~/types/push/find';
import { PushItem } from '~/types/push/search';

import { ReactComponent as Plus } from '~/assets/plus.svg';

import dateFilter from '~/utils/dateFilter/dateFilter';

import { getPushFindStatusData } from '~/api/push/find';
import { getSearchListStatusData } from '~/api/push/search';

import useAuth from '~/hooks/useAuth';

import NewSendPush from '~/components/mailBox/sendPushBox/NewSendPush';
import NewSendPushDefault from '~/components/mailBox/sendPushBox/NewSendPushDefault';
import RecipientList from '~/components/mailBox/sendPushBox/RecipientList';

export default function Index() {
  const [isModal, setIsModal] = useState(false);
  const [pushList, setPushList] = useState<PushItem[]>([]);
  const [isPushId, setIsPushId] = useState<number | undefined>();
  const [activePushId, setActivePushId] = useState<number | null>(null);
  const [data, setData] = useState<PushFindData>();
  const [searchPushList, setSearchPushList] = useState('');
  const [activeComponent, setActiveComponent] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const { user } = useAuth();

  useEffect(() => {
    const fetchDataForPushId = async () => {
      if (isPushId !== undefined) {
        try {
          const pushFindData = await getPushFindStatusData({
            pushId: isPushId,
          });

          setActivePushId(isPushId);
          setData(pushFindData.data.data);
        } catch (error) {
          console.error(error);
        }
      }
    };

    fetchDataForPushId();
  }, [isPushId]);

  const fetchData = async () => {
    if (!user) {
      return;
    }

    try {
      const { data } = await getSearchListStatusData({
        userId: user.userId,
      });

      const reversedPushList = data?.data?.pushList?.reverse();

      setPushList(reversedPushList);

      if (reversedPushList?.length !== 0) {
        setIsPushId(reversedPushList[0]?.pushId);
      } else {
        setIsLoading(true);
      }

      setIsLoading(true);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleModal = () => {
    setIsModal((prev) => !prev);
  };

  const handleListPushId = (pushId: number | undefined) => {
    setIsPushId(pushId);

    getPushFindStatusData({ pushId })
      .then(({ data }) => {
        setData(data.data);
      })
      .catch(console.error);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchPushList(event.target.value);
  };

  const filteredPushList = pushList?.filter((item) =>
    item.pushTitle.toLowerCase().includes(searchPushList?.toLowerCase()),
  );

  const DetailNameList = () => {
    if (!data || !data.childInfo || pushList === undefined) return null;

    const maxItemsToShow = 10;
    const totalItems = data.childInfo.length;

    const mappedItems = data.childInfo
      .slice(0, maxItemsToShow)
      .map((item, index) => {
        const isLastItem = index === maxItemsToShow - 1;
        const separator =
          isLastItem || index === Math.min(maxItemsToShow - 1, totalItems - 1)
            ? ''
            : ', ';

        return (
          <span key={item.childId}>
            <DetailNames>
              {item.name}({item.loginId}){separator}
            </DetailNames>
          </span>
        );
      });

    if (totalItems > maxItemsToShow) {
      return (
        <>
          {mappedItems}
          <span>... + {totalItems - maxItemsToShow}</span>
        </>
      );
    }

    return <>{mappedItems}</>;
  };

  const moveComponent = (id: string) => {
    setActiveComponent(id);
  };

  return (
    <Wrapper>
      {isLoading && (
        <>
          {pushList?.length === 0 ? (
            <>
              <TopContainer>
                <div>보낸 푸시함</div>
                <TopContainerItems>
                  <ButtonSection>
                    <TopContainerImg />
                    <span>
                      학생에게 푸시를 보내면, 해당 학생 계정으로 로그인 되어있는
                      단말에 알림이 전송됩니다.
                    </span>
                  </ButtonSection>
                  <button onClick={() => moveComponent('NewSendPushDefault')}>
                    <Plus />
                    <p>새 푸시 보내기</p>
                  </button>
                </TopContainerItems>
              </TopContainer>
              <NoData>
                <Round></Round>
                <PushImg />
                <h1>보낸 푸시가 아직 없습니다.</h1>
                <h2>
                  새 푸시 보내기 버튼을 클릭하여 학생에게 푸시를 보내보세요!
                </h2>
                <span>
                  <div>TIP</div>
                </span>
                <h3>
                  인지능력 강화 훈련을 독려하는 푸시를 보내보세요! 학생의
                  참여율을 높일 수 있어요!
                </h3>
                <button onClick={() => moveComponent('NewSendPushDefault')}>
                  새 푸시 보내기
                </button>
              </NoData>
            </>
          ) : (
            <>
              <TopContainer>
                <div>보낸 푸시함</div>
                <button onClick={() => moveComponent('NewSendPushDefault')}>
                  <Plus />
                  <p>새 푸시 보내기</p>
                </button>
              </TopContainer>
              <ItemsContainer>
                <ItemList>
                  <InputContainer>
                    <input
                      placeholder="찾을 푸시의 제목을 입력해주세요"
                      type="text"
                      value={searchPushList}
                      onChange={handleSearchChange}
                    />
                    <SearchIcon />
                  </InputContainer>
                  <ListContainer />
                  <ItemListScrollContainer>
                    {filteredPushList?.map((item) => (
                      <div key={item.pushId}>
                        <List
                          onClick={() => handleListPushId(item.pushId)}
                          active={activePushId === item.pushId}
                        >
                          <ListTitle active={activePushId === item.pushId}>
                            {Array.isArray(item.childName) && (
                              <>
                                {item.childName.slice(0, 3).join(', ')}
                                {item.childName.length > 3 &&
                                  ` + ${item.childName.length - 3}`}
                              </>
                            )}
                          </ListTitle>
                          <ListInfo active={activePushId === item.pushId}>
                            {item.pushTitle}
                          </ListInfo>
                          <ListDate active={activePushId === item.pushId}>
                            {dateFilter(item.pushDate)}
                          </ListDate>
                        </List>
                      </div>
                    ))}
                  </ItemListScrollContainer>
                </ItemList>
                <ItemWrapper>
                  <ItemTitle>
                    <ItemName>{DetailNameList()}</ItemName>
                    <ItemDate>{dateFilter(data?.pushDate)}</ItemDate>
                    <ItemIconButton onClick={handleModal}></ItemIconButton>
                    {isModal && (
                      <Modal>
                        <button onClick={() => moveComponent('RecipientList')}>
                          수신자 리스트 보기
                        </button>
                        <button onClick={() => moveComponent('NewSendPush')}>
                          해당 아동에게 새 푸시 보내기
                        </button>
                      </Modal>
                    )}
                  </ItemTitle>
                  <ItemInfo>
                    <InfoTitle>{data?.pushTitle}</InfoTitle>
                    <InfoText>{data?.pushContent}</InfoText>
                  </ItemInfo>
                </ItemWrapper>
              </ItemsContainer>
            </>
          )}
          {activeComponent === 'RecipientList' && (
            <RecipientList
              setActiveComponent={setActiveComponent}
              isPushId={isPushId}
              childInfo={data?.childInfo}
            />
          )}
          {activeComponent === 'NewSendPush' && (
            <NewSendPush
              data={data?.childInfo}
              setActiveComponent={setActiveComponent}
            />
          )}
          {activeComponent === 'NewSendPushDefault' && (
            <NewSendPushDefault setActiveComponent={setActiveComponent} />
          )}
        </>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const TopContainer = styled.div`
  width: 1332px;
  height: 50px;
  display: flex;
  justify-content: space-between;
  margin: 24px 0;

  color: #000;
  font-size: 24px;

  font-weight: 700;
  line-height: 1.6em;

  button {
    width: 160px;
    height: 50px;
    border-radius: 10px;
    border: 1px solid #0062ff;
    background: #0062ff;

    color: #fff;
    font-size: 16px;

    font-weight: 500;
    line-height: 1.3em;
    margin-left: 12px;

    p {
      margin-left: 7px;
      display: inline;
    }
  }
`;

const TopContainerItems = styled.div`
  display: flex;
`;

const ButtonSection = styled.div`
  display: flex;
  align-items: center;
  color: #8c9299;
  font-size: 14px;

  font-weight: 500;
  background: #f6f6f6;
  border-radius: 8px;
  span {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    margin-right: 12px;
  }
`;

const TopContainerImg = styled.div`
  width: 24px;
  height: 24px;
  padding-left: 12px;
  padding-right: 4px;
  background: url(/images/question-circle.png) no-repeat center;
  background-size: center;
`;

const ItemsContainer = styled.div`
  display: flex;
`;

const ItemList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 450px;
  height: 818px;

  input {
    width: 410px;
    height: 58px;
    padding: 0;
    padding-left: 20px;
    border-radius: 10px;
    border: 1px solid #e2e2ea;
    margin-top: 12px;
    margin-bottom: 20px;

    ::placeholder {
      color: #8c9299;
      font-size: 14px;

      font-weight: 500;
      line-height: 1.6em;
    }
  }
`;

const InputContainer = styled.div`
  position: relative;
`;

const SearchIcon = styled.div`
  position: absolute;
  width: 17px;
  height: 17px;
  top: 34px;
  right: 20px;
  background: url(/images/search.png) no-repeat center;
  background-size: contain;
`;

const ItemWrapper = styled.div`
  width: 882px;
  height: 818px;
  border-left: 1px solid #e2e2ea;
  background: #f6f6f6;
`;

const ListContainer = styled.div`
  width: 100%;
  border-bottom: 1px solid #e2e2ea;
`;

const ItemListScrollContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
`;

const List = styled.button<{ active: boolean }>`
  display: flex;
  flex-direction: column;
  width: 418px;
  height: 111px;
  margin: 10px 0;
  border-radius: 16px;
  background: ${(props) => (props.active ? '#f0f6ff' : '#fff')};
`;

const ListTitle = styled.div<{ active: boolean }>`
  margin: 16px 16px 0 16px;
  color: ${(props) => (props.active ? '#0062FF' : '#000')};
  text-overflow: ellipsis;
  font-size: 16px;

  font-weight: 700;
  line-height: 1.6em;
`;

const ListInfo = styled.div<{ active: boolean }>`
  margin: 0 16px;
  color: ${(props) => (props.active ? '#0062FF' : '#000')};
  font-size: 14px;

  font-weight: 400;
  line-height: 1.6em;
`;

const ListDate = styled.div<{ active: boolean }>`
  margin: 12px 16px 16px 16px;
  color: ${(props) => (props.active ? '#0062FF' : '#b5b5be;')};
  font-size: 14px;

  font-weight: 400;
  line-height: 1.6em;
`;

const ItemTitle = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 834px;
  height: 90px;

  margin: 0 24px 24px 24px;
  border-bottom: 1px solid #e2e2ea;
`;

const ItemName = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 657px;
  height: 44px;
  margin-right: 24px;
  color: #65696d;
  font-size: 14px;

  font-weight: 500;
  line-height: 1.6em;
`;

const ItemDate = styled.div`
  width: 120px;
  height: 44px;
  margin-right: 16px;

  color: #8c9299;
  font-size: 14px;

  font-weight: 400;
  line-height: 1.6em;
`;

const ItemIconButton = styled.div`
  width: 24px;
  height: 24px;
  background: url(/images/dots.png) no-repeat center;
  background-size: contain;
  cursor: pointer;
`;

const ItemInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 834px;
  margin: 0 24px;
  border-radius: 16px;
  border: 1px solid #e2e2ea;
  background: #fff;
`;

const InfoTitle = styled.div`
  width: 786px;
  height: 29px;
  padding: 16px 0;
  border-bottom: 1px solid #e2e2ea;

  color: #111827;

  font-size: 18px;

  font-weight: 500;
  line-height: 1.6em;
`;

const InfoText = styled.div`
  width: 786px;
  padding: 16px 0;

  color: #292d32;
  font-size: 14px;

  font-weight: 500;
  line-height: 1.6em;
`;

const Modal = styled.div`
  position: absolute;
  right: -11px;
  top: 58px;
  width: 211px;
  height: 100px;
  background-color: #fff;
  border-radius: 8px;

  button {
    display: flex;
    width: 100%;
    padding: 14px 0 14px 26px;
    color: #000;
    font-size: 14px;

    font-weight: 500;
    line-height: 1.6em;

    :nth-of-type(1) {
      border-bottom: 1px solid #e2e2ea;
    }
  }
`;

const DetailNames = styled.span`
  margin-right: 5px;
`;

const NoData = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 1332px;
  height: 818px;
  border-radius: 16px;
  background: #f6f6f6;

  h1 {
    margin-top: 381.5px;
    margin-bottom: 15px;
    color: #65696d;
    font-size: 20px;

    font-weight: 700;
    line-height: 1.6em;
    letter-spacing: -0.3px;
  }

  h2 {
    margin-bottom: 46px;
    color: #65696d;
    font-size: 18px;

    font-weight: 500;
    line-height: 1.6em;
  }
  span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 37px;
    height: 22px;
    margin-bottom: 12px;
    color: #fff;
    border-radius: 6px;
    background: #5a88ff;
    font-size: 14px;

    font-weight: 500;
    line-height: 1.6em;

    div {
      margin-top: 4px;
    }
  }

  h3 {
    margin-bottom: 24px;
    color: #5a88ff;
    font-size: 16px;

    font-weight: 500;
    line-height: 1.3em;
  }

  button {
    width: 480px;
    height: 56px;
    border-radius: 10px;
    background: #0062ff;

    color: #fff;
    font-size: 18px;

    font-weight: 700;
    line-height: 1.3em;
  }
`;

const Round = styled.div`
  position: absolute;
  top: 179.5px;
  left: 527px;
  width: 278px;
  height: 278px;
  border-radius: 278px;
  background: linear-gradient(
    180deg,
    rgba(52, 103, 255, 0.08) 0%,
    rgba(84, 81, 255, 0) 100%
  );
`;

const PushImg = styled.div`
  width: 111px;
  height: 77px;
  top: 265px;
  left: 602.66px;
  position: absolute;
  background: url(/images/PushImg.png) no-repeat center;
  background-size: contain;
`;
