import { AxiosResponse } from 'axios';

import {
  ReadGetMailRequestParams,
  ReadGetMailResponse,
} from '~/types/post/read';

import api from '~/api/base';

const BASE_URL = 'api/v1/web/post/read';

export const getReadMailStatusData = (
  param: ReadGetMailRequestParams,
): Promise<AxiosResponse<ReadGetMailResponse>> =>
  api.post(`${BASE_URL}/get`, param);
