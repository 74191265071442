import { useState } from 'react';

const useValidation = () => {
  const [validationCheck, setValidationCheck] = useState<{
    [key: string]: boolean;
  }>({
    loginId: false,
    name: false,
    phone: false,
    email: false,
    info: false,
    licenseKey: false,
    contactNumber: false,
    introduct: false,
    checkPassword: false,
    newPassword: false,
  });

  const handleCheckboxChange = (names: string, checked: boolean) => {
    setValidationCheck((prevErrors) => ({ ...prevErrors, [names]: checked }));
  };

  const validateInput = (id: string, value: string) => {
    const validationRules: { [key: string]: RegExp } = {
      email: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
      info: /^.{1,30}$/,
      name: /^[가-힣]{2,6}$/,
      phone: /^[0-9]{9,12}$/,
      introduct: /^.{1,30}$/,
    };

    try {
      if (!validationRules[id].test(value)) {
        throw new Error();
      } else if (!validationCheck.licenseKey) {
        throw new Error();
      } else if (id === 'email' && value === '') {
        setValidationCheck((prevErrors) => ({ ...prevErrors, email: false }));
      } else if (id === 'phone' && value === '') {
        setValidationCheck((prevErrors) => ({ ...prevErrors, phone: false }));
      } else {
        setValidationCheck((prevErrors) => ({ ...prevErrors, [id]: false }));
      }
    } catch (error) {
      setValidationCheck((prevErrors) => ({ ...prevErrors, [id]: true }));
    }
  };

  return {
    validationCheck,
    setValidationCheck,
    validateInput,
    handleCheckboxChange,
  };
};

export default useValidation;
