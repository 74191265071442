import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { ChangeEvent } from 'react';
import ReactPaginate from 'react-paginate';

import { weekPlayInfoData } from '~/types/student/force';

import dateFilter from '~/utils/dateFilter/dateFilter';

interface PostsProps {
  itemsPerPage: number;
  handleUserCheckboxChange: (
    userId: number,
  ) => (event: ChangeEvent<HTMLInputElement>) => void;
  checkedUserIds: weekPlayInfoData[];
  moveComponent(id: string): void;
  setCheckedUserIds: (data: weekPlayInfoData[]) => void;
  filteredUsers: weekPlayInfoData[];
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
}

const Posts: React.FC<PostsProps> = ({
  itemsPerPage,
  handleUserCheckboxChange,
  checkedUserIds,
  moveComponent,
  setCheckedUserIds,
  filteredUsers,
  currentPage,
  setCurrentPage,
}) => {
  const pageCount: number = Math.ceil(filteredUsers?.length / itemsPerPage);

  const handlePageChange = (selectedItem: { selected: number }) => {
    setCurrentPage(selectedItem.selected);
  };

  const offset = currentPage * itemsPerPage;
  const currentItems = filteredUsers?.slice(offset, offset + itemsPerPage);

  const handleSendPush = (item: weekPlayInfoData) => {
    moveComponent('StudentManagementSendPush');
    setCheckedUserIds([item]);
  };

  const handleSendMail = (item: weekPlayInfoData) => {
    moveComponent('StudentManagementSendMail');
    setCheckedUserIds([item]);
  };

  return (
    <>
      <ItemsWrapper>
        {currentItems?.map((item) => {
          const isChecked = checkedUserIds.some(
            (user) => user.userId === item.userId,
          );

          return (
            <Items key={item.userId} bgColor={isChecked ? '#f0f6ff' : '#fff'}>
              <ItemsInput>
                <Input
                  type="checkbox"
                  name="userId"
                  checked={checkedUserIds.some(
                    (user) => user.userId === item.userId,
                  )}
                  onChange={handleUserCheckboxChange(item.userId)}
                />
                <div>{item.loginId}</div>
              </ItemsInput>
              <div>{item.name}</div>
              {item.gender === 'F' ? (
                <div>
                  <Gender>
                    <GenderImg url="/images/girl.png" />
                  </Gender>
                </div>
              ) : item.gender === 'M' ? (
                <div>
                  <Gender>
                    <GenderImg url="/images/boy.png" />
                  </Gender>
                </div>
              ) : (
                <div>-</div>
              )}
              <div>
                {item.grade}학년 {item.clazz}반 {item.number}번
              </div>
              <div>{item.weekPlayTime} / 125분</div>
              <div>
                {item.playTimeStatus === 'N' ? (
                  <PlayTime color="#65696D" bgColor="#e2e2ea;">
                    미실시
                  </PlayTime>
                ) : item.playTimeStatus === 'Y' ? (
                  <PlayTime color="#0CAF60" bgColor="#EFFCF6">
                    완료
                  </PlayTime>
                ) : (
                  <PlayTime color="#F0A95A" bgColor="#FFFBEC">
                    진행중
                  </PlayTime>
                )}
              </div>
              {item.lastPlayDate !== '0' ? (
                <div>{dateFilter(item.lastPlayDate)}</div>
              ) : (
                <div>-</div>
              )}
              <div>{item.carrotScore}개</div>
              <div>{item.colorScore}개</div>
              <div>
                <Button onClick={() => handleSendMail(item)}>
                  <ButtonImg url="/images/Mail.png" />
                  우편 보내기
                </Button>
                <Button onClick={() => handleSendPush(item)}>
                  <ButtonImg url="/images/new.png" />
                  푸시 보내기
                </Button>
              </div>
            </Items>
          );
        })}
      </ItemsWrapper>
      <PaginateButton>
        <Paginate
          previousLabel={'<'}
          nextLabel={'>'}
          breakLabel={'...'}
          breakClassName={'break-me'}
          pageCount={pageCount}
          marginPagesDisplayed={1}
          pageRangeDisplayed={4}
          onPageChange={handlePageChange}
          containerClassName={'pagination'}
          activeClassName={'active'}
        />
      </PaginateButton>
    </>
  );
};

export default Posts;

const PaginateButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
`;

const Paginate = styled(ReactPaginate)`
  display: flex;
  align-items: center;
  justify-content: center;

  ${css`
    li {
      color: #171819;
      font-size: 12px;

      font-weight: 400;
      line-height: 1.6em;
      cursor: pointer;
    }

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
    }

    li:not(.previous, .next, .break-me):hover {
      color: #2c55fb;
      background: #f0f6ff;
      border-radius: 8px;
    }
    li.active {
      border-radius: 8px;
      color: #2c55fb;
      background: #f0f6ff;
    }

    li.previous {
      border-radius: 8px;
      color: #c7c7c7;
      border: 1px solid #c7c7c7;
      margin-right: 24px;

      &:hover {
        color: #2c55fb;
        border: 1px solid #2c55fb;
      }
    }

    li.next {
      color: #c7c7c7;
      border: 1px solid #c7c7c7;
      border-radius: 8px;
      margin-left: 24px;

      &:hover {
        color: #2c55fb;
        border: 1px solid #2c55fb;
      }
    }
  `}
`;
const ItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 528px;

  color: #292d32;

  font-size: 14px;

  font-weight: 500;
  line-height: 1.6em;
`;

const Items = styled.div<{ bgColor: string }>`
  display: flex;
  border-bottom: 1px solid #f1f2f4;
  height: 63px;
  width: 1544px;
  background: ${(props) => props.bgColor};

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    font-size: 14px;

    font-weight: 400;

    :nth-of-type(1) {
      width: 100px;
    }
    :nth-of-type(2) {
      width: 100px;
    }
    :nth-of-type(3) {
      width: 140px;
    }
    :nth-of-type(4) {
      width: 140px;
    }
    :nth-of-type(5) {
      width: 140px;
    }
    :nth-of-type(6) {
      width: 200px;
    }
    :nth-of-type(7) {
      width: 140px;
    }
    :nth-of-type(8) {
      width: 140px;
    }
    :nth-of-type(9) {
      width: 244px;
    }
  }
`;

const Input = styled.input`
  width: 16px;
  height: 16px;
  margin-left: 16px;
  margin-right: 12px;
`;

const ItemsInput = styled.span`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 200px;
  height: 56px;
`;

const Gender = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 33px;
  height: 33px;
  border-radius: 10px;
  background: #f6f6f6;
`;

const GenderImg = styled.div<{ url: string }>`
  width: 14px;
  height: 20px;
  background: url(${(props) => props.url}) no-repeat center;
  background-size: center;
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 34px;
  border: 1px solid black;
  color: #0062ff;
  font-size: 14px;

  font-weight: 500;
  border-radius: 6px;
  border: 1px solid #0062ff;
  background: #fff;

  :nth-of-type(1) {
    margin-right: 15px;
  }
`;

const ButtonImg = styled.div<{ url: string }>`
  width: 14px;
  height: 20px;
  margin-right: 4px;
  background: url(${(props) => props.url}) no-repeat center;
  background-size: center;
`;

const PlayTime = styled.div<{ bgColor: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.bgColor};
  color: ${(props) => props.color};
  width: 84px;
  height: 30px;
  border-radius: 100px;
  font-size: 14px;

  font-weight: 700;
`;
