import styled from '@emotion/styled';
import { useEffect, useMemo, useState } from 'react';

import { SearchChildData } from '~/types/push/search';
import { SearchCognitiveStudentList } from '~/types/student/search';

interface Props {
  isPushListData: SearchChildData | undefined;
  isData: SearchCognitiveStudentList[] | undefined;
  handleRemoveChild: (userId: number) => void;
  handleAddChild: (userId: number) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setIsData: any;
  handleListModal(): void;
  selectedItems: number[];
  setSelectedItems: React.Dispatch<React.SetStateAction<number[]>>;
}

export default function StudentManagementSendMailList({
  isPushListData,
  isData,
  handleRemoveChild,
  handleAddChild,
  setIsData,
  handleListModal,
  selectedItems,
  setSelectedItems,
}: Props) {
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [searchText, setSearchText] = useState('');

  const allUserIds = useMemo(
    () =>
      isPushListData?.childInfo
        ? isPushListData.childInfo.map((item) => item.userId)
        : [],
    [isPushListData?.childInfo],
  );

  const filteredChildInfo = useMemo(
    () =>
      isPushListData && isData
        ? isPushListData.childInfo.filter(
            (item) => !isData.some((data) => data.userId === item.userId),
          )
        : [],
    [isPushListData, isData],
  );

  const updatedChildInfo = useMemo(
    () =>
      isData
        ? isData.filter(
            (item) =>
              !isPushListData?.childInfo.some(
                (data) => data.userId === item.userId,
              ),
          )
        : [],
    [isData, isPushListData?.childInfo],
  );

  const handleCheckboxChange = (userId: number) => {
    if (selectedItems.includes(userId)) {
      handleRemoveChild(userId);
      setSelectedItems((prevSelectedItems) =>
        prevSelectedItems.filter((id) => id !== userId),
      );
    } else {
      handleAddChild(userId);
      setSelectedItems((prevSelectedItems) => [...prevSelectedItems, userId]);
    }
  };

  const handleSelectAllCheckboxChange = () => {
    if (isPushListData && isData) {
      const allChecked = allUserIds.every((userId) =>
        selectedItems.includes(userId),
      );

      if (allChecked) {
        setSelectedItems([]);
        setIsData(updatedChildInfo);
      } else {
        const newSelectedItems = allUserIds.filter(
          (userId) => !selectedItems.includes(userId),
        );
        setSelectedItems([...selectedItems, ...newSelectedItems]);
        setIsData((prevData: SearchCognitiveStudentList[] | undefined) => [
          ...(prevData || []),
          ...filteredChildInfo,
        ]);
      }

      setSelectAllChecked(!allChecked);
    }
  };

  useEffect(() => {
    if (isData && isPushListData?.childInfo) {
      const matchingUserIds = isData.map((data) => data.userId);

      setSelectedItems(
        isPushListData.childInfo
          .filter((item) => matchingUserIds.includes(item.userId))
          .map((item) => item.userId),
      );
    }
  }, [isData, isPushListData?.childInfo]);

  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  return (
    <Wrapper>
      <div>
        <Input
          type="text"
          placeholder="찾을 학생의 ID 또는 이름을 입력해주세요."
          value={searchText}
          onChange={handleSearchInputChange}
          bdColor={searchText.length <= 0 ? '#e2e2ea;' : '#0062FF'}
        />
      </div>
      <ItemsTitle>
        <div>
          {isPushListData?.childInfo && (
            <input
              type="checkbox"
              name="all"
              checked={selectAllChecked}
              onChange={handleSelectAllCheckboxChange}
            />
          )}
          ID
        </div>
        <div>이름</div>
        <div>학급정보</div>
      </ItemsTitle>
      <ItemListWrapper>
        {isPushListData?.childInfo.map((item) => {
          if (
            !searchText ||
            item.loginId.includes(searchText) ||
            item.name.includes(searchText)
          ) {
            return (
              <ItemList
                key={item.userId}
                color={selectedItems.includes(item.userId) ? '#F0F6FF' : '#fff'}
              >
                <div>
                  <input
                    type="checkbox"
                    name="user"
                    checked={selectedItems.includes(item.userId)}
                    onChange={() => handleCheckboxChange(item.userId)}
                  />
                  <div>{item.loginId}</div>
                </div>
                <Item>{item.name}</Item>
                <Item>
                  {item.grade}학년 {item.clazz}반 {item.number}번
                </Item>
              </ItemList>
            );
          } else {
            return null;
          }
        })}
      </ItemListWrapper>
      <button onClick={handleListModal}>확인</button>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 250px;
  right: 60px;
  width: 720px;
  height: 770px;
  background-color: #fff;

  button {
    width: 200px;
    height: 56px;
    margin-top: 16px;
    margin-bottom: 24px;
    border-radius: 10px;
    background-color: #0062ff;
    color: #fff;

    font-size: 16px;

    font-weight: 700;
    line-height: 1.3em;
  }
`;

const Input = styled.input<{ bdColor: string }>`
  width: 632px;
  height: 22px;
  padding: 0;
  padding: 16px 20px;
  border-radius: 10px;
  border: 1px solid ${(props) => props.bdColor};
  margin-top: 24px;
  margin-bottom: 16px;

  ::placeholder {
    color: #8c9299;
    font-size: 14px;

    font-weight: 500;
    line-height: 1.6em;
  }
`;

const ItemsTitle = styled.div`
  display: flex;
  width: 672px;
  height: 56px;
  border-radius: 10px;
  background: #fafafa;

  div {
    width: 224px;
    display: flex;
    align-items: center;

    :nth-of-type(2) {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    :nth-of-type(3) {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  input {
    width: 16px;
    height: 16px;
    margin: 0 10px 0 16px;
  }
`;

const ItemListWrapper = styled.div`
  height: 512px;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.05);
  overflow-y: scroll;
`;

const ItemList = styled.div`
  display: flex;
  background-color: ${(props) => props.color};
  border-bottom: 1px solid #f1f2f4;

  div {
    width: 224px;
    height: 64px;
    display: flex;
    align-items: center;

    input {
      width: 20px;
      height: 20px;
      margin: 0 12px 0 16px;
    }
  }
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
