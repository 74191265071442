import { AxiosResponse } from 'axios';

import {
  NonConnectsCancelRequestParams,
  NonConnectsCancelResponse,
  NonConnectsRequestParams,
  NonConnectsResponse,
} from '~/types/student/non';

import api from '~/api/base';

const BASE_URL = 'api/v1/student';

export const getNonConnectsStatusData = (
  param: NonConnectsRequestParams,
): Promise<AxiosResponse<NonConnectsResponse>> =>
  api.post(`${BASE_URL}/non/connects`, param);

export const getNonConnectsCancelStatusData = (
  param: NonConnectsCancelRequestParams,
): Promise<AxiosResponse<NonConnectsCancelResponse>> =>
  api.post(`${BASE_URL}/cancel/disconnector`, param);
