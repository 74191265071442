import styled from '@emotion/styled';
import { useEffect } from 'react';

export default function SuccessPage() {
  const handleClose = () => {
    window.close();
  };

  const temp = 'authSuccess';

  useEffect(() => {
    if (window.opener) {
      window.opener.postMessage(temp, '*');
      window.close();
    }
  }, []);

  return (
    <Wrapper>
      <div>본인 인증에 성공했습니다.</div>
      <button onClick={handleClose}>확인</button>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;

  & > div {
    margin-top: 257px;
    color: #111827;
    text-align: center;

    /* body/B02-18px-medium */

    font-size: 18px;

    font-weight: 500;
    line-height: 1.6em; /* 28.8px */
  }

  & > button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 56px;
    border-radius: 10px;
    text-align: center;
    background-color: #2c55fb;
    color: #fff;
    /* body/B03-16px-bold */

    font-size: 16px;

    font-weight: 700;
    line-height: 1.3em; /* 20.8px */
  }
`;
