import styled from '@emotion/styled';

import LoginLog from '~/components/nav/LoginLog';

const LABEL_DATA = [
  {
    key: 1,
    label: '로그인 이력',
    id: 'loginLog',
  },
];

export default function ActivityLogPage() {
  return (
    <Wrapper>
      <LogWrapper>
        {LABEL_DATA.map((item) => (
          <div key={item.key}>
            <LogComponents>{item.label}</LogComponents>
          </div>
        ))}
      </LogWrapper>
      <ComponentsWrapper>
        <LoginLog />
      </ComponentsWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 81px);
  background: #fafafa;
  @media (max-height: 1080px) {
    min-height: 999px;
  }
  @media (max-width: 1651px) {
    width: 1652px;
  }
`;

const LogWrapper = styled.div`
  display: flex;
  width: 1592px;
  height: 70px;
  margin-top: 30px;
  margin-left: 30px;
  margin-right: 30px;
  background-color: #fff;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  border-bottom: 1px solid #f1f2f4;
`;

const ComponentsWrapper = styled.div`
  margin: 0 30px;
`;

const LogComponents = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 176px;
  height: 54px;
  margin: 8px 12px;
  border-radius: 100px;
  background: #f0f6ff;

  overflow: hidden;
  color: #2c55fb;
  text-align: center;
  text-overflow: ellipsis;

  /* heading/H06-18px-bold */

  font-size: 18px;

  font-weight: 700;
`;
