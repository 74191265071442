import { AxiosResponse } from 'axios';

import {
  RequestStudentsRelationRequestParams,
  RequestStudentsRelationResponse,
  RequestStudentsRequestParams,
  RequestStudentsResponse,
} from '~/types/student/request';

import api from '~/api/base';

const BASE_URL = 'api/v1/student';

export const getRequestStudentsStatusData = (
  param: RequestStudentsRequestParams,
): Promise<AxiosResponse<RequestStudentsResponse>> =>
  api.post(`${BASE_URL}/request/students`, param);

export const getRequestStudentsRelationStatusData = (
  param: RequestStudentsRelationRequestParams,
): Promise<AxiosResponse<RequestStudentsRelationResponse>> =>
  api.post(`${BASE_URL}/receive/relation`, param);
