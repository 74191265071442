import styled from '@emotion/styled';
import { Dispatch, SetStateAction } from 'react';

import { ReactComponent as JoyCog140 } from '~/assets/JoyCog140.svg';
import { ReactComponent as Selected } from '~/assets/login/Default.svg';
import { ReactComponent as UnSelected } from '~/assets/login/unselected.svg';

interface Props {
  handleNextPageButton(): void;
  isChecked: {
    useCheck: boolean;
    infoCheck: boolean;
    password: boolean;
    second_password: boolean;
    loginId: boolean;
  };
  setIsChecked: Dispatch<
    SetStateAction<{
      useCheck: boolean;
      infoCheck: boolean;
      password: boolean;
      second_password: boolean;
      loginId: boolean;
    }>
  >;
  handlePageCheckButton(): void;
}

export default function SignupAgree({
  handleNextPageButton,
  isChecked,
  setIsChecked,
  handlePageCheckButton,
}: Props) {
  const handleUserCheck = () => {
    setIsChecked((prev) => ({ ...prev, useCheck: !prev.useCheck }));
  };

  const handleInfoCheck = () => {
    setIsChecked((prev) => ({ ...prev, infoCheck: !prev.infoCheck }));
  };

  return (
    <>
      <Wrapper>
        <JoyCog140 style={{ marginTop: '60px', marginBottom: '20px' }} />
        <Title>조이코그 관리자 회원가입</Title>
        <SubTitle>
          회원가입을 위해 개인정보 수집 ∙ 이용 약관 동의가 필요합니다.
        </SubTitle>
        <AgreeContainer>
          <AgreeTitle>
            {isChecked.useCheck ? (
              <Selected
                onClick={handleUserCheck}
                style={{ marginRight: '12px' }}
              />
            ) : (
              <UnSelected
                onClick={handleUserCheck}
                style={{ marginRight: '12px' }}
              />
            )}
            <span>[필수]</span>
            <div>조이코그 관리자 이용 약관</div>
          </AgreeTitle>
          <AgreeInfo>
            {/* 조이코그 관리자 이용 약관 */}
            <h1>제1장 (총칙)</h1>
            &nbsp;
            <h1>제1조 (목적)</h1>
            <p>
              이 약관은 ㈜이모티브(이하 “회사”라 합니다)가 제공하는 서비스의
              이용과 관련하여 회원이 서비스를 이용하는 데 필요한 권리, 의무 및
              책임사항, 이용조건 및 절차 등 기본적인 사항을 규정함을 목적으로
              합니다.
            </p>
            &nbsp;
            <h1> 제2조 (용어의 정의)</h1>
            <p>1. 이 약관에서 사용 되는 용어의 정의는 다음과 같습니다.</p>
            <p>
              ① ‘회사’는 ‘콘텐츠’ 산업과 관련된 경제활동을 영위하는 자로서
              ‘콘텐츠’ 및 제반 서비스를 제공하는 자를 말합니다.
            </p>
            <p>
              ② ‘회원’은 ‘회사’와 이용계약을 체결하고 계정을 부여받은 자로서
              ‘회사’의 정보를 지속적으로 제공받고 ‘회사’가 제공하는 서비스를
              지속적으로 이용할 수 있는 자를 말합니다.
            </p>
            <p>
              ③ ‘콘텐츠’는 온라인과 모바일 기기로 이용할 수 있도록 회사가
              제공하는 서비스와 관련하여 디지털 방식으로 제작한 유료 또는 무료의
              내용물 일체를 의미합니다.
            </p>
            <p>
              ④ ‘서비스’는 회사가 개발한 웹과 앱을 통해 회원에게 제공하는 학생
              등록 및 관리(정보 조회, 우편 및 푸시 발송), 인지능력 측정(검사),
              인지능력 강화 프로그램 등(이하 JoyCog)을 말합니다.
            </p>
            <p>
              ⑤ ‘유료서비스’는 ‘회사’가 유료로 제공하는 ‘콘텐츠’ 및 제반
              서비스를 말합니다.
            </p>
            <p>
              2. 이 약관에서 사용하는 용어의 정의는 제1항 각 호에서 정하는 것을
              제외하고는 관계 법령 및 개인정보처리방침, 회사가 별도로 정한
              지침과 기타 일반적인 상관례를 따릅니다.
            </p>
            &nbsp;
            <h1>제3조 (약관의 효력 범위)</h1>
            <p>
              1. 약관은 서비스를 이용하는 모든 회원에 대하여 효력이 있습니다.
            </p>
            <p>
              2. 이 약관은 회원이 회사로부터 약관에 동의하여 회사와 이용계약을
              체결한 날로부터 이용계약을 해지하는 시점까지 적용되는 것을
              원칙으로 합니다. 다만, 이 약관의 일부 조항은 약관의 적용이 종료한
              이후에도 효력이 계속될 수 있습니다.
            </p>
            &nbsp;
            <h1>제4조 (회사의 기본정보)</h1>
            <p>
              회사는 다음 각 호의 사항을 회원이 알아보기 쉽도록 웹사이트 내에
              표시합니다.
            </p>
            <p>1. 상호 및 대표자의 성명</p>
            <p>2. 영업장 소재지 주소</p>
            <p>3. 전화번호, 전자우편주소</p>
            <p>4. 사업자 등록번호</p>
            <p>5. 통신판매업 신고번호</p>
            <p>6. 개인정보처리방침</p>
            <p>7. 서비스 이용약관</p>
            &nbsp;
            <h1>제5조 (약관의 명시 및 개정)</h1>
            <p>
              1. 회사는 회원이 쉽게 알 수 있도록, 회사의 서비스 초기화면에
              상설메뉴로 이 약관의 내용을 게시합니다.
            </p>
            <p>
              2. 회사는 『약관의 규제에 관한 법률』, 『정보통신망 이용촉진 및
              정보 보호 등에 관한 법률』 등 관련 법령을 위반하지 않는 범위에서
              이 약관 및 개별 서비스 약관(제 6조에 정함)과 기타 서비스 정책을
              수시로 개정 또는 변경할 수 있습니다.
            </p>
            <p>
              3. 회사는 약관을 개정하는 경우 적용 일자 및 개정 사유를 개정 전
              약관과 함께 적용 일자 7일 전(이용자에게 불리하거나 중대한 사항의
              변경은 30일 이전)부터 서비스 공지사항에 게시하는 방법으로 회원에게
              통지합니다. 다만, 회원에게 불리한 내용의 약관 개정의 경우에는
              서비스 공지 외에 로그인 시 동의창, 휴대전화 메시지(SMS, LMS 등)
              등의 방법으로 회원에게 추가 통지합니다.
            </p>
            <p>
              4. 회사는 전항에 따라 개정 약관을 공지 또는 통지하면서 그와 함께
              회원이 개정 약관의 효력 발생일까지 그에 대한 거부의 의사표시를
              하지 않으면, 개정 약관에 승낙한 것으로 간주하겠다는 내용을 공지할
              수 있습니다. 이러한 공지가 이루어진 경우, 회원이 개정 약관의 효력
              발생일까지 그에 대한 거부 의사를 표시하지 않거나 탈퇴하지 않으면,
              그 회원은 청약철회 등 개정 약관에 정한 모든 내용에 대하여 동의한
              것으로 간주됩니다.
            </p>
            <p>
              5. 회사는 개정 약관의 적용에 동의하지 않는 회원에 대하여 개정
              약관의 내용을 적용할 수 없습니다. 단, 이 경우 회사 및 회원은
              이용계약을 해지할 수 있습니다.
            </p>
            <p>
              6. 개정된 약관은 원칙적으로 그 효력 발생일(적용 일자)로부터
              유효합니다.
            </p>
            &nbsp;
            <h1>제6조 (개별 서비스 약관 및 약관 외 준칙)</h1>
            <p>
              1. 회사는 서비스의 구체적인 내용에 따라 회원의 동의를 얻어 개별
              서비스에 대한 약관 및 이용조건(이하 “개별 서비스 약관”이라
              합니다)을 별도로 정할 수 있습니다.
            </p>
            <p>
              2. 제1항의 개별 서비스 이용에 관하여는 해당 개별 서비스 약관이 이
              약관에 우선하여 적용됩니다.
            </p>
            <p>
              3. 이 약관에 정하지 않은 사항에 대하여는 개별 서비스 약관 및
              개인정보처리방침, 관련 법령 또는 상관례를 따릅니다.
            </p>
            &nbsp;
            <h1>제7조 (정보의 통지)</h1>
            <p>
              1. 회사는 회원이 제공한 휴대폰 번호로 SMS 또는 LMS를 전송하는
              방법을 통하여 회원에게 정보를 통지할 수 있습니다.
            </p>
            <p>
              2. 회사는 불특정 다수의 회원에 대한 통지의 경우, 서비스 공지사항
              또는 게시판에 7일 이상의 기간 동안 그 통지를 게시하는 방법으로
              제1항의 통지를 갈음할 수 있습니다. 다만, 특정 회원의 거래와
              관련하여 중대한 영향을 미치는 사항에 대하여는 제1항에 따라
              개별적으로 통지를 하여야 합니다.
            </p>
            &nbsp;
            <h1>제2장 (서비스 이용)</h1>
            &nbsp;
            <h1>제8조 (회원가입)</h1>
            &nbsp;
            <p>
              1. 회원가입은 약관의 내용에 동의하여 회원이 되고자 하는 자(이하
              “가입신청자”라 합니다)가 회사에 가입신청을 하고, 회사가 이러한
              신청에 대하여 가입을 승낙함으로써 체결됩니다. 한편, 비회원은
              회사가 제공하는 서비스를 이용함으로써 이 약관에 따른 이용계약을
              체결하는 것에 동의한 것으로 봅니다.
            </p>
            <p>
              2. 회사는 가입신청자가 제1항의 가입신청을 하는 경우 원칙적으로
              이를 승낙합니다. 다만, 회사는 다음 각 호의 경우 가입신청의 승낙을
              거절할 수 있으며, 사후에 회원에게 다음 각 호의 사유가 존재함을 안
              경우 그 회원과의 이용계약을 해지할 수 있습니다.
            </p>
            <p> ① 가입신청자가 타인의 명의를 도용하여 가입신청을 한 경우</p>
            <p>
              ② 가입신청과 관련하여 허위 또는 부정확한 정보를 기재하거나, 회사가
              제시하는 내용을 기재하지 않은 경우
            </p>
            <p>
              ③ 가입신청자가 이전에 본 항 각 호의 사유로 인하여 회사에 의해
              이용계약이 해지된 적이 있는 경우. 단, 회사로부터 회원 재가입에
              대한 승낙을 얻은 경우는 예외로 함
            </p>
            <p>
              ④ 기타 제반 규정 위반 등 가입신청자의 귀책 사유로 인하여 회사가
              가입신청의 승낙을 거절할 만한 객관적인 사유가 존재하는 경우
            </p>
            <p>
              3. 회사는 서비스 관련 설비의 여유가 없거나, 기술상 또는 업무상
              문제가 있을 시 가입신청의 승낙을 유보할 수 있습니다. 이 경우,
              회사는 문제를 해결하고 가입신청의 승낙을 재개할 수 있도록 최선을
              다합니다.
            </p>
            <p>
              4. 제2항에 따라 회원가입신청의 승낙을 거절 또는 유보하는 경우,
              회사는 해당 사실을 가입신청자에게 통지하여야 합니다.
            </p>
            <p>
              5. 회사가 서비스 가입신청 절차상에서 가입신청자에게 가입완료
              사실을 통지함으로써 이용계약이 성립하며, 그에 따라 해당
              가입신청자는 회원의 지위에서 회사가 제공하는 서비스를 이용할 수
              있습니다.
            </p>
            &nbsp;
            <h1>제9조 (서비스의 내용)</h1>
            <p>1. 회사가 제공하는 서비스의 내용은 다음 각 호와 같습니다.</p>
            <p>① 선생님 Web</p>
            <p>
              (1) 학생 관리(등록 및 연결, 정보 조회, 우편/푸시/이용권/재화 발송)
            </p>
            <p>(2) 기관 조회</p>
            <p>(3) 인지능력 측정 이용권 구매</p>
            <p>(4) 인지능력 강화 결과 조회</p>
            <p>(5) 대시보드 및 인지능력 리포트</p>
            <p>
              (6) 기타 회사가 추가 개발하거나 다른 회사와의 제휴 계약 등을 통해
              이용자에게 제공하는 일체의 서비스
            </p>
            <p>② 학생 JoyCog App</p>
            <p>(1) 인지능력 측정</p>
            <p>(2) 인지능력 강화</p>
            <p>(3) 인벤토리, 마을 시스템</p>
            <p>(4) 대시보드</p>
            <p>
              (5) 기타 회사가 추가 개발하거나 다른 회사와의 제휴 계약 등을 통해
              이용자에게 제공하는 일체의 서비스
            </p>
            &nbsp;
            <h1>제10조 (서비스 이용요금)</h1>
            <p>
              1. 이용요금은 서비스의 내용에 따라 무료로 제공됩니다. 그러나 일부
              서비스(기능) 또는 콘텐츠의 경우 유료로 제공될 수 있습니다.
            </p>
            <p>
              2. 회사는 유료 서비스 등의 가격 및 지급방법, 지급시기 등 구체적인
              사항을 정하여 서비스 공지사항 또는 게시판을 통해 게시합니다.
            </p>
            <p>
              3. 이용요금에 관한 사항(기존에 무료로 제공 되던 서비스의 유료화
              등을 포함)은 회사의 정책에 따라 변경될 수 있습니다.
            </p>
            <p>
              4. 회사는 서비스 이용요금에 관한 사항을 변경하는 경우,
              변경일로부터 적어도 30일 이전까지 앱 또는 사이트 게시, 이메일 또는
              서면을 통한 방법으로 회원에게 해당 사항을 통지합니다. 위 변경
              내용의 효력은 통지상에 명기한 변경일로부터 유효하게 발생하며, 위
              변경에 동의하지 않는 회원은 이용계약을 해지할 수 있습니다.
            </p>
            <p>
              5. 회원은 회사와의 별도 합의를 통해 본 조항과 다른 내용으로
              이용요금에 관한 사항을 정할 수 있습니다. 회사와 회원 사이에 별도
              체결된 계약 내용은 서비스 공지사항 또는 게시판에 게시된 내용에
              대하여 효력상 우선합니다.
            </p>
            <p>
              6. 회원이 서비스를 이용하는 과정에서 통신 사업자 등과의 거래
              관계에서 발생하는 데이터 통신 요금은 제1항의 이용 요금과는 별개의
              것이며, 회사는 위 데이터 통신 요금과 관련하여 어떠한 의무 또는
              책임도 지지 않음을 분명히 합니다.
            </p>
            &nbsp;
            <h1>제11조 (서비스의 제공)</h1>
            <p>
              1. 회사는 관련 법령상 허용되는 그리고 기술적으로 가능한 범위
              내에서 제9조의 서비스를 제공합니다.
            </p>
            <p>
              2. 서비스를 이용하는 국가 및 회원의 단말기 모델에 따라 회사가
              제공하는 서비스의 내용 또는 범위가 달라질 수 있습니다. 일부
              서비스의 경우 회원이 이용하는 단말에서 특정 기능의 접근 권한이
              필요하며, 회원이 접근 권한을 불허하는 경우 관련 서비스의 이용이
              제한될 수 있습니다.
            </p>
            <p>
              3. 회원은 원칙적으로 단말기가 통신 네트워크에 연결된 상태에서
              서비스의 기능을 정상적으로 이용할 수 있습니다. 단말기가 통신
              네트워크에 연결되지 않은 경우, 일부 서비스의 이용이 제한될 수
              있습니다.
            </p>
            <p>
              4. 회사는 업무상 또는 기술상 특별한 지장이 없는 한 연중무휴 1일
              24시간 이용자에게 서비스를 제공합니다. 다만 회사는 정기 점검 등의
              필요가 있는 경우 일정 기간 동안 서비스의 제공을 제한할 수 있으며,
              회사는 이러한 사실을 회원에게 사전 통지합니다.
            </p>
            <p>
              5. 회사는 서비스 이용 가능 시간을 별도로 정할 수 있으며, 그 내용을
              이용자에게 사전에 통지합니다. 회사가 통제할 수 없는 사유로 발생한
              서비스 중단에 대하여 사전 통지가 불가능한 경우에는 이를 사후에
              통지할 수 있습니다.
            </p>
            <p>
              6. 회사는 내부 정책 및 각 유형에 따라 회원을 등급별로 구분하고,
              제공하는 서비스의 내용 및 메뉴, 이용시간, 이용횟수 등을 세분하여
              그 이용에 차등을 둘 수 있으며, 회사는 이러한 사실을 해당 회원에게
              사전 통지합니다.
            </p>
            &nbsp;
            <h1>제12조 (서비스의 이용 제한)</h1>
            <p>
              1. 회사는 회원이 제16조의 의무 및 기타 이 약관상 의무를 위반하는
              경우, 해당 회원에 대하여 다음 각 호의 이용제한 조치를 단계적으로
              취할 수 있습니다.
            </p>
            <p>① 시정요구</p>
            <p>② 일시정지</p>
            <p>③ 영구이용정지</p>
            <p>
              2. 본 조의 이용제한 조치에 관한 조건 및 세부내용은 회사의
              이용제한정책 및 개별 서비스 상의 운영정책을 통하여 정합니다.
            </p>
            &nbsp;
            <h1>제13조 (서비스의 변경 및 중단, 종료)</h1>
            <p>
              1. 회사는 다음 각 호의 경우 회원에 대하여 제공하는 서비스의 전부
              또는 일부를 일시적으로 변경하거나 중단할 수 있습니다.
            </p>
            <p>① 설비의 보수점검, 교체 등 공사로 인하여 부득이한 경우</p>
            <p>
              ② 정전, 제반 설비의 장애 또는 이용량의 폭주 등으로 정상적인 서비스
              이용에 지장이 있는 경우
            </p>
            <p>
              ③ 국가기관 또는 정부조직(방송통신위원회, 한국정보보호진흥원 등),
              수사기관, 법원 등의 행정 또는 사법적 처분 등에 따라 법률상
              서비스의 제공을 제한 또는 중단하여야 하는 경우
            </p>
            <p>④ 기타 회사의 운영상, 기술상 필요 등 상당한 이유가 있는 경우</p>
            <p>
              2. 회사는 다음 각 호의 경우 서비스의 제공을 영구적으로 종료할 수
              있습니다.
            </p>
            <p>
              ① 국가기관 또는 정부조직(방송통신위원회, 한국정보보호진흥원 등),
              수사기관, 법원 등의 행정 또는 사법적 처분 등에 따라 법률상
              서비스의 제공을 종료하여야 하는 경우
            </p>
            <p>
              ② 기타 경영상, 운영 정책상, 기술상 사유 등으로 인하여 회사가
              서비스 제공을 계속할 수 없는 상당한 이유가 있는 경우
            </p>
            <p>
              3. 본 조항에 따라 서비스를 변경 또는 중단(제2항의 종료를 포함)하는
              경우, 회사는 사전에 다음 각 호의 사항을 제7조에 정한 방법으로
              회원에게 통지하여야 합니다. 다만, 사전에 통지할 수 없는 부득이한
              사유가 있는 경우 회사는 서비스의 제공을 변경 또는 중단하고 나서
              사후에 회원에게 통지할 수 있습니다.
            </p>
            <p>① 서비스 변경(또는 중단) 사유</p>
            <p>② 서비스 변경일(또는 중단일)</p>
            <p>③ 변경될 서비스의 내용(서비스 변경의 경우)</p>
            <p>
              4. 본 조항에 따른 서비스 변경 또는 중단과 관련하여, 회사는 관련
              법령 또는 개별 계약상의 근거가 없는 이상 회원에 대하여 별도의 배상
              또는 보상 책임을 지지 않습니다. 다만, 유료 서비스를 이용한 회원의
              경우, 회사는 서비스 변경 또는 중단으로 인한 서비스 미사용 부분
              관련 이용요금을 회원에게 환불합니다.
            </p>
            &nbsp;
            <h1>제14조 (이용 데이터의 보관)</h1>
            <p>
              1. 로그 보관 목적본 서비스는 보안 및 안정성을 유지하고 서비스 이용
              시 발생한 문제를 파악하고 대응하기 위해 서버 로그를 수집하고
              보관합니다.
            </p>
            <p>
              2. 로그 보관 기간서비스 이용 기록, 접속 로그, 기기 정보 등의 서버
              로그는 30일까지 보관됩니다. 다만, 법령의 규정에 따라 서비스
              제공자가 보관하여야 하는 경우에는 해당 법령에서 정한 기간 동안
              보관됩니다.
            </p>
            <p>
              3. 개인정보 보호서버 로그는 본인 확인, 민원 처리, 서비스 제공 등
              목적 이외의 용도로 사용되지 않으며, 외부에 제공되지 않습니다.
              또한, 서비스 제공자는 개인정보보호법 등 관련 법령을 준수하며,
              이용자의 개인정보를 적절히 보호하기 위해 최선의 노력을 다할
              것입니다.
            </p>
            &nbsp;
            <h1>제15조 (광고 기타 정보의 제공)</h1>
            <p>
              1. 회사는 서비스 이용과 관련한 정보를 제7조에 정한 방법으로
              회원에게 제공할 수 있습니다. 회원은 관련 법령에 따른 거래 관련
              정보 및 고객문의 등에 대한 답변 등을 제외하고는 언제든지 회사가
              제공하는 정보가 포함된 이메일의 수신을 거절할 수 있습니다.
            </p>
            <p>
              2. 회사는 서비스 화면, 웹사이트, 앱, 이메일 등을 통하여 회원에게
              광고 등 정보를 제공할 수 있습니다. 회원은 회사에 대한 의사표시로서
              광고가 포함된 이메일에 대한 수신을 거절할 수 있습니다.
            </p>
            <p>
              3. 회원은 회사가 제공하는 정보 및 광고가 포함된 이메일에 대한
              수신을 동의한 상태에서 이용계약 해지(탈퇴)를 하지 않고, 앱을
              삭제하거나 단말기의 공장초기화를 진행한 경우, 앱 삭제 혹은
              공장초기화 이전의 수신 동의는 계속 유효합니다.
            </p>
            &nbsp;
            <h1>제3장 (계약 당사자의 권리 의무)</h1>
            &nbsp;
            <h1>제16조 (회사의 일반 의무)</h1>
            <p>
              1. 회사는 회원에 대한 서비스 제공과 관련하여 관계 법령 및
              미풍양속에 반하는 행위를 하지 않으며, 계속적이고 안정적인 서비스
              제공을 위하여 최선을 다합니다.
            </p>
            <p>
              2. 회사는 회원이 안전하게 서비스를 이용할 수 있도록 회원의
              개인정보(신용정보, 민감정보 포함)를 보호하기 위하여 최선을 다하며,
              개인정보처리방침을 공시하고 준수합니다.
            </p>
            <p>
              3. 회사는 서비스 이용과 관련한 회원의 불만 또는 피해구제 요청을
              적절하게 처리할 수 있도록 필요한 인력 및 시스템을 구축하고 유지할
              수 있도록 최선을 다합니다.
            </p>
            <p>
              4. 회사는 서비스 이용과 관련하여 회원이 정당한 의견 또는 불만을
              제기하는 경우, 이메일 등을 통하여 회원에게 처리 과정 및 결과를
              신속하게 전달합니다.
            </p>
            &nbsp;
            <h1>제17조 (이용자의 일반 의무)</h1>
            <p>
              1. 회원은 서비스 이용과 관련하여 다음 각 호의 행위를 하여서는 아니
              됩니다.
            </p>
            <p>① 가입신청 또는 회원정보 변경 시 허위 내용을 등록하는 행위</p>
            <p>
              ② 타인의 개인정보, 아이디 및 비밀번호 등 서비스 이용 정보 등을
              도용하는 행위
            </p>
            <p>
              ③ 회사의 사전 승낙 없이 회사가 서비스에 게시한 정보를 변경 또는
              삭제하는 행위
            </p>
            <p>
              ④ 회사 및 타인의 지식재산권, 인격권, 명예권 기타 권리를 침해하는
              행위
            </p>
            <p>
              ⑤ 서비스 정보통신망에 장애를 유발시킬 수 있는 내용의 정보, 문장,
              도형 등을 회사가 관리·운영하는 정보통신망에 유포하는 행위
            </p>
            <p>
              ⑥ 서비스 운영을 고의로 방해하거나 서비스의 안정적 운영을 방해할 수
              있는 정보를 전송하는 행위
            </p>
            <p>
              ⑦ 제공되는 서비스 및 콘텐츠를 본 약관에 명시된 목적 이외의 용도로
              사용하는 행위
            </p>
            <p>⑧ 기타 미풍양속에 위반하거나 부당한 행위</p>
            <p>
              2. 회원은 서비스 이용과 관련하여 관계 법령, 약관, 세부이용지침,
              서비스 이용안내 및 회사가 통지한 공지사항 등을 준수하여야 하며,
              기타 회사의 업무를 방해하는 행위를 하여서는 안됩니다.
            </p>
            <p>
              3. 회원은 회사의 사전 승낙 없이 회사가 제공하는 서비스를 이용하여
              회사의 영업과 동종 또는 유사한 영업을 영위할 수 없습니다.
            </p>
            <p>
              4. 회사의 사전 승낙 없이 회원이 행한 제3항의 동종·유사 영업에
              대하여 회사는 어떠한 책임도 지지 않으며, 위 영업으로 인하여
              회사에게 손해가 발생한 경우 해당 회원은 회사가 입은 손해를 전액
              배상하여야 합니다.
            </p>
            <p>
              5. 회원이 본 조항의 의무를 위반하는 경우, 회사는 관계 법령상
              허용되는 범위 내에서 그에 대한 (i) 관련 콘텐츠의 삭제, (ii) 서비스
              이용제한, (iii) 기타 필요한 조치를 취할 수 있습니다.
            </p>
            &nbsp;
            <h1>제18조 (이용자 계정 정보 관리에 대한 의무 및 책임)</h1>
            <p>
              1. 회원의 계정 정보(비밀번호, 이름, 휴대전화 번호 등을 포함)에
              대한 관리 책임은 회원 본인에게 있으며, 회원은 자신의 계정 정보를
              제3자가 이용하도록 하여서는 안됩니다.
            </p>
            <p>
              2. 회원은 제3자가 자신의 계정 정보 등 개인정보를 이용하고 있음을
              인지한 경우 즉시 비밀번호를 변경하고 이 사실을 회사에 통지한 후
              회사의 안내에 따라야 합니다.
            </p>
            <p>
              3. 제2항에 반하여 회원이 회사에게 제3자의 개인정보 이용 사실 등을
              통지하지 않거나, 회사의 안내에 따르지 않음으로 인해 발생한
              불이익에 대해 회사는 어떠한 책임도 지지 않습니다.
            </p>
            &nbsp;
            <h1>제19조 (권리의 귀속)</h1>
            <p>
              1. 회사가 회원에게 제공하는 서비스, 웹사이트, 앱, 소프트웨어, 기타
              자료, 소유권 및 특허권을 비롯한 일체의 지식재산권은 회사에
              귀속됩니다.
            </p>
            <p>
              2. 이용결과물에 대한 권리는 저작자인 회원 본인에게 귀속됩니다.
              다만, 회사는 이용결과물의 개인 정보를 식별할 수 없도록 가공한 후
              비상업적 용도로 이용할 수 있으며, 회원으로부터 별도 동의를 득하여
              이용결과물을 상업적 용도로 이용할 수 있습니다.
            </p>
            <p>
              3. 본 약관에서 명시적으로 허용하는 범위 내에서 회원은 회사가
              제공하는 정보 및 자료 등을 이용할 수 있으며, 서비스 이용과
              관련하여 회사의 지식재산권을 침해하여서는 아니 됩니다.
            </p>
            &nbsp;
            <h1>제4장 (계약 해지)</h1>
            &nbsp;
            <h1>제20조 (이용계약의 해지)</h1>
            <p>
              1. 회원은 언제든지 회사와의 이용계약을 해지할 수 있으며, 동일
              휴대전화 번호 또는 계정으로 가입할 수 없습니다. 회사는 회원의 해지
              요청이 있는 경우 지체 없이 그에 따른 조치를 취하여야 합니다.
            </p>
            <p>
              2. 해지 사유가 발생하는 경우 회사 또는 회원은 상대방에게 이메일
              내지 서면 통지를 통하여 이용계약을 해지할 수 있습니다.
            </p>
            <p>
              3. 회사는 회원에게 다음 각 호의 사유가 존재하는 경우 이메일 내지
              서면을 통한 사전 통지 후 이용계약을 해지할 수 있습니다.
            </p>
            <p>① 제8조 제2항의 승낙 거절 사유가 존재하는 경우</p>
            <p>② 제16조에 규정된 회원의 의무를 위반하는 경우</p>
            <p>
              ③ 기타 회사가 해당 회원에 대한 서비스의 제공을 거부할 만한 합리적
              사유가 존재하는 경우
            </p>
            <p>
              4. 회사 또는 회원은 다음 각 호의 사유가 존재하는 경우 상대방에
              대한 이메일 내지 서면을 통한 통지 후 서비스 이용계약을 해지할 수
              있습니다.
            </p>
            <p>
              ① 당사자 일방이 은행거래정지, 강제집행, 회생절차 개시 또는
              파산선고 등의 부도 사유가 발생한 경우
            </p>
            <p>
              ② 당사자 일방이 기타 이 약관을 중대하게 위반하여 서비스 이용계약의
              유지가 사실상 불가능한 경우
            </p>
            <p>
              5. 회원이 현행법 위반 및 고의 또는 중대한 과실로 회사에 손해를
              입힌 경우, 회사는 사전 통지 없이 해당 회원과의 이용계약을 해지할
              수 있습니다.
            </p>
            <p>
              6. 제2항과 제5항에 따라 이용계약이 해지되는 경우, 회사는 제7조에
              정한 방법으로 회원에게 다음 각 호의 사항을 통보합니다.
            </p>
            <p>① 계약 해지사유</p>
            <p>② 계약 해지일</p>
            <p>
              7. 회원이 이용계약을 해지(탈퇴)하는 경우, 회사는 해당 회원 관련
              정보(서비스 이용기록 포함)를 지체 없이 파기합니다.
            </p>
            <p>
              8. 이용계약을 해지하는 경우 기존의 모든 데이터를 복구할 수 없는
              형태로 파기하며, 동일한 계정으로 회원가입을 할 수 없습니다.
            </p>
            &nbsp;
            <h1>제21조 (계약해지의 효과)</h1>
            <p>
              1. 이용계약의 효력은 회사가 회원에게 이용계약 해지 통보를 한 때에
              소멸하며, 회원은 이후 서비스를 이용할 수 없습니다.
            </p>
            <p>
              2. 본 조항의 이용계약 해지에 따라, 회사는 관련 법령 및
              개인정보처리방침, 민감정보 등 회사가 보유한 회원의 모든 개인정보
              및 데이터, 회원이 설정하였던 서비스에 대한 개별 설정 등 일체의
              정보를 삭제합니다. 단, 제18조에 정한 바에 따른 회사 소유의 정보
              또는 회사가 이용할 수 있는 이용결과물 관련 정보는 예외로 합니다.
            </p>
            <p>3. 본 조의 해지는 제21조의 손해배상에 영향을 미치지 않습니다.</p>
            &nbsp;
            <h1>제5장 기타</h1>
            &nbsp;
            <h1>제22조 (손해배상)</h1>
            <p>
              1. 회사는 회원이 서비스를 이용함에 있어 회사의 고의 또는 중대한
              과실로 인하여 입은 손해를 배상하여야 합니다.
            </p>
            <p>
              2. 회원이 본 약관을 위반하여 회사에 손해를 입힌 경우, 회원은 이에
              대하여 고의 또는 과실이 없음을 입증하지 못하는 이상 회사가 입은
              손해를 배상하여야 합니다.
            </p>
            &nbsp;
            <h1>제23조 (양도의 제한)</h1>
            <p>
              회원은 약관 및 이용계약에 따라 가지는 권리 및 계약상 지위를
              제3자에게 양도, 판매, 담보 제공 등 처분할 수 없습니다.
            </p>
            &nbsp;
            <h1>제24조 (개인정보 등의 보호)</h1>
            <p>
              1. 회사는 이용계약을 위하여 회원이 제공한 개인정보를 회사의 서비스
              운영을 위한 목적 이외의 용도로 사용하거나 회원의 동의 없이
              제3자에게 제공하지 않습니다. 단, 다음 각 호의 경우는 예외로
              합니다.
            </p>
            <p>
              ① 법령에 근거하여 회원 정보 이용과 제3자에 대한 정보제공이
              허용되는 경우
            </p>
            <p>② 기타 회사의 약관 및 정책에 따라 회원의 동의를 구한 경우</p>
            <p>
              2. 개인정보의 보호 및 사용에 대해서는 관련 법령 및 회사의
              개인정보처리방침이 적용됩니다. 회사가 서비스상 또는 공지사항,
              게시판을 통하여 게재, 공시하는 개인정보처리방침은 약관의 일부를
              구성하며 회원은 그 내용에 동의합니다.
            </p>
            <p>
              3. 회사는 수집된 개인정보 처리 등의 업무를 스스로 수행하는 것을
              원칙으로 하나, 필요한 경우 업무의 일부 또는 전부를 제3자에게
              위탁할 수 있습니다. 이와 관련한 제반 사항은 개인정보처리방침을
              통하여 회원에게 고지, 안내합니다.
            </p>
            <p>
              4. 회사는 회원의 개인정보를 보호하기 위하여 『정보통신망 이용촉진
              및 정보보호 등에 관한 법률』 및 『개인정보 보호법』 등 관계
              법령에서 정하는 바를 준수합니다.
            </p>
            <p>
              5. 회사는 회원 또는 회원이 소지한 물건의 위치정보를 보호하기
              위하여 『위치정보의 보호 및 이용 등에 관한 법률』 등 관계 법령에서
              정하는 바를 준수합니다.
            </p>
            <p>
              6. 회사는 회원의 귀책 사유로 인하여 노출된 회원의 계정 정보를
              포함한 모든 정보에 대해서 일체의 책임을 지지 않습니다.
            </p>
            &nbsp;
            <h1>제25조 (면책)</h1>
            <p>
              1. 회사는 서비스를 있는 그대로(As-Is) 제공하며, 서비스의 품질 또는
              성능, 적합성, 적시성, 안전성, 무오류성 등에 대하여 어떠한 주장이나
              보증도 하지 않습니다.
            </p>
            <p>
              2. 회사는 서비스의 제공 및 이용과 관련하여 회원과 다른 회원 사이에
              발생한 분쟁(이용결과물의 지식재산권 침해 관련 분쟁 포함)에 대하여
              어떠한 책임도 지지 않습니다.
            </p>
            <p>
              3. 회사는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를
              제공할 수 없는 경우에는 서비스 제공에 관한 책임이 면제됩니다.
            </p>
            <p>
              4. 회사는 회원에게 책임 있는 사유로 인한 서비스 이용 장애에
              대하여는 책임을 지지 않습니다.
            </p>
            <p>
              5. 회사는 회원 간 또는 회원과 제3자 사이에서 이루어진 거래 등에
              대하여 어떠한 책임도 지지 않습니다.
            </p>
            <p>
              6. 회사는 관련 법령에 특별한 규정이 없는 이상 무료로 제공되는
              서비스와 관련하여 회원에 대하여 법적 책임을 지지 않습니다.
            </p>
            &nbsp;
            <h1>제25조 (분쟁의 해결)</h1>
            <p>
              1. 본 약관 및 이용계약에 관한 분쟁은 대한민국법을 준거법으로
              합니다.
            </p>
            <p>
              2. 본 약관 및 서비스 이용과 관련하여 회사와 회원 사이에 분쟁이
              발생하여 소송이 제기되는 경우에는 민사소송법에 따라 관할법원을
              정합니다.
            </p>
            &nbsp;
            <p>이용약관 버전 번호: 3.2</p>
            <p>이용약관 변경공고일자: 2023년 8월 11일</p>
            <p>이용약관 시행일자: 2023년 9월 1일</p>
          </AgreeInfo>
          <AgreeTitle>
            {isChecked.infoCheck ? (
              <Selected
                onClick={handleInfoCheck}
                style={{ marginRight: '12px' }}
              />
            ) : (
              <UnSelected
                onClick={handleInfoCheck}
                style={{ marginRight: '12px' }}
              />
            )}
            <span>[필수]</span>
            <div>개인정보 수집 및 이용</div>
          </AgreeTitle>
          <AgreeInfo>
            {/* 개인정보 수집 및 이용 */}
            <p>
              <span>㈜이모티브 (이하 ‘회사’)</span> 은(는) 「정보통신망 이용촉진
              및 정보보호에 관한 법률」 및 「개인정보보호법」에 따라 이용자의
              개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수
              있도록 하기 위하여 다음과 같이 개인정보 처리방침을
              수립·공개합니다. 본 개인정보처리방침은 회사가 제공하는
              <span> ‘JoyCog’ 웹 사이트 및 어플리케이션(이하 ‘서비스’)</span> 에
              적용되며 다음과 같은 내용을 포함합니다.
            </p>
            &nbsp;
            <br />
            &nbsp;
            <h1>제1조 (수집하는 개인정보의 항목)</h1>
            &nbsp;
            <h1>1. 수집하는 개인정보의 항목</h1>
            <p>
              회사는 서비스 제공을 위해 최소한의 개인정보를 수집하고 있습니다.
            </p>
            <p>
              회원 가입 시 또는 서비스 이용 과정, C/S 처리 과정에서 서비스 이용
              기록, 접속 로그, 쿠키, 접속 IP, 결제 기록, 부정 이용 기록이 등이
              자동으로 생성되어 수집될 수 있습니다.
            </p>
            <p>
              가. JoyCog 웹 사이트 가입 시 필수 항목으로 휴대전화 번호,
              비밀번호, 이름, 이메일, 서비스 가입 내역을 수집합니다. 이는 이용자
              식별, 고지사항 전달, 서비스 이용 및 상담 등 원활한 의사소통 경로
              확보, 부정이용 방지 등을 목적으로 수집됩니다.
            </p>
            <p>
              나. JoyCog 웹 사이트 본인 인증 시 필수 항목으로 이름, 성별,
              생년월일, 휴대전화번호, 통신사업자, 내/외국인 여부, 암호화된
              이용자 확인값(CI), 중복가입확인정보(DI)를 수집합니다. 이는 이용자
              식별 및 부정이용 방지를 위해 수집됩니다.
            </p>
            <p>
              다. 신용카드 결제 시 필수 항목으로 카드 번호(일부)와 카드사명 등을
              수집합니다. 이는 정량화 데이터 분석을 위해 수집됩니다.
            </p>
            <p>
              라. JoyCog 아동 계정 생성 시 필수 항목으로 이름과 학급정보,
              생년월일, 성별, 비밀번호 등을 수집합니다. 이는 인지능력측정과
              인지능력강화 정량화 데이터 분석을 위해 수집됩니다.
            </p>
            &nbsp;
            <h1>2. 수집방법</h1>
            <p>
              개인정보를 수집하는 경우에는 원칙적으로 사전에 이용자에게 해당
              사실을 고지 후 동의를 구하고 있으며, 아래와 같은 방법을 통해
              개인정보를 수집합니다.
            </p>
            <p>
              가. 회원가입 및 서비스 이용 과정에서 이용자가 개인정보 수집에 대해
              동의를 하고 직접 정보를 입력하는 경우
            </p>
            <p>나. 제휴사로부터 개인정보를 제공받은 경우</p>
            <p>다. 고객과의 상담 시 웹페이지, 메일, 전화, 챗봇 등</p>
            &nbsp;
            <h1>3. 민감정보 수집 및 이용</h1>
            <p>
              가. 회사는 민감정보를 맞춤 서비스 제공, 서비스 이용에 대한 분석 및
              통계 제공, 연구 및 통계분석자료 활용 등의 목적을 위해 취급합니다.
              취급한 민감정보는 해당 목적 이외의 용도로는 사용 되지 않으며, 이용
              목적이 변경될 시에는 사전 동의를 구할 예정입니다.
            </p>
            <p>
              나. 수집되는 민감정보는 주의력 저하/과다 행동 인지 능력 측정에
              대한 평가 척도 기록, 강화 컨텐츠 이용 기록입니다.
            </p>
            <p>
              다. 수집된 민감정보는 탈퇴 시 폐기됩니다.(단, 탈퇴 시 개인 식별
              항목 폐기 후 통계 자료로 활용됩니다.)
            </p>
            <p>라. 동의 거부의 권리</p>
            <p>
              ‘JoyCog’의 민감정보 수집 및 이용에 대한 동의 거부권이 있으며, 동의
              거부 시에는 ‘JoyCog’ 서비스 이용이 불가능합니다.
            </p>
            <p>
              ‘JoyCog’가 사용자의 인지 데이터를 기반으로 서비스를 제공하기 위해
              이용자의 민감정보(건강정보)를 수집, 이용하는 것에 동의한다면
              개인정보 처리 방침 동의 항목을 체크해주시기 바랍니다.
            </p>
            &nbsp;
            <br />
            &nbsp;
            <h1>제2조 (개인정보의 수집 및 이용목적)</h1>
            &nbsp;
            <p>
              회사는 다음의 목적을 위하여 개인정보를 수집합니다. 수집 목적과
              합리적으로 관련된 범위에서는 법령에 따라 이용자의 동의 없이
              개인정보를 이용하거나 제3자에게 제공할 수 있습니다. 이때 ‘당초
              수집 목적과 관련성이 있는지, 수집한 정황 또는 처리 관행에 비추어
              볼 때 개인정보의 추가적인 이용 또는 제공에 대한 예측 가능성이
              있는지, 이용자의 이익을 부당하게 침해 하는지, 가명처리 또는 암호화
              등 안전성 확보에 필요한 조치를 하였는지’를 종합적으로 고려합니다.
            </p>
            &nbsp;
            <p>
              회사는 수집한 개인정보를 특정 개인을 알아볼 수 없도록 가명처리하여
              통계작성, 과학적 연구, 공익적 기록보존 등을 위하여 처리할 수
              있습니다. 이 때 가명정보는 재 식별 되지 않도록 추가정보와 분리하여
              별도 저장・관리하고 필요한 기술적・관리적 보호조치를 취합니다.
            </p>
            &nbsp;
            <h1>1. 회원가입 및 관리</h1>
            <p>
              서비스 이용과 회원제 서비스 이용에 따른 본인확인, 개인 식별,
              불량회원의 부정 이용 방지와 비인가 사용 방지, 가입 의사 확인,
              연령확인, 만 14세 미만 아동 개인정보 수집 시 법정대리인 동의 여부
              확인, 분쟁 조정을 위한 기록보존, 고지사항 전달의 목적으로
              개인정보를 처리합니다.
            </p>
            &nbsp;
            <h1>2. 서비스 제공</h1>
            <p>
              콘텐츠 제공, 맞춤 서비스 제공, 구매 및 요금 결제, 금융거래 본인
              인증 및 금융 서비스를 목적으로 개인정보를 처리합니다.
            </p>
            &nbsp;
            <h1>3. 마케팅 및 광고에 활용</h1>
            <p>
              신규 서비스(제품) 개발 및 맞춤 서비스 제공, 이벤트 및 광고성 정보
              제공, 서비스의 유효성 확인, 접속 빈도 파악 또는 회원의 서비스
              이용에 대한 통계를 목적으로 개인정보를 처리합니다.
            </p>
            &nbsp;
            <br />
            &nbsp;
            <h1>제3조 (개인정보의 보유 및 이용 기간)</h1>
            &nbsp;
            <p>1. 회사 내부방침에 의한 정보 보유 사유</p>
            <p>
              회사에서 서비스를 제공하는 동안 회원의 개인정보는 서비스 제공을
              위해 이용하게 됩니다. 다만 회원이 회사에서 지정한 절차에 따라
              탈퇴(ID를 삭제)한 경우 해당 정보를 지체 없이 파기합니다. 다만,
              회사는 불량회원의 부정한 이용의 재발을 방지하기 위해, 이용계약
              해지일로부터 1년 간 해당 회원의 [이름, 기관코드, 아이디, 비밀번호,
              전화번호, 이메일 주소]를 보유할 수 있습니다.
            </p>
            &nbsp;
            <h1>2. 관련 법령에 의한 정보 보유 사유</h1>
            <p>
              관련 법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 아래와
              같이 관련 법령에서 정한 일정한 기간 동안 회원정보를 보관합니다.
            </p>
            &nbsp;
            <p>
              가. 표시/광고에 관한 기록 : 6개월 (전자상거래 등에서의
              소비자보호에 관한 법률)
            </p>
            <p>
              나. 계약 또는 청약철회 등에 관한 기록 : 5년 (전자상거래 등에서의
              소비자보호에 관한 법률)
            </p>
            <p>
              다. 대금결제 및 재화 등의 공급에 관한 기록 : 5년 (전자상거래
              등에서의 소비자보호에 관한 법률)
            </p>
            <p>
              라. 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년 (전자상거래
              등에서의 소비자보호에 관한 법률)
            </p>
            <p>
              마. 신용정보의 수집/처리 및 이용 등에 관한 기록 : 3년 (신용정보의
              이용 및 보호에 관한 법률)
            </p>
            <p>바. 로그 기록 : 3개월 (통신비밀보호법)</p>
            &nbsp;
            <p>
              개인정보의 열람 및 수정에 대해 회원은 언제든지 자신의 개인정보에
              대한 열람 및 수정을 요청할 수 있습니다.
            </p>
            &nbsp;
            <br />
            &nbsp;
            <h1>제4조 (개인정보의 파기절차 및 방법)</h1>
            <p>
              회사는 원칙적으로 개인정보 수집 및 이용목적이 달성되고 보유 및
              이용 기간이 경과된 후에는 해당 정보를 파기합니다.
            </p>
            &nbsp;
            <h1>1. 파기절차</h1>
            <p>
              회원이 회원가입을 위해 입력한 정보는 목적이 달성된 후 내부방침 및
              기타 관련 법령에 의한 사유에 따라(위 제3조 2항 참조) 일정 기간
              보관 후 파기됩니다.
            </p>
            &nbsp;
            <h1>2. 파기방법</h1>
            <p>
              수집 및 이용 목적의 달성 또는 회원 탈퇴 등 파기 사유가 발생한 경우
              개인정보의 형태를 고려하여 파기방법을 정합니다. 전자적 파일 형태인
              경우 복구 및 재생되지 않도록 안전하게 삭제하고, 그 밖에 기록물,
              인쇄물, 서면 등의 경우 분쇄하거나 소각하여 파기합니다.
            </p>
            &nbsp;
            <br />
            &nbsp;
            <h1>제5조 (개인정보처리 위탁)</h1>
            &nbsp;
            <p>
              회사는 전문적인 고객지원 및 서비스 제공을 위해 아래와 같이
              개인정보 처리 업무를 외부 업체에 위탁하여 운영하고 있습니다.
              위탁계약 시 개인정보보호에 안전을 기하기 위하여 개인정보보호 관련
              지시 엄수, 개인정보에 관한 유출금지 및 사고 시 책임부담 등을
              명확히 규정하고 위탁계약 내용에 포함합니다.
            </p>
            &nbsp;
            <h1>1. AWS</h1>
            <p>
              가. 개인정보 업무를 수탁받는 자(개인정보를 이전받는 법인) :
              AWS(Amazon Web Services Inc.)
            </p>
            <p>
              나. 이전되는 자의 개인정보 항목 : 휴대전화번호, 서비스 이용 기록
              및 수집된 개인정보
            </p>
            <p>
              다. 개인정보를 이전받는 자의 개인정보 이용목적 : 개인정보 보관기간
              동안 개인정보가 보관된 클라우드 서버 운영 및 관리
            </p>
            <p>
              라. 개인정보를 이전받는 자의 개인정보 보유/이용기간 : 현재 회사가
              이용 중인 클라우드 서비스 이용 변경 시까지
            </p>
            <p>
              마. 개인정보 이전일시 및 방법 : 서비스 이용 시점에 네트워크를 통한
              전송
            </p>
            <p>
              바. 개인정보를 이전받는 법인의 정보관리책임자의 연락처 :
              국내대리인 제너럴에이전트 주식회사(대표 김영수) / AWS Korea
              Privacy / 주소: 서울 종로구 새문안로5가길 28 / TEL: 02-735-6888 /
              e-mail: aws-korea-privacy@amazon.com
            </p>
            &nbsp;
            <br />
            &nbsp;
            <h1>제6조 (이용자 및 법정대리인의 권리와 그 행사방법)</h1>
            &nbsp;
            <p>
              1. 이용자는 회사에 대해 언제든지 개인정보 열람·정정·삭제·처리정지
              요구 등의 권리를 행사할 수 있습니다.
            </p>
            &nbsp;
            <p>
              2. 제1항에 따른 권리 행사는 회사에 대해 「개인정보보호법」 시행령
              제41조 제1항에 따라 서면, 전자우편, FAX 등을 통하여 할 수 있으며
              회사는 이에 대해 지체 없이 조치하겠습니다.
            </p>
            &nbsp;
            <p>
              3. 제1항에 따른 권리 행사는 이용자의 법정대리인이나 위임을 받은 자
              등 대리인을 통해 진행할 수 있습니다. 이 경우 “개인정보 처리 방법에
              관한 고시(제2020-7호)” 별지 제11호 서식에 따른 위임장을 제출하셔야
              합니다.
            </p>
            &nbsp;
            <p>
              4. 개인정보 열람 및 처리정지 요구는 「개인정보보호법」 제35조
              제4항, 제37조 제2항에 의하여 이용자의 권리가 제한될 수 있습니다.
            </p>
            &nbsp;
            <p>
              5. 개인정보의 정정 및 삭제 요구는 법령에 따라(위 제3조 2항 참조)
              개인정보 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수
              없습니다.
            </p>
            &nbsp;
            <p>
              6. 회사는 이용자의 권리에 따른 열람 요구, 정정·삭제 요구, 처리
              정지 요구 시 요구한 자가 본인이거나 정당한 대리인인지 확인합니다.
            </p>
            &nbsp;
            <br />
            &nbsp;
            <h1>
              제7조 (개인정보의 자동 수집 장치의 설치·운영 및 그 거부에 관한
              사항)
            </h1>
            &nbsp;
            <p>
              당사는 이용자의 정보를 수시로 저장하고 찾아내는 `쿠키(cookie)`를
              사용합니다. 쿠키란 당사 웹 사이트를 운영하는데 이용되는 서버가
              귀하의 브라우저에 보내는 아주 작은 텍스트 파일로서 이용자의 PC에
              저장됩니다.
            </p>
            &nbsp;
            <h1>1. 쿠키 사용 목적</h1>
            <p>
              가. 개인화되고 맞춤화된 서비스를 제공하기 위해서 이용자의 정보를
              저장하고 수시로 불러오는 쿠키를 사용합니다. 이용자가 웹사이트에
              방문할 경우 웹 사이트 서버는 이용자의 디바이스에 저장되어 있는
              쿠키를 읽어 이용자의 환경설정을 유지하고 맞춤화된 서비스를
              제공하게 됩니다. 쿠키는 이용자가 웹 사이트를 방문할 때, 웹 사이트
              사용을 설정한대로 접속하고 편리하게 사용할 수 있도록 돕습니다.
              또한, 이용자의 웹사이트 방문 기록, 이용 형태를 통해서 최적화된
              광고 등 맞춤형 정보를 제공하기 위해 활용됩니다.
            </p>
            <p>
              나. 이용자는 쿠키 설치에 대한 선택권을 가지고 있으며, 쿠키 설정을
              통해 쿠키 허용 및 거부를 할 수 있습니다.
            </p>
            &nbsp;
            <h1>2. 쿠키 설정 거부 방법</h1>
            <p>
              가. 웹브라우저 상단의 `설정 {'>'} 개인정보보호 {'>'} 쿠키 및 기타
              사이트 데이터` 경로에서 쿠키 설정을 통해 쿠키 거부를 할 수
              있습니다.
            </p>
            <p>
              나. 다만, 쿠키 설치를 거부할 경우 웹 사용이 불편해지며, 로그인이
              필요한 일부 서비스 이용이 어려울 수 있습니다.
            </p>
            &nbsp;
            <br />
            &nbsp;
            <h1>제8조(개인정보 보호를 위한 안전성 확보 조치)</h1>
            &nbsp;
            <p>
              회사는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고
              있습니다.
            </p>
            &nbsp;
            <h1>1. 정기적인 자체 감사 실시</h1>
            <p>
              개인정보 취급 관련 안정성 확보를 위해 정기적(분기 1회)으로 자체
              감사를 실시하고 있습니다.
            </p>
            &nbsp;
            <h1>2. 내부관리계획의 수립 및 시행</h1>
            <p>
              개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고
              있습니다.
            </p>
            &nbsp;
            <h1> 3. 개인정보의 암호화</h1>
            <p>
              이용자의 개인정보와 비밀번호는 암호화되어 저장 및 관리되고 있어,
              본인만 알 수 있으며 중요한 데이터는 파일 및 전송 데이터를
              암호화하거나 파일 잠금 기능을 사용하는 등 별도 보안 기능을
              사용하고 있습니다.
            </p>
            &nbsp;
            <h1>4. 개인정보에 대한 접근 제한</h1>
            <p>
              개인정보를 처리하는 데이터베이스시스템에 대한 접근 권한의 부여,
              변경, 말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한
              조치를 하고 있으며 침입 차단 시스템을 이용하여 외부로부터 무단
              접근을 통제하고 있습니다.
            </p>
            &nbsp;
            <br />
            &nbsp;
            <h1>제9조 (개인정보 보호 책임자)</h1>
            &nbsp;
            <p>
              회사는 회원의 개인정보를 보호하고, 개인정보와 관련한 불만을
              처리하기 위하여 아래와 같이 관련 부서 및 개인정보보호책임자를
              지정하고 있습니다. 회원의 개인정보와 관련한 문의사항이 있으시면
              아래의 개인정보보호책임자에게 연락 주시기 바랍니다.
            </p>
            &nbsp;
            <p>▶ 개인정보보호책임자</p>
            <p>성명: 민정상</p>
            <p>직책: 대표</p>
            <p>연락처: 02-508-2813</p>
            <p>이메일: info@emotiv.kr</p>
            &nbsp;
            <br />
            &nbsp;
            <h1>제10조 (개인정보처리방침 변경에 관한 사항)</h1>
            &nbsp;
            <p>
              법령이나 서비스의 변경사항을 반영하기 위한 목적 등으로 개인정보
              처리방침을 수정할 수 있습니다. 개인정보 처리방침이 변경되는 경우
              최소 7일 전 변경 사항을 사전에 안내 하겠습니다.
            </p>
            &nbsp;
            <br />
            &nbsp;
            <h1>제11조 (가명정보 처리에 관한 사항)</h1>
            &nbsp;
            <p>
              1. 회사는 개인정보보호법 제28조의 2, 제28조의 3에 근거하여
              가명정보를 처리하고 있습니다.
            </p>
            <p>
              2. 회사는 수집한 개인정보를 추가 정보가 없이는 특정 개인을 알아볼
              수 없도록 가명처리하여 통계작성, 과학적 연구 등을 위하여 이용할 수
              있습니다. 이 때 가명정보는 개인정보보호법 제28조의 4에 근거하여
              안전한 조치를 취하고 있습니다.
            </p>
            <p>
              3. 회사는 가명정보를 제3자에게 제공하는 경우, 업무 처리 목적에
              필요한 가명정보를 제공합니다. 이 경우 가명정보 재식별 금지,
              가명정보 재제공 또는 재위탁 제한 등 안전성 확보를 위한 준수사항이
              반영된 문서(서약서, 계약서 등)을 작성하고 있습니다.
            </p>
            &nbsp;
            <br />
            &nbsp;
            <h1>제12조 (개인정보에 관한 민원 서비스)</h1>
            &nbsp;
            <p>
              기타 개인정보침해에 대한 신고나 상담이 필요하신 경우 아래 기관에
              문의하시기 바랍니다.
            </p>
            &nbsp;
            <p>
              1. 개인정보분쟁조정위원회: (국번없이) 1833-6972 (www.kopico.go.kr)
            </p>
            <p>2. 개인정보침해신고센터: (국번없이) 118 (privacy.kisa.or.kr)</p>
            <p>3. 대검찰청: (국번없이) 1301 (www.spo.go.kr)</p>
            <p>4. 경찰청: (국번없이) 182 (ecrm.cyber.go.kr)</p>
          </AgreeInfo>
        </AgreeContainer>
        <Button bgColor="#0062ff" color="#fff" onClick={handleNextPageButton}>
          동의 후 회원가입
        </Button>
        <Button bgColor="#fff" color="#0062ff" onClick={handlePageCheckButton}>
          취소
        </Button>
      </Wrapper>
      <Reserved>© 2023 eMotiv. All rights reserved.</Reserved>
    </>
  );
}

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 720px;
  height: 800px;
  border-radius: 40px;
  background: #fff;

  box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.08);
`;

const Title = styled.h1`
  margin-bottom: 30px;
  color: #000;

  font-size: 32px;

  font-weight: 700;
  line-height: 1.3em; /* 41.6px */
  text-transform: capitalize;
`;

const SubTitle = styled.div`
  margin-bottom: 30px;
  color: #171819;

  /* body/B02-18px-medium */

  font-size: 18px;

  font-weight: 500;
  line-height: 1.6em; /* 28.8px */
`;

const AgreeContainer = styled.div`
  width: 481px;
  height: 346px;
  margin-bottom: 6px;
`;

const AgreeTitle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  & > input {
    width: 16px;
    height: 16px;
    margin-right: 12px;
  }

  & > span {
    margin-right: 5px;
    color: #0062ff;

    /* body/B03-16px-medium */

    font-size: 16px;

    font-weight: 500;
    line-height: 1.3em; /* 20.8px */
  }

  & > div {
    color: #000;

    /* body/B03-16px-medium */

    font-size: 16px;

    font-weight: 500;
    line-height: 1.3em;
  }
`;

const AgreeInfo = styled.div`
  width: 449px;
  height: 102px;
  margin-bottom: 14px;
  padding: 13px 12px 13px 20px;
  border-radius: 10px;
  border: 1px solid #e2e2ea;
  background: #f6f6f6;
  overflow-y: scroll;

  & > h1 {
    color: #65696d;

    font-size: 14px;

    font-weight: 800;
    line-height: 1.6em; /* 22.4px */
  }

  & > p {
    color: #65696d;

    font-size: 14px;

    font-weight: 400;
    line-height: 1.6em; /* 22.4px */

    & > span {
      color: #65696d;

      font-size: 14px;

      font-weight: 800;
      line-height: 1.6em; /* 22.4px */
    }
  }
`;

const Button = styled.button<{ bgColor: string }>`
  width: 480px;
  height: 56px;
  background: ${(props) => props.bgColor};
  color: ${(props) => props.color};
  border-radius: 10px;
  border: 1px solid #0062ff;

  /* body/B02-18px-bold */
  font-size: 18px;

  font-weight: 700;
  line-height: 1.3em; /* 23.4px */
  :nth-of-type(1) {
    margin-bottom: 12px;
  }
`;

const Reserved = styled.div`
  margin-top: 30px;
  color: #65696d;
  text-align: center;

  /* body/B04-14px-medium */

  font-size: 14px;

  font-weight: 500;
  line-height: 1.6em; /* 22.4px */
`;
