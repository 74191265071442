import { AxiosResponse } from 'axios';

import api from '~/api/base';

const BASE_URL = 'api/v1/account/reissue/token';

export const getTokenStatusData = (
  param: TokenRequestParams,
): Promise<AxiosResponse<JwtTokenData>> => api.post(`${BASE_URL}`, param);

export interface TokenRequestParams {
  refreshToken: string;
}

export interface JwtTokenData {
  status: number;
  data: {
    jwtToken: {
      userId: number;
      grantType: string;
      accessToken: string;
      refreshToken: string;
      authority: string;
    };
  };
}
