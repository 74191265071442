import { AxiosResponse } from 'axios';

import {
  ManagerIdRequestParams,
  ManagerIdResponse,
  ProfileRequestParams,
  ProfileResponse,
} from '~/types/manager/profile';

import api from '~/api/base';

const BASE_URL = 'api/v1/manager/find';

export const getProfileInfoStatusData = (
  param: ProfileRequestParams,
): Promise<AxiosResponse<ProfileResponse>> =>
  api.post(`${BASE_URL}/profile`, param);

export const getManagerIdStatusData = (
  param: ManagerIdRequestParams,
): Promise<AxiosResponse<ManagerIdResponse>> =>
  api.post(`${BASE_URL}/id`, param);
