import styled from '@emotion/styled';
import { ChangeEvent } from 'react';

import {
  ResponseDataList,
  ResponseStudentsResponse,
} from '~/types/student/response';

interface Props {
  setModalComponent: React.Dispatch<React.SetStateAction<string>>;
  isData?: ResponseStudentsResponse;
  handleUserCheckboxChange: (
    sendId: number,
  ) => (event: ChangeEvent<HTMLInputElement>) => void;
  checkedSendId: ResponseDataList[];
  handleAllCheck: () => void;
  allCheck: boolean;
  handleSelectAllChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleApiSendButton: () => void;
}

export default function StudentReceivedModal({
  setModalComponent,
  isData,
  handleUserCheckboxChange,
  checkedSendId,
  allCheck,
  handleSelectAllChange,
  handleApiSendButton,
}: Props) {
  return (
    <Wrapper>
      <Container>
        <Title>받은 연결 요청 수락</Title>
        {checkedSendId.length !== 0 ? (
          <SubTitle>
            학생에게 받은 연결 요청 {checkedSendId.length}건을 수락하시겠습니까?
          </SubTitle>
        ) : (
          <SubTitle />
        )}
        <ListTitle>생성한 학생 리스트</ListTitle>
        <StudentListTitleContainer>
          <input
            type="checkbox"
            checked={allCheck}
            onChange={handleSelectAllChange}
          />
          <div>ID</div>
          <p>학급정보</p>
        </StudentListTitleContainer>
        <StudentWrapper>
          {isData?.data.responseList.map((item) => {
            const isChecked = checkedSendId.some(
              (user) => user.sendId === item.sendId,
            );
            return (
              <StudentListContainer
                key={item.sendId}
                bgColor={isChecked ? '#F0F6FF' : '#fff'}
              >
                <input
                  type="checkbox"
                  checked={checkedSendId.some(
                    (user) => user.sendId === item.sendId,
                  )}
                  onChange={handleUserCheckboxChange(item.sendId)}
                />
                <div>{item.loginId}</div>
                <p>{item.classInfo}</p>
              </StudentListContainer>
            );
          })}
        </StudentWrapper>
        <ButtonContainer>
          <Button
            bgColor="#fff"
            color="#0062FF"
            onClick={() => setModalComponent('')}
          >
            취소
          </Button>
          <Button bgColor="#0062FF" color="#fff" onClick={handleApiSendButton}>
            수락
          </Button>
        </ButtonContainer>
      </Container>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  min-width: 1920px;
  min-height: 960px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
  z-index: 9;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 520px;
  height: 670px;
  padding: 60px 40px 40px 40px;
  border-radius: 16px;
  background: #fff;
  color: #000;
`;

const Title = styled.div`
  margin-bottom: 8px;
  color: #000;

  /* heading/H02-24px-bold */

  font-size: 24px;

  font-weight: 700;
  line-height: 1.6em; /* 38.4px */
`;

const SubTitle = styled.div`
  height: 30px;
  margin-bottom: 34px;
  color: #65696d;

  /* body/B02-18px-medium */

  font-size: 18px;

  font-weight: 500;
  line-height: 1.6em; /* 28.8px */
`;

const ListTitle = styled.div`
  margin-bottom: 10px;
  color: #000;

  /* body/B02-18px-bold */

  font-size: 18px;

  font-weight: 700;
  line-height: 1.3em; /* 23.4px */
`;

const StudentWrapper = styled.div`
  overflow-x: scroll;
  height: 378px;
  margin-bottom: 33px;
`;

const StudentListTitleContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 56px;
  border-radius: 10px;
  background: #fafafa;

  color: #000;

  font-size: 14px;

  font-weight: 400;

  input {
    width: 16px;
    height: 16px;
    margin-left: 16px;
    margin-right: 10px;
  }
  div {
    width: 228px;
    margin-right: 32px;
  }
  p {
    width: 228px;
    text-align: center;
  }
`;

const StudentListContainer = styled.div<{ bgColor: string }>`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f1f2f4;
  height: 62px;
  background-color: ${(props) => props.bgColor};

  color: #000;

  font-size: 14px;

  font-weight: 400;

  input {
    width: 16px;
    height: 16px;
    margin-left: 16px;
    margin-right: 10px;
  }
  div {
    width: 228px;
    margin-right: 32px;
  }
  p {
    width: 228px;
    text-align: center;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Button = styled.button<{ bgColor: string }>`
  width: 200px;
  height: 56px;
  color: ${(props) => props.color};
  background-color: ${(props) => props.bgColor};
  border-radius: 10px;
  border: 1px solid #2c55fb;

  /* body/B03-16px-bold */

  font-size: 16px;

  font-weight: 700;
  line-height: 1.3em; /* 20.8px */
  :nth-of-type(1) {
    margin-right: 12px;
  }
`;
