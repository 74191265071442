import styled from '@emotion/styled';

interface Props {
  transformedData?: { [key: string]: number | string };
  dateFilter: (value: string | Date | undefined | number) => string;
}

export default function PagFive({ transformedData, dateFilter }: Props) {
  return (
    <Wrapper>
      <Nav>
        <div>조이코그: 초등학생을 위한 인지측정 리포트</div>
        <div>측정일 : {dateFilter(transformedData?.screenDate)}</div>
      </Nav>
      <SolutionContainer>
        <NumberItemsContainer>
          <Bold>
            <IconImgNumber url="/images/3.png" />
            <div>생각 속도가 느려요</div>
          </Bold>
          <div>
            목표 시간을 단축해가면서 처리속도를 향상시킬 수 있습니다.
            <br />
            1. 어린이가 좋아하는 놀이 중에 제한 시간을 정해서 할 수 있는 놀이를
            하나 고릅니다.
            <br />
            (예. 퍼즐 놀이)
            <br />
            2. 처음에는 어린이가 충분히 끝낼 수 있는 시간으로 시작합니다.
            <br />
            3. 목표 시간을 점점 줄여나갑니다.
            <br />
            4. 시간 줄이기를 성공할 때마다 좋아하는 보상을 줍니다.
          </div>
          <div>Tip!</div>
          <div>
            속도가 느린 어린이를 재촉하는 것은 압박감을 줍니다. 시간적 압박은
            처리속도를 더 느려지게 할 수 있으니, 충분한 시간을 제공하는 것이
            바람직합니다.
          </div>
        </NumberItemsContainer>
        <NumberItemsContainer>
          <Bold>
            <IconImgNumber url="/images/4.png" />
            <div>깜빡깜빡 잊어요</div>
          </Bold>
          <div>
            어린이가 일상생활에서 잊지 않고 스스로 챙기는 훈련을 하도록 도움을
            주세요.
            <br />
            1. 하루 할 일을 시간과 함께 목록화합니다.
            <br />
            2. 한 일은 지우거나 표시해둡니다.
            <br />
            3. 할 일을 잊지 않고 해내면 어린이가 좋아하는 보상을 주면서
            반복합니다.
          </div>
          <div>Tip!</div>
          <p>
            그림을 활용한다면 기억해야 하는 것을 듣기만 할 때보다 더 잘 기억할
            수 있습니다.
            <br />
            메모, 일과표와 같은 기억 보조 도구를 적극적으로 활용해보세요.
          </p>
        </NumberItemsContainer>
      </SolutionContainer>
      <LogoImg />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: 842px;

  & > h2 {
    margin-bottom: 9.75px;

    color: #000;

    font-size: 14px;

    font-weight: 800;
    line-height: 1.6em; /* 22.4px */
  }
`;

const Nav = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 595px;
  height: 46px;
  background: #5a79e9;
  color: #fff;
  font-size: 10px;
  font-weight: 600;
  line-height: 1.6em; /* 16px */

  & > div {
    :nth-of-type(1) {
      margin-right: 229px;
    }
  }
`;

const Bold = styled.div`
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: bold;
  font-stretch: normal;

  color: #101010;

  & > div {
    padding-top: 2px;
  }
`;

const SolutionContainer = styled.div`
  width: 520px;
  margin-top: 24px;

  border-radius: 10px;
  border: 0.5px solid #cbcbcb;
`;

const LogoImg = styled.div`
  position: absolute;
  bottom: 10px;
  left: 253px;
  width: 90px;
  height: 36px;
  background: url(/images/JoyCog90.png) no-repeat center;
  background-size: contain;
`;

const IconImgNumber = styled.div<{ url: string }>`
  width: 15px;
  height: 15px;
  margin-right: 4px;
  background: url(${(props) => props.url}) no-repeat center;
  background-size: contain;
`;

const NumberItemsContainer = styled.div`
  width: 394px;
  margin-top: 17px;
  margin-left: 35px;

  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;

  line-height: 1.67;
  letter-spacing: -0.12px;
  color: #101010;

  :nth-of-type(1) {
    border-bottom: 1px solid #ebebeb;
    padding-bottom: 17px;
  }
  & > div {
    :nth-of-type(1) {
      display: flex;
      align-items: center;
      margin-bottom: 7px;
      height: 16px;
    }
    :nth-of-type(2) {
      margin-bottom: 7px;
    }
    :nth-of-type(3) {
      color: #5a79e9;

      font-size: 12px;

      font-weight: 600;
      line-height: 1.6em; /* 19.2px */
    }
  }

  & > p {
    margin-bottom: 25px;
  }
`;
