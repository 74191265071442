import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { ChangeEvent, useState } from 'react';
import ReactPaginate from 'react-paginate';

import { NonConnectsList } from '~/types/student/non';

import dateFilter from '~/utils/dateFilter/dateFilter';

import { getNonConnectsCancelStatusData } from '~/api/student/non';

import useAuth from '~/hooks/useAuth';

interface PostsProps {
  itemsPerPage: number;
  isData?: NonConnectsList[];
  handleUserCheckboxChange: (
    userId: number,
  ) => (event: ChangeEvent<HTMLInputElement>) => void;
  checkedChildIds: NonConnectsList[];
}

const CreateStudentPaginate: React.FC<PostsProps> = ({
  itemsPerPage,
  isData,
  handleUserCheckboxChange,
  checkedChildIds,
}) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [buttonStatuses, setButtonStatuses] = useState<{
    [key: number]: string;
  }>({});

  const { user } = useAuth();

  const pageCount: number = Math.ceil((isData?.length || 0) / itemsPerPage);

  const handlePageChange = (selectedItem: { selected: number }) => {
    setCurrentPage(selectedItem.selected);
  };

  const offset = currentPage * itemsPerPage;
  const currentItems = isData?.slice(offset, offset + itemsPerPage);

  const handleResponseAcceptButton = (childId: number) => {
    if (!user) {
      return;
    }
    setButtonStatuses((prevStatuses) => ({
      ...prevStatuses,
      [childId]: '삭제',
    }));

    getNonConnectsCancelStatusData({
      children: [childId],
      licenseId: Number(user.licenseId),
      userId: user.userId,
    })
      .then()
      .catch(console.error);
  };

  return (
    <>
      <ItemsWrapper>
        {currentItems?.map((item) => {
          const isChecked = checkedChildIds.some(
            (user) => user.childId === item.childId,
          );
          return (
            <Items key={item.childId} bgColor={isChecked ? '#f0f6ff' : '#fff'}>
              <ItemsInput>
                <Input
                  type="checkbox"
                  name="childId"
                  checked={checkedChildIds.some(
                    (user) => user.childId === item.childId,
                  )}
                  onChange={handleUserCheckboxChange(item.childId)}
                />
                <div>{item.loginId}</div>
              </ItemsInput>
              <div>{item.name}</div>
              {item.gender === 'F' ? (
                <div>
                  <Gender>
                    <GenderImg url="/images/girl.png" />
                  </Gender>
                </div>
              ) : item.gender === 'M' ? (
                <div>
                  <Gender>
                    <GenderImg url="/images/boy.png" />
                  </Gender>
                </div>
              ) : (
                <div>-</div>
              )}
              <div>{item.classInfo}</div>
              <div>{dateFilter(item.joinDate)}</div>
              <div>
                {buttonStatuses[item.childId] === undefined ? (
                  <>
                    <Button
                      bdColor="#65696D"
                      color="#65696D"
                      bgColor="#fff"
                      onClick={() => handleResponseAcceptButton(item.childId)}
                    >
                      <ButtonImg url="/images/trash.png" />
                      삭제
                    </Button>
                  </>
                ) : (
                  buttonStatuses[item.childId] === '삭제' && (
                    <ButtonStatus bgColor="#F6F6F6" color="#65696D">
                      <ButtonImg url="/images/trash.png" />
                      <p>삭제를 완료했습니다.</p>
                    </ButtonStatus>
                  )
                )}
              </div>
            </Items>
          );
        })}
      </ItemsWrapper>
      <PaginateButton>
        <Paginate
          previousLabel={'<'}
          nextLabel={'>'}
          breakLabel={'...'}
          breakClassName={'break-me'}
          pageCount={pageCount}
          marginPagesDisplayed={1}
          pageRangeDisplayed={4}
          onPageChange={handlePageChange}
          containerClassName={'pagination'}
          activeClassName={'active'}
        />
      </PaginateButton>
    </>
  );
};

export default CreateStudentPaginate;

const PaginateButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
`;

const Paginate = styled(ReactPaginate)`
  display: flex;
  align-items: center;
  justify-content: center;

  ${css`
    li {
      color: #171819;
      font-size: 12px;

      font-weight: 400;
      line-height: 1.6em;
      cursor: pointer;
    }

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
    }

    li:not(.previous, .next, .break-me):hover {
      color: #2c55fb;
      background: #f0f6ff;
      border-radius: 8px;
    }
    li.active {
      border-radius: 8px;
      color: #2c55fb;
      background: #f0f6ff;
    }

    li.previous {
      border-radius: 8px;
      color: #c7c7c7;
      border: 1px solid #c7c7c7;
      margin-right: 24px;

      &:hover {
        color: #2c55fb;
        border: 1px solid #2c55fb;
      }
    }

    li.next {
      color: #c7c7c7;
      border: 1px solid #c7c7c7;
      border-radius: 8px;
      margin-left: 24px;

      &:hover {
        color: #2c55fb;
        border: 1px solid #2c55fb;
      }
    }
  `}
`;
const ItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 528px;

  color: #292d32;

  font-size: 14px;

  font-weight: 500;
  line-height: 1.6em;
`;

const Items = styled.div<{ bgColor: string }>`
  display: flex;
  border-bottom: 1px solid #f1f2f4;
  height: 63px;
  width: 1544px;
  background: ${(props) => props.bgColor};

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    font-size: 14px;

    font-weight: 400;

    :nth-of-type(1) {
      width: 210px;
    }
    :nth-of-type(2) {
      width: 200px;
    }
    :nth-of-type(3) {
      width: 286px;
    }
    :nth-of-type(4) {
      width: 286px;
    }
    :nth-of-type(5) {
      width: 262px;
    }
  }
`;

const Input = styled.input`
  width: 16px;
  height: 16px;
  margin-left: 16px;
  margin-right: 12px;
`;

const ItemsInput = styled.span`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 300px;
  height: 56px;
`;

const Gender = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 33px;
  height: 33px;
  border-radius: 10px;
  background: #f6f6f6;
`;

const GenderImg = styled.div<{ url: string }>`
  width: 14px;
  height: 20px;
  background: url(${(props) => props.url}) no-repeat center;
  background-size: center;
`;

const Button = styled.button<{ bgColor: string; bdColor: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 34px;
  color: ${(props) => props.color};
  font-size: 14px;

  font-weight: 500;
  border-radius: 6px;
  border: 1px solid ${(props) => props.bdColor};
  background: ${(props) => props.bgColor};

  :nth-of-type(1) {
    margin-right: 15px;
  }

  :nth-of-type(2) {
    margin-right: 15px;
  }

  & > p {
    margin-top: 1px;
    margin-left: 4px;
  }
`;

const ButtonImg = styled.div<{ url: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14px;
  height: 20px;
  margin-right: 4px;
  background: url(${(props) => props.url}) no-repeat center;
  background-size: contain;
`;

const ButtonStatus = styled.div<{ bgColor: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 215px;
  height: 34px;
  border-radius: 6px;
  background: ${(props) => props.bgColor};

  /* body/B04-14px-medium */

  font-size: 14px;

  font-weight: 500;
  line-height: 1.6em; /* 22.4px */

  & > p {
    margin-top: 3px;
    margin-left: 6px;
    color: ${(props) => props.color};
  }
`;
