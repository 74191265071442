import styled from '@emotion/styled';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { AuthResponse } from '~/types/auth/auth';
import { ProfileData } from '~/types/manager/profile';

import Modal from '~/utils/portal/components/Modal';

import {
  getPgResultSearchStatusData,
  getPgTestStatusData,
} from '~/api/auth/auth';
import { getEditProfileStatusData } from '~/api/manager/edit';
import { getProfileInfoStatusData } from '~/api/manager/profile';

import useAuth from '~/hooks/useAuth';
import useInput from '~/hooks/useInput';

export default function ChangeProfilePage() {
  const { user, loginId } = useAuth();
  const [value, setValue, handleChange] = useInput({
    authPhone: '',
    phone: '',
    email: '',
    introduct: '',
    name: '',
  });
  const [validationCheck, setValidationCheck] = useState({
    phone: false,
    email: false,
    introduct: false,
  });
  const [pgData, setPgData] = useState<AuthResponse>();
  const [userData, setUserData] = useState<ProfileData>();
  const [isModal, setIsModal] = useState('');
  const refPhone = useRef<HTMLInputElement>(null);
  const refEmail = useRef<HTMLInputElement>(null);

  const navigate = useNavigate();

  const handleFocus = (inputRef: React.RefObject<HTMLInputElement>) => {
    if (inputRef.current !== null) {
      inputRef.current.focus();
    }
  };

  useEffect(() => {
    getPgTestStatusData({
      directAgency: null,
      reqSvcCd: 'SIMPLE',
      userBirth: null,
      userName: null,
      userPhone: null,
    })
      .then(({ data }) => {
        setPgData(data);
      })
      .catch(console.error);
  }, []);

  useEffect(() => {
    getProfileInfoStatusData({ userId: user?.userId as number })
      .then(({ data }) => {
        if (data.status === 20004) {
          return;
        }
        setUserData(data?.data);
        setValue({
          authPhone: data.data.authPhone,
          name: data.data.name,
          phone: data.data.contactNumber,
          email: data.data.email,
          introduct: data.data.introduct,
        });
      })
      .catch(console.error);
  }, []);

  const popupCenter = (): Window | null => {
    const _width = 400;
    const _height = 620;
    const xPos = document.body.offsetWidth / 2 - _width / 2;

    window.addEventListener('message', (event) => {
      if (event.source !== windowRef) return;
      setIsModal('true');
    });

    const windowRef = window.open(
      '',
      'sa_popup',
      `width=${_width}, height=${_height}, left=${xPos}, menubar=yes, status=yes, titlebar=yes, resizable=yes`,
    );

    return windowRef;
  };

  const callSa = async () => {
    const windowRef = popupCenter();
    if (windowRef) {
      const saForm = window.document.querySelector('form[name="saForm"]');
      if (saForm instanceof HTMLFormElement) {
        saForm.setAttribute('target', 'sa_popup');
        saForm.setAttribute('method', 'post');
        saForm.setAttribute('action', 'https://sa.inicis.com/auth');
        saForm.submit();
      }
    }
  };

  const handleValueChange = (id: string, value: string) => {
    handleChange(id, value);
  };

  const handleDeletevalue = (id: string) => {
    setValue((prevValue) => ({
      ...prevValue,
      [id]: '',
    }));
  };

  const handleProfileEdit = () => {
    getEditProfileStatusData({
      authPhone: value.authPhone as string,
      contactNumber: value.phone,
      email: value.email,
      introduct: value.introduct as string,
      managerId: Number(user?.managerId),
      name: value.name as string,
    })
      .then(({ data }) => {
        if (data.status === 19104) {
          setValidationCheck((prevErrors) => ({
            ...prevErrors,
            phone: true,
          }));
          refPhone.current?.focus();
          return;
        } else if (data.status === 19110) {
          setValidationCheck((prevErrors) => ({
            ...prevErrors,
            introduct: true,
          }));
          refEmail.current?.setAttribute('autoFocus', 'true');
          refEmail.current?.focus();
          return;
        } else if (data.status === 19105) {
          setValidationCheck((prevErrors) => ({
            ...prevErrors,
            email: true,
          }));
          return;
        } else if (data.status === 19106) {
          setValidationCheck((prevErrors) => ({
            ...prevErrors,
            email: false,
            phone: false,
            introduct: false,
          }));
        } else {
          setValidationCheck((prevErrors) => ({
            ...prevErrors,
            email: false,
            phone: false,
            introduct: false,
          }));
          setIsModal('success');
        }
      })
      .catch(console.error);
  };

  const handleSuccessProfileChange = () => {
    setIsModal('');
    navigate('/home/dashboard');
  };

  const handleAuth = () => {
    setIsModal('');
    getPgResultSearchStatusData({
      mtxId: pgData?.data.authentication.mtxId as string,
      loginId: null,
    })
      .then(({ data }) => {
        if (data.data.authentication.resultCode === '0000') {
          setValue({
            authPhone: data.data.authentication.userPhone,
            name: data.data.authentication.userName,
            phone: userData?.contactNumber as string,
            email: userData?.email as string,
            introduct: userData?.introduct as string,
          });
        }
      })
      .catch(console.error);
  };

  return (
    <Wrapper>
      <Header>
        <div>아이디</div>
        <span>{loginId}</span>
        <div>소속 기관명</div>
        <span>{user?.agencyName}</span>
      </Header>
      <Container>
        <h1>내 프로필</h1>
        <div>
          <div>
            <h3>성함</h3>

            <Input
              type="text"
              mgBottom="62px"
              width="260px"
              mgRight="28px"
              bdColor="#e2e2ea;"
              value={value.name || ''}
              disabled
            />
          </div>
          <div>
            <h3>휴대폰 본인인증</h3>
            <ButtonInputContainer>
              <Input
                type="number"
                mgBottom=""
                width="260px"
                mgRight="19px"
                bdColor="#e2e2ea;"
                value={value.authPhone || ''}
                disabled
              />
              <AuthButton onClick={callSa}>다른 번호 인증</AuthButton>
            </ButtonInputContainer>
          </div>
        </div>
        <div>
          <div>
            <h3>전화번호</h3>
            <ButtonInputContainer>
              <Input
                ref={refPhone}
                onClick={() => handleFocus(refPhone)}
                type="text"
                mgBottom="10px"
                width="260px"
                mgRight="28px"
                bdColor={!validationCheck.phone ? '#e2e2ea;' : '#FF4752'}
                value={value.phone || ''}
                onChange={(e) => handleValueChange('phone', e.target.value)}
              />
              <DeleteIcon
                right="30px"
                onClick={() => handleDeletevalue('phone')}
              />
            </ButtonInputContainer>

            {!validationCheck.phone ? (
              <Error />
            ) : (
              <Error>전화번호를 입력해주세요.</Error>
            )}
          </div>
          <div>
            <h3>이메일 주소</h3>
            <ButtonInputContainer>
              <Input
                ref={refEmail}
                onClick={() => handleFocus(refEmail)}
                type="text"
                mgBottom="10px"
                width="420px"
                mgRight="19px"
                bdColor={!validationCheck.email ? '#e2e2ea;' : '#FF4752'}
                value={value.email || ''}
                onChange={(e) => handleValueChange('email', e.target.value)}
              />
              <DeleteIcon
                right="23px"
                onClick={() => handleDeletevalue('email')}
              />
            </ButtonInputContainer>

            {!validationCheck.email ? (
              <Error />
            ) : (
              <Error>이메일 주소를 형식에 맞게 입력해주세요.</Error>
            )}
          </div>
        </div>
        <div>
          <div>
            <h3>소개</h3>
            <ButtonInputContainer>
              <Input
                maxLength={30}
                type="text"
                mgBottom="10px"
                width="748px"
                mgRight="28px"
                bdColor={!validationCheck.introduct ? '#e2e2ea;' : '#FF4752'}
                value={value.introduct || ''}
                onChange={(e) => handleValueChange('introduct', e.target.value)}
              />
              <InfoCount>{`${value.introduct?.length}/30`}</InfoCount>
            </ButtonInputContainer>
            {!validationCheck.introduct ? (
              <Error />
            ) : (
              <Error>소개를 입력해주세요.</Error>
            )}
          </div>
        </div>
        <ButtonContainer>
          <Button
            color="#2C55FB"
            bgColor=""
            left="220px"
            onClick={handleSuccessProfileChange}
          >
            취소
          </Button>
          <Button
            color="#FFF"
            bgColor="#2C55FB"
            left="432px"
            onClick={handleProfileEdit}
          >
            저장
          </Button>
        </ButtonContainer>
      </Container>
      <form name="saForm" hidden>
        <input
          type="text"
          name="mid"
          value={pgData?.data.authentication.mid ?? 0}
          readOnly
        />
        mid
        <br />
        <input
          type="text"
          name="reqSvcCd"
          value={pgData?.data.authentication.reqSvcCd ?? 0}
          readOnly
        />
        reqSvcCd
        <br />
        <input
          type="text"
          name="mTxId"
          value={pgData?.data.authentication.mtxId ?? 0}
          readOnly
        />
        mTxId
        <br />
        <input
          type="text"
          name="authHash"
          value={pgData?.data.authentication.authHash ?? 0}
          readOnly
        />
        authHash
        <br />
        <input
          type="text"
          name="flgFixedUser"
          value={pgData?.data.authentication.flgFixedUser ?? 0}
          readOnly
        />
        flgFixedUser
        <br />
        <input
          type="text"
          name="userName"
          value={pgData?.data.authentication.userName ?? 0}
          readOnly
        />
        userName
        <br />
        <input type="text" name="userPhone" value="" readOnly />
        userPhone
        <br />
        <input type="text" name="userBirth" value="" readOnly />
        userBirth
        <br />
        <input type="text" name="userHash" value="" readOnly />
        userHash
        <br />
        <input
          name="reservedMsg"
          value={pgData?.data.authentication.reservedMsg ?? 0}
          readOnly
        />
        reservedMsg
        <br />
        <input type="text" name="directAgency" value="" readOnly />
        directAgency
        <br />
        <input
          type="text"
          name="successUrl"
          value={pgData?.data.authentication.successUrl ?? 0}
          readOnly
        />
        successUrl
        <br />
        <input
          type="text"
          name="failUrl"
          value={pgData?.data.authentication.failUrl ?? 0}
          readOnly
        />
        failUrl
        <br />
      </form>
      {isModal === 'success' && (
        <Modal
          title="프로필이 수정되었습니다."
          onClose={handleSuccessProfileChange}
        ></Modal>
      )}
      {isModal && (
        <Modal title="본인인증이 완료되었습니다." onClose={handleAuth} />
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  height: calc(100vh - 81px);
  background: #fafafa;
  @media (max-height: 1080px) {
    min-height: 999px;
  }
  @media (max-width: 1651px) {
    width: 1652px;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 330px;
  height: 194px;
  margin: 30px 40px 0 30px;
  border-radius: 12px;
  background: #fff;

  /* shadow */
  box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.08);

  & > div {
    color: #8c9299;
    text-align: center;

    /* body/B03-16px-medium */

    font-size: 16px;

    font-weight: 500;
    line-height: 1.3em; /* 20.8px */
  }

  & > span {
    color: #111827;
    text-align: center;

    /* heading/H02-20px-bold */

    font-size: 20px;

    font-weight: 700;
    line-height: 1.6em; /* 32px */
    letter-spacing: -0.3px;

    :nth-of-type(1) {
      margin-bottom: 20px;
    }
  }
`;

const Container = styled.div`
  width: 788px;
  height: 866px;
  padding: 32px;
  margin-top: 30px;
  border-radius: 12px;
  background: #fff;

  /* shadow */
  box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.08);

  & > h1 {
    height: 40px;
    margin-bottom: 20px;
    color: #000;

    /* heading/H02-20px-bold */

    font-size: 20px;

    font-weight: 700;
    line-height: 1.6em; /* 32px */
    letter-spacing: -0.3px;
  }

  & > div {
    display: flex;
  }

  h3 {
    margin-bottom: 6px;
    color: #000;

    /* body/B04-14px-medium */

    font-size: 14px;

    font-weight: 500;
    line-height: 1.6em; /* 22.4px */
  }
`;

const Input = styled.input<{
  width: string;
  mgBottom: string;
  mgRight: string;
  bdColor: string;
}>`
  width: ${(props) => props.width};
  margin-bottom: ${(props) => props.mgBottom};
  margin-right: ${(props) => props.mgRight};
  border: 1px solid ${(props) => props.bdColor};
  height: 18px;
  padding: 15px 19px;
  border-radius: 10px;
  color: #000;

  /* body/B03-16px-medium */

  font-size: 16px;

  font-weight: 500;
  line-height: 1.3em; /* 20.8px */

  :disabled {
    color: #b5b5be;
    border: 1px solid #f8f9fa;
    /* body/B03-16px-medium */

    font-size: 16px;

    font-weight: 500;
    line-height: 1.3em; /* 20.8px */
  }

  :focus {
    border-radius: 10px;
    border: 1px solid #3467ff;
    background: #fff;
  }
`;

const ButtonInputContainer = styled.div`
  display: flex;
  position: relative;
`;

const Error = styled.div`
  height: 22px;
  margin-bottom: 30px;
  color: #ff4752;

  /* body/B04-14px-medium */

  font-size: 14px;

  font-weight: 500;
  line-height: 1.6em; /* 22.4px */
`;

const ButtonContainer = styled.div`
  margin-top: 324px;
  margin-left: 188px;
`;

const Button = styled.button<{ left: string; bgColor: string }>`
  left: ${(props) => props.left};
  width: 200px;
  height: 60px;
  border-radius: 10px;
  border: 1px solid #2c55fb;
  color: ${(props) => props.color};
  background: ${(props) => props.bgColor};

  &:nth-of-type(1) {
    margin-right: 12px;
  }
`;

const AuthButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 141px;
  height: 51px;
  border-radius: 10px;
  border: 1px solid #2c55fb;
  background: #fff;

  color: #3467ff;
  text-align: center;

  /* body/B03-16px-medium */

  font-size: 16px;

  font-weight: 500;
  line-height: 1.3em; /* 20.8px */
`;

const DeleteIcon = styled.button<{ right: string }>`
  position: absolute;
  right: ${(props) => props.right};
  top: 16px;
  width: 16px;
  height: 16px;
  background: url(/images/delete.png) no-repeat center;
  background-size: contain;
  margin-right: 12px;
`;

const InfoCount = styled.div`
  position: absolute;
  top: 16px;
  right: 56px;
  color: #b5b5be;
  text-align: right;

  /* body/B04-14px-medium */

  font-size: 14px;

  font-weight: 500;
`;
