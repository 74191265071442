import { AxiosResponse } from 'axios';

import { LoginRequestParams, LoginResponse } from '~/types/manager/login';

import api from '~/api/base';

const BASE_URL = 'api/v1/manager/login';

export const getLoginStatusData = (
  param: LoginRequestParams,
): Promise<AxiosResponse<LoginResponse>> =>
  api.post(`${BASE_URL}/account`, param);
