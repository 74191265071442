import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { useState } from 'react';
import ReactPaginate from 'react-paginate';

import { Activity } from '~/types/activeLog/activeLog';

import dateFilter from '~/utils/dateFilter/dateFilter';

interface PostsProps {
  items: Activity[];
  itemsPerPage: number;
}

const Posts: React.FC<PostsProps> = ({ items, itemsPerPage }) => {
  const [currentPage, setCurrentPage] = useState(0);

  const pageCount = Math.ceil(items?.length / itemsPerPage);

  const handlePageChange = (selectedItem: { selected: number }) => {
    setCurrentPage(selectedItem.selected);
  };

  const offset = currentPage * itemsPerPage;
  const currentItems = items?.slice(offset, offset + itemsPerPage);

  return (
    <Wrapper>
      <ItemsWrapper>
        {currentItems?.map((item) => (
          <ItemsContainer key={item.sortNo}>
            <div>{dateFilter(item.regDate)}</div>
            <div>{item.address}</div>
            {item.country === 'KR' ? <div>대한민국</div> : <div>-</div>}
            <div>
              {item.os} ({item.browser})
            </div>
            {item.successYn === 'Y' ? (
              <div>성공</div>
            ) : item.successYn === null ? (
              <div>-</div>
            ) : (
              <div>실패</div>
            )}
          </ItemsContainer>
        ))}
      </ItemsWrapper>
      <PaginateButton>
        <Paginate
          previousLabel={'<'}
          nextLabel={'>'}
          breakLabel={'...'}
          breakClassName={'break-me'}
          pageCount={pageCount}
          marginPagesDisplayed={1}
          pageRangeDisplayed={4}
          onPageChange={handlePageChange}
          containerClassName={'pagination'}
          activeClassName={'active'}
        />
      </PaginateButton>
    </Wrapper>
  );
};

export default Posts;

const Wrapper = styled.div``;

const PaginateButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Paginate = styled(ReactPaginate)`
  display: flex;
  align-items: center;
  justify-content: center;

  ${css`
    li {
      color: #171819;
      font-size: 12px;

      font-weight: 400;
      line-height: 1.6em;
      cursor: pointer;
    }

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
    }

    li:not(.previous, .next, .break-me):hover {
      color: #2c55fb;
      background: #f0f6ff;
      border-radius: 8px;
    }
    li.active {
      border-radius: 8px;
      color: #2c55fb;
      background: #f0f6ff;
    }

    li.previous {
      border-radius: 8px;
      color: #c7c7c7;
      border: 1px solid #c7c7c7;
      margin-right: 24px;

      &:hover {
        color: #2c55fb;
        border: 1px solid #2c55fb;
      }
    }

    li.next {
      color: #c7c7c7;
      border: 1px solid #c7c7c7;
      border-radius: 8px;
      margin-left: 24px;

      &:hover {
        color: #2c55fb;
        border: 1px solid #2c55fb;
      }
    }
  `}
`;

const ItemsWrapper = styled.div`
  width: 1544px;
  height: 735px;
  margin: 0 24px;
`;

const ItemsContainer = styled.div`
  display: flex;
  border-bottom: 1px solid #f1f2f4;

  color: #000;
  text-align: center;

  /* body/B04-14px-medium */

  font-size: 14px;

  font-weight: 500;
  line-height: 1.6em; /* 22.4px */

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;

    :nth-of-type(1) {
      width: 308px;
      height: 63px;
    }
    :nth-of-type(2) {
      width: 308px;
      height: 63px;
    }
    :nth-of-type(3) {
      width: 308px;
      height: 63px;
    }
    :nth-of-type(4) {
      width: 308px;
      height: 63px;
    }
    :nth-of-type(5) {
      width: 308px;
      height: 63px;
    }
  }
`;
