import { AxiosResponse } from 'axios';

import {
  LoginIdValidStudentRequestParams,
  LoginIdValidStudentResponse,
} from '~/types/student/valid';

import api from '~/api/base';

const BASE_URL = 'api/v1/student/valid';

export const getLoginIdValidStatusData = (
  param: LoginIdValidStudentRequestParams,
): Promise<AxiosResponse<LoginIdValidStudentResponse>> =>
  api.post(`${BASE_URL}/id`, param);
