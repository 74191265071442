import styled from '@emotion/styled';

import { ReactComponent as JoyCog140 } from '~/assets/JoyCog140.svg';

interface Props {
  handleNextPageButton(): void;
  value: { [key: string]: string | undefined | null };
  handleChange: (id: string, value: string) => void;
  handleLicenseCheck(): void;
  validationCheck: { [key: string]: boolean };
  isLicenseCheckButtonClicked: boolean;
  setValue: (id: string, value: string | null) => void;
}

export default function SignupCode({
  handleNextPageButton,
  value,
  handleChange,
  handleLicenseCheck,
  validationCheck,
  isLicenseCheckButtonClicked,
  setValue,
}: Props) {
  const handleDeleteLicenseKey = () => {
    setValue('licenseKey', null);
  };
  return (
    <>
      <Wrapper>
        <JoyCog140 style={{ marginTop: '60px', marginBottom: '20px' }} />
        <h1>조이코그 관리자 회원가입</h1>
        <p>부여받은 기관코드를 입력해주세요</p>
        <InputContainer>
          <div>
            기관코드<span>*</span>
          </div>
          <InputBox>
            <Input
              bdColor={
                isLicenseCheckButtonClicked && !validationCheck.licenseKey
                  ? '#FF4752'
                  : '#e2e2ea;'
              }
              type="text"
              placeholder="기관코드를 입력해주세요."
              onChange={(e) => handleChange('licenseKey', e.target.value)}
              value={value.licenseKey ?? ''}
            />
            {value.licenseKey && (
              <DeleteButton onClick={handleDeleteLicenseKey} />
            )}
            <button onClick={handleLicenseCheck}>기관인증</button>
          </InputBox>
          {isLicenseCheckButtonClicked && !validationCheck.licenseKey && (
            <Error>
              기관코드가 올바르지 않습니다. 기관코드는 소속된 기관에 문의해
              주세요.
            </Error>
          )}
        </InputContainer>
        <button onClick={handleNextPageButton}>다음</button>
      </Wrapper>
      <Reserved>© 2023 eMotiv. All rights reserved.</Reserved>
    </>
  );
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 720px;
  height: 800px;
  border-radius: 40px;
  background: #fff;

  /* shadow */
  box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.08);

  & > h1 {
    margin-bottom: 12px;

    color: #000;
    text-align: center;

    font-size: 32px;

    font-weight: 700;
    line-height: 1.3em; /* 41.6px */
    text-transform: capitalize;
  }

  & > p {
    margin-bottom: 100px;

    color: #000;
    text-align: center;

    /* body/B02-18px-medium */

    font-size: 18px;

    font-weight: 500;
    line-height: 1.6em; /* 28.8px */
  }

  & > button {
    width: 480px;
    height: 56px;
    border-radius: 10px;
    background: #2c55fb;

    color: #fff;
    text-align: center;

    /* body/B02-18px-bold */

    font-size: 18px;

    font-weight: 700;
    line-height: 1.3em; /* 23.4px */
  }
`;

const InputContainer = styled.div`
  width: 480px;
  height: 77px;
  margin-bottom: 300px;

  & > div {
    color: #000;

    font-size: 14px;

    font-weight: 500;
    line-height: 100%; /* 14px */
    margin-bottom: 10px;

    & > span {
      color: #0062ff;

      font-size: 14px;

      font-weight: 500;
      line-height: 100%;
    }
  }
`;

const InputBox = styled.div`
  position: relative;
  & > button {
    width: 140px;
    height: 53px;
    margin-left: 20px;
    border-radius: 10px;
    border: 1px solid #2c55fb;

    color: #2c55fb;
    text-align: center;

    /* body/B03-16px-medium */

    font-size: 16px;

    font-weight: 500;
    line-height: 1.3em; /* 20.8px */
  }
`;

const DeleteButton = styled.div`
  top: 18px;
  right: 180px;
  position: absolute;
  width: 16px;
  height: 16px;
  background: url(/images/delete.png) no-repeat center;
  background-size: cover;
`;

const Input = styled.input<{ bdColor: string }>`
  width: 273px;
  height: 26px;
  padding: 13px 22px;
  border-radius: 10px;
  border: 1px solid ${(props) => props.bdColor};

  ::placeholder {
    color: #b5b5be;

    font-size: 16px;

    font-weight: 400;
    line-height: 1.6em; /* 25.6px */
  }

  :focus {
    border-radius: 10px;
    border: 1px solid #0062ff;
  }
`;

const Reserved = styled.div`
  margin-top: 30px;
  color: #65696d;
  text-align: center;

  /* body/B04-14px-medium */

  font-size: 14px;

  font-weight: 500;
  line-height: 1.6em; /* 22.4px */
`;

const Error = styled.span`
  margin-top: 6px;
  color: #ff4752;

  /* body/B04-14px-medium */

  font-size: 14px;

  font-weight: 500;
  line-height: 1.6em; /* 22.4px */
`;
