import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';

import { Cognitive } from '~/types/dash/cognitive';

import { ReactComponent as SvgImg } from '~/assets/StudentSvg.svg';

const STUDENT_DATA = [
  { key: 1, label: '총 관리학생' },
  { key: 2, label: '주의단계 학생' },
  { key: 3, label: '관심단계 학생' },
  { key: 4, label: '연결 대기 학생' },
];

interface Props {
  dashboardData?: Cognitive;
}

export default function Student({ dashboardData }: Props) {
  const navigate = useNavigate();

  const handleNavigate = (key: number) => {
    switch (key) {
      case 1:
        return navigate('/home/student-management/student/measurement');

      case 2:
        return navigate('/home/student-management/student/measurement?1');

      case 3:
        return navigate('/home/student-management/student/measurement?2');

      default:
        return navigate('/home/student-management/student-request');
    }
  };

  return (
    <Wrapper>
      <Title>관리학생 현황</Title>
      <ItemsContainer>
        {STUDENT_DATA.map((item) => (
          <Items key={item.key} onClick={() => handleNavigate(item.key)}>
            <ItemsImgContainer>
              <SvgImg />
              {item.key === 2 && (
                <ItemImg
                  url="/images/student2.png"
                  width="100px"
                  height="100px"
                />
              )}
              {item.key === 3 && (
                <ItemImg
                  url="/images/student3.png"
                  width="100px"
                  height="100px"
                />
              )}
              <ItemsImg
                url={
                  item.key === 1
                    ? '/images/student1.png'
                    : item.key === 4
                    ? '/images/student4.png'
                    : ''
                }
              />
            </ItemsImgContainer>
            <ItemsTitle>{item.label}</ItemsTitle>
            <ItemsCount>
              {item.key === 1
                ? dashboardData?.totalStudentCount
                : item.key === 2
                ? dashboardData?.attentionStudentCount
                : item.key === 3
                ? dashboardData?.needAttentionStudentCount
                : dashboardData?.connectionRequestStudentCount}
              명
            </ItemsCount>
          </Items>
        ))}
      </ItemsContainer>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 721px;
  height: 274px;
  margin-bottom: 30px;
  padding: 24px 30px;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.08);
`;

const Title = styled.div`
  margin-bottom: 12px;
  color: #000;

  /* heading/H02-20px-bold */

  font-size: 20px;

  font-weight: 700;
  line-height: 1.6em; /* 32px */
  letter-spacing: -0.3px;
`;

const ItemsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Items = styled.div`
  width: 165px;
  height: 230px;
  border-radius: 20px;
  background: #fff;

  /* shadow */
  box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.08);

  cursor: pointer;
`;

const ItemsImgContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 28.5px;
  margin-bottom: 20px;
`;

const ItemsImg = styled.div<{ url: string }>`
  position: absolute;
  width: 50px;
  height: 50px;
  background: url(${(props) => props.url}) no-repeat center;
  background-size: center;
`;

const ItemImg = styled.div<{ url: string; width: string; height: string }>`
  position: absolute;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  background: url(${(props) => props.url}) no-repeat center;
  background-size: contain;
`;

const ItemsTitle = styled.div`
  height: 21px;
  color: #000;
  text-align: center;
  /* body/B03-16px-medium */

  font-size: 16px;

  font-weight: 500;
  line-height: 1.3em; /* 20.8px */
`;

const ItemsCount = styled.div`
  color: #000;
  text-align: center;

  /* heading/H02-20px-bold */

  font-size: 20px;

  font-weight: 700;
  line-height: 1.6em; /* 32px */
  letter-spacing: -0.3px;
`;
