import styled from '@emotion/styled';

import { authenticaionDetailData } from '~/types/auth/auth';

import { ReactComponent as JoyCog140 } from '~/assets/JoyCog140.svg';

interface Props {
  handleValueChange: (id: string, value: string) => void;
  value: { [key: string]: string | undefined | null };
  handleIdCheck: () => void;
  validationCheck: { [key: string]: boolean };
  handleSignupButton: () => void;
  authData?: authenticaionDetailData;
  setValue: (id: string, value: string | null) => void;
}

// J1076420300610

export default function SignupAccountDetailInfo({
  handleValueChange,
  value,
  validationCheck,
  handleSignupButton,
  authData,
  setValue,
}: Props) {
  const handleDeletePhone = () => {
    setValue('phone', null);
  };

  const handleDeleteEmail = () => {
    setValue('email', null);
  };

  const handleDeleteInfo = () => {
    setValue('info', null);
  };
  return (
    <>
      <Wrapper>
        <JoyCog140
          style={{
            marginTop: '60px',
            marginBottom: '24px',
          }}
        />
        <Title>조이코그 관리자 회원가입</Title>
        <InputContainer>
          <div>
            이름<span>*</span>
          </div>

          <Input
            width={'434px'}
            bdColor={'#e2e2ea;'}
            type="text"
            onChange={(e) => handleValueChange('name', e.target.value)}
            value={authData?.userName || ''}
            disabled
          />
          <Error />

          <div>전화번호</div>
          <InputBox>
            <Input
              width={'434px'}
              bdColor={validationCheck.phone ? '#FF4752' : '#e2e2ea;'}
              type="text"
              placeholder="전화번호를 입력해주세요"
              value={value.phone || ''}
              onChange={(e) => handleValueChange('phone', e.target.value)}
            />
            {value.phone && (
              <DeleteButton onClick={handleDeletePhone} right="22px" />
            )}
          </InputBox>
          {validationCheck.phone ? (
            <Error>전화번호를 입력해 주세요.</Error>
          ) : (
            <Error />
          )}

          <div>이메일</div>
          <InputBox>
            <Input
              width={'434px'}
              bdColor={validationCheck.email ? '#FF4752' : '#e2e2ea;'}
              type="email"
              placeholder="예: korea@school.com"
              value={value.email || ''}
              onChange={(e) => handleValueChange('email', e.target.value)}
            />
            {value.email && (
              <DeleteButton onClick={handleDeleteEmail} right="22px" />
            )}
          </InputBox>
          {validationCheck.email ? (
            <Error>이메일 주소를 형식에 맞게 입력해 주세요.</Error>
          ) : (
            <Error />
          )}

          <div>
            소개<span>*</span>
          </div>
          <InputBox>
            <Input
              maxLength={30}
              width={'434px'}
              bdColor={validationCheck.info ? '#FF4752' : '#e2e2ea;'}
              type="text"
              placeholder="예: 한국초등학교 3학년 1반 담임선생님 홍길동입니다."
              value={value.info || ''}
              onChange={(e) => handleValueChange('info', e.target.value)}
            />
            {value.info && (
              <DeleteButton onClick={handleDeleteInfo} right="22px" />
            )}
          </InputBox>
          {validationCheck.info ? (
            <Error>소개를 입력해 주세요.</Error>
          ) : (
            <Error />
          )}
        </InputContainer>
        <button onClick={handleSignupButton}>회원가입 완료</button>
      </Wrapper>
      <Reserved>© 2023 eMotiv. All rights reserved.</Reserved>
    </>
  );
}

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 720px;
  height: 800px;
  border-radius: 40px;
  background: #fff;

  box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.08);

  span {
    color: #0062ff;

    font-size: 14px;

    font-weight: 500;
    line-height: 100%;
  }

  button {
    width: 480px;
    height: 56px;

    margin-top: 14px;
    margin-bottom: 60px;
    border-radius: 10px;
    background: #0062ff;
    color: #fff;
    text-align: center;
    /* body/B02-18px-bold */

    font-size: 18px;

    font-weight: 700;
    line-height: 1.3em; /* 23.4px */
  }
`;

const Title = styled.h1`
  margin-bottom: 30px;
  color: #000;

  font-size: 32px;

  font-weight: 700;
  line-height: 1.3em; /* 41.6px */
  text-transform: capitalize;
`;

const Reserved = styled.div`
  margin-top: 30px;
  color: #65696d;
  text-align: center;

  /* body/B04-14px-medium */

  font-size: 14px;

  font-weight: 500;
  line-height: 1.6em; /* 22.4px */
`;

const InputContainer = styled.div`
  width: 480px;

  & > div {
    /* color: #000;
    
    font-size: 14px;
    
    font-weight: 500;
    line-height: 100%; */
    width: 100%;
    margin-bottom: 10px;
  }

  & > button {
    width: 140px;
    height: 53px;
    margin-left: 20px;
    border-radius: 10px;
    border: 1px solid #2c55fb;

    color: #2c55fb;
    text-align: center;

    /* body/B03-16px-medium */

    font-size: 16px;

    font-weight: 500;
    line-height: 1.3em; /* 20.8px */
  }
`;

const Error = styled.div`
  margin-top: 6px;
  margin-bottom: 12px;
  width: 100px;
  height: 22px;
  color: #ff4752;

  /* body/B04-14px-medium */

  font-size: 14px;

  font-weight: 500;
  line-height: 1.6em; /* 22.4px */
`;

const Input = styled.input<{ bdColor: string }>`
  width: ${(props) => props.width};
  height: 26px;
  padding: 13px 22px;
  border-radius: 10px;
  border: 1px solid ${(props) => props.bdColor};

  ::placeholder {
    color: #b5b5be;

    font-size: 16px;

    font-weight: 400;
    line-height: 1.6em; /* 25.6px */
  }

  :disabled {
    border: none;
  }

  :focus {
    border-radius: 10px;
    border: 1px solid #0062ff;
  }
`;

const InputBox = styled.div`
  position: relative;
`;

const DeleteButton = styled.div<{ right: string }>`
  top: 18px;
  right: ${(props) => props.right};
  position: absolute;
  width: 16px;
  height: 16px;
  background: url(/images/delete.png) no-repeat center;
  background-size: contain;
`;
