import styled from '@emotion/styled';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import {
  SearchCognitiveResponse,
  SearchCognitiveStudentList,
} from '~/types/student/search';

import { getSearchCognitiveStatusData } from '~/api/student/search';

import useAuth from '~/hooks/useAuth';

import CalendarComponent from '~/components/studentManagement/measurement/CalendarComponent';
import Paginate from '~/components/studentManagement/measurement/Paginate';
import StudentManagementSendMail from '~/components/studentManagement/measurement/StudentManagementSendMail/Index';
import StudentManagementSendPush from '~/components/studentManagement/measurement/StudentManagementSendPush/Index';

const ITEMS_LABEL = [
  {
    key: 1,
    label: 'ID',
  },
  {
    key: 2,
    label: '이름',
  },
  {
    key: 3,
    label: '성별',
  },
  {
    key: 4,
    label: '학급정보',
  },
  {
    key: 5,
    label: '측정 결과',
  },
  {
    key: 6,
    label: '측정 일시',
  },
  {
    key: 7,
    label: '측정 횟수',
  },
  {
    key: 8,
    label: '설문조사 여부',
  },
];

interface DropdownState {
  result: boolean;
  status: boolean;
  calendar: null | boolean;
}

export default function Index() {
  const [isData, setIsData] = useState<SearchCognitiveResponse>();
  const [isDate, setIsDate] = useState<Date | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState<DropdownState>({
    result: false,
    status: false,
    calendar: null,
  });
  const [currentPage, setCurrentPage] = useState(0);
  const [activeComponent, setActiveComponent] = useState('');
  const [checkedUserIds, setCheckedUserIds] = useState<
    SearchCognitiveStudentList[]
  >([]);
  const [searchText, setSearchText] = useState('');
  const [filteredUsers, setFilteredUsers] = useState<
    SearchCognitiveStudentList[]
  >([]);
  const [isScreenResult, setIsScreenResult] = useState<string>('');
  const [isScreenStatus, setIsScreenStatus] = useState<string>('');
  const [filtedButtonIndex, setFiltedButtonIndex] = useState<number | null>(
    null,
  );
  const [focusedButtonIndex, setFocusedButtonIndex] = useState<number | null>(
    null,
  );

  const location = useLocation();

  const pathName = location.search;

  useEffect(() => {
    if (pathName.includes('?1')) {
      setIsScreenResult('주의 요망');
      setIsDropdownOpen((prevState) => ({
        ...prevState,
        result: false,
      }));
      setFocusedButtonIndex(5);
    } else if (pathName.includes('?2')) {
      setIsScreenResult('관심 필요');
      setIsDropdownOpen((prevState) => ({
        ...prevState,
        result: false,
      }));
      setFocusedButtonIndex(6);
    }
  }, []);

  const handleModalClick = (id: string) => {
    setActiveComponent(id);
  };

  const { user } = useAuth();

  const dateFilter = (value?: string | Date | null) => {
    const GMT = moment().format('Z');

    const [gmtSign, gmtValue] = GMT.split(':');
    const gmtHours = parseInt(gmtSign);
    const gmtMinutes = parseInt(gmtValue);

    const countryTime = moment
      .utc(value)
      .utcOffset(gmtHours * 60 + gmtMinutes)
      .format('YYYY-MM-DD HH:mm:ss');
    return countryTime;
  };

  const date = dateFilter(isDate);
  const dateCheck = date === 'Invalid date' ? null : date;

  const calendarDateFilter = (value: string | Date | null) => {
    const GMT = moment().format('Z');
    const [gmtSign, gmtValue] = GMT.split(':');
    const gmtHours = parseInt(gmtSign);
    const gmtMinutes = parseInt(gmtValue);

    const selectedDate = moment
      .utc(value)
      .utcOffset(gmtHours * 60 + gmtMinutes);

    moment.updateLocale('en', {
      week: {
        dow: 1,
      },
    });

    const startDate = selectedDate.startOf('isoWeek').format('MM.DD');
    const endDate = selectedDate.endOf('isoWeek').format('MM.DD');
    const year = selectedDate.format('YYYY');
    const month = selectedDate.format('M');

    const startOfMonth = selectedDate.clone().startOf('month');
    const weekNumber = Math.ceil(
      selectedDate.diff(startOfMonth, 'weeks', true),
    );

    return `${year}년 ${month}월 ${weekNumber}주차 (${startDate}~${endDate})`;
  };

  useEffect(() => {
    if (!user) {
      return;
    }
    getSearchCognitiveStatusData({
      userId: user.userId,
      searchDate: dateCheck,
    })
      .then(({ data }) => {
        setIsData(data);
        setIsLoading(true);
      })
      .catch(console.error);
  }, [date]);

  const handleDropdown = (dropdownName: keyof DropdownState) => {
    setIsDropdownOpen((prevState) => ({
      ...prevState,
      [dropdownName]: !prevState[dropdownName],
    }));
  };

  const moveComponent = (id: string) => {
    setActiveComponent(id);
  };

  const findUserById = (
    userId: number,
  ): SearchCognitiveStudentList | undefined => {
    return isData?.data?.students?.find((user) => user.userId === userId);
  };

  const handleSelectAllChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const isChecked = event.target.checked;

    if (isChecked) {
      setCheckedUserIds(filteredUsers);
    } else {
      setCheckedUserIds([]);
    }
  };

  const handleUserCheckboxChange =
    (userId: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const isChecked = event.target.checked;

      if (isChecked) {
        const userToAdd = findUserById(userId);
        if (userToAdd) {
          setCheckedUserIds((prevUserIds) => [...prevUserIds, userToAdd]);
        }
      } else {
        setCheckedUserIds((prevUserIds) =>
          prevUserIds.filter((user) => user.userId !== userId),
        );
      }
    };

  const handleAllCheck = () => {
    setCheckedUserIds([]);
  };

  const applyFilters = () => {
    if (!isData?.data?.students) {
      return [];
    }

    const resultFilteredData = isScreenResult
      ? isData.data.students.filter(
          (item) => item.screenResult === isScreenResult,
        )
      : isData.data.students;

    const statusFilteredData = isScreenStatus
      ? resultFilteredData.filter(
          (item) => item.screenStatus === isScreenStatus,
        )
      : resultFilteredData;

    const searchTextFilteredData = searchText
      ? statusFilteredData.filter(
          (item) =>
            item.name.toLowerCase().includes(searchText.toLowerCase()) ||
            item.loginId.toLowerCase().includes(searchText.toLowerCase()),
        )
      : statusFilteredData;

    return searchTextFilteredData;
  };

  useEffect(() => {
    const filteredData = applyFilters();
    setFilteredUsers(filteredData);
    handleAllCheck();
  }, [searchText, isData, date, isScreenResult, isScreenStatus]);

  const handleCalendarReset = () => {
    setIsDate(null);
    handleAllCheck();
  };

  const handleResultReset = (result: string) => {
    setCurrentPage(0);
    if (result === '') {
      setIsScreenResult('');
      setIsDropdownOpen((prevState) => ({
        ...prevState,
        result: false,
      }));
      setFocusedButtonIndex(null);
    } else if (result === '주의 요망') {
      setIsScreenResult('주의 요망');
      setIsDropdownOpen((prevState) => ({
        ...prevState,
        result: false,
      }));
      setFocusedButtonIndex(5);
    } else if (result === '관심 필요') {
      setIsScreenResult('관심 필요');
      setIsDropdownOpen((prevState) => ({
        ...prevState,
        result: false,
      }));
      setFocusedButtonIndex(6);
    } else {
      setIsScreenResult('양호함');
      setIsDropdownOpen((prevState) => ({
        ...prevState,
        result: false,
      }));
      setFocusedButtonIndex(7);
    }
    handleAllCheck();
  };

  const handleStatusReset = (status: string) => {
    setCurrentPage(0);
    if (status === '') {
      setIsScreenStatus('');
      setIsDropdownOpen((prevState) => ({
        ...prevState,
        status: false,
      }));
      setFiltedButtonIndex(null);
    } else if (status === '설문조사 미완료') {
      setIsScreenStatus('설문조사 미완료');
      setIsDropdownOpen((prevState) => ({
        ...prevState,
        status: false,
      }));
      setFiltedButtonIndex(2);
    } else {
      setIsScreenStatus('아동 검사 미완료');
      setIsDropdownOpen((prevState) => ({
        ...prevState,
        status: false,
      }));
      setFiltedButtonIndex(3);
    }
    handleAllCheck();
  };

  return (
    <>
      {isLoading && (
        <>
          {isData?.data?.students !== null ? (
            <>
              <SearchWrapper>
                <InputContainer>
                  <SearchInput
                    name="filter"
                    type="text"
                    placeholder="찾을 학생의 ID 또는 이름을 입력해주세요."
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    bdColor={searchText.length <= 0 ? '#e2e2ea;' : '#0062FF'}
                  />
                  <InputImg
                    url={
                      searchText.length <= 0
                        ? '/images/search.png'
                        : '/images/blueDelete.png'
                    }
                    onClick={() => setSearchText('')}
                  />
                </InputContainer>
                <ButtonConatiner>
                  <Button
                    color={isScreenStatus === '' ? '#000' : '#0062FF'}
                    bdColor={isScreenStatus === '' ? '#e2e2ea;' : '#0062FF'}
                    width="170px"
                    onClick={() => handleDropdown('status')}
                  >
                    {isScreenStatus === '' ? (
                      <div>측정 진행 상태</div>
                    ) : isScreenStatus === '설문조사 미완료' ? (
                      <div>설문 미완료</div>
                    ) : (
                      <div>설문 완료</div>
                    )}
                  </Button>
                  <ButtonImg
                    right="525px"
                    url={
                      isScreenStatus === ''
                        ? '/images/reportDropdown.png'
                        : '/images/blueDelete.png'
                    }
                    onClick={() => handleStatusReset('')}
                  />
                  {isDropdownOpen.status && (
                    <DropDown width="170px" right="510px">
                      <button
                        onClick={() => handleStatusReset('')}
                        onFocus={() => setFiltedButtonIndex(0)}
                        onBlur={() => setFiltedButtonIndex(null)}
                        style={{
                          backgroundColor:
                            filtedButtonIndex === 0 ? '#0062FF' : '#fff',
                          color: filtedButtonIndex === 0 ? '#fff' : '#000',
                        }}
                      >
                        전체
                      </button>
                      <button
                        onClick={() => handleStatusReset('설문조사 미완료')}
                        onFocus={() => setFiltedButtonIndex(2)}
                        onBlur={() => setFiltedButtonIndex(null)}
                        style={{
                          backgroundColor:
                            filtedButtonIndex === 2 ? '#0062FF' : '#fff',
                          color: filtedButtonIndex === 2 ? '#fff' : '#000',
                        }}
                      >
                        설문 미완료
                      </button>
                      <button
                        onClick={() => handleStatusReset('아동 검사 미완료')}
                        onFocus={() => setFiltedButtonIndex(3)}
                        onBlur={() => setFiltedButtonIndex(null)}
                        style={{
                          backgroundColor:
                            filtedButtonIndex === 3 ? '#0062FF' : '#fff',
                          color: filtedButtonIndex === 3 ? '#fff' : '#000',
                        }}
                      >
                        설문 완료
                      </button>
                    </DropDown>
                  )}
                  <Button
                    color={isScreenResult === '' ? '#000' : '#0062FF'}
                    bdColor={isScreenResult === '' ? '#e2e2ea;' : '#0062FF'}
                    width="170px"
                    onClick={() => handleDropdown('result')}
                  >
                    {isScreenResult === '' ? (
                      <div>측정 결과</div>
                    ) : isScreenResult === '주의 요망' ? (
                      <div>주의 단계</div>
                    ) : isScreenResult === '관심 필요' ? (
                      <div>관심 단계</div>
                    ) : (
                      <div>양호 단계</div>
                    )}
                  </Button>
                  <ButtonImg
                    right="345px"
                    url={
                      isScreenResult === ''
                        ? '/images/reportDropdown.png'
                        : '/images/blueDelete.png'
                    }
                    onClick={() => handleResultReset('')}
                  />
                  {isDropdownOpen.result && (
                    <DropDown width="170px" right="330px">
                      <button
                        onClick={() => handleResultReset('')}
                        onFocus={() => setFocusedButtonIndex(4)}
                        onBlur={() => setFocusedButtonIndex(null)}
                        style={{
                          backgroundColor:
                            focusedButtonIndex === 4 ? '#0062FF' : '#fff',
                          color: focusedButtonIndex === 4 ? '#fff' : '#000',
                        }}
                      >
                        전체
                      </button>
                      <button
                        onClick={() => handleResultReset('주의 요망')}
                        onFocus={() => setFocusedButtonIndex(5)}
                        onBlur={() => setFocusedButtonIndex(null)}
                        style={{
                          backgroundColor:
                            focusedButtonIndex === 5 ? '#0062FF' : '#fff',
                          color: focusedButtonIndex === 5 ? '#fff' : '#000',
                        }}
                      >
                        주의 단계
                      </button>
                      <button
                        onClick={() => handleResultReset('관심 필요')}
                        onFocus={() => setFocusedButtonIndex(6)}
                        onBlur={() => setFocusedButtonIndex(null)}
                        style={{
                          backgroundColor:
                            focusedButtonIndex === 6 ? '#0062FF' : '#fff',
                          color: focusedButtonIndex === 6 ? '#fff' : '#000',
                        }}
                      >
                        관심 단계
                      </button>
                      <button
                        onClick={() => handleResultReset('양호함')}
                        onFocus={() => setFocusedButtonIndex(7)}
                        onBlur={() => setFocusedButtonIndex(null)}
                        style={{
                          backgroundColor:
                            focusedButtonIndex === 7 ? '#0062FF' : '#fff',
                          color: focusedButtonIndex === 7 ? '#fff' : '#000',
                        }}
                      >
                        양호 단계
                      </button>
                    </DropDown>
                  )}
                  <Button
                    bdColor={dateCheck === null ? '#e2e2ea;' : '#0062FF'}
                    width="320px"
                    onClick={() => handleDropdown('calendar')}
                  >
                    <div>
                      {isDate === null
                        ? '측정 일시'
                        : calendarDateFilter(isDate)}
                    </div>
                  </Button>
                  <ButtonImg
                    onClick={handleCalendarReset}
                    right="16px"
                    url={
                      dateCheck === null
                        ? '/images/calendar.png'
                        : '/images/blueDelete.png'
                    }
                  />
                  {isDropdownOpen.calendar && (
                    <CalendarComponent
                      isDate={isDate}
                      setIsDate={setIsDate}
                      setIsDropdownOpen={setIsDropdownOpen}
                    />
                  )}
                </ButtonConatiner>
              </SearchWrapper>
              <ItemsLabel>
                {ITEMS_LABEL.map((item) => (
                  <div key={item.key}>
                    {item.label === 'ID' && (
                      <>
                        <input
                          type="checkbox"
                          name="all"
                          checked={
                            checkedUserIds.length === filteredUsers.length &&
                            checkedUserIds.length > 0 &&
                            filteredUsers.length > 0
                          }
                          onChange={handleSelectAllChange}
                        />
                      </>
                    )}
                    {item.label}
                  </div>
                ))}
                {checkedUserIds.length !== 0 && (
                  <SendModal>
                    <SendModalTitle>
                      선택한 {checkedUserIds.length}명의 학생에게
                    </SendModalTitle>
                    <SendModalButtonContainer>
                      <SendModalButton
                        onClick={() =>
                          moveComponent('StudentManagementSendMail')
                        }
                      >
                        <SendModalButtonImg url="/images/whiteMail.png" />
                        <div>우편 보내기</div>
                      </SendModalButton>
                      <SendModalButton
                        onClick={() =>
                          moveComponent('StudentManagementSendPush')
                        }
                      >
                        <SendModalButtonImg url="/images/whitePush.png" />
                        <div>푸시 보내기</div>
                      </SendModalButton>
                    </SendModalButtonContainer>
                    <button onClick={handleAllCheck}>
                      <span>선택 취소</span>
                    </button>
                  </SendModal>
                )}
              </ItemsLabel>
              <Paginate
                itemsPerPage={7}
                handleUserCheckboxChange={handleUserCheckboxChange}
                checkedUserIds={checkedUserIds}
                moveComponent={moveComponent}
                setCheckedUserIds={setCheckedUserIds}
                filteredUsers={filteredUsers}
                setActiveComponent={setActiveComponent}
                activeComponent={activeComponent}
                handleModalClick={handleModalClick}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </>
          ) : (
            <NoDataContainer>
              <div>
                <NodataImg></NodataImg>
                <h1>관리 학생이 없습니다.</h1>
                <h2>새로운 학생을 등록 또는 연결해보세요.</h2>
              </div>
            </NoDataContainer>
          )}
        </>
      )}
      {activeComponent === 'StudentManagementSendPush' && (
        <StudentManagementSendPush
          data={checkedUserIds}
          setActiveComponent={setActiveComponent}
        />
      )}
      {activeComponent === 'StudentManagementSendMail' && (
        <StudentManagementSendMail
          setActiveComponent={setActiveComponent}
          childInfo={checkedUserIds}
        />
      )}
    </>
  );
}

const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-bottom: 24px;
`;

const InputContainer = styled.div`
  position: relative;
  font-size: 14px;

  font-weight: 500;
`;

const SearchInput = styled.input<{ bdColor: string }>`
  width: 814px;
  height: 18px;
  padding: 15px 19px;
  border-radius: 10px;
  border: 1px solid ${(props) => props.bdColor};

  ::placeholder {
    color: #8c9299;
    font-size: 14px;

    font-weight: 500;
  }
`;

const InputImg = styled.div<{ url: string }>`
  position: absolute;
  top: 16.32px;
  right: 20px;
  width: 17px;
  height: 17px;
  background: url(${(props) => props.url}) no-repeat center;
  background-size: cover;
`;

const ButtonConatiner = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

const ItemsLabel = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 56px;
  border-radius: 10px;
  background: #fafafa;

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    font-size: 14px;

    font-weight: 400;
    height: 56px;

    & > input {
      width: 16px;
      height: 16px;
      margin-left: 16px;
      margin-right: 10px;
      margin-bottom: 5px;
    }

    :nth-of-type(1) {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 200px;
    }
    :nth-of-type(2) {
      width: 100px;
    }
    :nth-of-type(3) {
      width: 100px;
    }
    :nth-of-type(4) {
      width: 160px;
    }
    :nth-of-type(5) {
      width: 140px;
    }
    :nth-of-type(6) {
      width: 180px;
    }
    :nth-of-type(7) {
      width: 100px;
    }
    :nth-of-type(8) {
      width: 180px;
    }
  }
`;

const Button = styled.button<{ width: string; bdColor: string }>`
  display: flex;
  align-items: center;
  width: ${(props) => props.width};
  height: 50px;
  padding: 16px;
  border-radius: 10px;
  color: ${(props) => props.color};
  border: 1px solid ${(props) => props.bdColor};
`;

const ButtonImg = styled.div<{ right: string; url: string }>`
  position: absolute;
  top: 15px;
  right: ${(props) => props.right};
  width: 20px;
  height: 20px;
  background: url(${(props) => props.url}) no-repeat center;
  background-size: center;
`;

const DropDown = styled.div<{ right: string; width: string }>`
  position: absolute;
  background-color: #fff;
  border-radius: 10px;
  width: ${(props) => props.width};
  top: 58px;
  right: ${(props) => props.right};
  box-shadow: 0px 5px 40px 0px rgba(0, 0, 0, 0.1);
  z-index: 2;

  & > button {
    width: 170px;
    height: 50px;
    padding: 14px 26px;
    border-bottom: 1px solid #f0f6ff;
    text-align: left;
    :nth-last-of-type(1) {
      border: none;
    }
  }
`;

const SendModal = styled.div`
  position: absolute;
  top: 490px;
  left: 402.5px;
  height: 33px;
  padding: 18px 100px;
  border-radius: 100px;
  background: #fff;

  /* shadow */
  box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.08);

  button {
    span {
      margin-left: 40px;
      text-decoration: underline;

      color: #0062ff;

      font-size: 18px;

      font-weight: 400;
      line-height: 1.3em; /* 23.4px */
      text-decoration-line: underline;
    }
  }
`;

const SendModalTitle = styled.div`
  margin-right: 40px;
  color: #000;

  /* body/B02-18px-bold */

  font-size: 18px;

  font-weight: 700;
  line-height: 1.3em; /* 23.4px */
`;

const SendModalButtonContainer = styled.div`
  display: flex;
`;

const SendModalButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 112px;
  height: 34px;
  border-radius: 6px;
  background: #0062ff;
  color: #fff;

  font-size: 14px;

  font-weight: 700;
  letter-spacing: -0.3px;

  :nth-of-type(1) {
    margin-right: 15px;
  }

  div {
    margin-right: 4px;
  }
`;

const SendModalButtonImg = styled.div<{ url: string }>`
  width: 13px;
  height: 10px;
  background: url(${(props) => props.url}) no-repeat center;
  background-size: center;
`;

const NoDataContainer = styled.div`
  display: flex;
  padding: 169px 538px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  & > div {
    width: 278px;
    height: 278px;
    border-radius: 278px;
    background: linear-gradient(
      180deg,
      rgba(52, 103, 255, 0.08) 0%,
      rgba(84, 81, 255, 0) 100%
    );
  }

  h1 {
    margin-bottom: 15px;
    color: #65696d;
    text-align: center;

    /* heading/H02-20px-bold */

    font-size: 20px;

    font-weight: 700;
    line-height: 1.6em; /* 32px */
    letter-spacing: -0.3px;
  }

  h2 {
    margin-bottom: 55px;
    color: #65696d;
    text-align: center;

    /* body/B02-18px-medium */

    font-size: 18px;

    font-weight: 500;
    line-height: 1.6em; /* 28.8px */
  }

  button {
    width: 300px;
    height: 56px;
    background-color: #0062ff;
    color: #fff;
    border-radius: 10px;
  }
`;

const NodataImg = styled.div`
  width: 130px;
  height: 89px;
  margin-top: 84px;
  margin-left: 65px;
  margin-bottom: 24px;
  background: url('/images/studentNodataImg.png') no-repeat center;
  background-size: cover;
`;
