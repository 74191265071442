import { AxiosResponse } from 'axios';

import { ReceiveMailParams, ReceiveMailResponse } from '~/types/post/receive';

import api from '~/api/base';

const BASE_URL = 'api/v1/web/post/receive';

export const getReceiveMailStatusData = (
  param: ReceiveMailParams,
): Promise<AxiosResponse<ReceiveMailResponse>> =>
  api.post(`${BASE_URL}/list`, param);
