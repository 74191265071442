import { AxiosResponse } from 'axios';

import { PostSendRequestParams, PostSendResponse } from '~/types/post/send';

import api from '~/api/base';

const BASE_URL = 'api/v1/web/post/send';

export const getPostSendStatusData = (
  param: PostSendRequestParams,
): Promise<AxiosResponse<PostSendResponse>> => api.post(`${BASE_URL}`, param);
