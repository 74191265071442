import styled from '@emotion/styled';
import { useEffect, useState } from 'react';

import { NonConnectsList, NonConnectsResponse } from '~/types/student/non';

import Modal from '~/utils/portal/components/Modal';

import {
  getNonConnectsCancelStatusData,
  getNonConnectsStatusData,
} from '~/api/student/non';

import useAuth from '~/hooks/useAuth';

import CreateStudentList from '~/components/studentManagement/createStudent/CreateStudentList';
import CreateStudentListModal from '~/components/studentManagement/createStudent/CreateStudentListModal';

const Index = () => {
  const [modalComponent, setModalComponent] = useState('');
  const [isData, setIsData] = useState<NonConnectsResponse>();
  const [isLoading, setIsLoading] = useState(false);
  const [isDate, setIsDate] = useState<Date>(new Date());
  const [checkedChildIds, setCheckedChildIds] = useState<NonConnectsList[]>([]);
  const [allCheck, setAllCheck] = useState(false);
  const [modalText, setModalText] = useState('');

  const { user } = useAuth();

  const handleModalClick = (id: string) => {
    if (checkedChildIds.length === 0) {
      setModalText('NonCheck');
    } else {
      setModalComponent(id);
    }
  };

  const findUserById = (childId: number): NonConnectsList | undefined => {
    return isData?.data?.studentList?.find((user) => user.childId === childId);
  };

  const handleSelectAllChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const isChecked = event.target.checked;
    setAllCheck(isChecked);

    if (isChecked) {
      const updatedChildId: NonConnectsList[] = isData?.data?.studentList || [];
      setCheckedChildIds(updatedChildId);
    } else {
      setCheckedChildIds([]);
    }
  };

  const handleUserCheckboxChange =
    (childId: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const isChecked = event.target.checked;

      if (isChecked) {
        const userToAdd = findUserById(childId);
        if (userToAdd) {
          setCheckedChildIds((prevChildId) => [...prevChildId, userToAdd]);
        }
      } else {
        setCheckedChildIds((prevChildId) =>
          prevChildId.filter((user) => user.childId !== childId),
        );
      }

      const allChecked =
        checkedChildIds.length === (isData?.data?.studentList?.length || 0);
      setAllCheck(allChecked);
    };

  useEffect(() => {
    if (isData?.data?.studentList.length === 0) {
      setAllCheck(false);
    } else {
      const allChecked =
        checkedChildIds.length === (isData?.data?.studentList?.length || 0);
      setAllCheck(allChecked);
    }
  }, [checkedChildIds, isData?.data?.studentList]);

  const handleAllCheck = () => {
    setCheckedChildIds([]);
  };

  const handleChildIdFilted = () => {
    const childIds = checkedChildIds.map((user) => user.childId);

    return childIds;
  };

  const handleApiSendButton = () => {
    if (!user) {
      return;
    }

    const childIdsToCancel = handleChildIdFilted();
    getNonConnectsCancelStatusData({
      children: childIdsToCancel,
      licenseId: Number(user.licenseId),
      userId: user.userId,
    })
      .then(() => {
        setModalText('Delete');
        setModalComponent('');
      })
      .catch(console.error);
  };

  useEffect(() => {
    if (!user) {
      return;
    }
    getNonConnectsStatusData({
      managerId: user?.userId,
    })
      .then(({ data }) => {
        setIsData(data);
        setIsLoading(true);
      })
      .catch(console.error);
  }, [isDate, modalText]);

  const handleDeleteStudent = () => {
    setModalText('');
    window.location.reload();
  };

  return (
    <Wrapper>
      <TitleContainer>
        <div>생성한 학생 목록</div>
        {isLoading && (
          <>
            {isData?.data?.studentList?.length !== 0 ? (
              <div>
                <TitleButton
                  bgColor="#0062ff"
                  color="#fff"
                  onClick={() => handleModalClick('CreateStudentListModal')}
                >
                  선택한 학생 삭제하기
                </TitleButton>
              </div>
            ) : (
              <div></div>
            )}
          </>
        )}
      </TitleContainer>
      <CreateStudentList
        isData={isData}
        isDate={isDate}
        isLoading={isLoading}
        setIsDate={setIsDate}
        handleUserCheckboxChange={handleUserCheckboxChange}
        checkedChildIds={checkedChildIds}
        handleAllCheck={handleAllCheck}
        allCheck={allCheck}
        handleSelectAllChange={handleSelectAllChange}
      />
      {modalComponent === 'CreateStudentListModal' && (
        <CreateStudentListModal
          handleApiSendButton={handleApiSendButton}
          setModalComponent={setModalComponent}
          isData={isData}
          handleUserCheckboxChange={handleUserCheckboxChange}
          checkedChildIds={checkedChildIds}
          handleAllCheck={handleAllCheck}
          allCheck={allCheck}
          handleSelectAllChange={handleSelectAllChange}
        />
      )}
      {modalText === 'NonCheck' && (
        <Modal
          title={'선택한 요청이 없습니다.'}
          onClose={() => setModalText('')}
        />
      )}
      {modalText === 'Delete' && (
        <Modal title={'삭제를 완료 했습니다.'} onClose={handleDeleteStudent} />
      )}
    </Wrapper>
  );
};
export default Index;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 1544px;
  height: 786px;
  margin: 20px 30px 30px 30px;
  padding: 20px 24px 24px 24px;
  border-radius: 12px;
  background: #fff;
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;

  & > div {
    color: #000;
    font-size: 24px;

    font-weight: 700;
    line-height: 1.6em;
    display: flex;
  }
`;

const TitleButton = styled.button<{ bgColor: string }>`
  display: flex;
  align-items: center;
  padding: 15px 25px;
  gap: 10px;
  border-radius: 10px;
  border: 1px solid #0062ff;
  color: ${(props) => props.color};
  background-color: ${(props) => props.bgColor};
  text-align: center;
  font-size: 16px;

  font-weight: 500;
`;
