import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

export interface ManagerData {
  addAuth: string;
  authLevel: string;
  authority: string;
  editDate: null | string;
  licenseId: string;
  managerId: string;
  name: string;
  regDate: string;
  userId: number;
  agencyName: string;
}

const { persistAtom } = recoilPersist({
  key: 'persist',
  storage: localStorage,
});

export const managerDataAtom = atom<ManagerData | null>({
  key: 'managerData',
  default: null,
  effects_UNSTABLE: [persistAtom],
});

export const loginIdAtom = atom<null | string>({
  key: 'EmotivId',
  default: null,
  effects_UNSTABLE: [persistAtom],
});
