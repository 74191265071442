import styled from '@emotion/styled';
import moment from 'moment';
import { useEffect, useState } from 'react';

import { DashService } from '~/types/dash/service';

import { getDashServiceStatusData } from '~/api/dash/service';

import ServiceCalendar from '~/components/dashboard/ServiceCalendar';

const SERVICE_ITEM = [
  { key: 1, label: '우편 발송' },
  { key: 2, label: '푸시 발송' },
  { key: 3, label: '설문조사 작성' },
  { key: 4, label: '새 아동 연결' },
];

interface Props {
  userId?: number;
}

export default function Service({ userId }: Props) {
  const [isServiceData, setIsServiceData] = useState<DashService>();
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(
    new Date(),
  );

  const dateFilter = (value?: string | Date | null) => {
    const countryTime = value
      ? moment(value).format('YYYY-MM-DD HH:mm:ss')
      : '';
    return countryTime;
  };

  const formattedDate = selectedDate
    ? moment(selectedDate).format('YYYY년 MM월 DD일')
    : '';

  useEffect(() => {
    if (!userId) {
      return;
    }

    getDashServiceStatusData({
      userId,
      searchDate: dateFilter(selectedDate),
    })
      .then(({ data }) => {
        setIsServiceData(data.data);
      })
      .catch(console.error);
  }, [userId, selectedDate]);

  return (
    <Wrapper>
      <Title>서비스 이용 현황</Title>
      <Container>
        <ServiceCalendar
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
        />
        <Border />
        <ItemsContainer>
          <ServiceDate>{formattedDate}</ServiceDate>
          {SERVICE_ITEM.map((item) => (
            <Items key={item.key}>
              <ItemsImg
                url={
                  item.key === 1
                    ? '/images/Mail.png'
                    : item.key === 2
                    ? '/images/new.png'
                    : item.key === 3
                    ? '/images/doc.png'
                    : '/images/student.png'
                }
              />
              <TextContainer>
                <h1>{item.label}</h1>
                <p>
                  {item.key === 1
                    ? isServiceData?.service.postCount
                    : item.key === 2
                    ? isServiceData?.service.pushCount
                    : item.key === 3
                    ? isServiceData?.service.surveyCount
                    : isServiceData?.service.connectNewStudentCount}
                  건
                </p>
              </TextContainer>
            </Items>
          ))}
        </ItemsContainer>
      </Container>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 721px;
  height: 400px;
  margin-bottom: 30px;
  padding: 24px 30px;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.08);
`;

const Title = styled.div`
  margin-bottom: 12px;

  color: #000;

  /* heading/H02-20px-bold */
  font-size: 20px;

  font-weight: 700;
  line-height: 1.6em; /* 32px */
  letter-spacing: -0.3px;
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
`;

const Border = styled.div`
  margin: 0 40px;
  border-left: 1px solid #e2e2ea;
`;

const ServiceDate = styled.div`
  margin-left: 12px;
  margin-bottom: 18px;

  color: #000;

  /* body/B03-16px-bold */

  font-size: 16px;

  font-weight: 700;
  line-height: 1.3em; /* 20.8px */
`;

const ItemsContainer = styled.div``;

const Items = styled.div`
  display: flex;
  align-items: center;
  width: 230px;
  height: 38.3px;
  padding: 8px 25px;
  border-radius: 12px;
  background: #f0f6ff;
  margin-bottom: 25px;

  h1 {
    color: #0062ff;
    /* body/B04-14px_bold */

    font-size: 14px;

    font-weight: 700;
  }

  p {
    color: #5a88ff;
    /* body/B05-14px-regular */

    font-size: 14px;

    font-weight: 400;
  }
`;

const ItemsImg = styled.div<{ url: string }>`
  width: 20px;
  height: 20px;
  margin-right: 10px;
  background: url(${(props) => props.url}) no-repeat center;
  background-size: contain;
`;

const TextContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
