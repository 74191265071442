import { AxiosResponse } from 'axios';

import {
  ActiveLogParams,
  ActiveLogResponse,
} from '~/types/activeLog/activeLog';

import api from '~/api/base';

const BASE_URL = 'api/v1/activity/search';

export const getActiveLogStatusData = (
  param: ActiveLogParams,
): Promise<AxiosResponse<ActiveLogResponse>> =>
  api.post(`${BASE_URL}/activities`, param);
// asdß
