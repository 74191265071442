import { AxiosResponse } from 'axios';

import {
  CheckPasswordRequestParams,
  CheckPasswordResponse,
} from '~/types/manager/check';

import api from '~/api/base';

const BASE_URL = 'api/v1/manager/check';

export const getCheckPasswordStatusData = (
  param: CheckPasswordRequestParams,
): Promise<AxiosResponse<CheckPasswordResponse>> =>
  api.post(`${BASE_URL}/password`, param);
