import { AxiosResponse } from 'axios';

import { DashDetailParams, DashDetailResponse } from '~/types/dash/detail';

import api from '~/api/base';

const BASE_URL = 'api/v1/dash/find';

export const getDashDetailStatusData = (
  param: DashDetailParams,
): Promise<AxiosResponse<DashDetailResponse>> =>
  api.post(`${BASE_URL}/detail`, param);
