import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { ChangeEvent, useState } from 'react';
import ReactPaginate from 'react-paginate';

import { ResponseDataList } from '~/types/student/response';

import dateFilter from '~/utils/dateFilter/dateFilter';
import Modal from '~/utils/portal/components/Modal';

import { getResponseStudentsRelationStatusData } from '~/api/student/response';

import useAuth from '~/hooks/useAuth';

interface PostsProps {
  itemsPerPage: number;
  isData?: ResponseDataList[];
  handleUserCheckboxChange: (
    sendId: number,
  ) => (event: ChangeEvent<HTMLInputElement>) => void;
  checkedSendId: ResponseDataList[];
}

const StudentReceivedPaginate: React.FC<PostsProps> = ({
  itemsPerPage,
  isData,
  handleUserCheckboxChange,
  checkedSendId,
}) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [buttonStatuses, setButtonStatuses] = useState<{
    [key: number]: string;
  }>({});
  const [isModal, setIsModal] = useState(false);
  const { user } = useAuth();

  const pageCount: number = Math.ceil((isData?.length || 0) / itemsPerPage);

  const handlePageChange = (selectedItem: { selected: number }) => {
    setCurrentPage(selectedItem.selected);
  };

  const offset = currentPage * itemsPerPage;
  const currentItems = isData?.slice(offset, offset + itemsPerPage);

  const handleResponseAcceptButton = (sendId: number) => {
    setButtonStatuses((prevStatuses) => ({
      ...prevStatuses,
      [sendId]: '수락',
    }));
  };

  const handleResponseRefuseButton = (sendId: number) => {
    setButtonStatuses((prevStatuses) => ({
      ...prevStatuses,
      [sendId]: '거절',
    }));
  };

  const handleApiSendButton = (sendId: number) => {
    if (!user) {
      return;
    }
    getResponseStudentsRelationStatusData({
      sendIds: [sendId],
      sendStatus: 'C',
      userId: user.userId,
    })
      .then(() => {
        handleResponseRefuseButton(sendId);
      })
      .catch(console.error);
  };

  const handleApiRetrySendButton = (sendId: number) => {
    if (!user) {
      return;
    }
    getResponseStudentsRelationStatusData({
      sendIds: [sendId],
      sendStatus: 'A',
      userId: user.userId,
    })
      .then(({ data }) => {
        if (data.status === 17015) {
          setIsModal(true);
          return;
        }
        handleResponseAcceptButton(sendId);
      })
      .catch(console.error);
  };

  const handleCancleResult = () => {
    setIsModal(false);
    window.location.reload();
  };

  return (
    <>
      <ItemsWrapper>
        {currentItems?.map((item) => {
          const isChecked = checkedSendId.some(
            (user) => user.sendId === item.sendId,
          );
          return (
            <Items key={item.sendId} bgColor={isChecked ? '#f0f6ff' : '#fff'}>
              <ItemsInput>
                <Input
                  type="checkbox"
                  name="sendId"
                  checked={checkedSendId.some(
                    (user) => user.sendId === item.sendId,
                  )}
                  onChange={handleUserCheckboxChange(item.sendId)}
                />
                <div>{item.loginId}</div>
              </ItemsInput>
              <div>{item.name}</div>
              {item.gender === 'F' ? (
                <div>
                  <Gender>
                    <GenderImg url="/images/girl.png" />
                  </Gender>
                </div>
              ) : item.gender === 'M' ? (
                <div>
                  <Gender>
                    <GenderImg url="/images/boy.png" />
                  </Gender>
                </div>
              ) : (
                <div>-</div>
              )}
              <div>{item.classInfo}</div>
              <div>
                <ScreenResult
                  color={
                    item.screenResult === '양호함'
                      ? '#0CAF60'
                      : item.screenResult === '관심 필요'
                      ? '#F0A95A'
                      : item.screenResult === '주의 요망'
                      ? '#FF4752'
                      : ''
                  }
                  bgColor={
                    item.screenResult === '양호함'
                      ? '#EFFCF6'
                      : item.screenResult === '관심 필요'
                      ? '#FFFBEC'
                      : item.screenResult === '주의 요망'
                      ? '#FFF2F3'
                      : ''
                  }
                >
                  {item.screenResult === null ? (
                    <span>-</span>
                  ) : (
                    item.screenResult
                  )}
                </ScreenResult>
              </div>
              {item.screenDate !== null ? (
                <div>{dateFilter(item.screenDate)}</div>
              ) : (
                <div>-</div>
              )}
              <div>{item.screenCount}회</div>
              <div>{dateFilter(item.joinDate)}</div>
              <div>
                {buttonStatuses[item.sendId] === undefined ? (
                  <>
                    <Button
                      bdColor="#0062ff"
                      color="#0062ff"
                      bgColor="#fff"
                      onClick={() => handleApiRetrySendButton(item.sendId)}
                    >
                      <ButtonImg url="/images/blueCheck.png" />
                      수락하기
                    </Button>
                    <Button
                      bdColor="#65696D"
                      color="#65696D"
                      bgColor="#fff"
                      onClick={() => handleApiSendButton(item.sendId)}
                    >
                      <ButtonImg url="/images/delete.png" />
                      거절하기
                    </Button>
                  </>
                ) : buttonStatuses[item.sendId] === '수락' ? (
                  <ButtonStatus bgColor="#F0F6FF" color="#0062FF">
                    <ButtonImg url="/images/blueCheck.png" />
                    <p>수락을 완료했습니다.</p>
                  </ButtonStatus>
                ) : (
                  <ButtonStatus bgColor="#FFF2F3" color="#FF4752">
                    <ButtonImg url="/images/redDelete.png" />
                    <p>요청을 거절했습니다.</p>
                  </ButtonStatus>
                )}
              </div>
            </Items>
          );
        })}
      </ItemsWrapper>
      <PaginateButton>
        <Paginate
          previousLabel={'<'}
          nextLabel={'>'}
          breakLabel={'...'}
          breakClassName={'break-me'}
          pageCount={pageCount}
          marginPagesDisplayed={1}
          pageRangeDisplayed={4}
          onPageChange={handlePageChange}
          containerClassName={'pagination'}
          activeClassName={'active'}
        />
      </PaginateButton>
      {isModal && (
        <Modal
          title={'이미 취소된 요청입니다.'}
          onClose={handleCancleResult}
        ></Modal>
      )}
    </>
  );
};

export default StudentReceivedPaginate;

const PaginateButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
`;

const Paginate = styled(ReactPaginate)`
  display: flex;
  align-items: center;
  justify-content: center;

  ${css`
    li {
      color: #171819;
      font-size: 12px;

      font-weight: 400;
      line-height: 1.6em;
      cursor: pointer;
    }

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
    }

    li:not(.previous, .next, .break-me):hover {
      color: #2c55fb;
      background: #f0f6ff;
      border-radius: 8px;
    }
    li.active {
      border-radius: 8px;
      color: #2c55fb;
      background: #f0f6ff;
    }

    li.previous {
      border-radius: 8px;
      color: #c7c7c7;
      border: 1px solid #c7c7c7;
      margin-right: 24px;

      &:hover {
        color: #2c55fb;
        border: 1px solid #2c55fb;
      }
    }

    li.next {
      color: #c7c7c7;
      border: 1px solid #c7c7c7;
      border-radius: 8px;
      margin-left: 24px;

      &:hover {
        color: #2c55fb;
        border: 1px solid #2c55fb;
      }
    }
  `}
`;
const ItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 528px;

  color: #292d32;

  font-size: 14px;

  font-weight: 500;
  line-height: 1.6em;
`;

const Items = styled.div<{ bgColor: string }>`
  display: flex;
  border-bottom: 1px solid #f1f2f4;
  height: 63px;
  width: 1544px;
  background: ${(props) => props.bgColor};

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    font-size: 14px;

    font-weight: 400;

    :nth-of-type(1) {
      width: 100px;
    }
    :nth-of-type(2) {
      width: 100px;
    }
    :nth-of-type(3) {
      width: 140px;
    }
    :nth-of-type(4) {
      width: 180px;
    }
    :nth-of-type(5) {
      width: 210px;
    }
    :nth-of-type(6) {
      width: 100px;
    }
    :nth-of-type(7) {
      width: 210px;
    }
    :nth-of-type(8) {
      width: 304px;
    }
  }
`;

const Input = styled.input`
  width: 16px;
  height: 16px;
  margin-left: 16px;
  margin-right: 12px;
`;

const ItemsInput = styled.span`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 200px;
  height: 56px;
`;

const Gender = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 33px;
  height: 33px;
  border-radius: 10px;
  background: #f6f6f6;
`;

const GenderImg = styled.div<{ url: string }>`
  width: 14px;
  height: 20px;
  background: url(${(props) => props.url}) no-repeat center;
  background-size: center;
`;

const Button = styled.button<{ bgColor: string; bdColor: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 34px;
  color: ${(props) => props.color};
  font-size: 14px;

  font-weight: 500;
  border-radius: 6px;
  border: 1px solid ${(props) => props.bdColor};
  background: ${(props) => props.bgColor};

  :nth-of-type(1) {
    margin-right: 15px;
  }

  :nth-of-type(2) {
    margin-right: 15px;
  }

  & > p {
    margin-top: 1px;
    margin-left: 4px;
  }
`;

const ButtonImg = styled.div<{ url: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14px;
  height: 20px;
  margin-right: 4px;
  background: url(${(props) => props.url}) no-repeat center;
  background-size: contain;
`;

const ScreenResult = styled.div<{ bgColor: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 84px;
  height: 30px;
  border-radius: 100px;
  font-size: 14px;

  font-weight: 700;
  color: ${(props) => props.color};
  background: ${(props) => props.bgColor};

  & > span {
    color: #000;
    font-size: 14px;

    font-weight: 400;
  }
`;

const ButtonStatus = styled.div<{ bgColor: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 215px;
  height: 34px;
  border-radius: 6px;
  background: ${(props) => props.bgColor};

  /* body/B04-14px-medium */

  font-size: 14px;

  font-weight: 500;
  line-height: 1.6em; /* 22.4px */

  & > p {
    margin-top: 3px;
    margin-left: 6px;
    color: ${(props) => props.color};
  }
`;
